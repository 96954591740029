/**机构 */
export const ITEM_OBJ = {
  orgName: '',
  uscCode: '',
  relOrgIdList: [],
  relOrgList: [],
  provinceCityTreeValue:[],
  provinceCode: '',
  cityCode: '',
  orgType: 100,
  orgLevel: undefined,
  maintenanceDeadline:undefined,
  aliasList: [
    {
      aliasName: "",
      aliasSource: '',
      id: '',
      isPrimary: 1
    }
  ],
  contactsList: [],
  id: '',
  remark: ''
}
export const CONTACTS_ITEM = {
  department: '',
  email: "",
  id: '',
  isPrimary: 0,
  name: "",
  phoneList: [
    {
      "id": '',
      "phone": ""
    }
  ],
  post: '',
  remark: "",
  wxAccount: "",
  departmentOptions: [],
  postOptions: []
}
export function duplicates(arr, key, isFlat = false) {
  let temp = []
  /* arr.forEach((item, index)=>{
    if(arr.indexOf(item) != arr.lastIndexOf(item) && temp.indexOf(item) == -1){
      temp.push(item)
    }
  }) */
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i][key]
    for (let j = 0; j < arr.length; j++) {
      let count = 0
      let each = arr[j][key]
      if (item === each) {
        count++
        temp.push({
          index: i,
          count,
          value: item,
          indexOuter: arr[i].indexOuter,
          indexInner: arr[i].indexInner
        })
        break
      }
    }
  }
  temp = temp.sort()
  let indexArrOut = []
  for (let i = 0; i < arr.length; i++) {
    let item = arr[i][key]
    let indexArr = []
    temp.find(t => {
      if (t.value === item) {
        if (!isFlat) {
          indexArr.push(t.index)
        } else {
          indexArr.push({
            index: t.index,
            indexOuter: t.indexOuter,
            indexInner: t.indexInner
          })
        }
      }
    })
    const flag = indexArrOut.some(indexItem => {
      if (!isFlat) {
        const isEauql = (indexItem.length === indexArr.length) && (indexItem.sort().toString() === indexArr.sort().toString())
        return isEauql
      } else {
        const isEauql = (indexItem.length === indexArr.length) && (JSON.stringify(indexItem) === JSON.stringify(indexArr))
        return isEauql
      }
    })
    if (!flag) {
      indexArrOut.push(indexArr)
    }
  }
  /* for (let i = 0; i < arr.length; i++) {
    let item = arr[i][key]
    let indexArr = []
    let count = 0
    arr.find((t, index) => {
      if (t[key] === item) {
        count++
        indexArr.push({
          index: i,
          count,
          value: item,
          i: index
        })
      }
    })
    const flag = indexArrOut.some(indexItem => {
      const isEauql = (indexItem.length === indexArr.length) && (indexItem.sort().toString() === indexArr.sort().toString())
      return isEauql
    })
    if (!flag) {
      indexArrOut.push(indexArr)
    }
  } */
  
  let sameArr = indexArrOut.filter(item => item.length > 1)
  let diffArr = indexArrOut.filter(item => item.length <= 1)
  console.log(indexArrOut)
  console.log(sameArr)
  console.log(diffArr)
  const obj = {
    arr: indexArrOut,
    sameArr,
    diffArr
  }
  return obj
}
