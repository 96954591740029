var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.pageLoading,
          expression: "pageLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("任务管理")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticStyle: { float: "right", display: "flex" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v(" 发布任务 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style export-btn",
                              attrs: { size: "small" },
                              on: { click: _vm.onExport },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "icon svg-icon export",
                                  staticStyle: {
                                    width: "16px",
                                    height: "16px",
                                    "margin-right": "6px",
                                  },
                                  attrs: {
                                    "aria-hidden": "true",
                                    fill: "#777777",
                                  },
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-export" },
                                  }),
                                ]
                              ),
                              _vm._v(" 导出任务 "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "block-form-item",
                                attrs: { label: "" },
                              },
                              [
                                _c(
                                  "el-tabs",
                                  {
                                    on: { "tab-click": _vm.tabClick },
                                    model: {
                                      value: _vm.queryParams.tabCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "tabCode",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.tabCode",
                                    },
                                  },
                                  _vm._l(_vm.tabList, function (item) {
                                    return _c("el-tab-pane", {
                                      key: item.id,
                                      attrs: {
                                        label: item.dictLabel,
                                        name: item["id"].toString(),
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "365px" },
                                  attrs: {
                                    size: "small",
                                    maxlength: "30",
                                    clearable: "",
                                    placeholder:
                                      "任务编号/任务标题/客户名称/项目名称/产品名称/提出人",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      multiple: "",
                                      "collapse-tags": "",
                                      placeholder: "请选择省份",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.provinceCodeList,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "provinceCodeList",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "queryParams.provinceCodeList",
                                    },
                                  },
                                  _vm._l(_vm.provinceList, function (item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.name,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    props: _vm.defaultParams,
                                    options: _vm.productTreeDict,
                                    size: "small",
                                    placeholder: "请选择产品名称",
                                    "show-all-levels": false,
                                  },
                                  on: { change: _vm.changeProduct },
                                  model: {
                                    value: _vm.queryParams.productIdArr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "productIdArr",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.productIdArr",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      remote: "",
                                      clearable: "",
                                      placeholder: "指派给",
                                      "remote-method": _vm.assignUserSearch,
                                    },
                                    model: {
                                      value: _vm.queryParams.assignUser,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "assignUser",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.assignUser",
                                    },
                                  },
                                  _vm._l(_vm.userList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nickName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "resetFilterClass",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetSearchValue()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "iconfont" }, [
                                  _vm._v(""),
                                ]),
                              ]
                            ),
                            _vm.isFold
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: { click: _vm.queryParamsFoldClick },
                                  },
                                  [
                                    _c("span", [_vm._v("更多筛选")]),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-caret-bottom el-icon--right",
                                    }),
                                  ]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: { click: _vm.queryParamsFoldClick },
                                  },
                                  [
                                    _c("span", [_vm._v("收起筛选")]),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-caret-top el-icon--right",
                                    }),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.isFold
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "queryForm",
                                staticClass: "query-form",
                                attrs: { model: _vm.queryParams, inline: true },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          size: "small",
                                          filterable: "",
                                          multiple: "",
                                          "collapse-tags": "",
                                          placeholder: "产品状态",
                                          clearable: "",
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleQuery($event)
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.queryParams.productStatusList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "productStatusList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "queryParams.productStatusList",
                                        },
                                      },
                                      _vm._l(
                                        _vm.projectStatusDict,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.dictLabel,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          size: "small",
                                          filterable: "",
                                          placeholder: "服务类型",
                                          clearable: "",
                                        },
                                        nativeOn: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleQuery($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.queryParams.serveType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "serveType",
                                              $$v
                                            )
                                          },
                                          expression: "queryParams.serveType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.taskServeTypeDict,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.dictLabel,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "mainTable",
                  attrs: {
                    height: "100%",
                    data: _vm.tableDataList,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "row-class-name": _vm.tableRowClassName,
                    "default-sort": { prop: "startTime", order: "descending" },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "任务编号", prop: "id", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "紧急状态",
                      prop: "taskUrgencyLevelName",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "任务标题",
                      prop: "taskName",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#F1880D",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCheck(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.taskName))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "终端客户",
                      prop: "customerName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "productName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "服务类型",
                      prop: "serveTypeName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "提出人",
                      prop: "feedbackUser",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.feedbackUser
                                    ? scope.row.feedbackUser.name
                                    : ""
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "指派给",
                      prop: "assignUserName",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "任务状态",
                      prop: "taskStatusName",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                class: _vm.getDictItemCssClass(
                                  _vm.dictConst.TASK_STATUS,
                                  scope.row.taskStatus
                                ),
                              },
                              [_vm._v(" " + _vm._s(scope.row.taskStatusName))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "开始时间",
                      prop: "startTime",
                      width: "130",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.startTime)),
                            _c("span", {
                              staticStyle: { "padding-right": "4px" },
                            }),
                            _vm._v(_vm._s(scope.row.startTimeExt) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "截止时间",
                      prop: "endTime",
                      width: "130",
                      sortable: "custom",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.endTime)),
                            _c("span", {
                              staticStyle: { "padding-right": "4px" },
                            }),
                            _vm._v(_vm._s(scope.row.endTimeExt) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "任务发出人",
                      prop: "createBy",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "130",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.dictDataConst.TASK_STATUS.RESOLVED !=
                            scope.row.taskStatus
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      color: "#ed923a",
                                      "font-size": "12px",
                                    },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDispose(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("去处理 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.createUser ==
                                      _vm.userInfo.userId,
                                    expression:
                                      "scope.row.createUser==userInfo.userId",
                                  },
                                ],
                                staticStyle: {
                                  color: "#ed923a",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.createUser ==
                                        _vm.userInfo.userId &&
                                      _vm.dictDataConst.TASK_STATUS.RESOLVED !==
                                        scope.row.taskStatus,
                                    expression:
                                      "scope.row.createUser==userInfo.userId && dictDataConst.TASK_STATUS.RESOLVED!==scope.row.taskStatus",
                                  },
                                ],
                                staticStyle: {
                                  color: "#ff0009",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#999",
                      "line-height": "40px",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v(" 按住Shift+鼠标滚轮左右滑动列表 ")]
                ),
              ]),
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "div",
                  [
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableDataTotal > 0,
                          expression: "tableDataTotal>0",
                        },
                      ],
                      attrs: {
                        total: _vm.tableDataTotal,
                        page: _vm.queryParams.pageNum,
                        limit: _vm.queryParams.pageSize,
                        "page-sizes": [10, 20, 50, 100],
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageSize", $event)
                        },
                        pagination: _vm.queryList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.editDrawer,
            modal: false,
            "with-header": false,
            wrapperClosable: false,
            "append-to-body": true,
            "before-close": function (done) {
              _vm.closeDrawer(2)
            },
            size: "980px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer_wrap" },
            [
              _c("edit-task", {
                attrs: {
                  "task-id": _vm.editTaskInfo.id,
                  type: _vm.editType,
                  "task-info": _vm.editTaskInfo,
                  visible: _vm.editDrawer,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "closeBtn",
              on: {
                click: function ($event) {
                  return _vm.closeDrawer(2)
                },
              },
            },
            [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
          ),
        ]
      ),
      _vm.dealTaskDialog
        ? _c("deal-task", {
            attrs: {
              "task-info": _vm.editTaskInfo,
              "task-dialog-visible": _vm.dealTaskDialog,
            },
            on: { closeTask: _vm.closeDealTaskDialog },
          })
        : _vm._e(),
      _vm.editCommon
        ? _c("edit-common", {
            attrs: {
              editCommonTitle: _vm.editCommonTitle,
              "is-edit": _vm.isEdit,
              drawer: _vm.editCommon,
              activeNum: _vm.activeNum,
              contractId: _vm.contractId,
              projectId: _vm.projectId,
              titleName: _vm.taskName,
              taskId: _vm.taskId,
              "task-info": _vm.editTaskInfo,
              "task-edit-auth": _vm.taskEditAuth,
              "task-del-auth": _vm.taskDelAuth,
              "task-handle-auth": _vm.taskHandleAuth,
            },
            on: {
              "update:isEdit": function ($event) {
                _vm.isEdit = $event
              },
              "update:is-edit": function ($event) {
                _vm.isEdit = $event
              },
              handleEditCommon: _vm.handleEditCommon,
              dealTask: _vm.dealTask,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }