// 合同管理
import request from '@/modules/crm/utils/request'

// 合同列表查询
export function searchContractList(data) {
  return request({
    url: 'crm/crmContract/contractList',
    method: 'post',
    data: data
  })
}
//合同:删除
export function delContractApi(contractId) {
  return request({
    url: '/crm/crmContract/delContract/' + contractId,
    method: 'post'
  })
}
// 产品树形结构列表
export function productTree() {
  return request({
    url: '/crm/crmProduct/productTree',
    method: 'post'
  })
}
// 机构关联合同列表
export function queryContractList(data) {
  return request({
    url: 'crm/crmContract/queryContractList',
    method: 'post',
    data: data
  })
}

// 合同详细信息
export function contractInfo(contractId) {
  return request({
    url: 'crm/crmContract/contractInfo/' + contractId,
    method: 'post'
  })
}
// 合同:新增
export function addContractApi(data) {
  return request({
    url: '/crm/crmContract/addContract',
    method: 'post',
    data: data
  })
}
// 合同:修改
export function editContractApi(data) {
  return request({
    url: '/crm/crmContract/editContract',
    method: 'post',
    data: data
  })
}

// 合同:查询全部有合同的终端客户列表
export function queryContractCustomerListApi(data) {
  return request({
    url: '/crm/crmContract/queryContractCustomerList',
    method: 'post',
    data: data
  })
}

// 3.8. 根据终端客户id查询关联的全部产品信息
export function queryCustomerProductListApi(orgId) {
  return request({
    url: 'crm/crmContract/queryCustomerProductList/' + orgId,
    method: 'post',
  })
}

// 判断该合同是否关联项目/任务
export function checkContractRelProjectTask(contractId) {
  return request({
    url: 'crm/crmContract/checkContractRelProjectTask/' + contractId,
    method: 'post'
  })
}
// 上传并批量导入合同文件
export function uploadAndImportCrmContract(data) {
  return request({
    url: 'crm/crmContract/uploadAndImportCrmContract',
    method: 'post',
    data: data,
    timeout: 30 * 60 * 1000,
    returnError: true
  })
}

// 生成合同导入报告
export function createContractImportReport(batchNum) {
  return request({
    url: 'crm/crmContract/createContractImportReport/' + batchNum,
    method: 'get',
    timeout: 30 * 60 * 1000,
    responseType: 'blob'
  })
}
