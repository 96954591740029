var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.pageLoading,
          expression: "pageLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("合同管理")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticStyle: { float: "right", display: "flex" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新建合同")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style batch-import-btn",
                              attrs: { size: "small" },
                              on: { click: _vm.handleImport },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "iconfont",
                                  staticStyle: { "margin-right": "6px" },
                                },
                                [_vm._v("")]
                              ),
                              _vm._v(" 批量导入 "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    size: "small",
                                    maxlength: "100",
                                    clearable: "",
                                    placeholder: "合同名称/合同编号/客户名称",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      placeholder: "合同分类",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.contractClassify,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "contractClassify",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "queryParams.contractClassify",
                                    },
                                  },
                                  _vm._l(
                                    _vm.contractClassifyDict,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "合同类型",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.contractType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "contractType",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.contractType",
                                    },
                                  },
                                  _vm._l(_vm.contractTypeDict, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.dictLabel,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "项目分类",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.projectClassify,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "projectClassify",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.projectClassify",
                                    },
                                  },
                                  _vm._l(
                                    _vm.contractProjectClassifyDict,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "resetFilterClass",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetSearchValue()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "iconfont" }, [
                                  _vm._v(""),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "mainTable",
                  attrs: {
                    height: "100%",
                    data: _vm.tableDataList,
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "default-sort": { prop: "createTime", order: "ascending" },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", prop: "id", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "合同编号",
                      prop: "contractNum",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "合同名称", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#F1880D",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCheck(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.contractName))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "分类",
                      prop: "contractClassifyName",
                      "min-width": "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "合同性质",
                      prop: "contractTypeName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "项目分类",
                      prop: "projectClassifyName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "终端客户",
                      prop: "userName",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.customerList,
                            function (item, index) {
                              return _c(
                                "span",
                                {
                                  staticStyle: { "margin-right": "4px" },
                                  attrs: { size: "mini" },
                                },
                                [
                                  _vm._v(" " + _vm._s(item.customerName)),
                                  index !== scope.row.customerList.length - 1
                                    ? _c("span", [_vm._v("、")])
                                    : _vm._e(),
                                ]
                              )
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "下单日期",
                      prop: "orderTime",
                      "min-width": "100",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作时间",
                      prop: "updateTime",
                      "min-width": "150",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      prop: "updateBy",
                      "min-width": "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#ed923a",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#ff0009",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#999",
                      "line-height": "40px",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v(" 按住Shift+鼠标滚轮左右滑动列表 ")]
                ),
              ]),
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "div",
                  [
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableDataTotal > 0,
                          expression: "tableDataTotal>0",
                        },
                      ],
                      attrs: {
                        total: _vm.tableDataTotal,
                        page: _vm.queryParams.pageNum,
                        limit: _vm.queryParams.pageSize,
                        "page-sizes": [10, 20, 50, 100],
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageSize", $event)
                        },
                        pagination: _vm.queryList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.editDialogTitle,
            visible: _vm.drawer,
            modal: false,
            "with-header": false,
            wrapperClosable: false,
            "modal-append-to-body": true,
            "append-to-body": true,
            "before-close": _vm.closeDrawer,
            size: "980px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer_wrap" },
            [
              _c(
                "el-row",
                { staticClass: "newBuildContractWrap" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "newConstructionContract" }, [
                      _vm._v("新建合同"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "mini" },
                          on: { click: _vm.onReset },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "saveBtn",
                          attrs: { plain: "", size: "mini" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("new-construction-contract", {
                ref: "form1",
                attrs: { isNewConstruction: _vm.isNewConstruction },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "closeBtn", on: { click: _vm.closeDrawer } },
            [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
          ),
        ]
      ),
      _vm.editCommon
        ? _c("edit-common", {
            attrs: {
              editCommonTitle: _vm.editCommonTitle,
              "is-edit": _vm.isEdit,
              "is-can-edit": _vm.isCanEdit,
              drawer: _vm.editCommon,
              activeNum: _vm.activeNum,
              contractId: _vm.contractId,
              projectId: _vm.projectId,
              titleName: _vm.contractName,
              isCanEdit: _vm.isCanEdit,
            },
            on: {
              "update:isEdit": function ($event) {
                _vm.isEdit = $event
              },
              "update:is-edit": function ($event) {
                _vm.isEdit = $event
              },
              handleEditCommon: _vm.handleEditCommon,
            },
          })
        : _vm._e(),
      _vm.importTaskDialog
        ? _c("import-contract", {
            attrs: { "dialog-visible": _vm.importTaskDialog },
            on: {
              close: function ($event) {
                _vm.importTaskDialog = false
              },
              refresh: _vm.handleQuery,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }