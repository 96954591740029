
import request from '@/modules/crm/utils/request'


//系统文件:预览/下载文件
export function previewFileApi(fileId) {
  return request({
    url: '/crm/sys/file/preview/' + fileId,
    method: 'get'
  })
}



// 下载文件
export function downloadFileApi(fileId) {
  return request({
    url: '/crm/sys/file/preview/' + fileId,
    method: 'get',
    timeout: 30*60 * 1000,
    responseType: 'blob'
  })
}



//系统文件:获取文件信息
export function fileInfoApi(fileId) {
  return request({
    url: '/crm/sys/file/info/' + fileId,
    method: 'get'
  })
}
// 文件上传
export const singleFileUploadApi = (params) => {
  /**
   * let formData = new FormData()
        formData.append('file', item.raw)
        formData.append('fileClassify', 9)
        formData.append('tempFlag', 0)
   */
  return request({
    url: '/crm/sys/file/uploadFile',
    method: 'POST',
    data: params,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  })
}
