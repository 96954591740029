<template>
  <div class="newConstructionContract_wrap">
    <div v-if="projectId">
      <div class="projectTitle">客户方</div>
      <el-row class="editProductWrap_contact">
        <el-col :span="24" style="display: flex;flex-wrap: wrap;">
          <div class="productWrap01" v-for="(item, index) in projectObject.purchaserContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">类型</el-col>
                <el-col :span="18" class="productSelect">
<!--                  <span>购买方</span>-->
                  <span class="typeSpan" v-if="item.orgId ==projectObject.purchaser">购买方</span>
                  <template v-for="i in projectObject.customerList">
                    <span class="typeSpan" v-if="item.orgId==i.customerId">
                      终端客户
                    </span>
                  </template>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">
                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{phoneItem.phone}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                     <span
                       class="remarkStyle"
                       :title="item.remark">
                      {{ item.remark }}
                    </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="projectTitle">乙方</div>
      <el-row class="editProductWrap_contact">
        <el-col :span="24" style="display: flex;flex-wrap: wrap;">
          <div class="productWrap01" v-for="(item, index) in projectObject.sellerContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">类型</el-col>
                <el-col :span="18" class="productSelect">
<!--                  <span  class="appreciationPackage" v-if="projectObject.sellerTypeName">{{ projectObject.sellerTypeName }}</span>-->
                  <span  class="appreciationPackage" v-if="item.orgId === projectObject.seller">经销</span>
                  <span  class="appreciationPackage" v-else-if="item.orgId === projectObject.distribution">分销</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">
                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{phoneItem.phone}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                     <span
                       class="remarkStyle"
                       :title="item.remark">
                      {{ item.remark }}
                    </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="projectTitle"v-if="projectObject.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">卫心方</div>
      <el-row class="editProductWrap_contact" v-if="projectObject.sysUserContactsList && projectObject.sysUserContactsList.length>0">
        <el-col :span="24" style="display: flex;flex-wrap: wrap;">
          <div class="productWrap01" v-for="(item, index) in projectObject.sysUserContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
<!--              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">-->
<!--                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>-->
<!--                <el-col :span="18" class="productSelect">-->
<!--                  <span>{{phoneItem.phone}}</span>-->
<!--                </el-col>-->
<!--              </el-row>-->
              <el-row>
                <el-col :span="6" class="productTitle">电话</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{ item.phone }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                     <span
                       class="remarkStyle"
                       :title="item.remark">
                      {{ item.remark }}
                    </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="noDataWrap">
      <div class="noData">
        <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
        <div class="noDataText">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  projectInfoApi
} from '@/modules/crm/api/crm/project'
export default {
  name: "EditPeople",
  props: {
    projectId: {
      type: Number
    }
  },
  data() {
    return {
      projectValue: '',
      projectObject: {}, // 单个项目信息
      value: '备注内容备注内容备注内容备注内容备注内容备注内容备注内容备注内容备注内容备注内容备注内容备注内容'
    }
  },
  created() {
    if (this.projectId) {
      this.projectInfoFn(this.projectId)
    }
  },
  methods: {
    // 获取相关项目详细信息
    async projectInfoFn(val) {
      let res = await projectInfoApi(val)
      console.log(res)
      if (res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.projectObject = res.data
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
.newConstructionContract_wrap{
  height: 100%;
  padding: 5px 0 20px;
  .projectUl{
    background: #EAECEF;
    border-radius: 4px;
    width:227px;
    overflow: hidden;
    float: left;
    margin-top: 7px;
    li{
      float: left;
      width: 18px;
      height: 8px;
      background: #F1880D;
      margin-right: 1px;
      &:last-child{
        margin-right: 0px;
      }
    }

  }
  .typeSpan{
    display: inline-block;
    padding: 0px 6px;
    border-radius: 20px;
    background: #EBEDFE;
    color: #7281ED;
    font-size: 12px;
    margin-right: 10px;
  }
  .noDataWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .noData{
      width: 96px;
      height:102px;
      text-align: center;
      .noDataText{
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 16px;
      }
    }
  }
  .projectStatusSpan{
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 10px;
  }
  .projectTitle{
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #3160BA;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
  }
  .projectNum{
    font-size: 15px;
    font-weight: 500;
    color: #5D5D5D;
    margin-left: 20px;
  }
  .productWrap{
    .productItem{
      float: left;
      width: 260px;
      padding:6px 10px;
      height: 76px;
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }
  }
}
.editProductWrap{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
}
.editProductWrap_contact{
  margin-bottom: 10px;
}
.editLabel{
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.editInput{
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.orgListItem{
  color: #F1880D;
  cursor: pointer;
}
.appreciationPackage{
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #DEDEDE;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 18px;
  margin-right: 4px;
  &:last-child{
    margin-right: 0;
  }
}
.productWrap01{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 280px;
  padding:6px 10px;
  display: flex;
  .productItem{
    float: left;
    width: 258px;
    el-row{
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .contactName{
      color:#5D5D5D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .productTitle{
      height: 20px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 20px;
    }
    .productSelect{
      //height: 20px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;
      word-wrap: break-word;
      .remarkStyle{
        width: 100%;
        display: inline-block;
        white-space: nowrap; /* 不换行 */
        overflow: hidden;    /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        cursor: pointer;
      }
    }
  }
}
.productWrap02{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 250px;
  padding:6px 10px;
  display: flex;
  .productItem{
    float: left;
    .batchClass{
      height: 28px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 28px;
      margin-right: 4px;
    }
    .relatedProductName{
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #5D5D5D;
      line-height: 20px;
    }
    .relatedProductProjectState{
      margin: 4px 0;
    }
    .productTitle{
      height: 28px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 28px;
    }
    .productSelect{
      height: 28px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 28px;
    }
  }
  .projectUl{
    background: #EAECEF;
    border-radius: 4px;
    width:227px;
    overflow: hidden;
    li{
      float: left;
      width: 18px;
      height: 8px;
      background: #F1880D;
      margin-right: 1px;
      &:last-child{
        margin-right: 0px;
      }
    }
  }
}
</style>
