<template>
  <div class="ecrv_wrap">
    <el-drawer
      :title="editDialogTitle"
      :visible.sync="drawer"
      :before-close="handleEditCommon"
      :modal="false"
      :with-header="false"
      :wrapperClosable="false"
      size="980px">
      <div class="drawer_common_wrap" style="display: flex;flex-direction: column;height: 100vh;">
        <!-- 编辑产品title-->
        <div>
          <el-row class="newBuildContractWrap">
            <el-col :span="12" style="display: flex">
              <span class="newConstructionContract">{{titleName}}</span>
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <el-button
                plain
                size="mini"
                @click="onReset()">取消</el-button>
              <el-button
                class="saveBtn"
                plain size="mini"
                @click="onEditSubmit()">提交</el-button>
            </el-col>
          </el-row>
        </div>
        <div style="flex:1;overflow-y: auto;">
          <el-row class="firstTitleWrap">
            <el-col :span="12">
              <span class="firstTitle">客户档案项</span>
            </el-col>
            <el-col :span="12" style="text-align: right;" class="firstTitleBtn">
              <el-button v-if="isVisible" class="foldDetail" type="text" @click="toggle('fold')">收起详情<i class="iconfont">&#xe6ab;</i></el-button>
              <el-button v-else class="unfoldDetail" type="text" @click="toggle('unfold')">展开详情<i class="iconfont">&#xe6ac;</i></el-button>
            </el-col>
          </el-row>
          <el-collapse-transition>
            <div v-if="isVisible" class="box">
              <el-form ref="form" :model="form" :rules="rules" label-width="110px" label-position="left" class="form-el">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="终端客户" prop="orderTime">
                      <span>{{form.customerName}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人" prop="contactId">
                      <el-select
                        v-model="form.contactId"
                        placeholder="请选择"
                        size="small"
                        style="width: 98%;"
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        :remote-method="remoteCPMethod">
                        <el-option
                          v-if="cpArr.length === 0"
                          value=""
                          disabled>
                          无匹配数据
                        </el-option>
                        <el-option
                          v-for="i in cpArr"
                          :key="i.id"
                          :label="i.name"
                          :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="卫宁联系人" prop="winningContact">
                      <el-select
                        v-model="form.winningContact"
                        placeholder="请选择"
                        size="small"
                        style="width: 98%;"
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        :remote-method="remoteWNMethod">
                        <el-option
                          v-if="wnArr.length === 0"
                          value=""
                          disabled>
                          无匹配数据
                        </el-option>
                        <el-option
                          v-for="i in wnArr"
                          :key="i.id"
                          :label="i.name"
                          :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="卫心联系人" prop="weixinContact">
                      <el-select
                        v-model="form.weixinContact"
                        placeholder="请选择"
                        size="small"
                        style="width: 98%;"
                        filterable
                        remote
                        reserve-keyword
                        clearable
                        :remote-method="remoteWXMethod">
                        <el-option
                          v-if="wxArr.length === 0"
                          value=""
                          disabled>
                          无匹配数据
                        </el-option>
                        <el-option
                          v-for="i in wxArr"
                          :key="i.id"
                          :label="i.nickName"
                          :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-bottom: 10px;">
                  <el-col :span="3" style="line-height: 30px;font-size: 16px;color: #909090;font-weight: 600;">维保期服务</el-col>
                  <el-col :span="21"><el-divider></el-divider></el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="沟通方式" prop="communicationMethod">
                      <el-input
                        v-model.trim="form.communicationMethod"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="沟通频率" prop="communicationFrequency">
                      <el-input
                        v-model.trim="form.communicationFrequency"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="数据变更计划" prop="dataUpdatePlan">
                      <el-input
                        v-model.trim="form.dataUpdatePlan"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="公共数据库账号" prop="publicDatabaseAccount">
                      <el-input
                        v-model.trim="form.publicDatabaseAccount"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="卫心产品服务群" prop="weixinProductServiceGroup">
                      <el-input
                        v-model.trim="form.weixinProductServiceGroup"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="头号药师群邀请" prop="thysGroupInvitation">
                      <el-input
                        v-model.trim="form.thysGroupInvitation"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="增值服务项" prop="valueAddedServiceItem">
                      <el-input
                        v-model.trim="form.valueAddedServiceItem"
                        placeholder="请输入"
                        style="width: 98%;"
                        size="small"
                        maxlength="50"
                        clearable
                        show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                      <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请输入备注信息"
                        style="width: 99%;"
                        v-model="form.remark"
                        maxlength="500"
                        show-word-limit
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-collapse-transition>
          <el-divider></el-divider>
          <div style="flex: 1;">
            <el-row class="firstTitleWrap">
              <el-col :span="12">
                <span class="firstTitle">满意度调查</span>
              </el-col>
              <el-col :span="12">
                <div style="float: right">
                  <el-button
                    v-if="!isRvEdit"
                    class="crm_btn_style"
                    icon="el-icon-circle-plus-outline"
                    size="mini"
                    @click="addTab(editableTabsValue)">新建回访</el-button>
                </div>
              </el-col>
            </el-row>
            <div style="background: #F4F6F8;display: inline-block;width: 100%;">
              <el-tabs v-model="editableTabsValue"
                       type="card"
                       v-if="isDateList"
                       @tab-click="handleTabClick">
                <el-tab-pane
                  :disabled="isRvEdit"
                  v-for="(item, index) in archivesDateListArr"
                  :key="index"
                  :label="item"
                  :name="item"
                >
  <!--                {{item.content}}-->
                </el-tab-pane>
              </el-tabs>
              <div v-if="isDateList">
                <edit-return-visit-content
                  ref="editReturnVisitRef"
                  v-if="isRvEdit"
                  :id="form.id"
                  :followUpTime="editableTabsValue"
                  :isAdd="isAdd"
                  :customerId="form.customerId"
                  @editCancel="editCancel">
                </edit-return-visit-content>
                <look-return-visit-content
                  v-else
                  :id="form.id"
                  :followUpTime="editableTabsValue"
                  @editSurveyData="editSurveyData"
                  @delSurveyData="delSurveyData">
                </look-return-visit-content>
              </div>
              <div class="noDataWrap" v-if="!isDateList&&!isRvEdit">
                <div class="noData">
                  <!-- <img src="../assets/images/noSurveyData.png" style="width: 140px;height: 120px;"/> -->
                  <div class="noDataText">暂无满意度调查</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="closeBtn" @click="handleEditCommon">
        <i class="iconfont">&#xe677;</i>
      </div>

    </el-drawer>
    <el-dialog
      title="提交确认"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
      <span>您的满意度调查{{ editableTabsValue }}还没有保存哦，请保存后再关闭。</span>
      <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">直接关闭</el-button>
          <el-button type="primary" @click="centerDialogVisible = false">保存并关闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchContactsList
} from '@/modules/crm/api/crm/org'
import { allUserListApi } from '@/modules/crm/api/system/user'
import LookReturnVisitContent from "./LookReturnVisitContent";
import EditReturnVisitContent from "./EditReturnVisitContent";
import {
  archivesInfo,
  archivesDateList,
  editFpArchives,
  satisfactionSurveyInfo,
  delSatisSurvey,
  queryWeiningContact
} from '@/modules/crm/api/crm/returnVisit'
export default {
  name: "EditCommon" ,
  props: {
    editDialogTitle:  '',
    drawer: { // 遮罩层开关
      type: Boolean,
      required: true
    },
    activeNum: { // 0代表从机构列表跳转编辑
      type: Number,
      required: true
    },
    archivesId: {
      type: Number
    },
    contractId: {
      type: Number
    },
    projectId: {
      type: Number
    },
    titleName: {
      type: String,
      required: true
    },
    orgType: {
      type: Number
    },
    row: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isProjectCorrelation: {
      type: Boolean,
      default: false
    },
    taskId:{
      type: Number
    },
    taskInfo: {
      type: Object
    },
    taskEditAuth: {
      type: Boolean,
      default: true
    },
    taskDelAuth: {
      type: Boolean,
      default: true
    },
    taskHandleAuth: {
      type: Boolean,
      default: true
    }
  },
  components:{
    LookReturnVisitContent,
    EditReturnVisitContent
  },
  data() {
    return {
      isRvEdit: false, // 是否是回访调查编辑状态
      editableTabsValue: undefined,
      // editableTabs: [{
      //   title: '2024-09-10',
      //   name: '1',
      //   content: 'Tab 1 content'
      // }, {
      //   title: '2024-09-11',
      //   name: '2',
      //   content: 'Tab 2 content'
      // }],
      tabIndex: 2,
      isVisible: true,
      // 合同teb =》合同、项目、人员
      // 项目teb =》合同、项目、人员
      // 任务teb =》合同、项目、人员、任务
      // 机构teb =》机构、合同、项目
      activeName: this.activeNum.toString(),
      isEditShow: false, // 是否在编辑
      text: '',
      form: {
        communicationFrequency: '',
        communicationMethod: '',
        contactId: '',
        contractId: '',
        customerId: '',
        dataUpdatePlan: '',
        id: '',
        projectId: '',
        publicDatabaseAccount: '',
        remark: '',
        thysGroupInvitation: '',
        valueAddedServiceItem: '',
        weixinContact: '',
        weixinProductServiceGroup: '',
        winningContact: ''
      },
      centerDialogVisible: false,
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        value1: [
          { type: 'daterange', required: true, message: '请选择日期', trigger: 'change' }
        ],
        // orderTime: [
        //   { type: 'data', required: true, message: '请选择日期', trigger: 'change' }
        // ],
        purchaser: [
          { required: true, message: '请选择购买方(甲方)', trigger: 'change' }
        ],
        contractNum: [
          { required: true, message: '请填写合同编号', trigger: 'blur' }
        ],
        contractName: [
          { required: true, message: '请填写合同名称', trigger: 'blur' }
        ],
        contractPrice: [
          { required: true, message: '请填写合同金额', trigger: 'blur' }
        ],
        productList: [
          { required: true, message: '请添加产品', trigger: 'change' }
        ]
      },
      isNewConstruction: false, // 编辑状态下
      currentContractId: null,
      currentProjectId:null,
      isNeedEdit: true,
      editType: 3,
      editDrawer: true,
      isHidden:false,
      isCorrelation: false,
      FpArchivesData: undefined,
      initialArchivesDateListArr: [],
      archivesDateListArr: [],
      cpArr: [], // 联系人列表
      wnArr: [], // 卫宁联系人
      wxArr: [], // 卫心联系人
      loading: false,
      sclArr: [],
      isDateList: false, // 是否有满意度调查时间列表
      satisfactionSurveyInfoArr: {}, // 满意度调查单个日期数据
      isAdd: true, // 编辑时是否是新增
      wnOrgId: null
    }
  },
  async created() {
    this.isEditShow = this.isEdit
    this.isCorrelation = this.isProjectCorrelation
    await this.archivesInfo()
    await this.archivesDateListFn()
    await this.queryWeiningContactFn()
    this.eventBus.$on(this.eventConst.returnVisit.editSucceed,()=>{
      this.isRvEdit = false
      // this.archivesInfo()
      this.archivesDateListFn()
      this.queryWeiningContactFn()
    });
  },
  methods: {
    // 回访方案详情信息
    async archivesInfo() {
      let res = await archivesInfo(this.archivesId)
      if(res.code == 200) {
        this.form = this.$deepClone(res.data)
        let cpArray = []
        if (this.form.customerId) {
          cpArray = [this.form.customerId]
        }
        this.cpArr = await this.searchContactsListFn(cpArray)
        let wnArray = []
        if (this.wnOrgId) {
          wnArray = [this.wnOrgId]
        }
        this.wnArr = await this.searchContactsListFn(wnArray)
        this.wxArr = await this.searchAllUser()
      }
    },
    // 联系人
    async remoteCPMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          let orgIdList = []
          orgIdList.push(this.row.customerId)
          obj.orgIdList = orgIdList
          let res = await searchContactsList(obj)
          if(res.code === 200) {
            this.loading = false
            this.cpArr = res.data
          }
        }, 200);
      } else {
        this.cpArr = [];
      }
    },
    // 卫宁联系人
    async remoteWNMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          let orgIdList = []
          if (this.wnOrgId) {
            orgIdList = [this.wnOrgId]
          }
          obj.orgIdList = orgIdList
          let res = await searchContactsList(obj)
          if(res.code === 200) {
            this.loading = false
            this.wnArr = res.data
          }
        }, 200);
      } else {
        this.wnArr = [];
      }
    },
    //卫心联系人
    async remoteWXMethod(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.wxArr = res.data
        console.log(this.wxArr)
      })
    },
    // 满意度调查(回访时间列表)
    async archivesDateListFn() {
      let res = await archivesDateList(this.archivesId)
      if(res.code == 200) {
        if(res.data&&res.data.length>0) {
          this.initialArchivesDateListArr = this.$deepClone(res.data)
          this.archivesDateListArr = this.$deepClone(res.data)
          this.editableTabsValue = this.archivesDateListArr[0]
          this.isDateList = true
          // await this.satisfactionSurveyInfoFn(this.archivesDateListArr[0])
        } else {
          this.archivesDateListArr = []
          this.isDateList = false
        }
      }
    },
    // 客户档案项目提交
    async editFpArchivesFn() {
      let res = await editFpArchives(this.form)
      if(res.code == 200) {
        this.msgSuccess(res.msg)
      }
    },
    // 联系人/卫宁联系人列表查询
    async searchContactsListFn(arr,query = '') {
      const obj = {
        searchValue: query,
        orgIdList: arr
      }
      let res = await searchContactsList(obj)
      return res.data?res.data:[]
    },
    // 卫心联系人列表查询
    async searchAllUser(query = '') {
      const obj = {searchValue: query}
      let res = await allUserListApi(obj)
      return res.data
    },
    // 满意度调查详细信息
    async satisfactionSurveyInfoFn(date) {
      let obj = {
        archiveId: this.form.id,
        followUpTime: date
      }
      let res = await satisfactionSurveyInfo(obj)
      if (res.code === 200) {
        this.satisfactionSurveyInfoArr = res.data
      }
    },
    // 编辑单条满意度调查数据
    editSurveyData(val) {
      this.editableTabsValue = val
      this.isRvEdit = true
      this.isAdd = false // 是否是新增
    },
    async delSurveyData(val) {
      let res = await delSatisSurvey(val)
      if(res.code === 200) {
        this.msgSuccess(res.msg)
        await this.resetData()
      }
    },
    // resetData
    async resetData() {
      await this.archivesInfo()
      await this.archivesDateListFn()
      this.isRvEdit = false
    },
    editCancel(date,val) {
      this.isAdd = val
      if (this.isAdd) {
        if(this.initialArchivesDateListArr&&this.initialArchivesDateListArr.length>0) {
          this.archivesDateListArr = this.$deepClone(this.initialArchivesDateListArr)
          this.editableTabsValue = this.archivesDateListArr[0]
          this.isDateList = true
        } else {
          this.archivesDateListArr = []
          this.isDateList = false
        }
      } else {
        this.editableTabsValue = date
      }
      this.isRvEdit = false
    },
    addTab(targetName) {
      let currentDate = new Date()
      this.archivesDateListArr.push(this.parseTime(currentDate, '{y}-{m}-{d}'));
      this.editableTabsValue = this.parseTime(currentDate, '{y}-{m}-{d}');
      this.isDateList = true
      this.isRvEdit = true
      this.isAdd = true
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
    handleTabClick(tab) {
      this.editableTabsValue = tab.name
    },
    // 关闭弹窗
    handleEditCommon(type) {
      this.$emit('handleEditCommon', type)
    },
    onEditSubmit() {
      let that = this
      if (this.isRvEdit) {
        this.$confirm('您的满意度调查'+ this.editableTabsValue +'还没有保存哦，请保存后再关闭。', '提交确认', {
          confirmButtonText: '保存并关闭',
          cancelButtonText: '直接关闭',
          showClose: false,
          confirmButtonClass: 'confirmMYDButton',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: false,
          customClass: 'delMYDMessage'
        }).then(async function() {
          let flag = that.$refs['editReturnVisitRef'].saveFpSatisSurvey()
          // console.log(flag)
          if(flag){
            this.editFpArchivesFn()
            this.$emit('handleEditCommon')
          }
        }).catch(() => {
          this.editFpArchivesFn()
          this.$emit('handleEditCommon')
        });
      } else {
        this.editFpArchivesFn()
        this.$emit('handleEditCommon')
      }

    },
    // 重置
    onReset() {
      this.$emit('handleEditCommon')
    },
    async queryWeiningContactFn() {
      let res = await queryWeiningContact(this.form.customerId)
      if(res.code ===200) {
        this.wnOrgId = res.data
      }
    }
  }
}
</script>

<style lang="scss">
.el-select .el-input .el-select__caret.el-icon-::before {
  content: "";
  //background: url(/../assets/images/inputIcon.png) center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
//也可以用el-icon

.el-select .el-input .el-select__caret.el-icon-::before {
  content: "\e78f";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


//::v-deep.ecrv_wrap{
  .confirmMYDButton{
    background-color: #F1880D!important;
    border-color:#F1880D!important;
  }
  .delMYDMessage{
    .el-message-box__btns{
      text-align: center!important;
    }
    .el-message-box__title{
      font-weight: 600;
    }
    .el-message-box__message p{
      color: #3C3C3C;
      //font-weight: 500;
    }
  }
//}
</style>

<style lang="scss"  scoped>
  .noDataWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    .noData{
      //width: 140px;
      padding-top: 16px;
      text-align: center;
      .noDataText{
        font-size: 14px;
        color: #5D5D5D;
        line-height: 28px;
      }
    }
  }
  .firstTitleWrap{
    margin-bottom: 16px;
    padding-right: 10px;
    .firstTitle{
      height: 26px;
      font-weight: 600;
      font-size: 18px;
      color: #3C3C3C;
      line-height: 26px;
    }
    .firstTitleBtn{
      height: 26px;
      line-height: 26px;
      margin-bottom: 16px;
    }
    .foldDetail{
      font-size: 12px;
      color: #F1880D;
      padding: 0;
    }
    .unfoldDetail{
      font-size: 12px;
      color: #F1880D;
      padding: 0;
    }
  }
  .el-form-item {
    margin-bottom: 8px;
  }
  .el-divider--horizontal{
    margin: 15px 0;
  }
  .closeBtn{
    position:fixed;
    right: 980px;
    top: 50%;
    display: flex;
    height: 60px;
    padding: 20px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 10px 0px 0px 10px;
    background: #FFF;
    /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
    cursor: pointer;
    i{
      color: #F09933;
      font-size: 20px;
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .drawer_common_wrap{
    display: flex;
    flex-direction: column;
    // height: calc(100% - 20px);
    padding:10px 20px;
    //height: -webkit-fill-available; (谷歌浏览器生效，safari浏览器不生效)
    .newBuildContractWrap{
      padding:22px 0px 30px;
      //border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
      .newConstructionContract{
        font-size: 24px;
        font-weight: 600;
        color: #3C3C3C;
        line-height: 24px;
        width: 700px;
        display: -webkit-box; /* 将文本显示为块元素 */
        -webkit-line-clamp: 2; /* 设置最大显示行数为2 */
        -webkit-box-orient: vertical; /* 设置块元素内部文本垂直排列 */
        overflow: hidden; /* 当文本溢出块元素时进行隐藏 */
        text-overflow: ellipsis; /* 溢出部分显示省略号 */
        word-break: break-all;
      }
      .el-button--warning.is-plain{
        background: none;
      }
      .delBtn{
        border: 1px solid #F23232;
        color: #F23232;
        &:hover{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
        &:active{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
        &:disabled{
          border: 1px solid #fab6b6;
          color: #fab6b6;
        }
      }
      .saveBtn{
        border: 1px solid #F1880D;
        color: #F1880D;
        &:hover{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
        &:active{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
        &:disabled{
          border: 1px solid #f3d19e;
          color: #f3d19e;
        }
      }
    }
  }
  .appreciationPackage{
    padding: 2px 4px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #DEDEDE;
    color: #5D5D5D;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
    &:last-child{
      margin-right: 0;
    }
  }
</style>
