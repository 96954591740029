<template>
  <div class="app-container" style="height:100%"
       v-loading.fullscreen.lock="pageLoading"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">项目管理</el-col>
            <el-col :span="12">
              <div style="float: right;display: flex;">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新建项目</el-button>
                <el-button
                  class="crm_btn_style batch-import-btn"
                  size="small"
                  @click="handleImport">
                  <i class="iconfont"style="margin-right: 6px;">&#xe6a7;</i>
                  批量导入
                </el-button>
              </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model.trim="queryParams.searchValue"
                            size="small"
                            style="width:240px;"
                            maxlength="100"
                            clearable
                            placeholder="项目名称/客户名称/合同编号"
                            @keydown.enter.native="handleQuery"
                  ></el-input>
                  <!--                  maxlength="30" show-word-limit-->
                </el-form-item>


                <el-form-item label="">
                  <el-select v-model="queryParams.projectStatusList" size="small"
                             style="width:200px;"
                             filterable
                             multiple
                             collapse-tags
                             @keydown.enter.native="handleQuery"
                             placeholder="项目状态" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in projectStatusDict"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="">
                  <el-select v-model="queryParams.implType" size="small"
                             style="width:150px;"
                             filterable
                             @keydown.enter.native="handleQuery"
                             placeholder="项目实施方" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in implTypeDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
<!--                <el-form-item label="">-->
<!--                  <el-select v-model="queryParams.projectClassify" size="small"-->
<!--                             style="width:150px;"-->
<!--                             filterable-->
<!--                             @keydown.enter.native="handleQuery"-->
<!--                             placeholder="项目分类" clearable-->
<!--                  >-->
<!--                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"-->
<!--                               v-for="item in projectClassifyDict"-->
<!--                    ></el-option>-->
<!--                  </el-select>-->
<!--                </el-form-item>-->
                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
                <el-button
                  type="text"
                  size="small"
                  class="resetFilterClass"
                  @click="resetSearchValue()">
                  <i class="iconfont">&#xe660;</i>
                </el-button>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table height="100%"
                  v-loading="tableLoading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="mainTable"
                  :key="isKey"
                  :default-sort="{prop: 'createTime', order: 'ascending'}"
                  @sort-change="tableSortChange"

        >
          <el-table-column label="ID" prop="id" width="50"></el-table-column>
          <el-table-column label="项目名称" min-width="150">
            <template slot-scope="scope">
              <span style="color:#F1880D;cursor: pointer;" @click="onCheck(scope.row)">{{scope.row.projectName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="终端客户" prop="userName" min-width="150">
            <template slot-scope="scope" style="margin-right: 4px;">
<!--              white-space:nowrap;overflow: hidden;text-overflow: ellipsis;-->
              <span v-for="(item,index) in scope.row.customerList" size="mini">
                {{ item.customerName }}<span v-if="index!==scope.row.customerList.length-1">、</span>
              </span>
            </template>
          </el-table-column>
<!--          <el-table-column label="项目分类" prop="projectClassifyName" min-width="80"/>-->
          <el-table-column label="项目状态" prop="implType" width="250">
            <template slot-scope="scope">
<!--              <ul class="projectUl">-->
<!--                <el-popover-->
<!--                  popper-class="projectUl_popover"-->
<!--                  v-for="(item,index) in scope.row.projectStatusProgress"-->
<!--                  :key="item.projectStatus"-->
<!--                  placement="top-start"-->
<!--                  width="160"-->
<!--                  trigger="click">-->
<!--                  <span-->
<!--                    style="-->
<!--                    padding: 2px 4px;-->
<!--                    border-radius: 2px;-->
<!--                    border: 1px solid #DDD;-->
<!--                    color: #303133;-->
<!--                    font-size: 14px;-->
<!--                    line-height: 22px;">{{ item.projectStatusShowName}}</span>-->
<!--                  <div-->
<!--                    style="-->
<!--                    color:#606266;-->
<!--                    font-size: 12px;-->
<!--                    line-height: 20px;">{{ item.startTime }}</div>-->
<!--                  <li-->
<!--                    slot="reference"-->
<!--                    v-if="index+1<=scope.row.projectStatusOrder"-->
<!--                    :class="index===8 ? 'lastLi': ''">-->
<!--                  </li>-->
<!--                </el-popover>-->
<!--              </ul>-->
<!--              {{ scope.row.projectStatusShowName }}-->
              {{ scope.row.projectStatusShowName }}
            </template>
          </el-table-column>
          <el-table-column label="项目实施方" prop="implTypeName" min-width="100"/>
          <el-table-column label="产品" min-width="200" class-name="projectList" style="padding: 0px!important;">
            <template slot-scope="scope">
              <div
                class="projectName"
                v-for="(item,index) in scope.row.productList"
                size="mini">
                {{ item.productName }}
                <el-divider v-if="index!==scope.row.productList.length-1"></el-divider>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="建项日期" prop="filingTime" min-width="100"/>
          <el-table-column label="操作时间" prop="updateTime" min-width="150"/>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a;font-size: 12px;"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009;font-size: 12px;"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10"><div style="color:#999;line-height: 40px;font-size: 12px;">
          按住Shift+鼠标滚轮左右滑动列表
        </div></el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"
            />
          </div>
        </el-col>

      </el-footer>
    </el-container>
    <el-drawer
      :title="editDialogTitle"
      :visible.sync="drawer"
      :modal="false"
      :with-header="false"
      :wrapperClosable="false"
      :before-close="closeDrawer"
      size="980px">
      <div class="drawer_wrap">
        <!--        新建项目title-->
        <el-row class="newBuildContractWrap">
          <el-col :span="12">
            <span class="newConstructionContract">新建项目</span>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button plain size="mini" @click="onReset">重置</el-button>
            <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
          </el-col>
        </el-row>
        <new-construction-project ref="form1" :isNewConstruction="isNewConstruction"></new-construction-project>
      </div>
      <div class="closeBtn" @click="closeDrawer">
        <i class="iconfont">&#xe677;</i>
      </div>
    </el-drawer>
    <edit-common
      v-if="editCommon"
      :editCommonTitle="editCommonTitle"
      :is-edit.sync="isEdit"
      :drawer="editCommon"
      :activeNum="activeNum"
      :projectId="projectId"
      :contractId="contractId"
      :titleName="projectName"
      :isProjectCorrelation="isCorrelation"
      @handleEditCommon="handleEditCommon">
    </edit-common>

    <import-project   v-if="importTaskDialog" :dialog-visible="importTaskDialog"
                      @close="importTaskDialog=false"
                      @refresh="handleQuery"
    ></import-project>
  </div>


</template>
<script>
import { isEmpty } from '@/utils/common'
import { getAllRoleApi } from '@/modules/crm/api/system/role'
import NewConstructionProject from '@/modules/crm/components/NewConstructionProject'
import {
  searchProjectList,
  delProjectApi,
  checkProjectRelTask,
  checkProjectRelFpArchivesApi
} from '@/modules/crm/api/crm/project'
import EditCommon from '@/modules/crm/components/EditCommon'
import ImportProject from '@/modules/crm/components/ImportProject'
export default {
  name: 'crmProject',
  components:{
    NewConstructionProject,
    EditCommon,
    ImportProject
  },
  data() {
    return {
      //导入项目弹窗
      importTaskDialog:false,
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        searchValue: '',
        projectStatusList: undefined,
        // projectClassify: undefined,
        implType: undefined
      },
      editDialogTitle: '新增角色',
      editDialogVisible: false,
      //角色列表
      roleList: [],
      // 项目状态字典
      projectStatusDict: this.getDict(this.dictConst.PROJECT_SHOW_STATUS),
      // 项目实施方式字典
      implTypeDict: this.getDict(this.dictConst.PRODUCT_IMPL_TYPE),
      // 项目分类字典
      projectClassifyDict: this.getDict(this.dictConst.CONTRACT_PROJECT_CLASSIFY),
      drawer: false,
      isNewConstruction: true,
      activeNum: 2,  // 项目
      editCommon: false,
      editCommonTitle: '',
      projectId: undefined,
      contractId: undefined,
      projectName: '',
      isEdit: true,
      isKey: false, // el-table数据实时更新
      isCorrelation: false // 该项目是否有关联
    }
  },
  created() {
    this.handleQuery()
    console.log('乙方状态', this.getDict(this.dictConst.CONTRACT_SELLER_TYPE))
    this.getAllRole()
    this.eventBus.$on(this.eventConst.project.addSucceed,()=>{
      this.drawer = false
      this.handleQuery()
    });
    this.eventBus.$on(this.eventConst.project.delSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
    this.eventBus.$on(this.eventConst.project.editSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
  },
  methods: {
    handleEditCommon() {
      this.editCommon = false
    },
    closeDrawer() {
      this.drawer = false
    },
    /** 搜索按钮操作 */
    handleQuery() {
      // console.log('list')
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    // 重置查询条件
    resetSearchValue() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        searchValue: '',
        projectStatusList: undefined,
        // projectClassify: undefined,
        implType: undefined
      }
      this.handleQuery()
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      searchProjectList(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        this.isKey = !this.isKey
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    // 查看
    onCheck(row) {
      this.isEdit = false
      this.editCommon = true
      this.projectId = row.id
      this.contractId = row.contractId
      this.projectName = row.projectName
      console.log(this.contractId)
    },
    /** 修改按钮操作 */
    async handleEdit(row) {
      const isCorrelation = await this.checkProjectRelTaskFn(row.id)
      // console.log(isCorrelation)
      this.isEdit = true
      this.editCommon = true
      this.projectId = row.id
      this.contractId = row.contractId
      this.projectName = row.projectName
      console.log(this.contractId)
      if (isCorrelation) {
        this.isCorrelation = true
      } else {
        this.isCorrelation = false
      }
    },
    validateMenuIds(rule, value, callback, index) {
      console.log(rule, value, callback, index)
      if (isEmpty(value)) {
        callback(new Error('角色的菜单不能为空'))
      } else {
        callback()
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('确认删除项目【' + row.projectName + '】吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        // return delProjectApi(row.id)
        return checkProjectRelFpArchivesApi(row.id)
      }).then((res) => {
        // console.log(res)
        if (res.data) {
          this.$confirm('当前项目存在回访数据,确认删除项目【' + row.projectName + '】吗?', '删除确认', {
            confirmButtonText: '确认删除',
            cancelButtonText: '取消',
            confirmButtonClass: 'confirmButton',
            customClass: 'delMessage'
          }).then(function() {
            return delProjectApi(row.id)
          }).then((res) => {
            this.handleQuery()
            this.msgSuccess('删除成功')
          })
        } else {
          this.delProjectApiFn(row.id)
        }
      })
    },
    async delProjectApiFn(id) {
      const productId = id
      let res = await delProjectApi(productId)
      // console.log(res)
      if (res.code === 200) {
        this.handleQuery()
        this.msgSuccess('删除成功')
      }
    },
    /** 新建按钮操作 */
    handleAdd() {
      this.drawer = true
      this.isNewConstruction = true
      this.editDialogTitle = '新建合同'
      this.$nextTick(()=> {
        this.onReset()
      })
    },
    /** 批量导入按钮操作 */
    handleImport(){
      this.importTaskDialog=true

    },
    //获取角色列表
    getAllRole() {
      getAllRoleApi().then(res => {
        this.roleList = res.data

      })
    },
    onReset() {
      let flag = this.$refs['form1'].reset()
      console.log(flag)
    },
    onSubmit() {
      console.log('onSubmit')
      let flag = this.$refs['form1'].onSubmit()
      console.log(flag)
      if(flag){
        this.drawer = false
        this.handleQuery()
      }
    },
    async checkProjectRelTaskFn(projectId) {
      let res = await checkProjectRelTask(projectId)
      // console.log(res)
      if (res&&res.code === 200) {
        return res.data
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.crm_btn_style {
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.batch-import-btn {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BCBCBC;
    color: #777777;
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}
::v-deep.el-table .projectList{
  padding:10px 0px!important;
  .projectName{
    width: 100%!important;
    white-space: nowrap!important; /* 不换行 */
    overflow: hidden!important; /* 溢出部分被裁切 */
    text-overflow: ellipsis!important; /* 添加省略号 */
  }
  .el-divider{
    margin:10px 0!important;
  }
}
.closeBtn{
  position:fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  i{
    color: #F09933;
    font-size: 20px;
  }
}
.projectUl{
  background: #EAECEF;
  border-radius: 4px;
  //width:227px;
  width: 170px;
  overflow: hidden;
  .lastLi{
    margin-right: 0px!important;
  }
  .el-popover__reference-wrapper{
    li{
      float: left;
      width: 18px;
      height: 8px;
      background: #F1880D;
      margin-right: 1px;
    }
  }
}
.drawer_wrap{
  .newBuildContractWrap{
    padding:22px 0px;
    //border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    .newConstructionContract{
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
      line-height: 24px;
    }
    .el-button--warning.is-plain{
      background: none;
    }
    .saveBtn{
      border: 1px solid #F1880D;
      color: #F1880D;
      &:hover{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
      &:active{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
    }
  }
}
</style>


