// 项目管理
import request from '@/modules/crm/utils/request'


//项目:查询全部项目名称列表
export function queryProjectListApi(data) {
  return request({
    url: '/crm/crmProject/queryProjectList',
    method: 'post',
    data: data
  })
}


//项目:项目详细信息
export function projectInfoApi(projectId) {
  return request({
    url: '/crm/crmProject/projectInfo/' + projectId,
    method: 'post'
  })
}

// 项目列表查询
export function searchProjectList(data) {
  return request({
    url: 'crm/crmProject/projectList',
    method: 'post',
    data: data
  })
}

// 项目信息删除
export function delProjectApi(projectId) {
  return request({
    url: '/crm/crmProject/delProject/' + projectId,
    method: 'post'
  })
}

// 机构关联项目列表
export function projectListApi(data) {
  return request({
    url: 'crm/crmOrg/projectList',
    method: 'post',
    data: data
  })
}

// 项目:新增
export function addProjectApi(data) {
  return request({
    url: '/crm/crmProject/addProject',
    method: 'post',
    data: data
  })
}

// 项目:修改
export function editProjectApi(data) {
  return request({
    url: '/crm/crmProject/editProject',
    method: 'post',
    data: data
  })
}

// 项目人员信息修改
export function editProjectContract(data) {
  return request({
    url: '/crm/crmProject/editProjectContract',
    method: 'post',
    data: data
  })
}

//项目:项目关联全部人员信息列表
export function projectContactsListApi(projectId) {
  return request({
    url: '/crm/crmProject/projectContactsList/' + projectId,
    method: 'post'
  })
}

// 判断该项目是否关联任务
export function checkProjectRelTask(projectId) {
  return request({
    url: 'crm/crmProject/checkProjectRelTask/' + projectId,
    method: 'post'
  })
}


// 上传并批量导入项目文件
export function uploadAndImportProjectApi(data) {
  return request({
    url: 'crm/crmProject/uploadAndImportProject',
    method: 'post',
    data: data,
    timeout: 30 * 60 * 1000,
    returnError: true
  })
}

// 生成导入报告
export function createImportReportApi(batchNum) {
  return request({
    url: 'crm/crmProject/createImportReport/' + batchNum,
    method: 'get',
    timeout: 30 * 60 * 1000,
    responseType: 'blob'
  })
}
// 根据终端客户id查询关联的全部产品信息
export function checkProjectRelFpArchivesApi(projectId) {
  return request({
    url: '/crm/crmProject/checkProjectRelFpArchives/' + projectId,
    method: 'post'
  })
}
