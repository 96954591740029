// 任务管理
import request from '@/modules/crm/utils/request'

//任务:任务列表查询
export function taskListApi(data) {
  return request({
    url: '/crm/crmTask/taskList',
    method: 'post',
    data: data
  })
}



//任务:任务详细信息
export function taskInfoApi(taskId) {
  return request({
    url: '/crm/crmTask/taskInfo/' + taskId,
    method: 'post'
  })
}

//任务:任务历史记录
export function taskHistoryApi(taskId) {
  return request({
    url: '/crm/crmTask/taskHistory/' + taskId,
    method: 'post'
  })
}


//任务:获取问题类型列表
export function getProblemDictApi(productId) {
  return request({
    url: '/crm/crmTask/getProblemDict/'+productId,
    method: 'post',
  })
}


//任务:任务信息添加
export function addTaskApi(data) {
  return request({
    url: '/crm/crmTask/addTask',
    method: 'post',
    data: data
  })
}


//任务:任务信息修改
export function editTaskApi(data) {
  return request({
    url: '/crm/crmTask/editTask',
    method: 'post',
    data: data
  })
}

//任务:删除
export function delTaskApi(taskId) {
  return request({
    url: '/crm/crmTask/delTask/' + taskId,
    method: 'post'
  })
}


//任务:处理任务
export function dealtTaskApi(data) {
  return request({
    url: '/crm/crmTask/dealtTask',
    method: 'post',
    data: data
  })
}

// 合同关联全部人员信息列表
export function contractContactsListApi(data) {
  return request({
    url: '/crm/crmProject/contractContactsList',
    method: 'post',
    data: data
  })
}
// 任务管理-新建客户联系人
export function taskNewCustomerApi(data) {
  return request({
    url: '/crm/crmTask/taskNewCustomer',
    method: 'post',
    data: data
  })
}
// 任务历史记录排序
export function taskHistoryOrderApi(data) {
  return request({
    url: '/crm/crmTask/taskHistory',
    method: 'post',
    data: data
  })
}
// 任务数据导出
export function taskDataReportApi(data) {
  return request({
    url: '/crm/crmTask/taskDataReport',
    method: 'POST',
    data,
    timeout: 30*60 * 1000,
    responseType: 'blob'
  })
}
