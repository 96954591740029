<template>
  <div class="indexWrap">
    <div class="indexImage">
      <img class="indexImageContent" src="../assets/images/crmIndex.png"/>
    </div>
    <div class="indexFont">
      欢迎进入
      <span>[卫心CRM系统]</span>
    </div>

  </div>


</template>
<script>


export default {
  name: 'index',
  data() {
    return {
      num: '1'

    }
  },
  created() {
    console.log('crm',this.$store)

  },
  methods: {}
}
</script>
<style lang="scss" scoped>
  .indexWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    background: #FFFDFA;
    .indexImage{
      width: 310px;
      height: 342px;
      //background: #FFF2E0;
    }
    .indexFont{
      color: #626262;
      font-size: 28px;
      font-weight: 400;
      //letter-spacing: 0.56px;
      span{
        color: #F09933;
        font-size: 28px;
        font-weight: 600;
        //letter-spacing: 0.56px;
      }
    }
  }
</style>
