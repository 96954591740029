<!--规则执行结果-->
<template>
  <div class="app-container" style="height:100%"
       v-loading.fullscreen.lock="pageLoading"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">用户管理</el-col>
            <el-col :span="12">
              <div style="float: right">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新建用户</el-button>
              </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model="queryParams.searchValue" size="small" style="width:400px;"
                            maxlength="30" show-word-limit clearable
                            placeholder="用户名称/手机号/邮箱"
                            @keydown.enter.native="handleQuery"
                  ></el-input>
                </el-form-item>


                <el-form-item label="">
                  <el-select v-model="queryParams.department" size="small"
                             style="width:130px;"
                             filterable
                             @keydown.enter.native="handleQuery"
                             placeholder="请选择部门" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in sysUserDeptDict"
                    ></el-option>


                  </el-select>
                </el-form-item>

                <el-form-item label="">
                  <el-select v-model="queryParams.status" size="small"
                             style="width:130px;"
                             @keydown.enter.native="handleQuery"
                             placeholder="请选择状态" clearable
                  >
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="停用" :value="0"></el-option>

                  </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table border stripe height="100%" v-loading="tableLoading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="mainTable"
                  :default-sort="{prop: 'createTime', order: 'descending'}"
                  @sort-change="tableSortChange"

        >
          <el-table-column label="用户名称" prop="nickName" min-width="150"/>
          <el-table-column label="角色名称" prop="roleList" min-width="150">
            <template slot-scope="scope">
              <el-tag type="info" v-for="item in scope.row.roleList" size="mini" style="margin-right: 4px;">
                {{ item.roleName }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="手机号" prop="phone" min-width="150"/>
          <el-table-column label="邮箱" prop="email" min-width="150"/>
          <el-table-column label="所属部门" prop="departmentName" min-width="150"/>
          <el-table-column label="状态" align="center" min-width="100">
            <template slot-scope="scope">
              <el-switch
                class="switch"
                active-color="#F1880D"
                active-text="启用"
                inactive-text="停用"
                inactive-color="#B5CCF5"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleStatusChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" min-width="150" sortable="custom"/>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleResetPwd(scope.row)"
              >重置</el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <pagination
          v-show="tableDataTotal>0"
          :total="tableDataTotal"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="queryList"
          :page-sizes="[10, 20, 50, 100]"
        />
      </el-footer>
    </el-container>

    <el-dialog :title="editDialogTitle" width="60%"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="editForm" status-icon :model="editFormData" label-width="140px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名称:" prop="nickName"
                          :rules="[
                            { required: true, message: '请输入用户名称', trigger: 'blur'},
                            { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                            ]"
            >
              <el-input placeholder="请输入用户名称" maxlength="30" clearable show-word-limit style="width:90%;"
                        v-model.trim="editFormData.nickName"

              />
            </el-form-item>
          </el-col>


          <el-col :span="12" v-if="isEmpty(editFormData.id)">
            <el-form-item label="手机号:" prop="phone"
                          :rules="[
                            { required: true, message: '请输入手机号', trigger: 'blur'},
                            { min: 11, max: 11, message: '手机号长度在11个字符', trigger: 'blur' },
                            { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: '请输入正确的手机号码',trigger: 'blur'
            }
                            ]"
            >
              <el-input placeholder="请输入手机号" maxlength="11" clearable show-word-limit style="width:90%;"
                        v-model.trim="editFormData.phone"
              />
              <div style="color:#ea8a3d;font-weight: bold;font-size: 12px;height: 20px;">*手机号就是登录用户名</div>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="部门:" prop="department"
                          :rules="[{ required: true, message: '请选择部门', trigger: 'blur'}]"
            >
              <el-select v-model="editFormData.department"
                         style="width:90%;"
                         filterable
                         placeholder="请选择部门" clearable
              >
                <el-option :label="item.dictLabel" :value="item.id" :key="item.id" v-for="item in sysUserDeptDict"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="角色:" prop="roleIdList"
                          :rules="[{ required: true, message: '请选择角色', trigger: 'blur'}]"
            >
              <el-select v-model="editFormData.roleIdList"
                         multiple
                         style="width:90%;"
                         filterable
                         placeholder="请选择角色" clearable
              >
                <el-option :label="item.roleName" :value="item.id" :key="item.id" v-for="item in roleList"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="邮箱:" prop="email"
                          :rules="[
                            { required: true, message: '请输入邮箱', trigger: 'blur'},
                            { min: 6, max: 50, message: '邮箱长度在6-50个字符', trigger: 'blur' },
                            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                            ]"
            >
              <el-input placeholder="请输入邮箱" maxlength="50" clearable show-word-limit style="width:90%;"
                        v-model.trim="editFormData.email"
              />
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="editFormData.status">
                <el-radio :label=1>正常</el-radio>
                <el-radio :label=0>停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注信息:" prop="remark"
            >
              <el-input type="textarea" :rows="2" placeholder="请输入备注信息" style="width:90%;"
                        v-model="editFormData.remark"
                        maxlength="400" clearable show-word-limit

              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="confirmEdit" style="background-color: #F1880D;border-color: #F1880D;">确 定</el-button>
      </span>
    </el-dialog>

  </div>


</template>
<script>
import {
  addUserApi,
  changeUserStatusApi,
  delUserApi,
  editUserApi, resetPwdApi,
  userInfoApi,
  userListApi
} from '@/modules/crm/api/system/user'
import { getAllRoleApi } from '@/modules/crm/api/system/role'
import { resetUserPwd } from '@/api/system/user'

export default {
  name: 'sysUser',
  data() {
    return {
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc'
      },
      editDialogTitle: '新增角色',
      editDialogVisible: false,
      editFormData: {},
      //角色列表
      roleList: [],
      //用户部门字典
      sysUserDeptDict: this.getDict(this.dictConst.SYS_USER_DEPT)

    }
  },
  created() {
    this.handleQuery()
    //console.log('dictConst.SYS_USER_DEPT', this.isEmpty(1),this.msgInfo())
    this.getAllRole()
  },
  methods: {
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      userListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status == 1 ? '启用' : '停用'
      this.$confirm('确认要"' + text + '""' + row.nickName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return changeUserStatusApi(row.id, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status == 1 ? 0 : 1
      })
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      this.editDialogTitle = '编辑用户'
      this.pageLoading = true
      this.reset()
      const userId = row.id
      this.editDialogVisible = true
      userInfoApi(userId).then(res => {
        this.editFormData = res.data
        this.pageLoading = false
      })

    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除用户【' + row.nickName + '】?', '警告', {
        confirmButtonText: '确定',
        customClass: 'crm-delete-user-confirm',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return delUserApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 密码重置操作 */
    handleResetPwd(row){
      this.$confirm('是否确认重置用户【' + row.nickName + '】的登录密码?重置后的密码为手机号后6位', '警告', {
        confirmButtonText: '确定',
        customClass: 'crm-delete-user-confirm',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return resetPwdApi(row.id)
      }).then(() => {
        this.msgSuccess('重置成功,密码是手机号后6位')
      })
    },
    /** 新建按钮操作 */
    handleAdd() {
      this.editDialogTitle = '新建用户'
      this.reset()
      this.editDialogVisible = true

    },
    /** 取消编辑 */
    cancelEdit() {
      this.editDialogVisible = false
      this.reset()
    },
    /** 确定编辑 */
    confirmEdit() {
      console.log('this.editFormData', this.editFormData)
      this.$refs['editForm'].validate(async valid => {
        if (valid) {
          let res
          if (this.editFormData.id) {
            res = await editUserApi(this.editFormData)
          } else {
            res = await addUserApi(this.editFormData)
          }
          if (res.code == 200) {
            this.msgSuccess()
            this.handleQuery()
            this.reset()
            this.editDialogVisible = false
          }

        } else {
          this.msgError('请规范填写')
        }
      })

    },
    /** 表单重置 */
    reset() {
      this.editFormData = {
        nickName: '',
        phone: '',
        department: undefined,
        roleIdList: [],
        email: '',
        remark: '',
        status: 1
      }
      this.resetForm('editForm')
    },
    //获取角色列表
    getAllRole() {
      getAllRoleApi().then(res => {
        this.roleList = res.data

      })
    }

  }
}
</script>
<style lang="scss" scoped>


.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}


</style>

<style lang="scss">
.crm-delete-user-confirm {
  .el-button--primary {
    background-color: #F1880D !important;
    border-color: #F1880D !important;
  }
}
</style>
