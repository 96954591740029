import request from '@/modules/crm/utils/request'

//获取菜单下拉树列表
export function menuSelectTreeApi() {
  return request({
    url: '/crm/sys/menu/menuSelectTree',
    method: 'post',
  })
}


//获取全部菜单列表
export function menuListApi(data) {
  return request({
    url: '/crm/sys/menu/menuList',
    method: 'post',
    data: data
  })
}

// 查询菜单详细
export function menuInfoApi(menuId) {
  return request({
    url: '/crm/sys/menu/menuInfo/' + menuId,
    method: 'post'
  })
}


// 新增/修改菜单
export function editMenuApi(data) {
  return request({
    url: '/crm/sys/menu/editMenu',
    method: 'post',
    data: data
  })
}



//删除菜单
export function delMenuApi(menuId) {
  return request({
    url: '/crm/sys/menu/delMenu/' + menuId,
    method: 'post'
  })
}

