var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("权限测试页面")]),
    _vm._v(" 所有权限： " + _vm._s(_vm.user.permissions) + " "),
    _c("p"),
    _c(
      "div",
      [
        _vm._v(" 有 permissionsConst.test.add 权限才会显示新增按钮 "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: [_vm.permissionsConst.test.add],
                expression: "[permissionsConst.test.add]",
              },
            ],
          },
          [_vm._v("新增")]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _vm._v(" 有 permissionsConst.test.edit 权限才会显示修改按钮 "),
        _c(
          "el-button",
          {
            directives: [
              {
                name: "hasPermi",
                rawName: "v-hasPermi",
                value: [_vm.permissionsConst.test.edit],
                expression: "[permissionsConst.test.edit]",
              },
            ],
          },
          [_vm._v("修改")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }