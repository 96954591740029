/**任务 */
export const TASK_OBJ = {
  contractId: '',
  taskName: '',
  //项目ID
  projectId: undefined,
  //产品ID
  productId: undefined,
  //提出人
  feedbackUser: undefined,
  //提出人类型
  feedbackUserType: undefined,
  //feedbackUserKey
  feedbackUserKey:undefined,
  //任务紧急级别
  taskUrgencyLevel:undefined,
  //指派人
  assignUser: undefined,
  //问题类型 数组
  problemTypeList: [],
  //任务描述
  taskDesc: '',
  //截止时间
  endTime: undefined,
  //上午下午
  endTimeExt: new Date().getHours() >= 12 ? '下午' : '上午',
  //附件id列表
  taskFileIdList: []
}
export const TASK_TYPE_LIST = [
  {
    id: 1,
    dictLabel: '全部'
  },
  {
    id: 0,
    dictLabel: '进行中'
  },
  {
    id: 2,
    dictLabel: '指给我'
  },
  {
    id: 3,
    dictLabel: '我发布'
  }
]
export const TASK_TAB_LIST = []
export const QUERY_OBJ = {
  pageNum: 1,
  pageSize: 10,
  orderByColumn: 'startTime',
  orderByField: '',
  isAsc: 'desc',
  logId: '', // 登录人ID
  roleIds: [],
  searchValue: '',
  serveType: undefined, // 服务类型（远程支持等，使用task_serve_type字典）
  assignUser: undefined, // 指派人ID
  taskType: '2', // 任务类型 (1全部(默认) 2指给我 3我发布 )
  taskStatus: undefined, // 
  taskStatusList: [], // 任务状态,多选(待处理、已处理等，使用task_status字典)
  productStatusList: [], // 产品状态,多选(使用project_status字典)
  provinceCodeList: [], // 省份代码,多选(使用crm_dict_region字典)
  productId: '', // 产品ID
  productIdArr: [],
  tabCode: '2', // 1全部(默认) 2指给我 3我发布, + 任务状态（超期未解决，待处理，已处理，已解决；进行中 = 超期未解决 + 待处理 + 已处理）
}
// 任务管理-新建客户联系人contactsList好对应的 obj
export const ADD_CUSTOM_ITEM_OBJ = {
  department: '', // 科室(使用org_contacts_department字典)
  email: '',
  isPrimary: 0, // 首选项 (1是 0否)
  name: '',
  phoneList: [
    {
      phone: '',
      id: 0
    }
  ],
  post: '', // 职务(使用org_contacts_post字典)
  remark: '', // 备注信息
  wxAccount: '', // 
  departmentOptions: [],
  postOptions: []
}
// 任务管理-新建客户联系人 
export const ADD_CUSTOM_OBJ = {
  contactsList: [
    {
      department: '', // 科室(使用org_contacts_department字典)
      email: '',
      isPrimary: 0, // 首选项 (1是 0否)
      name: '',
      phoneList: [
        {
          phone: '',
          id: 0
        }
      ],
      post: '', // 职务(使用org_contacts_post字典)
      remark: '', // 备注信息
      wxAccount: '', // 
      departmentOptions: [],
      postOptions: []
    }
  ],
  customerId: '' // 终端客户ID
}
// export function removeElementFrom2DArray(array, rowIndex, colIndex) {
//   array.splice(rowIndex, 1) // 删除指定行
  
//   // 如果有必要，重新索引数组
//   if (colIndex !== 0) {
//     array = array.map((row, index) => {
//       return row.splice(colIndex, 1).map((value, innerIndex) => {
//         return innerIndex + colIndex >= row.length ? null : row[innerIndex + colIndex];
//       })
//     }).filter(Boolean) // 过滤掉空行
//   }
//   return array
// }
// 删除二维数组的某一行某一列
export function removeElementFrom2DArray(arr, rowIndex, colIndex) {
  // 创建一个新的二维数组，用于存放删除元素后的结果
  /* let result = [];
 
  for (let i = 0; i < arr.length; i++) {
    // 跳过要删除的行
    if (i === rowIndex) continue;
 
    // 创建一个新的一维数组，用于存放删除列元素后的结果
    let row = [];
 
    for (let j = 0; j < arr[i].length; j++) {
      // 跳过要删除的列
      if (j === colIndex) continue;
 
      row.push(arr[i][j]);
    }
 
    // 将新的一维数组添加到结果二维数组中
    result.push(row);
  }
 
  return result; */
  // 删除指定位置的元素
  arr[rowIndex].splice(colIndex, 1)
 
  // 如果该行之后的行不满三个元素，则上移该行的剩余元素
  if (arr[rowIndex].length < 3) {
    for (let i = rowIndex + 1; i < arr.length; i++) {
      if (arr[i].length > 0) {
        // 将当前行的元素移动到上一行
        arr[i - 1] = arr[i - 1].concat(arr[i].splice(0, 1))
      }
    }
    // 删除最后一行如果为空
    while (arr[arr.length - 1].length === 0 && arr.length > 0) {
      arr.pop()
    }
  }
}