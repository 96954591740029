<template>
  <el-dialog title="服务报告预览"
             width="90%" append-to-body :visible.sync="dialogVisible"
             @close="closeDialog"
             top="5vh"

             :close-on-click-modal="false"
  >
    <div   v-loading="loading">
      <div style="padding: 6px;">
        <el-button class="crm_btn_style" icon="el-icon-download" size="small" @click="tableToExcel">下载服务报告</el-button>
      </div>
      <div id="html-content" ref="htmlToPdf">
        <div style="">
          <div style="">
            <span></span>
          </div>
          <table
            style=" width:100%;font-size: 14px;border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;border-collapse: collapse;">
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;">
              <td style="" colspan="12">
                <div style="text-align: center;font-size: 20px;font-weight: bold;">服务报告</div>
                <div style="text-align: right;padding-right: 20px;">服务报告日期:{{a1.reportDate}}</div>
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 120px;" rowspan="11">客户档案项目</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 120px;">客户名称</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="10">{{
                  a1.customerName
                }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">联系人</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">{{
                  a1.contactName
                }}
              </td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">合同</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="7">{{ a1.contract }}</td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">卫宁联系人</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">
                {{ a1.winningContactName }}
              </td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 220px;">卫心联系人</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="7">
                {{ a1.weixinContactName }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" rowspan="7">维保期服务</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">沟通方式</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.communicationMethod }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">沟通频率</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.communicationFrequency }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">数据更新计划</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">{{
                  a1.dataUpdatePlan
                }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">公共数据库账号</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.publicDatabaseAccount }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">卫心产品服务群</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.weixinProductServiceGroup }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">头号药师群邀请</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.thysGroupInvitation }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">增值服务项</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                {{ a1.valueAddedServiceItem }}
              </td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">备注</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="10">
                {{ a1.remark }}
              </td>
            </tr>


            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :rowspan="a2.length+1">服务记录项</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :rowspan="a2.length+1">问题清单</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">提出日期</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 80px;">提出人</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">产品名称</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 120px;">问题类型</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">任务描述</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">承诺解决日期</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">实际解决日期</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">解决方法</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">核实日期</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;width: 120px;">核实情况</td>
            </tr>
            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                :style="{ background:index%2==0?'#ffffff':'#ececec' }"
                v-for="(item,index) in a2">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{ item.startTime }}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{ item.questionUser }}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{ item.productName }}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{item.problemName}}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"><p v-html="filterTaskDesc(item.taskDesc)"></p></td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{item.endTime}}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{item.solveTime}}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"><p v-html="filterTaskDesc(item.solveSolution)"></p></td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">{{item.checkTime}}</td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"><p v-html="filterTaskDesc(item.checkSolution)"></p></td>
            </tr>

            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" v-if="isEmpty(a2)">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;color: #878686" colspan="12">暂无服务记录</td>
            </tr>

            <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" v-if="isEmpty(a3)">
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
                满意度调查项
              </td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;">
                回访清单
              </td>
              <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;color: #878686" colspan="10">暂无回访记录</td>
            </tr>

            <template  v-for="(a3Item,a3Index) in a3">
              <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                  :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :rowspan="a31Rowspan"
                    v-if="a3Index==0">满意度调查项
                </td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :rowspan="a31Rowspan"
                    v-if="a3Index==0">回访清单
                </td>

                <template v-if="a3Item.productScore">
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :colspan="2"
                      :rowspan="a3Item.productScore.length">产品满意评分
                  </td>
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :colspan="3">
                    {{ a3Item.productScore[0].productName }}
                  </td>
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="5">
                    {{ a3Item.productScore[0].ratingName }}
                  </td>
                </template>


              </tr>

              <tr
                v-for="(productScoreItem,productScoreIndex) in (a3Item.productScore && a3Item.productScore.length>1?a3Item.productScore.slice(1):[])"
                :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :colspan="3">
                  {{ productScoreItem.productName }}
                </td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="5">
                  {{ productScoreItem.ratingName }}
                </td>
              </tr>


              <template v-if="a3Item.productSuggest">
                <tr v-for="(productSuggestItem,productSuggestIndex) in (a3Item.productSuggest?a3Item.productSuggest:[])"
                    :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :colspan="2"
                      :rowspan="a3Item.productSuggest.length"
                      v-if="productSuggestIndex==0">产品建议
                  </td>
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" :colspan="3">
                    {{ productSuggestItem.productName }}
                  </td>
                  <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="5">
                    {{ productSuggestItem.suggestion }}
                  </td>
                </tr>
              </template>


              <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                  :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">

                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">服务满意度评分</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">
                  {{ a3Item.serviceRatingName }}
                </td>
              </tr>


              <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                  :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">

                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">服务建议</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">{{a3Item.serviceSuggestion}}</td>
              </tr>
              <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                  :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">

                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">回访时间</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="3">{{a3Item.followUpTime}}</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">回访人</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="3">{{a3Item.followUpBy}}</td>
              </tr>
              <tr style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;"
                  :style="{ background:a3Index%2==0?'#ffffff':'#ececec' }">

                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="2">客户回执证明</td>
                <td style="border: 0.3px solid #0e0d0d;padding: 8px;text-align: center;" colspan="8">{{a3Item.receiptProof}}</td>
              </tr>
            </template>


          </table>
        </div>


      </div>
    </div>

  </el-dialog>

</template>

<script>
import {
  createServiceReportApi
} from '@/modules/crm/api/crm/returnVisit'
import { isEmpty } from '@/utils/common'
export default {
  name: "ServiceReport",
  props: {
    visible: {type: Boolean},
    customerId: {type: Number},
    customerName: {type: String}
  },
  watch: {
    visible: {
      deep: true,
      handler(newVal, oldVal) {
        this.dialogVisible = newVal
      }
    }
  },
  data() {
    return {

      loading: true,
      dialogVisible: this.visible,
      a1: {},
      a2: [],
      a3: []


    }
  },
  created() {
    this.loading=true
    createServiceReportApi(this.customerId).then(res => {
      this.a1 = res.data.customerInfoReport
      this.a2 = res.data.serviceRecordReport
      this.a3 = res.data.satisfactSurvey
      this.loading=false
    })
  },
  computed: {
    a31Rowspan() {
      let rowspan = 0;
      this.a3.forEach(item => {
        rowspan += item.productScore.length
        rowspan += item.productSuggest.length
        rowspan += 4
      })

      return rowspan;
    }
  },
  methods: {
    //关闭弹窗
    closeDialog() {
      // 通知父组件，关闭弹窗
      this.dialogVisible = false
      this.$emit('update:visible', false)
    },
    base64(content) {
      return window.btoa(unescape(encodeURIComponent(content)));
    },
    tableToExcel() {
      const element = document.getElementById('html-content'); // 获取包含HTML内容的元素
      var excelContent = element.innerHTML;
      var excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
      excelFile += "<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>服务报告</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head>";
      excelFile += "<body><table>";
      excelFile += excelContent;
      excelFile += "</table></body>";
      excelFile += "</html>";
      var link = "data:application/vnd.ms-excel;base64," + this.base64(excelFile);
      var a = document.createElement("a");
      let nowDate = this.parseTime(new Date().getTime() - 1 * 24 * 60 * 60 * 1000, '{y}{m}{d}{h}{i}{s}')
      var fileName = this.customerName + "-服务报告-" + nowDate;
      a.download = fileName + ".xlsx";
      a.href = link;
      a.click();
    },
    filterTaskDesc(taskDesc){
      if(!isEmpty(taskDesc)){
        return taskDesc.replaceAll("<img","<img style='display:none;'")
      }else{
        return ""
      }

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding: 0 20px 20px 20px;
}

</style>
