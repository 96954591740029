var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionContract_wrap" },
    [
      _c(
        "el-row",
        { staticClass: "editProductWrap" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("属性")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.orgTypeName)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("机构名称")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.orgName)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "editProductWrap" },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("span", { staticClass: "editLabel" }, [_vm._v("别名")]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 22 } },
            _vm._l(_vm.institutionList.aliasList, function (item, index) {
              return _c("span", { key: item.id, staticClass: "editInput" }, [
                _vm._v(" " + _vm._s(item.aliasName) + " "),
                index < _vm.institutionList.aliasList.length - 1
                  ? _c("span", [_vm._v("、")])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "editProductWrap" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [
                  _vm._v("社会统一信用代码"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.uscCode)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("机构等级")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.orgLevelName)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "editProductWrap" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("所属省份")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.provinceName)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("所属城市")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.institutionList.cityName)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "editProductWrap" },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("span", { staticClass: "editLabel" }, [_vm._v("备注")]),
          ]),
          _c("el-col", { attrs: { span: 22 } }, [
            _c("span", { staticClass: "editInput" }, [
              _vm._v(_vm._s(_vm.institutionList.remark)),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "editProductWrap_contact" },
        [
          _c("el-col", { attrs: { span: 2 } }, [
            _c("span", { staticClass: "editLabel" }, [_vm._v("联系人")]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 22 } },
            _vm._l(_vm.institutionList.contactsList, function (item, index) {
              return _c("div", { key: item.id, staticClass: "productWrap01" }, [
                _c(
                  "div",
                  { staticClass: "productItem" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "contactName", attrs: { span: 16 } },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 8 },
                          },
                          [
                            item.isPrimary == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      float: "right",
                                    },
                                    attrs: { type: "text", size: "mini" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon svg-icon shouyao",
                                        staticStyle: {
                                          width: "38px",
                                          height: "20px",
                                        },
                                        attrs: { "aria-hidden": "true" },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-shouyao",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      float: "right",
                                    },
                                    attrs: { type: "text", size: "mini" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "icon svg-icon bushishouyao",
                                        staticStyle: {
                                          width: "38px",
                                          height: "20px",
                                        },
                                        attrs: { "aria-hidden": "true" },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-bushishouyao",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("部门")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [_c("span", [_vm._v(_vm._s(item.departmentName))])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("职务")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [_c("span", [_vm._v(_vm._s(item.postName))])]
                        ),
                      ],
                      1
                    ),
                    _vm._l(item.phoneList, function (phoneItem, phoneIndex) {
                      return _c(
                        "el-row",
                        { key: phoneItem.id },
                        [
                          _c(
                            "el-col",
                            { staticClass: "productTitle", attrs: { span: 6 } },
                            [_vm._v("电话" + _vm._s(Number(phoneIndex + 1)))]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "productSelect",
                              attrs: { span: 18 },
                            },
                            [_c("span", [_vm._v(_vm._s(phoneItem.phone))])]
                          ),
                        ],
                        1
                      )
                    }),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("邮箱")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [_c("span", [_vm._v(_vm._s(item.email))])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("微信")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [_c("span", [_vm._v(_vm._s(item.wxAccount))])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("备注")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "remarkStyle",
                                attrs: { title: item.remark },
                              },
                              [_vm._v(" " + _vm._s(item.remark) + " ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }