var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newConstructionContract_wrap" }, [
    _vm.projectId
      ? _c(
          "div",
          [
            _c("div", { staticClass: "projectTitle" }, [_vm._v("客户方")]),
            _c(
              "el-row",
              { staticClass: "editProductWrap_contact" },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { display: "flex", "flex-wrap": "wrap" },
                    attrs: { span: 24 },
                  },
                  _vm._l(
                    _vm.projectObject.purchaserContactsList,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "productWrap01" },
                        [
                          _c(
                            "div",
                            { staticClass: "productItem" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "contactName",
                                      attrs: { span: 24 },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("类型")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      item.orgId == _vm.projectObject.purchaser
                                        ? _c(
                                            "span",
                                            { staticClass: "typeSpan" },
                                            [_vm._v("购买方")]
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.projectObject.customerList,
                                        function (i) {
                                          return [
                                            item.orgId == i.customerId
                                              ? _c(
                                                  "span",
                                                  { staticClass: "typeSpan" },
                                                  [_vm._v(" 终端客户 ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("部门")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.departmentName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("职务")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.postName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                item.phoneList,
                                function (phoneItem, phoneIndex) {
                                  return _c(
                                    "el-row",
                                    { key: phoneItem.id },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [
                                          _vm._v(
                                            "电话" +
                                              _vm._s(Number(phoneIndex + 1))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(phoneItem.phone)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("邮箱")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.email))])]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("微信")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.wxAccount)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("备注")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "remarkStyle",
                                          attrs: { title: item.remark },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.remark) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _c("div", { staticClass: "projectTitle" }, [_vm._v("乙方")]),
            _c(
              "el-row",
              { staticClass: "editProductWrap_contact" },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { display: "flex", "flex-wrap": "wrap" },
                    attrs: { span: 24 },
                  },
                  _vm._l(
                    _vm.projectObject.sellerContactsList,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "productWrap01" },
                        [
                          _c(
                            "div",
                            { staticClass: "productItem" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "contactName",
                                      attrs: { span: 24 },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("类型")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      item.orgId === _vm.projectObject.seller
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "appreciationPackage",
                                            },
                                            [_vm._v("经销")]
                                          )
                                        : item.orgId ===
                                          _vm.projectObject.distribution
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "appreciationPackage",
                                            },
                                            [_vm._v("分销")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("部门")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.departmentName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("职务")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.postName)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                item.phoneList,
                                function (phoneItem, phoneIndex) {
                                  return _c(
                                    "el-row",
                                    { key: phoneItem.id },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [
                                          _vm._v(
                                            "电话" +
                                              _vm._s(Number(phoneIndex + 1))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(phoneItem.phone)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("邮箱")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.email))])]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("微信")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.wxAccount)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 6 },
                                    },
                                    [_vm._v("备注")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect",
                                      attrs: { span: 18 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "remarkStyle",
                                          attrs: { title: item.remark },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.remark) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _vm.projectObject.sellerType !==
            _vm.dictDataConst.CONTRACT_SELLER_TYPE.SELL
              ? _c("div", { staticClass: "projectTitle" }, [_vm._v("卫心方")])
              : _vm._e(),
            _vm.projectObject.sysUserContactsList &&
            _vm.projectObject.sysUserContactsList.length > 0
              ? _c(
                  "el-row",
                  { staticClass: "editProductWrap_contact" },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        attrs: { span: 24 },
                      },
                      _vm._l(
                        _vm.projectObject.sysUserContactsList,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "productWrap01" },
                            [
                              _c(
                                "div",
                                { staticClass: "productItem" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "contactName",
                                          attrs: { span: 24 },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("部门")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.departmentName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("职务")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.postName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("电话")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.phone)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("邮箱")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.email)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("微信")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.wxAccount)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productTitle",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("备注")]
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "productSelect",
                                          attrs: { span: 18 },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "remarkStyle",
                                              attrs: { title: item.remark },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.remark) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c("div", { staticClass: "noDataWrap" }, [_vm._m(0)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noData" }, [
      _c("img", {
        staticStyle: { width: "96px", height: "60px" },
        attrs: { src: require("../assets/images/noData.png") },
      }),
      _c("div", { staticClass: "noDataText" }, [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }