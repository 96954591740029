var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionContract_wrap" },
    [
      _vm.contractList.length > 0 || _vm.contractId
        ? _c(
            "div",
            [
              !_vm.contractId
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "400px",
                                "margin-bottom": "10px",
                              },
                              attrs: {
                                placeholder: "请选择合同编号",
                                size: "small",
                              },
                              on: { change: _vm.contractChange },
                              model: {
                                value: _vm.contractValue,
                                callback: function ($$v) {
                                  _vm.contractValue = $$v
                                },
                                expression: "contractValue",
                              },
                            },
                            _vm._l(_vm.contractList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.contractName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _c("span", { staticClass: "projectNum" }, [
                            _vm._v(
                              "共" +
                                _vm._s(
                                  _vm.contractListLen ? _vm.contractListLen : 0
                                ) +
                                "个合同"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("分类")]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.contractObject.contractClassifyName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("合同性质"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(
                        " " + _vm._s(_vm.contractObject.contractTypeName) + " "
                      ),
                      _vm.contractObject.contractType ===
                      _vm.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                        ? _c("span", [
                            _vm._v(
                              "-(" +
                                _vm._s(_vm.contractObject.serveTypeName) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.contractObject.serveType !==
                _vm.getDict(this.dictConst.CONTRACT_SERVE_TYPE)[0].id &&
              _vm.contractObject.contractType ===
                _vm.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                ? _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("服务时间"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _vm.contractObject.serveStartTime
                          ? _c("span", { staticClass: "editInput" }, [
                              _vm._v(
                                _vm._s(_vm.contractObject.serveStartTime) +
                                  "至" +
                                  _vm._s(_vm.contractObject.serveEndTime)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contractObject.contractType ===
              _vm.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                ? _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("备注"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.contractObject.remark)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("项目分类"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.contractObject.projectClassifyName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("购买方"),
                      _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v("(甲方)"),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _c("span", { staticClass: "orgListItem" }, [
                        _vm._v(_vm._s(_vm.contractObject.purchaserName)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("终端客户"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.contractObject.customerList,
                      function (item, index) {
                        return _c(
                          "span",
                          { key: item.id, staticClass: "editInput" },
                          [
                            _c("span", { staticClass: "orgListItem" }, [
                              _vm._v(_vm._s(item.customerName)),
                            ]),
                            index < _vm.contractObject.customerList.length - 1
                              ? _c("span", [_vm._v("、")])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("乙方")]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _vm.contractObject.sellerTypeName
                      ? _c("span", { staticClass: "appreciationPackage" }, [
                          _vm._v(_vm._s(_vm.contractObject.sellerTypeName)),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "orgListItem" }, [
                      _vm._v(" " + _vm._s(_vm.contractObject.sellerName)),
                    ]),
                    _vm.contractObject.distribution
                      ? _c(
                          "span",
                          {
                            staticClass: "appreciationPackage",
                            staticStyle: { margin: "0px 6px 0px 10px" },
                          },
                          [_vm._v("分销")]
                        )
                      : _vm._e(),
                    _vm.contractObject.distribution
                      ? _c("span", { staticClass: "orgListItem" }, [
                          _vm._v(_vm._s(_vm.contractObject.distributionName)),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("名称")]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.contractObject.contractName)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("编号")]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.contractObject.contractNum)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("金额")]),
                  ]),
                  _vm.contractObject.contractPrice
                    ? _c("el-col", { attrs: { span: 22 } }, [
                        _vm.isTotalMoneyShow
                          ? _c("span", { staticClass: "editInput" }, [
                              _vm._v(
                                _vm._s(_vm.contractObject.contractPrice) +
                                  "万元"
                              ),
                            ])
                          : _c("span", { staticClass: "editInput" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("starNumbers")(
                                    _vm.contractObject.contractPrice
                                  )
                                ) + "万元"
                              ),
                            ]),
                        _vm.contractPriceShowPermission
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  color: "#C4C4C4",
                                },
                              },
                              [
                                _vm.isTotalMoneyShow
                                  ? _c(
                                      "i",
                                      {
                                        staticClass: "iconfont",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            _vm.isTotalMoneyShow = false
                                          },
                                        },
                                      },
                                      [_vm._v("")]
                                    )
                                  : _c(
                                      "i",
                                      {
                                        staticClass: "iconfont",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            _vm.isTotalMoneyShow = true
                                          },
                                        },
                                      },
                                      [_vm._v("")]
                                    ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("下单日期"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.contractObject.orderTime)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("维护期限"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(
                        _vm._s(_vm.contractObject.maintenanceDeadline) + "年"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("附件")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.getList(_vm.contractObject, "contractFileList"),
                      function (fileItem, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "attachment" },
                          [
                            _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                            _c(
                              "span",
                              {
                                staticClass: "editInput",
                                staticStyle: {
                                  "margin-left": "4px",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPreviewFile(fileItem)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(fileItem.fileName))]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("产品")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(_vm.contractObject.productList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "productWrap02" },
                        [
                          _c(
                            "div",
                            { staticClass: "productItem" },
                            [
                              _c("div", { staticClass: "relatedProductName" }, [
                                _vm._v(_vm._s(item.productName)),
                              ]),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "productTitleName" },
                                        [_vm._v("增值包")]
                                      ),
                                      item.extrasList &&
                                      item.extrasList.length > 0
                                        ? _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                trigger: "hover",
                                                content:
                                                  item.extrasList[0].extrasName,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "appreciationPackage appreciationPackageName",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.extrasList[0]
                                                        .extrasName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            "popper-class": "projectUl_popover",
                                            placement: "bottom",
                                            width: "220",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            item.extrasList.slice(1),
                                            function (i) {
                                              return _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#5D5D5D",
                                                    "font-size": "14px",
                                                    "line-height": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(i.extrasName) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          item.extrasList.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "appreciationPackage",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    "+" +
                                                      _vm._s(
                                                        item.extrasList.length -
                                                          1
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "productTitleName" },
                                        [_vm._v("产品金额")]
                                      ),
                                      item.productAmount
                                        ? _c("span", [
                                            item.isTotalMoneyShow
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "productAmountNum",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.productAmount
                                                      ) + "万元"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "productAmountNum",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("starNumbers")(
                                                          item.productAmount
                                                        )
                                                      ) + "万元"
                                                    ),
                                                  ]
                                                ),
                                            _vm.contractPriceShowPermission
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                      color: "#C4C4C4",
                                                    },
                                                  },
                                                  [
                                                    item.isTotalMoneyShow
                                                      ? _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "iconfont",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$forceUpdate()
                                                                  item.isTotalMoneyShow =
                                                                    !item.isTotalMoneyShow
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("")]
                                                        )
                                                      : _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "iconfont",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$forceUpdate()
                                                                  item.isTotalMoneyShow =
                                                                    !item.isTotalMoneyShow
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("")]
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "noDataWrap" }, [_vm._m(0)]),
      _c("preview-file", {
        attrs: {
          "is-show-dialog": _vm.showPreviewFile,
          "file-id": _vm.previewFileId,
        },
        on: {
          "update:isShowDialog": function ($event) {
            _vm.showPreviewFile = $event
          },
          "update:is-show-dialog": function ($event) {
            _vm.showPreviewFile = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noData" }, [
      _c("img", {
        staticStyle: { width: "96px", height: "60px" },
        attrs: { src: require("../assets/images/noData.png") },
      }),
      _c("div", { staticClass: "noDataText" }, [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }