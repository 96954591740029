<template>
<!--编辑回访内容-->
  <div class="drvc_wrap">
    <el-form ref="form" :model="form" :rules="rules">
      <el-row class="rrTimeWrap">
        <el-col :span="12" class="rrTime">
          <b>回访时间</b>
          <span>
            <el-form-item label="" prop="followUpTime">
              <el-date-picker
                v-model="form.followUpTime"
                value-format="yyyy-MM-dd"
                clearable
                size="small"
                type="date"
                placeholder="请输入"
                style="width: 204px;">
              </el-date-picker>
            </el-form-item>
          </span>
        </el-col>
        <el-col :span="12">
<!--          <div style="float: right">-->
<!--            <el-button-->
<!--              size="mini"-->
<!--              @click="editCancel">取消</el-button>-->
<!--            <el-button-->
<!--              class="crm_btn_style"-->
<!--              size="mini"-->
<!--              @click="saveFpSatisSurvey">保存</el-button>-->
<!--          </div>-->
        </el-col>
      </el-row>
      <div style="background: #fff;margin: 10px;padding: 6px;">
        <div class="suggestMark">
          产品满意评分
          <el-button
            class="crm_btn_style"
            size="mini"
            style="margin-left: 12px;"
            @click="addProductItem">
            添加
          </el-button>
        </div>
        <div v-for="(item,index) in form.fpProductRatingList" :key="item.id">
          <div class="suggestTitle">
            <el-row>
              <el-col :span="12">
                <div style="line-height: 24px;display: flex;">
                  <span class="productDel" @click="productDelHandle(index)">
                     <i class="iconfont">&#xe6ad;</i>
                  </span>
                  <span>
                    {{index+1}}.
<!--                    {{ item.productName}}-->
                  </span>
                  <el-select
                    v-model="item.productKey"
                    placeholder="请选择产品名称"
                    size="mini"
                    style="width:260px;"
                    @change="(val) => choiceProduct(val, index)">
                    <el-option
                      v-for="i in productList"
                      :disabled="setDisabled(i,index)"
                      :label="i.productName"
                      :value="i.productKey"
                      :key="i.productKey"
                    >
                    </el-option>
                  </el-select>
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    v-if="item.extrasList&&item.extrasList.length>0"
                    :content="item.extrasList[0].extrasName">
                    <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                  </el-popover>
                  <el-popover
                    popper-class="projectUl_popover"
                    placement="bottom"
                    width="220"
                    trigger="click">
                    <div
                      v-for="i in item.extrasList.slice(1)"
                      style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                      {{ i.extrasName }}
                    </div>
                    <span style="cursor: pointer" slot="reference" class="appreciationPackage lastNum" v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                  </el-popover>
                </div>
              </el-col>
              <el-col :span="12" style="text-align: right;">
                <span
                  class="nomark"
                  v-for="i in visitGradeDict"
                  :class="{ mark: i.id == item.rating}"
                  @click="ratingChange(i,item)">
                  {{ i.dictLabel }}
                </span>
              </el-col>
            </el-row>
          </div>
          <div class="suggestContent">
            <el-form-item label="" prop="remark">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入建议内容"
                style="width: 99%;"
                v-model="item.suggestion"
                maxlength="500"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <div style="background: #fff;margin: 10px;padding: 6px;">
        <div class="suggestMark">
          <el-row>
            <el-col :span="12">
              <span>服务满意评分</span>
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <span
                class="nomark"
                v-for="i in visitGradeDict"
                :class="{ mark: i.id == form.serviceRating}"
                @click="serviceRatingChange(i)">
                  {{ i.dictLabel }}
                </span>
            </el-col>
          </el-row>
        </div>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">建议</span>
          </el-col>
          <el-col :span="21">
<!--            <span class="editInput"></span>-->
            <el-form-item label="" prop="serviceSuggestion">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入建议内容"
                style="width: 99%;"
                v-model="form.serviceSuggestion"
                maxlength="500"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">客户回执证明</span>
          </el-col>
          <el-col :span="21">
            <el-upload
              class="upload-demo"
              :action="baseURL+'/crm/sys/file/uploadFile'"
              :on-change="handleFileChange"
              :file-list="fileList"
              :data="uploadFileParams"
              :auto-upload="true"
              :before-upload="beforeUpload"
              accept=".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx"
              :on-success="uploadSuccess"
              :on-remove="handleFileRemove"
            >
              <el-button size="mini">添加附件</el-button>
              <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
            </el-upload>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
<!--          <el-col :span="3">-->
<!--            <span class="editLabel">本次回访类型</span>-->
<!--          </el-col>-->
          <el-col :span="21">
            <el-form-item label="本次回访类型" prop="visitType">
              <span
                v-for="(item,index) in returnVisitTypeDict"
                :key="item.id"
                class="normal_btn"
                :class="{ select_btn: item.id == form.visitType }"
                @click="visitTypeSelect(item.id)">
                {{ item.dictLabel }}
              </span>
            </el-form-item>
<!--            <span class="editInput">{{ form.visitTypeName }}</span>-->
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">后续无需服务</span>
          </el-col>
          <el-col :span="21">
            <el-switch
              v-model="form.laterServiceStatus"
              active-color="#13ce66"
              inactive-color="#d3d5d8"
              :active-value="1"
              :inactive-value="0"
              @change="laterServiceStatusChange">
            </el-switch>
          </el-col>
        </el-row>
        <el-row class="editProductWrap" v-if="!form.laterServiceStatus">
<!--          <el-col :span="3">-->
<!--            <span class="editLabel">下次回访时间</span>-->
<!--          </el-col>-->
          <el-col :span="21">
<!--            <span class="editInput"></span>-->
            <el-form-item label="下次回访时间" prop="nextFollowUpTime" label-width="106px">
              <el-date-picker
                v-model="form.nextFollowUpTime"
                value-format="yyyy-MM-dd"
                clearable
                size="small"
                type="date"
                placeholder="请选择日期"
                :picker-options="endPickerOptions"
                style="width: 204px;">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="editProductWrap" v-if="!form.laterServiceStatus">
<!--          <el-col :span="3">-->
<!--            <span class="editLabel">下次回访类型</span>-->
<!--          </el-col>-->
          <el-col :span="21">
            <el-form-item label="下次回访类型" prop="nextVisitType" label-width="106px">
              <span
                v-for="(item,index) in returnVisitTypeDict"
                :key="item.id"
                class="normal_btn"
                :class="{ select_btn: item.id == form.nextVisitType }"
                @click="nextVisitTypeSelect(item.id)">
                {{ item.dictLabel }}
              </span>
            </el-form-item>
<!--            <span class="editInput">{{ // form.nextVisitTypeName }}</span>-->
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">回访人</span>
          </el-col>
          <el-col :span="21">
            <span class="editInput">{{ form.followUpBy }}</span>
          </el-col>
        </el-row>
      </div>
      <el-row class="rrTimeWrap">
<!--        <el-col :span="12" class="rrTime">-->
<!--        </el-col>-->
        <el-col :span="24">
          <div style="float: right;padding-bottom: 10px;">
            <el-button
              size="mini"
              @click="editCancel">取消</el-button>
            <el-button
              class="crm_btn_style"
              size="mini"
              @click="saveFpSatisSurvey">保存</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  satisfactionSurveyInfo,
  queryCustomerProductArchivesList
} from '@/modules/crm/api/crm/returnVisit'
import {mapGetters} from "vuex";
import {addFpSatisSurvey, editFpSatisSurvey} from "../api/crm/returnVisit";
// import { queryCustomerProductListApi } from '@/modules/crm/api/crm/contract'
export default {
  name: "EditReturnVisitContent",
  props: {
    followUpTime: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      // required: true
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number
    }
  },
  data() {
    return {
      value: true, //后续无需服务value值
      form: {
        fpProductRatingList: [
        //   {
        //   productName: '',
        //   extrasList: [],
        //   rating: this.getDictPrimaryItemId(this.dictConst.VISIT_GRADE),  // 回访得分
        // }
        ],
        followUpTime: '',
        nextVisitType: this.getDictPrimaryItemId(this.dictConst.VISIT_TYPE), // 下次回访类型
        visitType: this.getDictPrimaryItemId(this.dictConst.VISIT_TYPE), // 本次回访类型
        serviceRating: this.getDictPrimaryItemId(this.dictConst.VISIT_GRADE),  // 回访得分
        laterServiceStatus: 0
      },
      rules: {
        visitType: [
          { required: true, message: '请选择本次回访类型', trigger: 'change' }
        ],
        nextFollowUpTime: [
          { required: true, message: '请选择下次回访时间', trigger: 'change'},
          { validator: this.validateEndTime, trigger: 'change' }
        ],
        nextVisitType: [
          { required: true, message: '请选择下次回访类型', trigger: 'change' }
        ],
      },
// 回访评分字典
      visitGradeDict: this.getDict(this.dictConst.VISIT_GRADE),
      // score: this.getDictPrimaryItemId(this.dictConst.VISIT_GRADE),  // 回访得分
      // 回访类型字典
      returnVisitTypeDict: this.getDict(this.dictConst.VISIT_TYPE),
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams:{
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.TASK_FILE,
        tempFlag: 1
      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],
      //产品列表
      productList: [],
      endPickerOptions: {
        disabledDate: time => {
          console.log(this.form.followUpTime)
          if (this.form.followUpTime) {
            return time.getTime() < new Date(this.form.followUpTime).getTime()-1 * 24 * 3600 * 1000;
          }
        }
      }
    }
  },
  computed:{
    setDisabled () {
      return function (opt, index) {
        console.log(opt)
        console.log(this.form.fpProductRatingList)
        const selectedArr = this.form.fpProductRatingList.map(item => item.productKey)
        selectedArr.splice(index, 1)
        return selectedArr.includes(opt.productKey)
      }
    },
    ...mapGetters([
      'name',
    ])
  },
  watch: {
    followUpTime(val, oldVal){
      console.log(val,oldVal)
      this.form.followUpTime = val
      this.satisfactionSurveyInfoFn(val)
    },
  },
  async created() {
    console.log(this.visitGradeDict)
    this.form.followUpTime = this.followUpTime
    await this.queryCustomerProductListApiFn()
    console.log(this.isAdd)
    if(!this.isAdd) {
      await this.satisfactionSurveyInfoFn(this.followUpTime)
    } else {
      console.log(this.name)
      if(!this.form.followUpBy) {
        this.form.followUpBy = this.name
      }
      console.log(this.productList)
      this.productList?.forEach((item,index) => {
        this.form.fpProductRatingList.push({
          productName: item.productName,
          extrasList: item.extrasList,
          productKey:item.productKey,
          rating: this.getDictPrimaryItemId(this.dictConst.VISIT_GRADE),  // 回访得分
      })
      })
    }
  },
  methods: {
    validateEndTime(rule, value, callback) {
      if (this.form.followUpTime && value < this.form.followUpTime) {
        callback(new Error('下次回访时间必须大于等于回访时间'));
      } else {
        callback();
      }
    },
    choiceProduct(val,index) {
      console.log(val,index)
      let values = val.split("#"); // 使用split方法按逗号分割字符串
      // this.form.fpProductRatingList[index].projectId = parseInt(values[0])
      this.form.fpProductRatingList[index].productId = parseInt(values[0])
      this.form.fpProductRatingList[index].contractId = parseInt(values[1])
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    editCancel() {
      this.$emit('editCancel', this.followUpTime, this.isAdd)
    },
    // 满意度调查详细信息
    async satisfactionSurveyInfoFn(date) {
      let obj = {
        archiveId: this.id,
        followUpTime: date
      }
      let res = await satisfactionSurveyInfo(obj)
      console.log(res)
      if (res.code === 200) {
        console.log(res.data)
        if(res.data) {
          this.form = this.$deepClone(res.data)
          this.fileList = []
          res.data.fpFileList?.forEach(item => {
            let fileItem = {
              fileId:  item.fileId,
              name:item.fileName,
              status: 'success',
              uid: item.fileId
            }
            this.fileList.push(fileItem)
            this.uploadFileList.push(fileItem)
          })

        }
        console.log(this.name)
        if(!this.form.followUpBy) {
          this.form.followUpBy = this.name
        }
        this.form.fpProductRatingList?.forEach((item,index)=> {
          // item.productKey=item.projectId+"#"+item.productId+"#"+item.contractId
          item.productKey=item.productId+"#"+item.contractId
          // item.extrasList = [
          //   {
          //     "id": 117,
          //     "contractId": 3954,
          //     "productId": 33,
          //     "extrasId": 505,
          //     "extrasName": "抗菌药物专项审方增值包"
          //   },
          //   {
          //     "id": 118,
          //     "contractId": 3954,
          //     "productId": 33,
          //     "extrasId": 504,
          //     "extrasName": "重点审方增值包"
          //   },
          //   {
          //     "id": 119,
          //     "contractId": 3954,
          //     "productId": 33,
          //     "extrasId": 506,
          //     "extrasName": "电子病历评审增值包"
          //   }
          // ]
        })
      }
    },
    visitTypeSelect(val) {
      this.form.visitType = val
    },
    nextVisitTypeSelect(val) {
      this.form.nextVisitType = val
    },
    handleFileChange(file, fileList) {
      // this.fileList = fileList.slice(-3)
    },
    //we你按上传前的校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传附件大小不能超过 50MB');
        return false;
      }
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {
      console.log(res, file, fileList)
      if (res.code == 200) {
        this.msgSuccess("上传成功")
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: "success",
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除文件
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileList.splice(i, 1)
            return true
          }
        })
      }

    },
    //移除文件
    handleFileRemove(file, fileList) {
      console.log("移除文件",file, fileList)
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          this.uploadFileList.splice(i, 1)
          return true
        }
      })

    },
    async queryCustomerProductListApiFn() {
      // let obj = {
      //   orgId:this.customerId
      // }
      let res = await queryCustomerProductArchivesList(this.customerId)
      if(res.code === 200) {
        this.productList = res.data
        this.productList?.forEach(item=>{
          //因为机构下可能会有两个相同的产品，但是分属于不同的项目
          // item.productKey=item.projectId+"#"+item.productId+"#"+item.contractId
          item.productKey=item.productId+"#"+item.contractId
          // item.label=item.productName+"-"+item.projectName
          // if(item.contractId==this.form.contractId && item.productId==this.form.productId){
          //   this.productInfo=item
          // }
        })
      }
    },
    // 产品满意评分
    ratingChange(i, item) {
      console.log(i, item)
      item.rating = i.id
    },
    serviceRatingChange(i) {
      console.log(i)
      this.form.serviceRating = i.id
    },
    // 删除产品满意度任意项目
    productDelHandle(index) {
      // if (this.form.fpProductRatingList.length ===1) {
      //   this.$message.error('至少有一个产品，如需修改请切换产品项目');
      //   return false
      // }
      this.form.fpProductRatingList.splice(index,1)
    },
    // 添加产品项
    addProductItem() {
      const len = this.productList.length
      if (this.form.fpProductRatingList.length<len) {
        this.form.fpProductRatingList.push({
          productName: '',
          extrasList: [],
          rating: this.getDictPrimaryItemId(this.dictConst.VISIT_GRADE),  // 回访得分
        })
      } else {
        this.$message.error('暂时不能添加产品，当前已选产品个数等于产品列表个数！')
        return false
      }
    },
    // 满意度保存
    saveFpSatisSurvey() {
      console.log(this.uploadFileList)
      let projectFileList = []
      if(this.uploadFileList&&this.uploadFileList.length>0) {
        this.uploadFileList.forEach((item)=> {
          projectFileList.push(item.fileId)
        })
      }
      this.form.fpFileList = projectFileList
      if(this.form.laterServiceStatus){
        this.form.nextFollowUpTime = null
        this.form.nextVisitType = null
      }
      this.form.fpProductRatingList?.forEach((item,index)=> {
        // console.log(item.productKey)
        let values = item.productKey.split("#"); // 使用split方法按逗号分割字符串
        // item.projectId = parseInt(values[0])
        item.productId = parseInt(values[0])
        item.contractId = parseInt(values[1])
      })
      // 新增
      let flag = null
      if (this.isAdd) {
        console.log(this.form)
        this.form.customerArchiveId = this.id //客户档案 ID
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            flag = true
            let res = await addFpSatisSurvey(this.form)
            if (res.code == 200) {
              this.msgSuccess()
              // flag = true
              this.eventBus.$emit(this.eventConst.returnVisit.editSucceed)
            } else {
              flag = false
              this.msgError(res.msg)
              return false
            }
          } else {
            flag = false
            // this.msgError('请规范填写')
            // return false
          }
        })
      } else { // 修改
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            flag = true
            let res = await editFpSatisSurvey(this.form)
            if (res.code == 200) {
              // flag = true
              this.msgSuccess()
              this.eventBus.$emit(this.eventConst.returnVisit.editSucceed)
            } else {
              flag = false
              this.msgError(res.msg)
              return false
            }
          } else {
            flag = false
            // this.msgError('请规范填写')
            // return false
          }
        })
      }
      return flag
    },

    laterServiceStatusChange(val) {
      console.log(val)
      this.form.laterServiceStatus = val
    }

  }
}
</script>
<style lang="scss" >
.drvc_wrap{
  .el-form-item--medium .el-form-item__label{
    height: 28px;
    font-weight: 600;
    font-size: 14px;
    color: #3C3C3C;
    line-height: 28px;
  }
}
</style>

<style lang="scss" scoped>
.appreciationPackage{
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #DEDEDE;
  color: #5D5D5D;
  font-weight: 400!important;
  font-size: 14px;
  line-height: 24px;
  height: 28px;
  margin-right: 4px;
  //margin-top: 3px;
  &:last-child{
    margin-right: 0;
  }
}
.lastNum{
  margin-left: 2px;
  display: inline-block;
  height: 28px!important;
  line-height: 24px!important;
}
.appreciationPackageName{
  display: inline-block;
  margin-left:2px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;    /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.nomark{
  //float: right;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50px 50px 50px 50px;
  border: 1px solid #DCDFE6;
  font-size: 14px;
  color: #A8ABB2;
  line-height: 24px;
  text-align: center;
  margin-left: 4px;
  cursor: pointer;
}
.mark{
  //float: right;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #F1880D;
  border-radius: 50px 50px 50px 50px;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;
}
.el-form-item {
  margin-bottom: 0px;
}
.attachment {
  //margin-top: 4px;
  padding: 2px 4px;

  .editInput, i {
    color: #686EC5!important;
    font-size: 14px;
    line-height: 18px;
  }
}
.rrTimeWrap{
  padding:0 10px;
  .rrTime{
    b{
      display: inline-block;
      width: 92px;
      height: 28px;
      font-size: 14px;
      color: #3C3C3C;
      line-height: 28px;
    }
    span{
      display: inline-block;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 16px;
    }
  }
}
.suggestMark{
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  color: #3C3C3C;
  line-height: 24px;
  margin-bottom: 12px;
}
.suggestTitle{
  font-weight: 600;
  font-size: 14px;
  color: #5D5D5D;
  line-height: 14px;
  .productDel{
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    line-height:22px;
    background: #FFFFFF;
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #F23232;
    font-weight:400;
    margin-right:4px;
    cursor:pointer;
    i{
      color: #F23232;
    }
  }
}
.suggestContent{
  font-size: 14px;
  color: #5D5D5D;
  line-height: 16px;
  padding: 11px 0;
}
.editProductWrap{
  margin-bottom: 16px;
  .editLabel{
    height: 28px;
    font-weight: 600;
    font-size: 14px;
    color: #3C3C3C;
    line-height: 28px;
  }
  .editInput{
    font-size: 14px;
    color: #5D5D5D;
    line-height: 28px;
  }
}
</style>
