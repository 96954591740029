<template>
  <div>
    <h1>test2页面</h1>
    <el-input v-model="num"></el-input>

    <br>
    <el-row v-show="false">
      <el-button>默认按钮</el-button>
      <el-button type="primary">主要按钮</el-button>
      <el-button type="success">成功按钮</el-button>
      <el-button type="info">信息按钮</el-button>
      <el-button type="warning">警告按钮</el-button>
      <el-button type="danger">危险按钮</el-button>
    </el-row>


  </div>


</template>
<script>



export default {
  name: 'test2',
  data() {
    return {
      num:"test2"

    }
  },
  created() {

  },
  methods: {

  },
}
</script>
<style scoped>

</style>
