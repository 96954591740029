// 回访管理
import request from '@/modules/crm/utils/request'

// 回访档案信息列表
export function fpArchivesList(data) {
  return request({
    url: '/crm/crmFollowUp/fpArchivesList',
    method: 'post',
    data: data
  })
}
// 回访档案详细信息
export function archivesInfo(archivesId) {
  return request({
    url: '/crm/crmFollowUp/archivesInfo/' + archivesId,
    method: 'post'
  })
}
// 回访档案修改
export function editFpArchives(data) {
  return request({
    url: '/crm/crmFollowUp/editFpArchives',
    method: 'post',
    data: data
  })
}
// 满意度调查(回访时间列表)
export function archivesDateList(archivesId) {
  return request({
    url: '/crm/crmFollowUp/archivesDateList/' + archivesId,
    method: 'post'
  })
}
// 满意度调查详细信息
export function satisfactionSurveyInfo(data) {
  return request({
    url: '/crm/crmFollowUp/satisfactionSurveyInfo',
    method: 'post',
    data: data
  })
}
// 回访满意度调查新增
export function addFpSatisSurvey(data) {
  return request({
    url: '/crm/crmFollowUp/addFpSatisSurvey',
    method: 'post',
    data: data
  })
}
// 回访满意度调查修改
export function editFpSatisSurvey(data) {
  return request({
    url: '/crm/crmFollowUp/editFpSatisSurvey',
    method: 'post',
    data: data
  })
}
// 回访满意度调查信息删除
export function delSatisSurvey(surveyId) {
  return request({
    url: '/crm/crmFollowUp/delSatisSurvey/' + surveyId,
    method: 'post'
  })
}
// (回访)根据终端客户id获得可评分产品信息
// export function queryCustomerProductArchivesList(orgId) {
//   return request({
//     url: '/crm/crmFollowUp/queryCustomerProductArchivesList/' + orgId,
//     method: 'post'
//   })
// }
export function queryCustomerProductArchivesList(orgId) {
  return request({
    url: '/crm/crmContract/queryCustomerProductList/' + orgId,
    method: 'post'
  })
}
// (回访)根据终端客户id获得卫宁联系人数据
export function queryWeiningContact(orgId) {
  return request({
    url: '/crm/crmFollowUp/queryWeiningContact/' + orgId,
    method: 'post'
  })
}

// 获取服务报告
export function createServiceReportApi (customerId) {
  return request({
    url: '/crm/crmFollowUp/createServiceReport/'+customerId,
    method: 'post'
  })
}
