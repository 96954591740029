var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "处理任务：" + _vm.taskInfo.taskName,
        width: "900px",
        visible: _vm.taskDialogVisible,
        "before-close": _vm.cancelBtn,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.taskDialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "处理结果",
                prop: "operationType",
                rules: [
                  {
                    required: true,
                    message: "请选择处理结果",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "214px" },
                  attrs: { placeholder: "请选择处理结果" },
                  on: { change: _vm.operationTypeChange },
                  model: {
                    value: _vm.form.operationType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "operationType", $$v)
                    },
                    expression: "form.operationType",
                  },
                },
                _vm._l(_vm.taskOperationTypeDict, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.dictLabel, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "服务类型",
                prop: "serveType",
                rules: [
                  {
                    required: true,
                    message: "请选择服务类型",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "214px" },
                  attrs: { placeholder: "请选择服务类型" },
                  model: {
                    value: _vm.form.serveType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "serveType", $$v)
                    },
                    expression: "form.serveType",
                  },
                },
                _vm._l(_vm.taskServeTypeDict, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.dictLabel, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "99%" },
              attrs: {
                label: "解决方法",
                prop: "solution",
                rules: [
                  {
                    required: true,
                    message: "请输入解决方法",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c("my-editor", {
                attrs: {
                  "editor-content": _vm.form.solution,
                  "editor-style":
                    "height: 200px; overflow-y: auto;z-index: 100;border: 1px solid #dcdcdc;",
                },
                on: { editorInput: _vm.onEditorInput },
              }),
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    action: _vm.baseURL + "/crm/sys/file/uploadFile",
                    data: _vm.uploadFileParams,
                    "on-change": _vm.handleChange,
                    "file-list": _vm.fileList,
                    "auto-upload": true,
                    "before-upload": _vm.beforeUpload,
                    accept:
                      ".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx",
                    "on-success": _vm.uploadSuccess,
                    "on-remove": _vm.handleFileRemove,
                    limit: 10,
                    "on-exceed": _vm.handleOnExceed,
                  },
                },
                [
                  _c("el-button", { attrs: { size: "mini" } }, [
                    _vm._v("添加附件"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "指派给",
                prop: "assignUser",
                rules: [
                  {
                    required: true,
                    message: "请选择指派人",
                    trigger: "change",
                  },
                ],
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "214px" },
                  attrs: {
                    disabled:
                      _vm.form.operationType ==
                      _vm.dictDataConst.TASK_OPERATION_TYPE.PROCESSED,
                    remote: "",
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择指派人员",
                    "remote-method": _vm.assignUserSearch,
                  },
                  model: {
                    value: _vm.form.assignUser,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "assignUser", $$v)
                    },
                    expression: "form.assignUser",
                  },
                },
                _vm._l(_vm.userList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nickName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-right": "20px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelBtn } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.taskInfo.createUser == _vm.userInfo.userId,
                  expression: "taskInfo.createUser==userInfo.userId",
                },
              ],
              attrs: {
                disabled:
                  _vm.userInfo.userId != _vm.form.assignUser ||
                  _vm.form.operationType !=
                    _vm.dictDataConst.TASK_OPERATION_TYPE.PROCESSED,
              },
              on: {
                click: function ($event) {
                  return _vm.onSubmit(1)
                },
              },
            },
            [_vm._v("已解决")]
          ),
          _c(
            "el-button",
            {
              staticStyle: {
                "background-color": "#F1880D",
                "border-color": "#F1880D",
              },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit(0)
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }