<template>
  <div
    class="app-container"
    style="height:100%"
    v-loading.fullscreen.lock="pageLoading">
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">回访管理</el-col>
          </el-row>
          <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">
            <div style="width: 230px;">
              <el-tabs v-model="queryParams.fpStatus" @tab-click="handleClick">
                <el-tab-pane
                  v-for="(item) in returnVisitTypeList"
                  :key="item.id"
                  :label="item.label"
                  :name="item['id'].toString()">
                </el-tab-pane>
              </el-tabs>
            </div>
            <el-row>
              <el-col :span="24">
                  <el-form-item label="">
                    <el-input
                      v-model.trim="queryParams.searchValue"
                      size="small"
                      style="width:240px;"
                      placeholder="客户名称"
                      maxlength="30"
                      show-word-limit
                      @keydown.enter.native="handleQuery"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.visitType"
                      size="small"
                      style="width:150px;"
                      filterable
                      @keydown.enter.native="handleQuery"
                      placeholder="本次回访类型" clearable>
                      <el-option
                        :label="item.dictLabel"
                        :value="item.id"
                        :key="item.id"
                        v-for="item in returnVisitTypeDict"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="下次回访时间:"
                    >
                    <el-date-picker
                      v-model="visitTimeInterval"
                      size="small"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      @change="visitTimeIntervalChange">
                    </el-date-picker>
                    <el-select
                      v-model="queryParams.nextVisitType"
                      size="small"
                      style="width:150px;margin-left: 10px;"
                      filterable
                      @keydown.enter.native="handleQuery"
                      placeholder="下次回访类型" clearable>
                      <el-option
                        :label="item.dictLabel"
                        :value="item.id"
                        :key="item.id"
                        v-for="item in returnVisitTypeDict"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                  </el-form-item>
                  <el-button
                    type="text"
                    size="small"
                    class="resetFilterClass"
                    @click="resetSearchValue()">
                    <i class="iconfont">&#xe660;</i>
                  </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          height="100%"
          v-loading="tableLoading"
          :data="tableDataList"
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          ref="mainTable"
          :default-sort="{prop: 'createTime', order: 'ascending'}"
          @sort-change="tableSortChange">
<!--          :cell-style="{'text-align':'center'}"-->
          <el-table-column label="ID" prop="customerId" width="50"></el-table-column>
          <el-table-column label="客户名称" min-width="150">
            <template slot-scope="scope">
              <span>{{scope.row.customerName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="本次回访类型" min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.visitTypeName">{{scope.row.visitTypeName }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
<!--          prop="nextFollowUpTime"-->
          <el-table-column label="下次回访时间" min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.nextFollowUpTime">{{scope.row.nextFollowUpTime}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="下次回访类型" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.nextVisitTypeName">{{scope.row.nextVisitTypeName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="回访人" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.followUpBy">{{scope.row.followUpBy}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="100">
            <template slot-scope="scope">
              <span>{{scope.row.laterServiceStatus?'回访终止':'待回访'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime" min-width="150" sortable="custom"/>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                class="saveBtn"
                @click="handleEdit(scope.row)">编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleViewReport(scope.row)"
                class="downloadBtn">下载服务报告
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10"><div style="color:#999;line-height: 40px;font-size: 12px;">
          按住Shift+鼠标滚轮左右滑动列表
        </div></el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"/>
          </div>
        </el-col>
      </el-footer>
    </el-container>
    <!--编辑-->
    <edit-common
      v-if="editCommon"
      :editCommonTitle="editCommonTitle"
      :is-edit.sync="isEdit"
      :drawer="editCommon"
      :activeNum="activeNum"
      :archivesId="archivesId"
      :titleName="customerName"
      :row="currentRow"
      :org-type="null"
      @handleEditCommon="handleEditCommon">
    </edit-common>
    <service-report :visible.sync="serviceReportConfig.serviceReportDialog" v-if="serviceReportConfig.serviceReportDialog"
                    :customerId="serviceReportConfig.customerId" :customerName="serviceReportConfig.customerName"></service-report>
  </div>
</template>
<script>
import serviceReport from '@/modules/crm/components/ServiceReport'
import { isEmpty } from '@/utils/common'
import { getAllRoleApi } from '@/modules/crm/api/system/role'
import NewConstructionInstitution from '@/modules/crm/components/NewConstructionInstitution'
import EditCommon from '@/modules/crm/components/EditCommonReturnVisit'
import {
  ITEM_OBJ
} from '@/modules/crm/utils/org'
import {
  QUERY_OBJ,
  RETURN_VISIT_TYPE_LIST
} from '@/modules/crm/utils/returnVisit'
import {
  fpArchivesList
} from '@/modules/crm/api/crm/returnVisit'

export default {
  name: 'crmOrg',
  components:{
    serviceReport,
    NewConstructionInstitution,
    EditCommon
  },
  computed: {
    addPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.add)
    },
    editPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.edit)
    },
    delPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.del)
    },
  },
  data() {
    return {
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 回访类型字典
      returnVisitTypeDict: this.getDict(this.dictConst.VISIT_TYPE),
      // 回访查询状态列表
      returnVisitTypeList: this.$deepClone(RETURN_VISIT_TYPE_LIST),
      // 查询参数
      queryParams: this.$deepClone(QUERY_OBJ),
      customerName: '',
      visitTimeInterval: undefined,
      currentRow: this.$deepClone(ITEM_OBJ),
      editDialogVisible: false,
      //角色列表
      roleList: [],
      activeNum: 0,  // 机构
      editCommon: false,
      editCommonTitle: '',
      provinceDict: [], // 省份字典
      cityDict: [], // 城市字典
      archivesId: '',
      orgName: '',
      isEdit: true,
      //服务报告相关配置参数
      serviceReportConfig:{
        serviceReportDialog:false,
        customerId:'',
        customerName:'',
      }
    }
  },
  created() {
    this.handleQuery()
    this.eventBus.$on(this.eventConst.returnVisit.delSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
  },
  methods: {
  // 回访时间选择
    visitTimeIntervalChange(val){
      console.log(this.visitTimeInterval)
      console.log(val)
      this.queryParams.nextFollowUpTimeStart = val[0]
      this.queryParams.nextFollowUpTimeEnd = val[1]
    },
    handleEditCommon(type) {
      this.editCommon = false
      // if (type === 'edit') {
        this.queryParams.pageNum = 1
        this.queryList()
      // }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    // 重置查询条件
    resetSearchValue() {
      const fpStatus = this.queryParams.fpStatus
      this.queryParams = this.$deepClone(QUERY_OBJ)
      this.visitTimeInterval = undefined
      this.queryParams.fpStatus = fpStatus
      this.handleQuery()
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      fpArchivesList(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      }).catch(err => {
        console.log(err)
        this.tableLoading = false
      })
    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    // 查看
    onCheck(row) {
      this.editCommon = true
      this.isEdit = false
      this.archivesId = row.id
      this.orgName = row.orgName
      this.currentRow = this.$deepClone(row)
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      console.log('edit btn click')
      this.editCommon = true
      this.isEdit = true
      this.archivesId = row.id
      this.customerName = row.customerName
      this.currentRow = this.$deepClone(row)
    },
    validateMenuIds(rule, value, callback, index) {
      console.log(rule, value, callback, index)
      if (isEmpty(value)) {
        callback(new Error('角色的菜单不能为空'))
      } else {
        callback()
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      console.log('del btn click')
      this.$confirm('确认删除机构【' + row.orgName + '】吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delOrgApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    handleViewReport(row){
      this.serviceReportConfig.serviceReportDialog=true
      this.serviceReportConfig.customerId=row.customerId
      this.serviceReportConfig.customerName=row.customerName
    }
  }
}
</script>
<style lang="scss">
.confirmButton{
  background-color: #F23232!important;
  border-color:#F23232!important;
}
.delMessage{
  .el-message-box__btns{
    text-align: center!important;
  }
  .el-message-box__title{
    font-weight: 600;
  }
  .el-message-box__message p{
    color: #3C3C3C;
    font-weight: 500;
  }
}

</style>
<style lang="scss" scoped>
.saveBtn{
  font-size: 12px;
  color: #ed923a;
  &:hover{
    color: #d0760c;
  }
  &:active{
    color: #d0760c;
  }
  &:disabled{
    color: #f3d19e;
  }
}
.downloadBtn{
  font-size: 12px;
  color: #288AE4;
  &:hover{
    color: #1f507d;
  }
  &:active{
    color: #1f507d;
  }
  &:disabled{
    color: #62abee;
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}
.closeBtn{
  position:fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  i{
    color: #F09933;
    font-size: 20px;
  }
}
</style>

