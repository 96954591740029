<!--新建客户联系人-->
<template>
  <el-dialog
    :visible="showAddCustom"
    :show-close="false"
    :append-to-body="true"
    custom-class="center-dialog new-custom-dialog">
    <div slot="title" class="header-wrap">
      <div class="title-txt">新建客户联系人</div>
      <div class="add-btn-wrap">
        <el-button class="add-btn" @click="onAdd">添 加</el-button>
      </div>
    </div>
    <div class="form-view">
      <el-form
        ref="formRef"
        :model="addCustomObj"
        :rules="rules"
        label-width="50px"
        label-position="left">
        <el-row
          v-for="(row, rowIndex) in contactsList2DArray"
          :key="rowIndex"
          :gutter="10">
          <el-col
            v-for="(col, colIndex) in row"
            :key="rowIndex + colIndex"
            :span="spanNum"
            class="col-form-item">
            <div class="col-item-wrap">
              <div class="form-item-wrap">
              <el-form-item
                :prop="`contactsList[${rowIndex * 3 + colIndex}].name`"
                :rules="rules.name"
                label="姓名"
                size="mini"
                :show-message="false">
                <el-row style="margin-bottom: 0;">
                  <el-col :span="16">
                    <el-input
                      v-model.trim="addCustomObj.contactsList[rowIndex * 3 + colIndex].name"
                      size="mini"
                      maxlength="30"
                      clearable
                      show-word-limit></el-input>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      v-if="addCustomObj.contactsList[rowIndex * 3 + colIndex].isPrimary === 1"
                      type="text"
                      style="margin:4px 0 0 0px;padding: 0;float: right;"
                      @click="changeContactPrimary(col, 0, rowIndex, colIndex)">
                      <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                        <use xlink:href="#icon-shouyao"></use>
                      </svg>
                    </el-button>
                    <el-button
                      v-else
                      type="text"
                      style="margin:4px 0 0 0px;padding: 0;float: right;"
                      @click="changeContactPrimary(col, 1, rowIndex, colIndex)">
                      <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                        <use xlink:href="#icon-bushishouyao"></use>
                      </svg>
                    </el-button>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                :prop="`contactsList[${rowIndex * 3 + colIndex}].department`"
                :rules="[{ required: true, message: '请选择部门', trigger: 'blur' }]"
                :show-message="false"
                label="部门"
                size="mini"
                required
                class="phoneStyle">
                <el-select
                  v-model="addCustomObj.contactsList[rowIndex * 3 + colIndex].department"
                  placeholder="请选择部门"
                  filterable
                  clearable
                  size="mini"
                  @change="(val) =>departmentChange(val, rowIndex, colIndex)">
                  <el-option
                    v-for="item in addCustomObj.contactsList[rowIndex * 3 + colIndex].departmentOptions"
                    :label="item.dictLabel"
                    :value="item.id"
                    :key="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="职务"
                size="mini">
                <el-select
                    v-model="addCustomObj.contactsList[rowIndex * 3 + colIndex].post"
                    placeholder="请选择职务"
                    filterable
                    clearable
                    size="mini">
                    <el-option
                      v-for="item in addCustomObj.contactsList[rowIndex * 3 + colIndex].postOptions"
                      :label="item.dictLabel"
                      :value="item.id"
                      :key="item.id"></el-option>
                  </el-select>
              </el-form-item>
              <el-row
                v-for="(phoneItem, phoneIndex) in col.phoneList"
                :key="phoneIndex"
                style="margin-bottom: 0;">
                <el-form-item
                  class="phoneStyle"
                  :label="'电话'+ Number(phoneIndex+1)"
                  :prop="`contactsList[${rowIndex * 3 + colIndex}].phoneList[${phoneIndex}].phone`"
                  :rules="[{ validator:(rule,value,callback) => validatorPhone(rule,value,callback,rowIndex,colIndex,phoneIndex), trigger:['change'] }]"
                  size="mini"
                  :show-message="false">
                  <el-col :span="16" class="productSelect">
                    <el-input
                      v-model.trim="addCustomObj.contactsList[rowIndex * 3 + colIndex].phoneList[phoneIndex].phone"
                      size="mini"
                      clearable
                      class="no-word-limit-input"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <i
                      v-if="phoneIndex!== 0"
                      class="iconfont delBtn"
                      @click="delPhone(col.phoneList, phoneIndex, rowIndex, colIndex)">&#xe665;</i>
                    <i class="iconfont addBtn" @click="addPhone(col.phoneList, rowIndex, colIndex)">&#xe65e;</i>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-form-item
                label="邮箱"
                class="phoneStyle"
                :prop="`contactsList[${rowIndex * 3 + colIndex}].email`"
                :rules="[
                  { pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,message: '请输入正确的邮箱',trigger: 'blur'}
                ]"
                :show-message="false"
                size="mini">
                <el-input
                  v-model.trim="addCustomObj.contactsList[rowIndex * 3 + colIndex].email"
                  size="mini"
                  maxlength="30"
                  clearable
                  show-word-limit></el-input>
              </el-form-item>
              <el-form-item
                label="微信"
                size="mini">
                <el-input
                  v-model.trim="addCustomObj.contactsList[rowIndex * 3 + colIndex].wxAccount"
                  size="mini"
                  maxlength="50"
                  clearable
                  show-word-limit></el-input>
              </el-form-item>
              <el-form-item
                label="备注"
                size="mini">
                <el-input
                  v-model.trim="addCustomObj.contactsList[rowIndex * 3 + colIndex].remark"
                  size="mini"
                  maxlength="200"
                  clearable
                  show-word-limit></el-input>
              </el-form-item>
              </div>
              <div class="del-item-btn-wrap" v-if="addCustomObj.contactsList.length > 0">
                <i class="iconfont closeBtn" @click="delContactsClick(col, rowIndex, colIndex)">&#xe672;</i>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!--等于三的倍数的时候，el-row 被添加--->
    </div>
    <div slot="footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button @click="onConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  ADD_CUSTOM_ITEM_OBJ,
  ADD_CUSTOM_OBJ,
  removeElementFrom2DArray
} from '@/modules/crm/utils/task'
import {
  duplicates
} from '@/modules/crm/utils/org'
import {
  taskNewCustomerApi
} from '@/modules/crm/api/crm/task'
export default {
  props: {
    showAddCustom: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number
    }
  },
  computed: {
    spanNum() {
      console.log(Math.ceil(24 / (Math.ceil(this.contactsList.length / 3))))
      // return Math.ceil(24 / (Math.ceil(this.contactsList.length / 3)))
      const len = this.addCustomObj.contactsList.length
      if (len === 1) {
        return 24
      } else if (len === 2) {
        return 12
      } else {
        return 8
      }
    }
  },
  data() {
    return {
      departmentOptions: [],
      addCustomObj: this.$deepClone(ADD_CUSTOM_OBJ), // 所有的表格
      contactsItemObj: this.$deepClone(ADD_CUSTOM_ITEM_OBJ),
      contactsList2DArray: [], // 二维数组
      contactsList: [this.$deepClone(ADD_CUSTOM_ITEM_OBJ)],
      rules: {
        // 联系人姓名
        name: [
          { required: true, message: '请填写名称', trigger: 'blur' },
          { min: 2, max: 30, message: '至少 2 个字符', trigger: 'blur' },
          { trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              let sameArr = []
              let diffArr = []
              let obj = duplicates(this.addCustomObj.contactsList, 'name')
              sameArr = obj.sameArr
              diffArr = obj.diffArr
              for (let i = 0; i < diffArr.length; i++) {
                let index = diffArr[i]
                this.$refs.formRef.clearValidate(`contactsList[${index}].name`)
              }
              const index = this.addCustomObj.contactsList.findIndex(item => item.name === value)
              const isExit = sameArr.some(item => item.indexOf(index) > -1 )
              if (sameArr.length > 0 && isExit) {
                callback(new Error('联系人姓名不可重复'))
              } else {
                callback()
              }
            }
          }
        ],
        phoneRules: [
          // { required: true, message: '请输入电话号码', trigger: ['blur', 'change']},
          { min: 7, max: 15, message: '电话号码长度在15个字符', trigger: ['blur', 'change'] },
          // { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: '请输入正确的手机号码',trigger: 'blur'}, -->
          {
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (value === '') {
                callback()
              }
              let regPone = null
              let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
              let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
              if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
                regPone = tel     
              } else {          
                regPone = mobile
              }
              if (!regPone.test(value)) {
                callback(new Error('请填写联系人电话(座机格式区号-座机号码)'))
              } else {
                callback()
              }
            }
          }
        ],
      }
    }
  },
  watch: {
    'addCustomObj.contactsList': {
      handler(newVal) {
        console.log(newVal)
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    await this.$store.dispatch('dict/fetchDeptAndPostTree')
    this.departmentOptions = this.$store.state.dict.customDeptPostTree || []
    this.handleData()
    this.addCustomObj.customerId = this.customerId
    var myArray = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [10, 11, 12],
      [13, 14, 15]
    ]
    removeElementFrom2DArray(myArray, 4, 2)
    console.log(myArray)
  },
  methods: {
    onAdd() {
      // this.contactsList.push(this.$deepClone(ADD_CUSTOM_ITEM_OBJ))
      // this.addCustomObj.contactsList = this.$deepClone(this.contactsList)
      this.addCustomObj.contactsList.push(this.$deepClone(ADD_CUSTOM_ITEM_OBJ))
      this.handleData()
      console.log(this.contactsList2DArray)
      console.log(this.addCustomObj.contactsList)
    },
    // 数据处理
    handleData() {
      // this.contactsList.forEach((item, index) => {
      //   item.departmentOptions = this.$deepClone(this.departmentOptions)
      //   const deptItem = item.departmentOptions.find(each => each.id === item.department)
      //   item.postOptions = deptItem ? deptItem.children : []
      // })
      this.addCustomObj.contactsList.forEach((item, index) => {
        item.departmentOptions = this.$deepClone(this.departmentOptions)
        const deptItem = item.departmentOptions.find(each => each.id === item.department)
        item.postOptions = deptItem ? deptItem.children : []
      })
      
      let contactsList = this.$deepClone(this.addCustomObj.contactsList)
      this.contactsList2DArray = this.to2DArray(contactsList, 3)
      // this.addCustomObj.contactsList = this.toFlatArray(this.$deepClone(this.contactsList2DArray))
    },
    to2DArray(array, col) {
      let twoDArray = []
      let deepCloneArray = this.$deepClone(array)
      const len = deepCloneArray.length
      const row = Math.ceil(len / col)
      for (let i = 0; i < row; i++) {
        twoDArray[i] = []
        for (let j = 0; j < col; j++) {
          if (deepCloneArray[i * col + j]) {
            twoDArray[i][j] = deepCloneArray[i * col + j] ? deepCloneArray[i * col + j] : []
          }
        }
      }
      return twoDArray
    },
    // 二维数组转换为一维数组
    toFlatArray(twoDimensionalArray) {
      return [].concat(...twoDimensionalArray)
    },
    validatorPhone (rule, value, callback, rowIndex, colIndex, phoneIndex) {
      console.log(value)
      if (value === '') {
        callback()
      }
      let regPone = null
      let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
      let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
      if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
        regPone = tel     
      } else {          
        regPone = mobile
      }
      if (!regPone.test(value)) {
        callback(new Error('请填写联系人电话(座机格式区号-座机号码)'))
      }
      let arrList = this.addCustomObj.contactsList
      let arr =  []
      for (let i = 0; i < this.addCustomObj.contactsList.length; i++) {
        let item = this.addCustomObj.contactsList[i]
        for (let j = 0; j < item.phoneList.length; j++) {
          let phoneItem = item.phoneList[j]
          phoneItem.indexOuter = i
          phoneItem.indexInner = j
          arr.push(phoneItem)
        }
      }
      let obj = duplicates(arr, 'phone', true)
      let sameArr = obj.sameArr
      let diffArr = obj.diffArr
      for (let i = 0; i < diffArr.length; i++) {
        let item = diffArr[i]
        const {indexOuter, indexInner} = item
        this.$refs.formRef.clearValidate(`contactsList[${indexOuter}].phoneList[${indexInner}].phone`)
      }
      const isExit = sameArr.some(itemArr => {
        console.log(itemArr)
        return itemArr.some(item => item.indexOuter === (rowIndex * 3 + colIndex) && item.indexInner === phoneIndex)
      })
      if (isExit) {
        callback(new Error('电话号码不可重复'))
      } else {
        callback()
      }
    },
    addPhone(arr, rowIndex, colIndex) {
      this.contactsList[rowIndex * 3 + colIndex].phoneList.push({
        phone: '',
        id: 0
      })
      this.addCustomObj.contactsList[rowIndex * 3 + colIndex].phoneList.push({
        phone: '',
        id: 0
      })
      return arr.push({
        phone: '',
        id: 0
      })
    },
    delPhone(arr,index, rowIndex, colIndex) {
      this.contactsList[rowIndex * 3 + colIndex].phoneList.splice(index, 1)
      this.addCustomObj.contactsList[rowIndex * 3 + colIndex].phoneList.splice(index, 1)
      return arr.splice(index,1)
    },
    changeContactPrimary(item, val, rowIndex, colIndex) {
      if(val === 1) {
        this.addCustomObj.contactsList.forEach((i)=> {
          i.isPrimary = 0
        })
        // this.contactsList.forEach((i)=> {
        //   i.isPrimary = 0
        // })
        this.contactsList2DArray.forEach(row => {
          row.forEach(col => {
            col.isPrimary = 0
          })
        })
        // this.contactsList[rowIndex * 3 + colIndex].isPrimary =  val
        this.addCustomObj.contactsList[rowIndex * 3 + colIndex].isPrimary =  val
        return item.isPrimary = val
      }
    },
    delContactsClick(item, rowIndex, colIndex) {
      this.addCustomObj.contactsList.splice(rowIndex * 3 + colIndex, 1)
      // this.contactsList.splice(rowIndex * 3 + colIndex, 1)
      
      if (item.isPrimary === 1 && this.addCustomObj.contactsList[0]) {
        this.addCustomObj.contactsList[0].isPrimary = this.addCustomObj.contactsList[0] ? 1 : 0
      }
      console.log(this.contactsList2DArray)
      console.log(this.addCustomObj.contactsList)
      const twoDArrayLen = this.contactsList2DArray.length
      /* if (twoDArrayLen > 3) {
        if (this.contactsList2DArray[twoDArrayLen - 2].length > 1 && this.contactsList2DArray[twoDArrayLen - 2].length < 3) {
          // 说明最后一行有元素，但是倒数第二行元素个数小于 3（删除的是倒数第二行的元素），此时要把最后一行元素往上排，
        }
      } else {
        this.contactsList2DArray[rowIndex].splice(colIndex, 1)
      } */
      removeElementFrom2DArray(this.contactsList2DArray, rowIndex, colIndex)
    },
    // 部门 change
    departmentChange(val, rowIndex, colIndex) {
      const deptItem = this.departmentOptions.find(item => item.id === val)
      this.addCustomObj.contactsList[rowIndex * 3 + colIndex].postOptions = deptItem ? deptItem.children : []
      this.addCustomObj.contactsList[rowIndex * 3 + colIndex].post = ''
      // this.contactsList[rowIndex * 3 + colIndex].postOptions = deptItem ? deptItem.children : []
      // this.contactsList[rowIndex * 3 + colIndex].post = ''
      this.contactsList2DArray[rowIndex][colIndex].postOptions = deptItem ? deptItem.children : []
      this.contactsList2DArray[rowIndex][colIndex].post = ''
      console.log(this.addCustomObj.contactsList)
    },
    async onConfirm() {
      this.$refs['formRef'].validate(async (valid) => {
        if (valid) {
          let res = await taskNewCustomerApi(this.addCustomObj)
          if (res.code === 200) {
            console.log(res)
            this.$message({
              type: 'success',
              message: '新建客户联系人成功'
            })
            this.$emit('update:showAddCustom', false)
            this.$emit('addCustom', true)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      });
    },
    onCancel() {
      this.$emit('update:showAddCustom', false)
    }
  }
}
</script>

<style lang="scss">
.center-dialog.new-custom-dialog.el-dialog {
  min-width: 350px;
  max-width: 960px;
  // width: auto;
  margin: 0 auto;
  width: max-content;
  margin-top: 0 !important;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .closeBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #EBEBEB;
    line-height: 16px;
    color: #F23232;
    font-size: 8px;
    text-align: center;
    cursor: pointer;
  }
  .setBtn,.addBtn,.delBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f1f1f1;
    line-height: 16px;
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 8px 5px 0;
    cursor: pointer;
  }
  .el-dialog__header {
    background: #fff;
    border: none;
    height: 58px;
    line-height: 58px;
    display: flex;
    .header-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      .title-txt {
        font-weight: 600;
        font-size: 20px;
        color: #262626;
        line-height: 23px;
      }
      .add-btn {
        margin-left: 10px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DBDBDB;
        padding: 4px 12px !important;
        font-weight: 400;
        font-size: 12px;
        color: #444444;
      }
    }
  }
  .el-dialog__body {
    max-height: 440px;
    min-height: 210px;
    height: auto;
    overflow: auto;
    padding: 10px 20px !important;
    .el-row {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .col-form-item {
      // background: #F8F8F8;
      // border-radius: 4px 4px 4px 4px;
      // padding: 6px 10px;
      display: flex;
      align-items: center;
      .col-item-wrap {
        background: #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        padding: 6px 10px;
        
        display: flex;
        .el-form-item {
          margin-bottom: 4px;
        }
      }
      .form-item-wrap {
        // display: flex;
        border-right: 1px solid #EAECFD;
        padding-right: 10px;
        margin-right: 10px;
      }
      .del-item-btn-wrap {
        background-color: #f8f8f8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .el-dialog__footer {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>