var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShowDialog,
            modal: false,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-header",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm.showType === "solution"
                ? _c("div", [
                    _c(
                      "span",
                      {
                        class: _vm.getDictItemCssClass(
                          _vm.dictConst.TASK_STATUS,
                          _vm.currentItem.taskStatus
                        ),
                      },
                      [_vm._v(_vm._s(_vm.currentItem.taskStatusName))]
                    ),
                    _c("span", { staticClass: "txt" }, [_vm._v("/")]),
                    _c("span", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.currentItem.createTime)),
                    ]),
                    _c("span", { staticClass: "txt" }, [_vm._v("/")]),
                    _c("span", { staticClass: "txt" }, [
                      _vm.dictDataConst.TASK_STEP_TYPE.OVER_NO_RESOLVED ==
                      _vm.currentItem.stepType
                        ? _c("span", [_vm._v(" 由系统创建 ")])
                        : _c("span", { staticClass: "txt" }, [
                            _vm._v(" 由"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.currentItem.createBy)),
                            ]),
                            _vm._v("创建，指派给"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.currentItem.assignUserName)),
                            ]),
                          ]),
                      _c("span", { staticClass: "txt" }, [_vm._v("/")]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentItem.serveType > 0,
                              expression: "currentItem.serveType>0",
                            },
                          ],
                          staticClass: "txt",
                        },
                        [
                          _vm._v(
                            "服务类型:" + _vm._s(_vm.currentItem.serveTypeName)
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm.showType === "image"
                ? _c("div", { staticClass: "txt" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.isShowDialog && _vm.showType === "solution"
            ? _c("my-editor", { attrs: { "editor-content": _vm.solution } })
            : _vm.isShowDialog && _vm.showType === "image"
            ? _c("el-image", {
                attrs: {
                  fit: "fill",
                  src: _vm.getImgUrl(),
                  "preview-src-list": _vm.getImgUrlList(),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }