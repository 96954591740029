<template>
  <div class="NewConstructionProject_wrap">
      <el-row  v-if="!isNewConstruction&&projectList.length>0">
        <el-col :span="24">
          <el-select v-model="projectValue"
                     placeholder="请选择项目编号"
                     style="width: 400px;margin-bottom: 10px;"
                     size="mini"
                     :disabled="!isNewConstruction"
                     @change="projectChange">
            <el-option v-for="item in projectList" :key="item.id" :label="item.projectName" :value="item.id"></el-option>
          </el-select>
          <span class="projectNum">共{{projectListLen?projectListLen:0}}个项目</span>
        </el-col>
      </el-row>
      <div v-if="!isNewConstruction&&projectId==null&&!projectValue"  class="noDataWrap">
        <div class="noData">
          <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
          <div class="noDataText">暂无数据</div>
        </div>
      </div>
      <div v-else>
        <div class="projectTitle">合同信息</div>
        <el-form ref="form" :model="form" :rules="rules" label-width="85px" label-position="left">
          <el-row>
            <el-col :span="12">
              <el-form-item label="编号" prop="contractId">
                <el-select
                  v-model="form.contractId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请选择编号"
                  :remote-method="remoteMethod"
                  style="width: 300px;"
                  size="mini"
                  :disabled="isProjectCorrelation"
                  @change="contractInfoChange"
                  :loading="loading">
                  <el-option
                    v-for="item in contractDict"
                    :key="item.contractId"
                    :label="item.contractNum"
                    :value="item.contractId">
                  </el-option>
                </el-select>
                <!--            <el-button class="creatInstitution" type="text">新建</el-button>-->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" class="contractNameClass">
                <span>{{ contractInfoList.contractName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="金额">
                <span v-if="contractInfoList.contractPrice">{{ contractInfoList.contractPrice }}万元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下单时间">
                <span>{{ contractInfoList.orderTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="项目分类">
                <span>{{ contractInfoList.projectClassifyName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="购买方(甲方)" label-width="120" class="contractNameClass">
                <span>{{ contractInfoList.purchaserName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="终端客户" class="contractNameClass">
                <span v-for="(item,index) in contractInfoList.customerList" :key="item.id">
                  {{ item.customerName }}
                  <span v-if="index!==contractInfoList.customerList.length-1">、</span>
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="乙方" class="contractNameClass">
                <span  class="appreciationPackage" v-if="contractInfoList.sellerTypeName">{{ contractInfoList.sellerTypeName }}</span>
                <span v-if="contractInfoList.sellerTypeName">{{ contractInfoList.sellerName }}</span>
                <span  class="appreciationPackage" v-if="contractInfoList.distribution" style="margin: 0 6px 0 10px;">分销</span>
                <span v-if="contractInfoList.distribution">{{ contractInfoList.distributionName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="projectTitle">项目信息</div>
          <!--      <el-row>-->
          <!--        <el-col :span="12">-->
          <!--          <el-form-item label="项目名称">-->
          <!--            <span>{{ contractInfoList.orderTime }}</span>-->
          <!--          </el-form-item>-->
          <!--        </el-col>-->
          <!--        <el-col :span="12">-->
          <!--          <el-form-item label="项目状态">-->
          <!--            <span>{{ contractInfoList.orderTime }}</span>-->
          <!--          </el-form-item>-->
          <!--        </el-col>-->
          <!--      </el-row>-->
          <el-row>
            <el-col :span="24">
              <el-form-item label="项目实施方">
                <span
                  v-for="(item,index) in ssfList"
                  :key="item.id"
                  class="normal_btn"
                  :class="{ select_btn: item.id == form.implType }"
                  @click="implTypeSelect(item.id)">
                  {{ item.dictLabel }}
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="上传附件" prop="taskFileIdList">
                <el-upload
                  class="upload-demo"
                  :action="baseURL+'/crm/sys/file/uploadFile'"
                  :on-change="handleFileChange"
                  :file-list="fileList"
                  :data="uploadFileParams"
                  :auto-upload="true"
                  :before-upload="beforeUpload"
                  accept=".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx"
                  :on-success="uploadSuccess"
                  :on-remove="handleFileRemove"
                >
                  <el-button size="mini">添加附件</el-button>
                  <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入备注信息"
                  v-model="form.remark"
                  maxlength="500"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="建项日期" prop="filingTime">-->
<!--                <el-date-picker-->
<!--                  style="width:360px"-->
<!--                  v-model="form.filingTime"-->
<!--                  type="date"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  clearable-->
<!--                  size="mini"-->
<!--                  :disabled="isProjectCorrelation"-->
<!--                  placeholder="请选择建项日期">-->
<!--                </el-date-picker>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <div class="projectTitle">人员信息</div>
          <!--      style="border-bottom: 1px dotted #F0F0F0;" required-->
          <el-form-item label="客户方">
            <div style="text-align: right;">
              <el-button size="mini" @click="addPurchaserContactsList">添加</el-button>
            </div>
          </el-form-item>
          <div class="contactList">
            <div class="productWrap01"
                 v-for="(item, index) in form.purchaserContactsListArr"
                 :key="item.id">
              <div class="productItem">
                <el-row>
                  <el-col :span="21" class="productSelect">
<!--                    :rules=" [-->
<!--                    { required: true, message: '请选择客户方', trigger: 'change' }-->
<!--                    ]"-->
                    <el-form-item
                      :prop="`purchaserContactsListArr[${index}].id`"
                      :show-message="false"
                      class="contract_product"
                      label-width="40">
                      <el-select
                        v-model="item.id"
                        placeholder="请先选择客户方"
                        size="mini"
                        style="width: 100%;"
                        :disabled="!form.contractId"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteKHFMethod"
                        @change="purchaserContactsListChange($event,index)">
                        <el-option
                          v-for="i in pclArr"
                          :disabled="setDisabled(i, index,0)"
                          :key="i.id"
                          :label="i.name"
                          :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" class="productClose">
                    <i class="iconfont" @click="delPurchaserContactsList(index)">&#xe672;</i>
<!--                    v-if="form.purchaserContactsListArr.length > 1"-->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">类型</el-col>
                  <el-col :span="18" class="productSelect">
                    <span class="typeSpan" v-if="item.pclObject.orgId && item.pclObject.orgId===contractInfoList.purchaser">购买方</span>
                    <template v-for="i in contractInfoList.customerList">
                        <span class="typeSpan" v-if="item.pclObject.orgId==i.customerId">
                          终端客户
                        </span>
                    </template>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">部门</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.pclObject.departmentName}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">职务</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.pclObject.postName}}</span>
                  </el-col>
                </el-row>
                <el-row v-for="(phoneItem,phoneIndex) in item.pclObject.phoneList" :key="phoneItem.id">
                  <el-col :span="5" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{phoneItem.phone}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">邮箱</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.pclObject.email}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">微信</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.pclObject.wxAccount}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">备注</el-col>
                  <el-col :span="19" class="productSelect">
                     <span
                       class="remarkStyle tipClass"
                       :title="item.pclObject.remark">
                      {{ item.pclObject.remark }}
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <el-form-item label="乙方">
            <div style="text-align: right;">
              <el-button size="mini" @click="addSellerContactsList">添加</el-button>
            </div>
          </el-form-item>
          <div class="contactList">
            <div class="productWrap01"
                 v-for="(item, index) in form.sellerContactsListArr"
                 :key="item.id">
              <div class="productItem">
                <el-row>
                  <el-col :span="21" class="productSelect">
<!--                    :rules=" [-->
<!--                    { required: true, message: '请选择乙方', trigger: 'change' }-->
<!--                    ]"-->
                    <el-form-item
                      :prop="`sellerContactsListArr[${index}].id`"
                      :show-message="false"
                      class="contract_product"
                      label-width="40">
                      <el-select
                        v-if="contractInfoList.sellerType === dictDataConst.CONTRACT_SELLER_TYPE.SELL"
                        v-model="item.id"
                        placeholder="请先选择乙方"
                        size="mini"
                        style="width: 100%;"
                        :disabled="!form.contractId"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteWXFMethod"
                        @change="sellerContactsListArrChange($event,index,userList)">
                        <el-option
                          v-for="i in userList"
                          :disabled="setDisabled(i, index,1)"
                          :key="i.id"
                          :label="i.nickName"
                          :value="i.id">
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="item.id"
                        placeholder="请先选择乙方"
                        size="mini"
                        style="width: 100%;"
                        :disabled="!form.contractId"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteYFMethod"
                        @change="sellerContactsListArrChange($event,index,sclArr)">
                        <el-option
                          v-for="i in sclArr"
                          :disabled="setDisabled(i, index,2)"
                          :key="i.id"
                          :label="i.name"
                          :value="i.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" class="productClose">
                    <i class="iconfont" @click="delSellerContactsList(index)">&#xe672;</i>
<!--                    v-if="form.sellerContactsListArr.length > 1"-->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">类型</el-col>
                  <el-col :span="19" class="productSelect">
<!--                    <span  class="appreciationPackage" v-if="contractInfoList.sellerTypeName">{{ contractInfoList.sellerTypeName }}</span>-->
                    <span  class="appreciationPackage" v-if="item.sclObject.orgId && item.sclObject.orgId===contractInfoList.seller">经销</span>
                    <span  class="appreciationPackage" v-if="item.sclObject.orgId && item.sclObject.orgId===contractInfoList.distribution">分销</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">部门</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.departmentName}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">职务</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.postName}}</span>
                  </el-col>
                </el-row>
                <!--            v-if="sclArr.length>0"-->
                <el-row
                  v-for="(phoneItem,phoneIndex) in item.sclObject.phoneList"
                  :key="phoneItem.id">
                  <el-col :span="5" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{phoneItem.phone}}</span>
                  </el-col>
                </el-row>
                <!--            <el-row v-else>-->
                <!--              <el-col :span="5" class="productTitle">电话</el-col>-->
                <!--              <el-col :span="19" class="productSelect">-->
                <!--                <span>{{item.sclObject.phone}}</span>-->
                <!--              </el-col>-->
                <!--            </el-row>-->
                <el-row>
                  <el-col :span="5" class="productTitle">邮箱</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.email}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">微信</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.wxAccount}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">备注</el-col>
                  <el-col :span="19" class="productSelect">
                     <span
                       class="remarkStyle tipClass"
                       :title="item.sclObject.remark">
                      {{ item.sclObject.remark }}
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <el-form-item
            label="卫心方"
            v-if="contractInfoList.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
            <div style="text-align: right;">
              <el-button size="mini" @click="addSysUserContactsList">添加</el-button>
            </div>
          </el-form-item>
<!--          {{contractInfoList.sellerType}}-->
<!--          {{contractSellerTypeDict}}-->
<!--          {{dictDataConst.CONTRACT_SELLER_TYPE.SELL}}-->
          <div class="contactList" v-if="contractInfoList.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
            <div class="productWrap01" v-for="(item, index) in form.sysUserContactsListArr" :key="item.id">
              <div class="productItem">
                <el-row>
                  <el-col :span="21" class="productSelect">
<!--                    :rules=" [-->
<!--                    { required: true, message: '请选择卫心方', trigger: 'change' }-->
<!--                    ]"-->
                    <el-form-item
                      :prop="`sysUserContactsListArr[${index}].id`"
                      :show-message="false"
                      class="contract_product"
                      label-width="40">
                      <el-select
                        v-model="item.id"
                        placeholder="请先选择卫宁方"
                        size="mini"
                        style="width: 100%;"
                        :disabled="!form.contractId"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="remoteWXFMethod"
                        @change="sysUserContactsListArrChange($event,index,userList)">
                        <el-option
                          v-for="i in userList"
                          :disabled="setDisabled(i, index,3)"
                          :key="i.id"
                          :label="i.nickName"
                          :value="i.id">
<!--                          optionList.indexOf(i.id) > -1||i.status===0-->
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" class="productClose">
                    <i class="iconfont" @click="delSysUserContactsList(index)">&#xe672;</i>
<!--                    v-if="form.sysUserContactsListArr.length > 1"-->
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">部门</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.departmentName}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">职务</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.postName}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">电话</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.phone}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">邮箱</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.email}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">微信</el-col>
                  <el-col :span="19" class="productSelect">
                    <span>{{item.sclObject.wxAccount}}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="5" class="productTitle">备注</el-col>
                  <el-col :span="19" class="productSelect">
                     <span
                       class="remarkStyle tipClass"
                       :title="item.sclObject.remark">
                      {{ item.sclObject.remark }}
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="projectTitle">产品信息</div>
          <el-form-item label="相关产品" label-width="45" required class="labelSet productInformation" style="display: flex;flex-wrap: wrap;">
<!--            服务合同-->
<!--            {{form.contractType}}  {{getDict(this.dictConst.CONTRACT_TYPE)[1].id}}-->
            <div  v-if="form.contractType==getDict(this.dictConst.CONTRACT_TYPE)[1].id">
              <div class="productWrap02" v-for="(item,index) in form.productListArr" :key="item.id">
                <div class="productItem">
                  <el-row>
                    <el-col :span="18" style="display: flex">
                      <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="item.productName">
                        <div class="relatedProductName" slot="reference">{{item.productName}}</div>
                      </el-popover>
                      <el-button @click="changeContactPrimary(item,0)" type="text" style="padding: 0;float: right;" size="mini" v-if="item.primaryFlag===1">
                        <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                          <use xlink:href="#icon-shouyao"></use>
                        </svg>
                      </el-button>
                      <el-button @click="changeContactPrimary(item,1)" type="text" style="padding: 0;float: right;" size="mini" v-else>
                        <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                          <use xlink:href="#icon-bushishouyao"></use>
                        </svg>
                      </el-button>
                    </el-col>
                    <el-col :span="6" class="productSelectMoney" style="text-align: right;">
                      <span>{{item.productAmount?item.productAmount:0}}万元</span>
                    </el-col>
                  </el-row>
                  <div class="porductContent" style="background:#fff;padding:0 4px;height: 250px;">
                    <el-row class="porductContentRow" v-for="(p,pIndex) in item.productStatusProgress" :key="pIndex">
                      <el-col :span="6" style="display: flex;height: 20px;align-items: center;">
                        <div class="oneKeySynchronization" v-if="item.primaryFlag===1" @click="oneKeySynchronizationClick(index,p,pIndex)">
                          <i class="iconfont"
                             style="color: #F09933;font-size:10px;">&#xe683;</i>
                        </div>
                        <div v-if="pIndex===0 || p.startTime!==null || (item.productStatusProgress[pIndex-1].startTime!==null&&pIndex<1) || (item.productStatusProgress[pIndex-1].endTime!==null&&pIndex===1)" class="productTitle" style="display: flex;margin-left: 2px;">{{ p.productStatusName }}</div>
                        <div v-else  class="productTitle productTitleEmpty" style="display: flex;margin-left: 2px;">{{ p.productStatusName }}</div>
                      </el-col>
                      <el-col :span="18" class="productSelect">
                        <div v-if="pIndex===0 || p.startTime!==null || (item.productStatusProgress[pIndex-1].startTime!==null&&pIndex<1) || (item.productStatusProgress[pIndex-1].endTime!==null&&pIndex===1)">
                          <el-row>
                            <el-col :span="11">
                              <el-form-item
                                :prop="'productListArr.'+ index +'.productStatusProgress.'+pIndex+'.startTime'"
                                :rules=" [
                            { required: true,validator: fwValidateTime, message: '请输入时间', trigger: 'change', index: index, pIndex:pIndex, timeType: 'start'}
                          ]"
                                :show-message="false"
                                label-width="0"
                                key="start">
                                <el-date-picker
                                  v-model="p.startTime"
                                  type="date"
                                  size="mini"
                                  style="width:90px;"
                                  class="dateChoice"
                                  placeholder="请输入时间"
                                  value-format="yyyy-MM-dd"
                                  @change="fwCheckTime($event,'start', index, pIndex)">
                                </el-date-picker>
                              </el-form-item>
                            </el-col>
                            <el-col :span="2" v-if="p.productStatus === dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS">至</el-col>
                            <el-col :span="11" style="text-align: right;" v-if="p.productStatus === dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS">
                              <el-form-item
                                :prop="'productListArr.'+ index +'.productStatusProgress.'+pIndex+'.endTime'"
                                :rules=" [
                            { required: true,validator: fwValidateTime, message: '请输入时间', trigger: 'change', index: index, pIndex:pIndex, timeType: 'end'}
                          ]"
                                :show-message="false"
                                label-width="0"
                                key="start">
                                <el-date-picker
                                  v-model="p.endTime"
                                  type="date"
                                  size="mini"
                                  style="width:90px;"
                                  class="dateChoice"
                                  placeholder="请输入时间"
                                  value-format="yyyy-MM-dd"
                                  :picker-options="{
                                  minTime: p.startTime
                                }"
                                  @change="fwCheckTime($event,'end', index, pIndex)">
                                </el-date-picker>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>
                        <span v-else class="mustInputPrevious">请先输入上一个状态</span>
                      </el-col>
                    </el-row>
                  </div>
                  <el-row class="zzb_row">
                    <el-col :span="5" class="productTitle">增值包</el-col>
                    <el-col :span="19" class="productSelect zzb" v-if="item.extrasList.length>0">
                      <el-popover
                        placement="top-start"
                        trigger="hover"
                        v-if="item.extrasList&&item.extrasList.length>0"
                        :content="item.extrasList[0].extrasName">
                        <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                      </el-popover>
                      <el-popover
                        popper-class="projectUl_popover"
                        placement="bottom"
                        width="220"
                        trigger="click">
                        <div
                          v-for="i in item.extrasList.slice(1)"
                          style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                          {{ i.extrasName }}
                        </div>
                        <span style="cursor: pointer" slot="reference" class="appreciationPackage" v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="productWrap02" v-for="(item,index) in form.productListArr" :key="item.id">
                <div class="productItem">
                  <el-row>
                    <el-col :span="18" style="display: flex">
                      <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="item.productName">
                        <div class="relatedProductName" slot="reference">{{item.productName}}</div>
                      </el-popover>
                      <el-button @click="changeContactPrimary(item,0)" type="text" style="padding: 0;float: right;" size="mini" v-if="item.primaryFlag===1">
                        <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                          <use xlink:href="#icon-shouyao"></use>
                        </svg>
                      </el-button>
                      <el-button @click="changeContactPrimary(item,1)" type="text" style="padding: 0;float: right;" size="mini" v-else>
                        <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                          <use xlink:href="#icon-bushishouyao"></use>
                        </svg>
                      </el-button>
                    </el-col>
                    <el-col :span="6" class="productSelectMoney" style="text-align: right;">
                      <span>{{item.productAmount?item.productAmount:0}}万元</span>
                    </el-col>
                  </el-row>
                  <div class="porductContent" style="background:#fff;padding:0 4px;height: 250px;">
                    <el-row class="porductContentRow" v-for="(p,pIndex) in item.productStatusProgress" :key="pIndex">
                      <el-col :span="6" style="display: flex;height: 20px;align-items: center;">
                        <div class="oneKeySynchronization" v-if="item.primaryFlag===1" @click="oneKeySynchronizationClick(index,p,pIndex)">
                          <i class="iconfont"
                             style="color: #F09933;font-size:10px;">&#xe683;</i>
                        </div>
                        <div v-if="pIndex===0 || p.startTime!==null || (item.productStatusProgress[pIndex-1].startTime!==null&&pIndex<8) || (item.productStatusProgress[pIndex-1].endTime!==null&&pIndex===8)" class="productTitle" style="display: flex;margin-left: 2px;">{{ p.productStatusName }}</div>
                        <div v-else  class="productTitle productTitleEmpty" style="display: flex;margin-left: 2px;">{{ p.productStatusName }}</div>
                      </el-col>
                      <el-col :span="18" class="productSelect">
                        <div v-if="pIndex===0 || p.startTime!==null || (item.productStatusProgress[pIndex-1].startTime!==null&&pIndex<8) || (item.productStatusProgress[pIndex-1].endTime!==null&&pIndex===8)">
                          <el-row>
                            <el-col :span="11">
                              <el-form-item
                                :prop="'productListArr.'+ index +'.productStatusProgress.'+pIndex+'.startTime'"
                                :rules=" [
                            { required: true,validator: validateTime, message: '请输入时间', trigger: 'change', index: index, pIndex:pIndex, timeType: 'start'}
                          ]"
                                :show-message="false"
                                label-width="0"
                                key="start">
                                <el-date-picker
                                  v-model="p.startTime"
                                  type="date"
                                  size="mini"
                                  style="width:90px;"
                                  class="dateChoice"
                                  placeholder="请输入时间"
                                  value-format="yyyy-MM-dd"
                                  @change="checkTime($event,'start', index, pIndex)">
                                </el-date-picker>
                              </el-form-item>
                            </el-col>
                            <el-col :span="2" v-if="p.productStatus === dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS">至</el-col>
                            <el-col :span="11" style="text-align: right;" v-if="p.productStatus === dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS">
                              <el-form-item
                                :prop="'productListArr.'+ index +'.productStatusProgress.'+pIndex+'.endTime'"
                                :rules=" [
                            { required: true,validator: validateTime, message: '请输入时间', trigger: 'change', index: index, pIndex:pIndex, timeType: 'end'}
                          ]"
                                :show-message="false"
                                label-width="0"
                                key="start">
                                <el-date-picker
                                  v-model="p.endTime"
                                  type="date"
                                  size="mini"
                                  style="width:90px;"
                                  class="dateChoice"
                                  placeholder="请输入时间"
                                  value-format="yyyy-MM-dd"
                                  :picker-options="{
                                  minTime: p.startTime
                                }"
                                  @change="checkTime($event,'end', index, pIndex)">
                                </el-date-picker>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>
                        <span v-else class="mustInputPrevious">请先输入上一个状态</span>
                      </el-col>
                    </el-row>
                  </div>
                  <el-row class="zzb_row">
                    <el-col :span="5" class="productTitle">增值包</el-col>
                    <el-col :span="19" class="productSelect zzb" v-if="item.extrasList.length>0">
                      <el-popover
                        placement="top-start"
                        trigger="hover"
                        v-if="item.extrasList&&item.extrasList.length>0"
                        :content="item.extrasList[0].extrasName">
                        <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                      </el-popover>
                      <el-popover
                        popper-class="projectUl_popover"
                        placement="bottom"
                        width="220"
                        trigger="click">
                        <div
                          v-for="i in item.extrasList.slice(1)"
                          style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                          {{ i.extrasName }}
                        </div>
                        <span style="cursor: pointer" slot="reference" class="appreciationPackage" v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                      </el-popover>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
</template>

<script>
import {
  queryContractList,
  contractInfo
} from '@/modules/crm/api/crm/contract'
import {
  searchContactsList
} from '@/modules/crm/api/crm/org'
import { allUserListApi } from '@/modules/crm/api/system/user'
import {
  projectListApi,
  projectInfoApi,
  addProjectApi,
  editProjectApi
} from "@/modules/crm/api/crm/project";
export default {
  name: "NewConstructionProject" ,
  props: {
    isNewConstruction: { // 遮罩层开关
      type: Boolean,
      required: true
    },
    isProjectCorrelation: { // 该项目是否有关联任务
      type: Boolean,
      required: false
    },
    institutionId: {
      type: Number
    },
    projectId: {
      type: Number
    },
    currentProjectId: {
      type: Number
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams:{
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.TASK_FILE,
        tempFlag: 1
      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],
      ssfList:  this.getDict(this.dictConst.PRODUCT_IMPL_TYPE),
      contractDict: [], // 合同列表
      loading: false,
      contractInfoList: {}, // 合同详情信息
      contractSellerTypeDict: [],
      pclArr: [], // 甲方
      sclArr: [], // 乙方
      wxArr: [], // 卫心方
      userList: [], // 全部用户列表
      value1: '',
      // 项目状态字典
      projectStatusDict: this.getDict(this.dictConst.PROJECT_STATUS),
      value: true,
      form: {},
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        // filingTime: [
        //   { type: 'string', required: true, message: '请选择建项日期', trigger: 'change' }
        // ],
        contractId: [
          { required: true, message: '请选择合同编号', trigger: 'change' }
        ],
        purchaserContactsList: [
          { required: true, message: '请选择客户方', trigger: 'change' }
        ],
        sellerContactsList: [
          { required: true, message: '请选择乙方', trigger: 'change' }
        ],
        sysUserContactsList: [
          { required: true, message: '请选择卫心方', trigger: 'change' }
        ]
      },
      fwValidateTime: (rule, value, callback) => {
        console.log(rule, value, callback)
        if (!value) {
          const index = rule.index
          const pIndex = rule.pIndex
          if (pIndex===0) {
            const start = this.form.productListArr[index].productStatusProgress[pIndex].startTime
            const end = this.form.productListArr[index].productStatusProgress[pIndex].endTime
            const nextStart = this.form.productListArr[index].productStatusProgress[pIndex+1].startTime
            if (nextStart) {
              callback(new Error())
              // return false
            } else {
              if (!start&&!end) {
                callback()
              } else {
                callback(new Error())
                // return false
              }
            }
          } else if (pIndex===1) {
            callback()
          }
        } else {
          const index = rule.index
          const pIndex = rule.pIndex
          if (pIndex === 0) {
            const start = this.form.productListArr[index].productStatusProgress[pIndex].startTime
            const end = this.form.productListArr[index].productStatusProgress[pIndex].endTime
            if (start && end) {
              if (start.replace('-', '') <= end.replace('-', '')) {
                callback()
              } else {
                callback(new Error())
              }
            }
          } else if (pIndex === 1) {
            const prevEnd = this.form.productListArr[index].productStatusProgress[pIndex - 1].endTime
            if (prevEnd) {
              if (prevEnd.replace('-', '') <= value.replace('-', '')) {
                callback()
              } else {
                callback(new Error())
              }
            }
          }
        }
      },
      validateTime: (rule, value, callback) => {
        console.log(rule, value, callback)
        if (!value) {
          const index = rule.index
          const pIndex = rule.pIndex
          if (pIndex===7) {
            const start = this.form.productListArr[index].productStatusProgress[pIndex].startTime
            const end = this.form.productListArr[index].productStatusProgress[pIndex].endTime
            const nextStart = this.form.productListArr[index].productStatusProgress[pIndex+1].startTime
            if (nextStart) {
              callback(new Error())
              // return false
            } else {
              if (!start&&!end) {
                callback()
              } else {
                callback(new Error())
                // return false
              }
            }
          } else if (pIndex===8) {
            callback()
          } else {
            let temp = this.form.productListArr[index].productStatusProgress.every((j,jIndex)=>{
              if(jIndex>pIndex && j.startTime) {
                return false
              } else {
                return true
              }
            })
            console.log(temp)
            if (temp) {
              callback()
            } else {
              callback(new Error())
            }
          }

        } else {
          const index = rule.index
          const pIndex = rule.pIndex
          if (pIndex===0) {
            const nextStart = this.form.productListArr[index].productStatusProgress[pIndex+1].startTime
            if (nextStart) {
              if (value.replace('-', '') <= nextStart.replace('-', '')) {
                callback()
              } else {
                callback(new Error())
              }
            } else {
              callback()
            }
          } else if(pIndex-1>=0) {
            if(pIndex<7) {
              const prevStart = this.form.productListArr[index].productStatusProgress[pIndex-1].startTime
              const nextForStart = this.form.productListArr[index].productStatusProgress[pIndex+1].startTime
              if (prevStart && (prevStart.replace('-', '') <= value.replace('-', ''))) {
                if (nextForStart) {
                  if (value.replace('-', '') > nextForStart.replace('-', '')) {
                    callback(new Error())
                  } else {
                    callback()
                  }
                } else {
                  callback()
                }
              } else {
                callback(new Error())
              }
            } else if(pIndex===7) {
              const prevStart = this.form.productListArr[index].productStatusProgress[pIndex-1].startTime
              const start = this.form.productListArr[index].productStatusProgress[pIndex].startTime
              const end = this.form.productListArr[index].productStatusProgress[pIndex].endTime
              const nextForStart = this.form.productListArr[index].productStatusProgress[pIndex-1].startTime
              if (prevStart) {
                if (start && end) {
                  if (prevStart.replace('-', '') <= start.replace('-', '') && start.replace('-', '') <= end.replace('-', '')) {
                    callback()
                  } else {
                    callback(new Error())
                  }
                }
                // else {
                //   callback(new Error())
                // }
              } else {
                if (start && end) {
                  if (start.replace('-', '') <= end.replace('-', '')) {
                    callback()
                  } else {
                    callback(new Error())
                  }
                }
                // else {
                //   callback(new Error())
                // }
              }
              // if (nextForStart) {
              //   if (start && end) {
              //     if ( start.replace('-', '') <= end.replace('-', '')&& end.replace('-', '') <= nextForStart.replace('-', '')) {
              //       callback()
              //     } else {
              //       callback(new Error())
              //     }
              //   } else {
              //     callback(new Error())
              //   }
              // } else {
              //   if (start && end) {
              //     if (start.replace('-', '') <= end.replace('-', '')) {
              //       callback()
              //     } else {
              //       callback(new Error())
              //     }
              //   } else {
              //     callback(new Error())
              //   }
              // }
            } else if(pIndex===8) {
              const prevEnd = this.form.productListArr[index].productStatusProgress[pIndex-1].endTime
              if (prevEnd) {
                if (prevEnd.replace('-', '') <= value.replace('-', '')) {
                  callback()
                } else {
                  callback(new Error())
                }
              }
            }
          }
        }
      },
      projectListLen: null, // 项目个数
      projectList: [], // 项目列表
      projectObject: {}, // 单个项目信息
      projectValue: '',
      khfOrgIdList: [], // 客户方orgIdList
      yfOrgIdList: [], // 乙方orgIdList
      khfLength: null, // 客户方可添加最大人数
      yfLength: null, // 乙方可添加最大人数
      wxfLength: null, // 卫心方可添加最大人数
    }
  },
  computed:{
    setDisabled () {
      return function (opt, index,val) {
        if (val === 0) {
          // 客户方
          const selectedArr = this.form.purchaserContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id)
        } else if (val === 1) {
          // 已方（自营）
          const selectedArr = this.form.sellerContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id) || opt.status===0
        } else if (val === 2) {
          // 已方（经销）
          const selectedArr = this.form.sellerContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id)
        } else if (val === 3) {
          // 卫心方
          // opt为每项option，index为el-select索引
          // 全部已选择
          const selectedArr = this.form.sysUserContactsListArr.map(item => item.id)
          // 删除自己的已选
          selectedArr.splice(index, 1)
          // 其它的已选，禁用
          return selectedArr.includes(opt.id) || opt.status===0
        }
      }
    }
  },
  async created() {
    this.contractSellerTypeDict = this.getDict(this.dictConst.CONTRACT_SELLER_TYPE)
    this.getAllUser()
    this.reset()
    if(this.institutionId) {
      await this.projectListApiFn(this.institutionId)
    }
    if (this.projectId) {
      await this.projectInfoFn(this.projectId)
    }
  },
  methods: {
    handleFileChange(file, fileList) {
      // this.fileList = fileList.slice(-3)
    },
    //we你按上传前的校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传附件大小不能超过 50MB');
        return false;
      }
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {
      console.log(res, file, fileList)
      if (res.code == 200) {
        this.msgSuccess("上传成功")
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: "success",
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除文件
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileList.splice(i, 1)
            return true
          }
        })
      }

    },
    //移除文件
    handleFileRemove(file, fileList) {
      console.log("移除文件",file, fileList)
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          this.uploadFileList.splice(i, 1)
          return true
        }
      })

    },
    // 校验已转售后的开始时间和结束时间
    fwCheckTime(val,type, index, pIndex) {
      console.log(val,type, index, pIndex)
      if(pIndex === 0) {
        if ( type=== 'end') {
          this.$refs.form.validateField('productListArr.'+ index +'.productStatusProgress.'+pIndex+'.startTime')
        } else if ( type=== 'start')  {
          this.$refs.form.validateField('productListArr.'+ index +'.productStatusProgress.'+pIndex+'.endTime')
        }
      }

    },
    // 校验已转售后的开始时间和结束时间
    checkTime(val,type, index, pIndex) {
      console.log(val,type, index, pIndex)
      if(pIndex === 7) {
        if ( type=== 'end') {
            this.$refs.form.validateField('productListArr.'+ index +'.productStatusProgress.'+pIndex+'.startTime')
        } else if ( type=== 'start')  {
            this.$refs.form.validateField('productListArr.'+ index +'.productStatusProgress.'+pIndex+'.endTime')
        }
      }

    },
    // 机构关联项目列表
    async projectListApiFn(val) {
      let obj = {}
      obj.orgId = val
      let res = await projectListApi(obj)
      console.log(res)
      if(res.code === 200) {
        this.projectList = res.data
        this.projectListLen = res.data.length
        console.log(this.currentProjectId)
        if (this.currentProjectId) {
          this.projectValue = this.currentProjectId
        } else {
          if (this.projectListLen>0) {
            this.projectValue = this.projectList[0].id // 默认选中第一个
          }
        }
        this.projectChange(this.projectValue)
      }
    },
    projectChange(val) {
      console.log(val)
      if (val) {
        this.projectInfoFn(val)
      } else {
        this.projectObject = {}
      }
    },
    // 获取相关项目详细信息
    async projectInfoFn(val) {
      let res = await projectInfoApi(val)
      if (res.code === 200) {
        this.loading = false
        this.projectObject = res.data
        this.form =this.$deepClone(res.data)
        Object.assign(this.form,res.data)
        this.fileList = []
        res.data.projectFileList?.forEach(item => {
          let fileItem = {
            fileId:  item.fileId,
            name:item.fileName,
            status: 'success',
            uid: item.fileId
          }
          this.fileList.push(fileItem)
          this.uploadFileList.push(fileItem)
        })
        // console.log(this.form)
        this.contractDict = [
          {
            contractId: this.form.contractId,
            contractName: this.form.contractName,
            contractNum: this.form.contractNum
          }
        ]
        this.contractInfoList =this.$deepClone(res.data)
        Object.assign(this.contractInfoList,res.data)
        let productList = res.data.productList
        // 相关产品第一行状态默认时间
        if (this.form.contractType == this.getDict(this.dictConst.CONTRACT_TYPE)[1].id) {
          productList.forEach((item,index)=> {
            item.productStatusProgress.forEach((j,jIndex)=> {
              if (jIndex===0&&j.startTime===null&&j.endTime===null) {
                let currentDate = new Date()
                j.startTime = this.parseTime(currentDate, '{y}-{m}-{d}')
                j.endTime = this.parseTime(currentDate, '{y}-{m}-{d}')
              }
            })
          })
        }
        // productList.forEach((item)=> {
        //   item.productStatusProgress.forEach((i)=> {
        //     // 已转售后
        //     if (i.productStatus === this.dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS) {
        //       if(i.startTime&&i.endTime) {
        //         i.rangTime = [i.startTime, i.endTime]
        //       }else {
        //         i.rangTime = []
        //       }
        //
        //     }
        //   })
        // })
        // 设置首要
        let flag = productList.every((item)=>{
          return  item.primaryFlag === 0
        })
        console.log(flag)
        if(flag) {
          productList[0].primaryFlag = 1
        }
        this.$set(this.form, 'productListArr',productList)
        // console.log(this.form.productListArr)
        // 客户list
        this.form.purchaserContactsListArr = []
        this.form.purchaserContactsList.forEach((item,index)=> {
          let obj = {}
          obj.id = item.id
          obj.pclObject = item
          this.form.purchaserContactsListArr.push(obj)
        })
        let khArr = [this.contractInfoList.purchaser]
        if(this.contractInfoList.customerList.length>0) {
          this.contractInfoList.customerList.forEach((item) => {
            khArr.push(item.customerId)
          })
        }
        let khArray = Array.from(new Set(khArr)); // 将Set转换为数组并赋值给khArray
        // console.log(khArray)
        this.khfOrgIdList = khArray
        this.pclArr = await this.searchContactsListFn(khArray)
        if (this.pclArr&&this.pclArr.length>0) {
          this.khfLength = this.pclArr.length
        } else {
          this.khfLength = 0
        }
        // console.log(this.pclArr)
        // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
        if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
          // 乙方list
          this.form.sellerContactsListArr = []
          this.form.sellerContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sellerContactsListArr.push(obj)
          })
          if (this.userList&&this.userList.length>0) {
            this.yfLength = this.userList.length
          } else {
            this.yfLength = 0
          }
          // await this.getAllUser()
        } else {
          // 乙方list
          this.form.sellerContactsListArr = []
          this.form.sellerContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sellerContactsListArr.push(obj)
          })
          this.form.sysUserContactsListArr = []
          this.form.sysUserContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sysUserContactsListArr.push(obj)
          })
          // console.log(this.contractInfoList.distribution)
          let sclArray = []
          if (this.contractInfoList.distribution) {
            sclArray = [this.contractInfoList.seller,this.contractInfoList.distribution]
          } else {
            sclArray = [this.contractInfoList.seller]
          }
          // let sclArray = [this.contractInfoList.seller,this.contractInfoList.distribution]
          this.yfOrgIdList = sclArray
          this.sclArr = await this.searchContactsListFn(sclArray)
          if (this.sclArr&&this.sclArr.length>0) {
            this.yfLength = this.sclArr.length
          } else {
            this.yfLength = 0
          }
          if (this.userList&&this.userList.length>0) {
            this.wxfLength = this.userList.length
          } else {
            this.wxfLength = 0
          }
          // console.log(this.sclArr)
          this.$forceUpdate()
        }
      }
    },
    editProject() {
      console.log(this.uploadFileList)
      let projectFileList = []
      if(this.uploadFileList&&this.uploadFileList.length>0) {
        this.uploadFileList.forEach((item)=> {
          projectFileList.push(item.fileId)
        })
      }
      this.form.projectFileList = projectFileList
      console.log('editProject!', this.form)
      this.form.productList = []
      this.form.productListArr.forEach((item,index)=> {
        let object = {}
        object.productId = item.productId
        object.productStatus = item.productStatus
        object.batchFlag = item.batchFlag?1:0
        object.primaryFlag = item.primaryFlag?1:0
        object.productStatusTimeList = []
        item.productStatusProgress.forEach((j,jIndex) => {
          let statusObj = {}
          statusObj.productStatus = j.productStatus
          statusObj.startTime = j.startTime
          statusObj.endTime = j.endTime
          if(statusObj.startTime) {
            object.productStatusTimeList.push(statusObj)
          }
        })
        this.form.productList.push(object)
      })
      this.form.purchaserContactsList = []
      if(this.form.purchaserContactsListArr&&this.form.purchaserContactsListArr.length>0) {
        this.form.purchaserContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.purchaserContactsList.push(item.id)
          }
        })
      }
      this.form.sellerContactsList = []
      if(this.form.sellerContactsListArr&&this.form.sellerContactsListArr.length>0) {
        this.form.sellerContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.sellerContactsList.push(item.id)
          }
        })
      }
      // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
      if (this.contractInfoList.sellerType !== this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 经销
        this.form.sysUserContactsList = []
        if(this.form.sysUserContactsListArr&&this.form.sysUserContactsListArr.length>0) {
          this.form.sysUserContactsListArr.forEach((item,index)=> {
            if (item.id) {
              this.form.sysUserContactsList.push(item.id)
            }
          })
        }
      }
      console.log(this.form)
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res = await editProjectApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
            flag = true
            this.eventBus.$emit(this.eventConst.project.editSucceed)
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      // return flag
    },
    // 编辑重置
    async editReset() {
      this.contractSellerTypeDict = this.getDict(this.dictConst.CONTRACT_SELLER_TYPE)
      // this.getAllUser()
      this.reset()
      if(this.institutionId) {
        await this.projectListApiFn(this.institutionId)
      }
      if (this.projectId) {
        await this.projectInfoFn(this.projectId)
      }
    },
    onSubmit() {
      console.log(this.uploadFileList)
      let projectFileList = []
      if(this.uploadFileList&&this.uploadFileList.length>0) {
        this.uploadFileList.forEach((item)=> {
          projectFileList.push(item.fileId)
        })
      }
      this.form.projectFileList = projectFileList
      console.log('submit!', this.form)
      this.form.productList = []
      this.form.productListArr.forEach((item,index)=> {
        let object = {}
        object.productId = item.productId
        object.productStatus = item.productStatus
        object.batchFlag = item.batchFlag?1:0
        object.primaryFlag = item.primaryFlag?1:0
        object.productStatusTimeList = []
        item.productStatusProgress.forEach((j,jIndex) => {
          let statusObj = {}
          statusObj.productStatus = j.productStatus
          statusObj.startTime = j.startTime
          statusObj.endTime = j.endTime
          if(statusObj.startTime) {
            object.productStatusTimeList.push(statusObj)
          }
        })
        this.form.productList.push(object)
      })
      this.form.purchaserContactsList = []
      if(this.form.purchaserContactsListArr&&this.form.purchaserContactsListArr.length>0) {
        this.form.purchaserContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.purchaserContactsList.push(item.id)
          }
        })
      }
      this.form.sellerContactsList = []
      if(this.form.sellerContactsListArr&&this.form.sellerContactsListArr.length>0) {
        this.form.sellerContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.sellerContactsList.push(item.id)
          }
        })
      }
      // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
      if (this.contractInfoList.sellerType !== this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 经销
        this.form.sysUserContactsList = []
        if(this.form.sysUserContactsListArr&&this.form.sysUserContactsListArr.length>0) {
          this.form.sysUserContactsListArr.forEach((item,index)=> {
            if (item.id) {
              this.form.sysUserContactsList.push(item.id)
            }
          })
        }
      }
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res = await addProjectApi(this.form)
          if (res.code == 200) {
            console.log('onSubmit')
            this.msgSuccess()
            this.reset()
            flag = true
            this.eventBus.$emit(this.eventConst.project.addSucceed)
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      // return flag
    },
    /** 表单重置 */
    reset() {
      this.form = {
        implType: this.getDictPrimaryItemId(this.dictConst.PRODUCT_IMPL_TYPE), // 实施方式
        contractId: '',
        // filingTime: '', // 建项日期
        contractClassify: 0, // 合同分类
        contractType: 0, // 合同类型
        serverType: 0,
        purchaserContactsListArr: [
          {
            id: '',
            pclObject:  {}
          }
        ],
        sellerContactsListArr:  [
          {
            id: '',
            sclObject:  {}
          }
        ],
        sysUserContactsListArr:  [
          {
            id: '',
            sclObject:  {}
          }
        ],
        projectClassify: 0, // 机构类型
        seller: 0,
        sellerName: '',
        projectFileList: [],
        productListArr: [],
        productList: [],
        purchaserContactsList: [],
        sellerContactsList: [],
        sysUserContactsList: [],
        remark: ''
      }
      this.fileList = []
      this.resetForm('form')
      this.contractInfoList = {} // 合同详情信息
    },
    implTypeSelect(val) {
      this.form.implType = val
    },
    contractTypeSelect(val) {
      this.form.contractType = val
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    },
    async remoteWXFMethod(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.userList = res.data
      })
    },
    // 查询全部合同列表
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 2
          obj.searchValue = query
          let res = await queryContractList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.contractDict = res.data
          }
        }, 200);
      } else {
        this.contractDict = [];
      }
    },
    contractInfoChange(val) {
      console.log(val)
      if(val)  {
        this.contractInfoFn(val)
      }
    },
    // 获取合同详细信息
    async contractInfoFn(val) {
      let res = await contractInfo(val)
      console.log(res)
      if(res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.contractInfoList = res.data
        let productList = res.data.productList
        // 设置首要
        let flag = productList.every((item)=>{
          return  item.primaryFlag === 0
        })
        console.log(flag)
        if(flag) {
          productList[0].primaryFlag = 1
        }
        // 相关产品第一行状态默认时间
        if (this.contractInfoList.contractType == this.getDict(this.dictConst.CONTRACT_TYPE)[1].id) {
          productList.forEach((item,index)=> {
            item.productStatusProgress.forEach((j,jIndex)=> {
              if (jIndex===0&&j.startTime===null&&j.endTime===null) {
                let currentDate = new Date()
                j.startTime = this.parseTime(currentDate, '{y}-{m}-{d}')
                j.endTime = this.parseTime(currentDate, '{y}-{m}-{d}')
              }
            })
          })
        }
        this.$set(this.form, 'productListArr', productList)
        this.$set(this.form, 'contractType', res.data.contractType)
        // this.form.contractType = res.data.contractType
        // 客户list
        let khArr = [this.contractInfoList.purchaser]
        if(this.contractInfoList.customerList.length>0) {
          this.contractInfoList.customerList.forEach((item) => {
            khArr.push(item.customerId)
          })
        }
        let khArray = Array.from(new Set(khArr)); // 将Set转换为数组并赋值给khArray
        console.log(khArray)
        this.khfOrgIdList = khArray
        this.pclArr = await this.searchContactsListFn(khArray)
        if (this.pclArr&&this.pclArr.length>0) {
          this.khfLength = this.pclArr.length
        } else {
          this.khfLength = 0
        }
        console.log(this.pclArr)
        this.form.purchaserContactsListArr = []
        this.pclArr.forEach((item,index) =>{
          let obj = {
          }
          obj.id = item.id
          obj.pclObject = item
          this.form.purchaserContactsListArr.push(obj)
        })
        // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
        if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
          // await this.getAllUser()
          this.form.sellerContactsListArr = []
          // this.userList.forEach((item,index) =>{
          //   let obj = {
          //   }
          //   obj.id = item.id
          //   obj.sclObject = item
          //   this.form.sellerContactsListArr.push(obj)
          // })
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          if (this.userList&&this.userList.length>0) {
            this.yfLength = this.userList.length
          } else {
            this.yfLength = 0
          }
        } else {
          let sclArray = []
          if (this.contractInfoList.distribution) {
            sclArray = [this.contractInfoList.seller,this.contractInfoList.distribution]
          } else {
            sclArray = [this.contractInfoList.seller]
          }
          this.yfOrgIdList = sclArray
          this.sclArr = await this.searchContactsListFn(sclArray)
          if (this.sclArr&&this.sclArr.length>0) {
            this.yfLength = this.sclArr.length
          } else {
            this.yfLength = 0
          }
          this.form.sellerContactsListArr = []
          // this.sclArr.forEach((item,index) =>{
          //   let obj = {
          //   }
          //   obj.id = item.id
          //   obj.sclObject = item
          //   this.form.sellerContactsListArr.push(obj)
          // })
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          if (this.userList&&this.userList.length>0) {
            this.wxfLength = this.userList.length
          } else {
            this.wxfLength = 0
          }
          // 卫心方等于全部用户列表
          this.form.sysUserContactsListArr = []
          // this.userList.forEach((item,index) =>{
          //   let obj = {
          //   }
          //   obj.id = item.id
          //   obj.sclObject = item
          //   this.form.sysUserContactsListArr.push(obj)
          // })
          this.form.sysUserContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
        }

      }
    },
    purchaserContactsListChange(val, index) {
      console.log(val, index)
      if(val) {
        let pclObject = this.pclArr.find(item => item.id === val)
        console.log(pclObject)
        this.form.purchaserContactsListArr[index].pclObject = pclObject
      } else {
        this.form.purchaserContactsListArr[index].pclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加客户方
    addPurchaserContactsList() {
      if(this.form.purchaserContactsListArr.length<this.khfLength) {
        this.form.purchaserContactsListArr.push(
          {
            id: '',
            pclObject:  {}
          }
        )
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'warning',
          message: '操作失败,已添加全部联系人！',
          duration: '1000'
        })
        return false
      }
    },
    delPurchaserContactsList(index) {
      this.form.purchaserContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    sellerContactsListArrChange(val, index,arr) {
      console.log(val, index)
      if(val) {
        let sclObject = arr.find(item => item.id === val)
        console.log(sclObject)
        this.form.sellerContactsListArr[index].sclObject = sclObject
      } else {
        this.form.sellerContactsListArr[index].sclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加乙方
    addSellerContactsList() {
      if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
        // 乙方list
        if(this.form.sellerContactsListArr.length<this.yfLength) {
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          this.$forceUpdate()
        } else {
          this.$message({
            type: 'warning',
            message: '操作失败,已添加全部联系人！',
            duration: '1000'
          })
          return false
        }
      } else {
        // 乙方list
        if(this.form.sellerContactsListArr.length<this.yfLength) {
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          this.$forceUpdate()
        } else {
          this.$message({
            type: 'warning',
            message: '操作失败,已添加全部联系人！',
            duration: '1000'
          })
          return false
        }
      }

    },
    delSellerContactsList(index) {
      this.form.sellerContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    // 卫心方
    sysUserContactsListArrChange(val, index,arr) {
      console.log(val, index)
      if(val) {
        let sclObject = arr.find(item => item.id === val)
        console.log(sclObject)
        this.form.sysUserContactsListArr[index].sclObject = sclObject
      } else {
        this.form.sysUserContactsListArr[index].sclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加卫心方
    addSysUserContactsList() {
      if(this.form.sysUserContactsListArr.length<this.wxfLength) {
        this.form.sysUserContactsListArr.push(
          {
            id: '',
            sclObject:  {}
          }
        )
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'warning',
          message: '操作失败,已添加全部联系人！',
          duration: '1000'
        })
        return false
      }
    },
    delSysUserContactsList(index) {
      this.form.sysUserContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    // 客户方
    async remoteKHFMethod(query) {
      // console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          obj.orgIdList = this.khfOrgIdList
          let res = await searchContactsList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.pclArr = res.data
          }
        }, 200);
      } else {
        this.pclArr = [];
      }
    },
    // 乙方
    async remoteYFMethod(query) {
      // console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          obj.orgIdList = this.yfOrgIdList
          let res = await searchContactsList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.sclArr = res.data
          }
        }, 200);
      } else {
        this.sclArr = [];
      }
    },
    // 机构联系人列表查询
    async searchContactsListFn(arr,query = '') {
      const obj = {
        searchValue: query,
        orgIdList: arr
      }
      let res = await searchContactsList(obj)
      console.log(res)
      return res.data
    },
    // 新增项目时修改产品状态
    productStatusChange(val,index) {
      console.log(val,index)
      let order = null
      this.projectStatusDict.forEach((item,i)=> {
        if(item.id === val) {
          order = i+1
        }
      })
      this.form.productListArr[index].projectStatusOrder = order
    },
    // 切换首要
    changeContactPrimary(item,val) {
      if(val === 1) {
        this.form.productListArr.forEach((i)=> {
          i.primaryFlag = 0
        })
        return item.primaryFlag = val
      }
    },
    oneKeySynchronizationClick(index,p,pIndex) {
      console.log(p.startTime)
      for (let jIndex=0;jIndex<=this.form.productListArr.length;jIndex++) {
        if (jIndex!==index) {
          if (pIndex===0) {
            if (p.startTime) {
              console.log(this.form.productListArr[jIndex],jIndex)
              console.log(this.form.productListArr[jIndex].productStatusProgress)
              this.form.productListArr[jIndex].productStatusProgress[pIndex].startTime = p.startTime
            } else {
              this.$message({
                type: 'warning',
                message: '状态时间未填写完整，暂不能对当前状态一键同步！',
                duration: '1000'
              })
              return false
            }
          } else if (p.productStatus === this.dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS){
            if (this.form.productListArr[jIndex].productStatusProgress[pIndex-1].startTime!==null) {
              if(p.startTime!==null&&p.endTime!==null) {
                this.form.productListArr[jIndex].productStatusProgress[pIndex].startTime = p.startTime
                this.form.productListArr[jIndex].productStatusProgress[pIndex].endTime = p.endTime
              } else {
                this.$message({
                  type: 'warning',
                  message: '状态时间未填写完整，暂不能对当前状态一键同步！',
                  duration: '1000'
                })
                return false
              }
            } else {
              this.$message({
                type: 'warning',
                message: '前一个状态时间为空，暂不能对当前状态一键同步！',
                duration: '1000'
              })
              return false
            }
          } else {
            if (p.startTime) {
              if(this.form.productListArr[jIndex].productStatusProgress[pIndex-1].startTime!==null) {
                this.form.productListArr[jIndex].productStatusProgress[pIndex].startTime = p.startTime
              } else {
                this.$message({
                  type: 'warning',
                  message: '前一个状态时间为空，暂不能对当前状态一键同步！',
                  duration: '1000'
                })
                return false
              }
            } else {
              this.$message({
                type: 'warning',
                message: '状态时间未填写完整，暂不能对当前状态一键同步！',
                duration: '1000'
              })
              return false
            }
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.NewConstructionProject_wrap{
  .el-form-item--medium .el-form-item__content{
    line-height: 14px!important;
  }
  .el-form-item--medium .el-form-item__label{
    line-height: 14px!important;
  }
  .contractNameClass{
    word-break: break-all;
  }
  .dateChoice{
    .el-input__inner{
      padding-left: 5px!important;
      width: 90px;
      height: 20px;
      border-radius: 4px 4px 4px 4px;
      padding-right: 20px;
      //border: 1px solid #CECECE;
    }
    .el-input__suffix{
      right: 0px;
    }
  }
}
</style>
<style lang="scss"  scoped>
  .NewConstructionProject_wrap{
    height: 100%;
    padding: 5px 0 20px;
    .productSelect{
      ::v-deep.el-input__prefix{
        display: none;
      }
      ::v-deep.el-icon-date{
        display: none;
      }
    }
    ::v-deep.productClose{
      text-align: right!important;
      .iconfont{
        font-size: 12px!important;
        color: #F23232!important;
        line-height: 28px!important;
        cursor: pointer;
      }
    }
    ::v-deep.tipClass{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .typeSpan{
      display: inline-block;
      padding: 0px 6px;
      border-radius: 20px;
      background: #EBEDFE;
      color: #7281ED;
      font-size: 12px;
      margin-right: 10px;
      line-height: 20px;
    }
    .noDataWrap{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .noData{
        width: 96px;
        height:102px;
        text-align: center;
        .noDataText{
          margin-top: 15px;
          color: rgba(0, 0, 0, 0.25);
          font-size: 16px;
        }
      }
    }
    .contract_product{
      margin-bottom: 0!important;
      line-height:25px!important;
      ::v-deep.el-form-item__content{
        line-height: 25px!important;
      }
    }
    .appreciationPackage{
      padding: 2px 4px;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #DEDEDE;
      color: #5D5D5D;
      font-size: 14px;
      line-height: 18px;
      height: 22px;
      margin-right: 4px;
      margin-top: 3px;
      &:last-child{
        margin-right: 0;
      }
    }
    ::v-deep.appreciationPackageName{
      display: inline-block;
      margin-left:10px;
      max-width: 125px;
      white-space: nowrap;
      overflow: hidden;    /* 超出部分隐藏 */
      text-overflow: ellipsis; /* 显示省略号 */
    }
    //.zzbSpan{
    //  display: inline-block;
    //  padding: 2px 4px;
    //  color: #5D5D5D;
    //  font-size: 14px;
    //  line-height: 18px;
    //  align-items: center;
    //  //gap: 4px;
    //  border-radius: 2px;
    //  border: 1px solid #DEDEDE;
    //}
    .projectNum{
      font-size: 15px;
      font-weight: 500;
      color: #5D5D5D;
      margin-left: 20px;
    }
    .projectTitle{
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 8px;
      margin-bottom: 20px;
      color: #3160BA;
      border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    }
    .productInformation{
      ::v-deep.el-form-item__label{
        width: 100%;
        margin-bottom: 8px;
      }
    }
    .contactList{
      display: flex;
      flex-wrap: wrap;
    }
    .productWrap{
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin:0 10px 10px 0;
      width: 280px;
      padding:6px 0;
      display: flex;
      .productItem{
        float: left;
        width: 260px;
        padding:0 10px;
        border-right: 1px solid #EAECFD;
        .el-row{
          margin-bottom: 4px;
        }
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 28px;
        }
      }
      .delBtnWrap{
        padding:0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .productWrap01{
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EFEFEF;
      margin:0 10px 10px 0;
      width: 280px;
      padding:6px 10px;
      display: flex;
      .productItem{
        float: left;
        width: 100%;
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 28px;
        }
        .productSelect{
          //height: 28px;
          font-size: 14px;
          color: #5D5D5D;
          line-height: 28px;
          word-wrap: break-word;
        }
      }
    }
    .productWrap02{
      background: #f8f8f8;
      border-radius: 4px 4px 4px 4px;
      margin:0 9px 10px 0;
      width: 300px;
      padding:6px 10px 6px 10px;
      display: flex;
      float: left;
      height: 320px;
      ::v-deep.oneKeySynchronization{
        width: 18px;
        height: 18px;
        background: #FFFFFF;
        line-height: 16px;
        text-align: center;
        box-shadow: inset 0px -1px 1px 0px rgba(240,153,51,0.5);
        border-radius: 20px 20px 20px 20px;
        cursor: default;
        &:hover{
          box-shadow: inset 0px -1px 1px 0px rgba(240,153,51,0.5), 0px 3px 3px 0px rgba(223,137,37,0.25);
          border-radius: 20px 20px 20px 20px;
        }
        &:active{
          background: #E5E5E5;
          box-shadow: inset 0px -1px 1px 0px rgba(240,153,51,0.5);
          border-radius: 20px 20px 20px 20px;
        }
      }
      .mustInputPrevious{
        font-weight: 400;
        font-size: 12px;
        color: #A8ABB2;
        line-height: 20px;
        cursor: default;
      }
      .productItem{
        float: left;
        width: 100%;
        .batchClass{
          display: inline-block;
          height: 20px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 20px;
          margin-right: 4px;
        }
        .relatedProductName{
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #5D5D5D;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .relatedProductProjectState{
          //margin: 4px 0;
          display: inline-block;
        }
        .productTitle{
          //height: 28px;
          font-size: 12px;
          color: #A8ABB2;
          //line-height: 28px;
        }
        .productSelect{
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #5D5D5D;
          word-wrap: break-word;
          display: flex;
          justify-content: flex-end;
        }
        .zzb{
          display: flex;
          justify-content: end;
        }
        .productSelectMoney{
          font-size: 14px;
          color: #5D5D5D;
          line-height: 20px;
        }
      }
      .projectUl{
        background: #EAECEF;
        border-radius: 4px;
        width: 170px;
        overflow: hidden;
        li{
          float: left;
          width: 18px;
          height: 8px;
          background: #F1880D;
          margin-right: 1px;
          &:last-child{
            margin-right: 0px;
          }
        }
      }
      .zzb_row{
        margin-top: 10px;
        .appreciationPackage{
          margin-top: 0px;
        }
        .productTitle{
          height: 22px;
          line-height:22px;
          font-size: 14px;
          color: #A8ABB2;
        }
      }
      .porductContent{
        .porductContentRow{
          padding: 4px 0;
          .productTitle{
            color: #303133;
          }
          .productTitleEmpty{
            color: #A8ABB2;
          }
        }
        ::v-deep.el-range-separator{
          line-height: 14px;
        }
        ::v-deep.el-range-editor--mini .el-range__close-icon{
          line-height: 14px;
        }
        ::v-deep.el-input--mini .el-input__icon{
          line-height: 14px;
        }
      }
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  .shouyao{
    //margin-top: 9px;
  }
  .setBtn,.addBtn,.delBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f1f1f1;
    line-height: 16px;
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 8px 5px 0;
    cursor: pointer;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .closeBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #EBEBEB;
    line-height: 16px;
    color: #F23232;
    font-size: 8px;
    text-align: center;
    cursor: pointer;
  }
  /* switch按钮样式 */
  ::v-deep.batchSwitch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  ::v-deep.batchSwitch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  ::v-deep.batchSwitch .el-switch__label--right span{
    width: 25px;
    margin-right: 28px;
    font-size: 12px!important;
  }
  /*关闭时文字位置设置*/
  ::v-deep.batchSwitch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  ::v-deep.batchSwitch .el-switch__label--left span{
    width: 25px;
    margin-left: 16px;
    font-size: 12px !important;
    float: left;
    line-height: 20px;
  }
  /*显示文字*/
  ::v-deep.batchSwitch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  ::v-deep.batchSwitch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 48px !important;
    margin: 0;
  }
</style>
