<!--规则执行结果-->
<template>
  <div class="app-container" style="height:100%"
       v-loading.fullscreen.lock="pageLoading"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">注册审核</el-col>
            <el-col :span="12">
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model="queryParams.searchValue" size="small" style="width:400px;"
                            maxlength="30" show-word-limit clearable
                            placeholder="用户名/手机号/邮箱"
                            @keydown.enter.native="handleQuery"
                  ></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="queryParams.registerStatus" size="small"
                             style="width:130px;"
                             filterable
                             @keydown.enter.native="handleQuery"
                             placeholder="请选择状态" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in registerStatusDict"
                    ></el-option>
                  </el-select>
                </el-form-item>


                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table border stripe height="100%" v-loading="tableLoading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="roleTable"
                  :default-sort="{prop: 'registerTime', order: 'descending'}"
                  @sort-change="tableSortChange"

        >
          <el-table-column label="用户名称" prop="name" min-width="150"/>
          <el-table-column label="手机号" prop="phone" min-width="150"/>
          <el-table-column label="邮箱" prop="email" min-width="150"/>
          <el-table-column label="状态" prop="registerStatusName" min-width="150"/>
          <el-table-column label="申请时间" prop="registerTime" min-width="150" sortable="custom"/>
          <el-table-column label="审批时间" prop="approvalTime" min-width="150" sortable="custom"/>
          <el-table-column label="拒绝理由" prop="refuseReason" min-width="150" />
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                v-show="scope.row.allowApproval==1"
                style="color:#ed923a"
                @click="handleApproval(scope.row.id)"
              >通过
              </el-button>
              <el-button
                size="mini"
                type="text"
                v-show="scope.row.allowApproval==1"
                style="color:#ff0009"
                @click="handleRefuse(scope.row.id)"
              >拒绝
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <pagination
          v-show="tableDataTotal>0"
          :total="tableDataTotal"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="queryList"
          :page-sizes="[10, 20, 50, 100]"
        />
      </el-footer>
    </el-container>

    <el-dialog title="审批" width="500px"
               :visible.sync="approvalDialogVisible"
               :close-on-click-modal="false"
    >
      拒绝理由(将通过邮件发送至用户)
      <el-form ref="editForm" status-icon :model="editFormData" label-width="0px">
        <el-form-item label="" prop="refuseReason" :rules="[{ required: true, message: '请填写拒绝理由', trigger: 'blur'}]"
        >
          <el-input type="textarea" :rows="4" placeholder="请填写拒绝理由"
                    v-model="editFormData.refuseReason"
                    maxlength="200" clearable show-word-limit

          />
        </el-form-item>
      </el-form>

      <span slot="footer" style="display: flex;justify-content: center;">
        <el-button @click="approvalDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRefuse()"  style="background-color: #F1880D;border-color: #F1880D;" >提 交</el-button>
      </span>
    </el-dialog>


  </div>


</template>
<script>
import {
  registerApprovalApi,
  registerUserListApi
} from '@/modules/crm/api/system/user'
import { isEmpty } from '@/utils/common'
import { addRoleApi, editRoleApi } from '@/modules/crm/api/system/role'

export default {
  name: 'registerManage',
  data() {
    return {
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      //系统用户注册状态字典
      registerStatusDict: this.getDict(this.dictConst.SYS_USER_REGISTER_STATUS),
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'registerTime',
        isAsc: 'desc',
        searchValue: '',
        registerStatus: ''
      },
      //审批弹窗
      approvalDialogVisible: false,
      editFormData: {
        id: 0,
        registerStatus: 0,
        refuseReason: ''
      }
    }
  },
  created() {
    this.handleQuery()
  },
  methods: {
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this
      registerUserListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.roleTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },

    /** 通过操作 */
    handleApproval(id) {
      registerApprovalApi({ id, registerStatus: 1 }).then(res => {
        this.msgSuccess()
        this.handleQuery()
      })
    },
    /** 拒绝操作 */
    handleRefuse(id) {
      this.approvalDialogVisible = true
      this.editFormData.id = id
      this.editFormData.refuseReason = ""

    },
    /** 拒绝 */
    doRefuse() {
      this.$refs['editForm'].validate(valid => {
        if (valid) {
          registerApprovalApi(this.editFormData).then(res => {
            this.approvalDialogVisible = false
            this.msgSuccess()
            this.handleQuery()
          })
        } else {
          this.msgError('请填写拒绝理由')
        }
      })
    }

  }
}
</script>
<style lang="scss" scoped>


.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}


</style>
