var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.pageLoading,
          expression: "pageLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("用户管理")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新建用户")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    size: "small",
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    clearable: "",
                                    placeholder: "用户名称/手机号/邮箱",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      placeholder: "请选择部门",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.department,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "department",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.department",
                                    },
                                  },
                                  _vm._l(_vm.sysUserDeptDict, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.dictLabel,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      size: "small",
                                      placeholder: "请选择状态",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryParams, "status", $$v)
                                      },
                                      expression: "queryParams.status",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "正常", value: 1 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "停用", value: 0 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "mainTable",
                  attrs: {
                    border: "",
                    stripe: "",
                    height: "100%",
                    data: _vm.tableDataList,
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "default-sort": { prop: "createTime", order: "descending" },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名称",
                      prop: "nickName",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "角色名称",
                      prop: "roleList",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(scope.row.roleList, function (item) {
                            return _c(
                              "el-tag",
                              {
                                staticStyle: { "margin-right": "4px" },
                                attrs: { type: "info", size: "mini" },
                              },
                              [_vm._v(" " + _vm._s(item.roleName) + " ")]
                            )
                          })
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号",
                      prop: "phone",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邮箱", prop: "email", "min-width": "150" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属部门",
                      prop: "departmentName",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      align: "center",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              staticClass: "switch",
                              attrs: {
                                "active-color": "#F1880D",
                                "active-text": "启用",
                                "inactive-text": "停用",
                                "inactive-color": "#B5CCF5",
                                "active-value": 1,
                                "inactive-value": 0,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleStatusChange(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "150",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#ed923a" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#ff0009" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResetPwd(scope.row)
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableDataTotal > 0,
                    expression: "tableDataTotal>0",
                  },
                ],
                attrs: {
                  total: _vm.tableDataTotal,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.queryList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialogTitle,
            width: "60%",
            visible: _vm.editDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "status-icon": "",
                model: _vm.editFormData,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "用户名称:",
                            prop: "nickName",
                            rules: [
                              {
                                required: true,
                                message: "请输入用户名称",
                                trigger: "blur",
                              },
                              {
                                min: 2,
                                max: 30,
                                message: "长度在 2 到 30 个字符",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: "请输入用户名称",
                              maxlength: "30",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.editFormData.nickName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editFormData,
                                  "nickName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "editFormData.nickName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isEmpty(_vm.editFormData.id)
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "手机号:",
                                prop: "phone",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入手机号",
                                    trigger: "blur",
                                  },
                                  {
                                    min: 11,
                                    max: 11,
                                    message: "手机号长度在11个字符",
                                    trigger: "blur",
                                  },
                                  {
                                    pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                                    message: "请输入正确的手机号码",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  placeholder: "请输入手机号",
                                  maxlength: "11",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editFormData.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editFormData,
                                      "phone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "editFormData.phone",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#ea8a3d",
                                    "font-weight": "bold",
                                    "font-size": "12px",
                                    height: "20px",
                                  },
                                },
                                [_vm._v("*手机号就是登录用户名")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "部门:",
                            prop: "department",
                            rules: [
                              {
                                required: true,
                                message: "请选择部门",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择部门",
                                clearable: "",
                              },
                              model: {
                                value: _vm.editFormData.department,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editFormData, "department", $$v)
                                },
                                expression: "editFormData.department",
                              },
                            },
                            _vm._l(_vm.sysUserDeptDict, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色:",
                            prop: "roleIdList",
                            rules: [
                              {
                                required: true,
                                message: "请选择角色",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "90%" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                placeholder: "请选择角色",
                                clearable: "",
                              },
                              model: {
                                value: _vm.editFormData.roleIdList,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editFormData, "roleIdList", $$v)
                                },
                                expression: "editFormData.roleIdList",
                              },
                            },
                            _vm._l(_vm.roleList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.roleName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "邮箱:",
                            prop: "email",
                            rules: [
                              {
                                required: true,
                                message: "请输入邮箱",
                                trigger: "blur",
                              },
                              {
                                min: 6,
                                max: 50,
                                message: "邮箱长度在6-50个字符",
                                trigger: "blur",
                              },
                              {
                                type: "email",
                                message: "请输入正确的邮箱地址",
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              placeholder: "请输入邮箱",
                              maxlength: "50",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.editFormData.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editFormData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "editFormData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.editFormData.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editFormData, "status", $$v)
                                },
                                expression: "editFormData.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("正常"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("停用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注信息:", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "90%" },
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入备注信息",
                              maxlength: "400",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.editFormData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.editFormData, "remark", $$v)
                              },
                              expression: "editFormData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelEdit } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "background-color": "#F1880D",
                    "border-color": "#F1880D",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmEdit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }