<template>
  <el-drawer
    :title="editDialogTitle"
    :visible.sync="drawer"
    :before-close="handleEditDrawer"
    :modal="false"
    :with-header="false"
    size="980px">
    <div class="drawer_wrap">
      <!-- 编辑产品title-->
      <el-row class="newBuildContractWrap">
        <el-col :span="12" style="display: flex">
          <span class="newConstructionContract">{{form.productName}}</span>
          <div class="newConstructionContractChange" v-if="false">
            <i class="iconfont" style="font-size: 24px;color: #E1E7F5;margin-left: 10px;">&#xe676;</i>
            <i class="iconfont" style="font-size: 24px;color: #B3BDD6;margin-left: 10px;">&#xe678;</i>
          </div>
        </el-col>
        <el-col :span="12" style="text-align: right;" v-if="!isEdit">
          <el-button plain size="mini" @click="isEdit=true">编辑</el-button>
<!--          <el-button class="delBtn" plain size="mini">删除</el-button>-->
        </el-col>
        <el-col :span="12" style="text-align: right;" v-else>
          <el-button plain size="mini" @click="reset">重置</el-button>
          <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
        </el-col>
      </el-row>
      <!--查看-->
      <div class="newConstructionContract_wrap" v-if="!isEdit">
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">产品大类</span>
          </el-col>
          <el-col :span="22">
            <span class="editInput">{{form.classifyName}}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">版本</span>
          </el-col>
          <el-col :span="22">
            <span class="editInput">{{form.versionName}}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">产品名称</span>
          </el-col>
          <el-col :span="22">
            <span class="editInput">{{form.productName}}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">产品实施方</span>
          </el-col>
          <el-col :span="22">
            <span class="editInput">{{form.implTypeName}}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">增值包</span>
          </el-col>
          <el-col :span="22">
            <el-tag
              v-for="(item, index) in form.extrasList"
              :key="index"
              type="info"
              style="margin-right: 4px;">{{ item.extrasName }}
            </el-tag>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="2">
            <span class="editLabel">备注</span>
          </el-col>
          <el-col :span="22">
            <span class="editInput">{{form.remark}}</span>
          </el-col>
        </el-row>
      </div>
      <!--编辑-->
      <div class="newConstructionContract_wrap" v-else>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="left"  size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品大类" prop="classify">
                <el-select
                  v-model="form.classify"
                  :disabled="isRelContract"
                  size="small"
                  style="width: 98%;"
                  placeholder="请选择产品大类"
                  @change="classifyChange">
                  <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in productClassifyDict"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="版本">
            <span
              v-for="item in productVersionDict"
              :key="item.id"
              class="normal_btn"
              :class="{ 'select_btn': item.id == form.version, 'disabled-btn': isRelContract }"
              @click="versionSelect(item.id)">
              {{ item.name }}
            </span>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品名称" prop="productName">
                <el-input
                  v-model.trim="form.productName"
                  :disabled="isRelContract"
                  style="width: 98%;"
                  maxlength="40"
                  clearable
                  show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="产品实施方" prop="projectClassify">
             <span
               v-for="item in implTypeDict"
               :key="item.id"
               :class="{ 'select_btn': item.id == form.implType, 'disabled-btn': isRelContract }"
               class="normal_btn"
               @click="implTypeSelect(item.id)">
              {{ item.dictLabel }}
            </span>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="增值包">
                <el-tag
                  v-for="(item, index) in form.extrasList"
                  :key="index"
                  type="info"
                  style="margin-right: 4px;">{{ item.extrasName }}
                </el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入备注信息"
                  v-model="form.remark"
                  maxlength="100"
                  show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="closeBtn" @click="handleEditDrawer">
      <i class="iconfont">&#xe677;</i>
    </div>
  </el-drawer>

</template>

<script>
import {
  addProductApi,
  editProductApi,
  productClassifyDictApi,
  productInfoApi,
  productVersionDictApi,
  checkProContractApi
} from '@/modules/crm/api/crm/product'

export default {
  name: "EditProduct" ,
  props: {
    editDialogTitle:  '',
    drawer: { // 遮罩层开关
      type: Boolean,
      required: true
    },
    productId:{
      type: Number,
      required: true
    },
    // 1编辑2浏览
    editType:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isEdit: false, // 是否在编辑
      isRelContract: false, // 是否与合同相关
      //产品大类字典
      productClassifyDict: [],
      //产品版本字典
      productVersionDict: [],
      //产品实施方字典
      implTypeDict: this.getDict(this.dictConst.PRODUCT_IMPL_TYPE),
      form: {},
      rules: {
        classify: [
          { required: true, message: '请选择产品大类', trigger: 'change' }
        ],
        productName: [
          { required: true, message: '请填写产品名称', trigger: 'blur' }
        ],
        implType: [
          { required: true, message: '请选择产品实施方', trigger: 'change' }
        ]
      }
    }
  },
  async created() {
    //获取产品大类字典
    this.getProductClassifyDict()
    //获取产品版本字典
    this.getProductVersionDict()
    this.reset()
    this.isEdit = this.editType == 1
    await this.checkProContract()
  },
  methods: {
    onSubmit() {
      console.log('submit!', this.form)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let res = await editProductApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.eventBus.$emit(this.eventConst.product.addSucceed)
            this.isEdit=false
          }

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /** 表单重置 */
    reset() {
      productInfoApi(this.productId).then(res=>{
        console.log(res)
        //this.form = Object.assign(this.form, res.data)
        this.form =res.data
      })
    },
    async checkProContract() {
      const productId = this.productId
      let res = await checkProContractApi(productId)
      if (res.data) {
        this.isRelContract = true
      } else {
        this.isRelContract = false
      }
    },
    // 产品大类选项变化
    classifyChange(val) {
      console.log(val)
      let selectClassify = this.productClassifyDict.find(item => {
        return item.id == val
      })
      this.form.extrasList = selectClassify.extrasList

    },
    // 版本点击
    versionSelect(val) {
      if (this.isRelContract) {
        return
      }
      this.form.version = this.form.version == val ? undefined : val
    },
    implTypeSelect(val) {
      if (this.isRelContract) {
        return
      }
      this.form.implType = val
    },
    // 关闭弹窗
    handleEditDrawer() {
      this.$emit('handleEditDrawer')
    },
    //获取产品大类字典
    getProductClassifyDict() {
      productClassifyDictApi().then(res => {
        this.productClassifyDict = res.data
      })
    },
    //获取产品版本字典
    getProductVersionDict() {
      productVersionDictApi().then(res => {
        this.productVersionDict = res.data
      })
    }
  }
}
</script>

<style lang="scss"  scoped>
  .newConstructionContract_wrap{
    padding: 20px 0;
    .disabled-btn {
      cursor: not-allowed !important;
      /* pointer-events: none!important; */
    }
    .productWrap{
      .productItem{
        float: left;
        width: 260px;
        padding:6px 10px;
        height: 76px;
        background: #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        margin-right: 10px;
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 28px;
        }
        //.productSelect{
        //  .el-select{
        //    height: 28px;
        //    line-height: 28px;
        //  }
        //}
      }
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .drawer_wrap{
    .newBuildContractWrap{
      padding:22px 0px;
      border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
      .newConstructionContract{
        word-wrap: break-word;
        width: 100%;
        font-size: 24px;
        font-weight: 600;
        color: #3C3C3C;
        line-height: 24px;
      }
      .el-button--warning.is-plain{
        background: none;
      }
      .delBtn{
        border: 1px solid #F23232;
        color: #F23232;
        &:hover{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
        &:active{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
      }
      .saveBtn{
        border: 1px solid #F1880D;
        color: #F1880D;
        &:hover{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
        &:active{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
      }
    }
  }
  .editProductWrap{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .editLabel{
    color: #3C3C3C;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .editInput{
    color: #5D5D5D;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .appreciationPackage{
    padding: 2px 4px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #DEDEDE;
    color: #5D5D5D;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
    &:last-child{
      margin-right: 0;
    }
  }

  .closeBtn{
    position:fixed;
    right: 980px;
    top: 50%;
    display: flex;
    height: 60px;
    padding: 20px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 10px 0px 0px 10px;
    background: #FFF;
    /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
    cursor: pointer;
    i{
      color: #F09933;
      font-size: 20px;
    }
  }
</style>
