var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionContract_wrap" },
    [
      !_vm.isNewConstruction && _vm.contractList.length > 0
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px", "margin-bottom": "10px" },
                      attrs: {
                        placeholder: "请选择合同编号",
                        size: "mini",
                        disabled: !_vm.isNewConstruction,
                      },
                      on: { change: _vm.contractChange },
                      model: {
                        value: _vm.contractValue,
                        callback: function ($$v) {
                          _vm.contractValue = $$v
                        },
                        expression: "contractValue",
                      },
                    },
                    _vm._l(_vm.contractList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.contractName, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticClass: "projectNum" }, [
                    _vm._v(
                      "共" +
                        _vm._s(_vm.contractListLen ? _vm.contractListLen : 0) +
                        "个合同"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isNewConstruction && _vm.contractId == null && !_vm.contractValue
        ? _c("div", { staticClass: "noDataWrap" }, [_vm._m(0)])
        : _c(
            "div",
            { staticClass: "content-wrap" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form-el",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "contractClassify" } },
                    _vm._l(_vm.contractClassify, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          staticClass: "normal_btn",
                          class: {
                            select_btn: item.id == _vm.form.contractClassify,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.contractClassifySelect(item.id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同性质", prop: "contractType" } },
                    [
                      _vm._l(_vm.contractTypeDict, function (item, index) {
                        return _c(
                          "span",
                          {
                            key: item.id,
                            staticClass: "normal_btn",
                            class: {
                              select_btn: item.id == _vm.form.contractType,
                              select_stop: _vm.isCanEdit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.contractTypeSelect(item.id)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                        )
                      }),
                      _vm.form.contractType ==
                      this.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                        ? _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.serveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "serveType", $$v)
                                },
                                expression: "form.serveType",
                              },
                            },
                            _vm._l(_vm.serveTypeDict, function (item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.id,
                                  attrs: {
                                    disabled: _vm.isCanEdit,
                                    label: item.id,
                                  },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.form.contractType ==
                    this.getDict(this.dictConst.CONTRACT_TYPE)[1].id &&
                  _vm.form.serveType ==
                    this.getDict(this.dictConst.CONTRACT_SERVE_TYPE)[1].id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "服务时间", prop: "serveTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              size: "mini",
                              "value-format": "yyyy-MM-dd",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { blur: _vm.timeChange },
                            model: {
                              value: _vm.form.serveTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "serveTime", $$v)
                              },
                              expression: "form.serveTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.contractType ==
                  this.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              v: "",
                              rows: 4,
                              size: "mini",
                              maxlength: "500",
                              clearable: "",
                              "show-word-limit": "",
                              placeholder: "请输入备注内容",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "remark",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目分类", prop: "projectClassify" } },
                    _vm._l(_vm.projectClassify, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          staticClass: "normal_btn",
                          class: {
                            select_btn: item.id == _vm.form.projectClassify,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.projectClassifySelect(item.id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "labelSet",
                      attrs: { label: "购买方(甲方)", prop: "purchaser" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "372px" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            size: "mini",
                            "reserve-keyword": "",
                            placeholder: "请选择购买方(甲方)",
                            "remote-method": _vm.remoteMethod,
                            clearable: "",
                            disabled: _vm.isCanEdit,
                            loading: _vm.loading,
                          },
                          model: {
                            value: _vm.form.purchaser,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "purchaser", $$v)
                            },
                            expression: "form.purchaser",
                          },
                        },
                        _vm._l(_vm.purchaserDict, function (item) {
                          return _c("el-option", {
                            key: item.orgId,
                            attrs: { label: item.orgName, value: item.orgId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "终端客户", prop: "customerIdList" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "800px" },
                          attrs: {
                            multiple: "",
                            filterable: "",
                            remote: "",
                            size: "mini",
                            "reserve-keyword": "",
                            placeholder: "请选择终端客户",
                            "remote-method": _vm.customerIdListRemoteMethod,
                            disabled: _vm.isCanEdit,
                            loading: _vm.loading,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.form.customerIdList,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "customerIdList", $$v)
                            },
                            expression: "form.customerIdList",
                          },
                        },
                        _vm._l(_vm.customerIdListDict, function (item) {
                          return _c("el-option", {
                            key: item.orgId,
                            attrs: { label: item.orgName, value: item.orgId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "乙方", required: "" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "sellerType" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.sellerType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sellerType", $$v)
                                    },
                                    expression: "form.sellerType",
                                  },
                                },
                                _vm._l(
                                  _vm.sellerTypeDict,
                                  function (item, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: item.id,
                                        attrs: {
                                          label: item.id,
                                          disabled: _vm.isCanEdit,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.dictLabel) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.sellerType !==
                      _vm.dictDataConst.CONTRACT_SELLER_TYPE.SELL
                        ? _c(
                            "el-col",
                            { attrs: { span: 19 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "seller" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "elementSelect",
                                          staticStyle: { width: "216px" },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            size: "mini",
                                            "reserve-keyword": "",
                                            placeholder: "请选择经销商",
                                            "remote-method":
                                              _vm.sellerRemoteMethod,
                                            disabled: _vm.isCanEdit,
                                            clearable: "",
                                            loading: _vm.loading,
                                          },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.trimInput($event)
                                            },
                                          },
                                          model: {
                                            value: _vm.form.seller,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "seller", $$v)
                                            },
                                            expression: "form.seller",
                                          },
                                        },
                                        _vm._l(_vm.sellerDict, function (item) {
                                          return _c("el-option", {
                                            key: item.orgId,
                                            attrs: {
                                              label: item.orgName,
                                              value: item.orgId,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    "margin-right": "3px",
                                  },
                                  attrs: { span: 2 },
                                },
                                [_vm._v("分销")]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "distribution" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          ref: "elementDistributionSelect",
                                          staticStyle: { width: "216px" },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            size: "mini",
                                            "reserve-keyword": "",
                                            placeholder: "请选择分销商",
                                            "remote-method":
                                              _vm.distributionRemoteMethod,
                                            disabled: _vm.isCanEdit,
                                            clearable: "",
                                            loading: _vm.loading,
                                          },
                                          nativeOn: {
                                            input: function ($event) {
                                              return _vm.trimDistributionInput(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.distribution,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "distribution",
                                                $$v
                                              )
                                            },
                                            expression: "form.distribution",
                                          },
                                        },
                                        _vm._l(
                                          _vm.distributionDict,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.orgId,
                                              attrs: {
                                                label: item.orgName,
                                                value: item.orgId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "编号", prop: "contractNum" } },
                            [
                              _c("el-input", {
                                staticClass: "setInput",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.isCanEdit,
                                  size: "mini",
                                  maxlength: "100",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.contractNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "contractNum",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.contractNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "金额", prop: "contractPrice" } },
                            [
                              _vm.form.contractPrice >= 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.form.contractPrice) + "万元"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "下单时间", prop: "orderTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  clearable: "",
                                  size: "mini",
                                  type: "date",
                                  placeholder: "选择日期",
                                },
                                model: {
                                  value: _vm.form.orderTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "orderTime", $$v)
                                  },
                                  expression: "form.orderTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "维护期限",
                                prop: "maintenanceDeadline",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "98%" },
                                attrs: { maxlength: "5", size: "mini" },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleOninput(
                                      $event,
                                      "maintenanceDeadline"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.maintenanceDeadline,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "maintenanceDeadline",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.maintenanceDeadline",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 1 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "line-height": "36px",
                              color: "#606266",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("年")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上传附件",
                                prop: "taskFileIdList",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action:
                                      _vm.baseURL + "/crm/sys/file/uploadFile",
                                    "on-change": _vm.handleFileChange,
                                    "file-list": _vm.fileList,
                                    data: _vm.uploadFileParams,
                                    "auto-upload": true,
                                    "before-upload": _vm.beforeUpload,
                                    accept:
                                      ".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx",
                                    "on-success": _vm.uploadSuccess,
                                    "on-remove": _vm.handleFileRemove,
                                  },
                                },
                                [
                                  _c("el-button", { attrs: { size: "mini" } }, [
                                    _vm._v("添加附件"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "border-bottom": "1px dotted #F0F0F0" },
                      attrs: { label: "产品", required: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.addProduct },
                            },
                            [_vm._v("添加")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "productWrap" },
                    _vm._l(
                      _vm.form.productList,
                      function (productItem, productIndex) {
                        return _c(
                          "div",
                          { key: productIndex, staticClass: "productItem" },
                          [
                            _c(
                              "el-row",
                              { staticStyle: { height: "100%" } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "productItem_left",
                                    attrs: { span: 21 },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "margin-bottom": "4px" },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("产品")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "contract_product",
                                                attrs: {
                                                  prop:
                                                    "productList[" +
                                                    productIndex +
                                                    "].productSingle",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "请选择产品",
                                                      trigger: "change",
                                                    },
                                                  ],
                                                  "show-message": false,
                                                  "label-width": "40",
                                                },
                                              },
                                              [
                                                _c("el-cascader", {
                                                  ref:
                                                    "productRef_" +
                                                    productIndex,
                                                  refInFor: true,
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    props: _vm.defaultParams,
                                                    options:
                                                      _vm.productTreeDict,
                                                    size: "mini",
                                                    placeholder: "",
                                                    disabled:
                                                      _vm.isCanEdit &&
                                                      productIndex + 1 <=
                                                        _vm.productListLength,
                                                    "show-all-levels": false,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleChange(
                                                        $event,
                                                        productIndex
                                                      )
                                                    },
                                                    "visible-change": function (
                                                      $event
                                                    ) {
                                                      return _vm.visibleHandler(
                                                        $event,
                                                        productIndex
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      productItem.productSingle,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        productItem,
                                                        "productSingle",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "productItem.productSingle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      {
                                        staticStyle: { "margin-bottom": "4px" },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("增值包")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    productItem.productSingle
                                                      .length == 0 ||
                                                    (_vm.isCanEdit &&
                                                      productIndex + 1 <=
                                                        _vm.productListLength),
                                                  size: "mini",
                                                  placeholder: "",
                                                  multiple: "",
                                                  "collapse-tags": "",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    productItem.extrasIdList,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      productItem,
                                                      "extrasIdList",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "productItem.extrasIdList",
                                                },
                                              },
                                              _vm._l(
                                                productItem.zzbArr,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.extrasId,
                                                    attrs: {
                                                      label: item.extrasName,
                                                      value: item.extrasId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("产品金额")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "contract_product",
                                                attrs: {
                                                  prop:
                                                    "productList[" +
                                                    productIndex +
                                                    "].productAmount",
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "请输入产品金额",
                                                      trigger: "blur",
                                                    },
                                                    {
                                                      pattern:
                                                        /^\d{1,4}(\.\d{1,6})?$/,
                                                      message:
                                                        "请输入正确的格式,小于10000且最多可保留六位小数",
                                                    },
                                                  ],
                                                  "show-message": false,
                                                  "label-width": "40",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticStyle: { width: "80%" },
                                                  attrs: {
                                                    disabled:
                                                      productItem.productSingle
                                                        .length == 0 ||
                                                      (_vm.isCanEdit &&
                                                        productIndex + 1 <=
                                                          _vm.productListLength),
                                                    maxlength: "15",
                                                    size: "mini",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.productAmountInput(
                                                        $event,
                                                        productIndex
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      productItem.productAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        productItem,
                                                        "productAmount",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "productItem.productAmount",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      color: "#5D5D5D",
                                                      "margin-left": "4px",
                                                    },
                                                  },
                                                  [_vm._v("万元")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                (!_vm.isCanEdit &&
                                  _vm.form.productList.length > 1) ||
                                (_vm.isCanEdit &&
                                  productIndex + 1 > _vm.productListLength)
                                  ? _c(
                                      "el-col",
                                      {
                                        staticClass: "productClose",
                                        attrs: { span: 2 },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "iconfont",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delProductItem(
                                                  productItem,
                                                  productIndex
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noData" }, [
      _c("img", {
        staticStyle: { width: "96px", height: "60px" },
        attrs: { src: require("../assets/images/noData.png") },
      }),
      _c("div", { staticClass: "noDataText" }, [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }