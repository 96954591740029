<template>
  <div
    class="app-container"
    style="height:100%"
    v-loading.fullscreen.lock="pageLoading">
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">机构管理</el-col>
            <el-col :span="12">
              <div style="float: right;display: flex;">
                <el-button
                  :disabled="!addPermission"
                  class="crm_btn_style"
                  icon="el-icon-circle-plus-outline"
                  size="small"
                  @click="handleAdd">新建机构</el-button>
                <el-button
                  class="crm_btn_style batch-import-btn"
                  size="small"
                  @click="onBatchImport">
                  <svg
                    class="icon svg-icon batch-import"
                    aria-hidden="true"
                    style="width: 16px;height: 16px;margin-right: 6px;"
                    fill="#777777">
                    <use xlink:href="#icon-batch-import"></use>
                  </svg>
                  批量导入
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">
            <div style="width: 200px;">
              <el-tabs v-model="queryParams.orgType" @tab-click="handleClick">
                <el-tab-pane
                  v-for="(item) in orgTypeDict"
                  :key="item.id"
                  :label="item.dictLabel"
                  :name="item['id'].toString()">
                </el-tab-pane>
              </el-tabs>
            </div>
            <el-row>
              <el-col :span="24">
                  <el-form-item label="">
                    <el-input
                      v-model.trim="queryParams.searchValue"
                      size="small"
                      style="width:240px;"
                      placeholder="机构名称/别名/社会统一信用代码"
                      maxlength="30"
                      show-word-limit
                      @keydown.enter.native="handleQuery"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.levelCode"
                      size="small"
                      style="width:150px;"
                      filterable
                      @keydown.enter.native="handleQuery"
                      placeholder="机构等级" clearable>
                      <el-option
                        :label="item.dictLabel"
                        :value="item.id"
                        :key="item.id"
                        v-for="item in orgLevelDict"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.provinceCode"
                      size="small"
                      style="width:150px;"
                      filterable
                      @change="changeProvince"
                      @clear="clearProvince"
                      @keydown.enter.native="handleQuery"
                      placeholder="所属省份"
                      clearable>
                      <el-option
                        :label="item.name"
                        :value="item.code"
                        :key="item.code"
                        v-for="item in provinceDict"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-select
                      v-model="queryParams.cityCode"
                      size="small"
                      style="width:150px;"
                      filterable
                      :disabled="!queryParams.provinceCode"
                      @keydown.enter.native="handleQuery"
                      placeholder="所属城市"
                      clearable>
                      <el-option
                        v-for="item in cityDict"
                        :label="item.name"
                        :value="item.code"
                        :key="item.code"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                  </el-form-item>
                  <el-button
                    type="text"
                    size="small"
                    class="resetFilterClass"
                    @click="resetSearchValue()">
                    <i class="iconfont">&#xe660;</i>
                  </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          height="100%"
          v-loading="tableLoading"
          :data="tableDataList"
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          ref="mainTable"
          :default-sort="{prop: 'createTime', order: 'ascending'}"
          @sort-change="tableSortChange">
          <el-table-column label="ID" prop="id" width="50"></el-table-column>
          <el-table-column label="属性" min-width="150">
            <template slot-scope="scope">
              <span>{{scope.row.orgTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="机构名称" min-width="150">
            <template slot-scope="scope">
              <span style="color:#F1880D;cursor:pointer;" @click="onCheck(scope.row)">{{scope.row.orgName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="别名" min-width="120">
            <template slot-scope="scope">
              <span v-for="item in scope.row.aliasList" :key="item.id" v-if="item.isPrimary==1">{{ item.aliasName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="社会统一信用代码" prop="uscCode" min-width="150"/>
          <el-table-column label="机构等级" prop="orgLevelName" min-width="100"/>
          <el-table-column label="所属省份" prop="provinceName" min-width="100"/>
          <el-table-column label="所属城市" prop="cityName" min-width="80"/>
          <el-table-column label="联系人" min-width="80">
            <template slot-scope="scope">
              <span v-for="item in scope.row.contactsList" :key="item.id" v-if="item.isPrimary==1">{{ item.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="更新时间" prop="updateTime" min-width="150" sortable="custom"/>
          <el-table-column label="操作人" prop="updateBy" min-width="80"/>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <el-button
                :disabled="!editPermission"
                size="mini"
                type="text"
                class="saveBtn"
                @click="handleEdit(scope.row)">编辑
              </el-button>
              <el-button
                :disabled="!delPermission"
                size="mini"
                type="text"
                class="delBtn"
                @click="handleDelete(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10"><div style="color:#999;line-height: 40px;font-size: 12px;">
          按住Shift+鼠标滚轮左右滑动列表
        </div></el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"/>
          </div>
        </el-col>
      </el-footer>
    </el-container>
    <el-drawer
      :title="editDialogTitle"
      :visible.sync="drawer"
      :modal="false"
      :with-header="false"
      :wrapperClosable="false"
      :before-close="closeDrawer"
      size="980px">
      <div class="drawer_wrap">
        <!--新建机构title-->
        <el-row class="newBuildContractWrap">
          <el-col :span="12">
            <span class="newConstructionContract">新建机构</span>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button plain size="mini" @click="onReset">重置</el-button>
            <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
          </el-col>
        </el-row>
        <new-construction-institution
          v-if="drawer"
          ref="addForm"
          @setSource="setSource"
          :org-type="Number(queryParams.orgType)">
        </new-construction-institution>
      </div>
      <div class="closeBtn" @click="closeDrawer">
        <i class="iconfont">&#xe677;</i>
      </div>
    </el-drawer>
    <!--编辑-->
    <edit-common
      v-if="editCommon"
      :editCommonTitle="editCommonTitle"
      :is-edit.sync="isEdit"
      :drawer="editCommon"
      :activeNum="activeNum"
      :orgId="orgId"
      :titleName="orgName"
      :row="currentRow"
      :org-type="Number(queryParams.orgType)"
      @handleEditCommon="handleEditCommon"
      @dealTask="dealTask">
    </edit-common>
    <set-source v-if="sourceDialogVisible" :source-dialog-visible="sourceDialogVisible" @closeSource="closeSource"></set-source>
    <deal-task v-if="taskDialogVisible" :task-dialog-visible="taskDialogVisible" @closeTask="closeTask"></deal-task>
    <batch-import
      v-if="importDialog"
      :dialog-visible="importDialog"
      batchFrom="org"
      @close="importDialog=false"
      @refresh="batchImportRefresh"></batch-import>
  </div>
</template>
<script>
import { isEmpty } from '@/utils/common'
import { getAllRoleApi } from '@/modules/crm/api/system/role'
import NewConstructionInstitution from '@/modules/crm/components/NewConstructionInstitution'
import EditCommon from '@/modules/crm/components/EditCommon'
import SetSource from "../../../components/SetSource"
import BatchImport from '@/modules/crm/components/BatchImport'
import {
  ITEM_OBJ
} from '@/modules/crm/utils/org'
import {
  provinceListApi,
  cityList,
  orgListApi,
  delOrgApi
} from '@/modules/crm/api/crm/org'

export default {
  name: 'crmOrg',
  components:{
    SetSource,
    NewConstructionInstitution,
    EditCommon,
    BatchImport
  },
  computed: {
    addPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.add)
    },
    editPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.edit)
    },
    delPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.del)
    },
  },
  data() {
    return {
      sourceDialogVisible: false,
      taskDialogVisible: false,
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        orgType:this.getDict(this.dictConst.ORG_TYPE)[0].id.toString(),
        pageNum: 1,
        pageSize: 10,
        searchValue: '',
        orderByColumn: 'createTime',
        isAsc: 'desc',
        cityCode:'',
        provinceCode:'',
        levelCode: ''
      },
      currentRow: this.$deepClone(ITEM_OBJ),
      editDialogTitle: '新增角色',
      editDialogVisible: false,
      //角色列表
      roleList: [],
      //用户部门字典
      sysUserDeptDict: this.getDict(this.dictConst.SYS_USER_DEPT),
      // 机构等级
      orgLevelDict: this.getDict(this.dictConst.ORG_LEVEL),
      // 机构类型
      orgTypeDict: this.getDict(this.dictConst.ORG_TYPE),
      drawer: false,
      activeNum: 0,  // 机构
      editCommon: false,
      editCommonTitle: '',
      provinceDict: [], // 省份字典
      cityDict: [], // 城市字典
      orgId: '',
      orgName: '',
      isEdit: true,
      importDialog: false
    }
  },
  created() {
    this.getProvinceListFn()
    this.handleQuery()
    console.log('dictConst.ORG_TYPE',this.getDict(this.dictConst.ORG_TYPE))
    this.getAllRole()
    this.eventBus.$on(this.eventConst.org.delSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
  },
  methods: {
    onBatchImport() {
      this.importDialog = true
    },
    // 省份列表
    async getProvinceListFn() {
      let res = await provinceListApi()
      console.log(res)
      if(res.code === 200) {
        this.provinceDict = res.data
      }
    },
    // 城市列表
    async getCityListFn(provinceCode) {
      let res = await cityList(provinceCode)
      console.log(res)
      if(res.code === 200) {
        this.cityDict = res.data
      }
    },
    // 切换省份
    changeProvince(provinceCode) {
      console.log(provinceCode)
      if(provinceCode) {
        this.getCityListFn(provinceCode)
      }
    },
    // 清除省份
    clearProvince() {
      this.cityDict = []
      this.queryParams.provinceCode = ''
      this.queryParams.cityCode = ''
    },
    closeDrawer() {
      this.drawer = false
    },
    handleEditCommon(type) {
      this.editCommon = false
      if (type === 'edit') {
        this.queryParams.pageNum = 1
        this.queryList()
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    batchImportRefresh() {
      this.queryParams.pageNum = 1
      this.queryParams.orderByColumn = 'updateTime'
      this.queryList()
    },
    // 重置查询条件
    resetSearchValue() {
      this.queryParams = {
        orgType:this.getDict(this.dictConst.ORG_TYPE)[0].id.toString(),
        pageNum: 1,
        pageSize: 10,
        searchValue: '',
        orderByColumn: 'updateTime',
        isAsc: 'desc',
        cityCode:'',
        provinceCode:'',
        levelCode: ''
      }
      this.handleQuery()
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      orgListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      }).catch(err => {
        console.log(err)
        this.tableLoading = false
      })
    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    async onSubmit() {
      console.log('onSubmit')
      let flag = await this.$refs['addForm'].onSubmit()
      console.log(flag)
      if(flag){
        this.drawer = false
        this.handleQuery()
      }
    },
    onReset() {
      let flag = this.$refs['addForm'].reset()
      console.log(flag)
    },
    // 查看
    onCheck(row) {
      this.editCommon = true
      this.isEdit = false
      this.orgId = row.id
      this.orgName = row.orgName
      this.currentRow = this.$deepClone(row)
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      console.log('edit btn click')
      this.editCommon = true
      this.isEdit = true
      this.orgId = row.id
      this.orgName = row.orgName
      this.currentRow = this.$deepClone(row)
    },
    validateMenuIds(rule, value, callback, index) {
      console.log(rule, value, callback, index)
      if (isEmpty(value)) {
        callback(new Error('角色的菜单不能为空'))
      } else {
        callback()
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      console.log('del btn click')
      this.$confirm('确认删除机构【' + row.orgName + '】吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delOrgApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 新建按钮操作 */
    handleAdd() {
      this.drawer = true
      this.editDialogTitle = '新建合同'
      this.$refs.addForm && this.$refs.addForm.reset()
      // this.reset()
      // this.editDialogVisible = true

    },
    //获取角色列表
    getAllRole() {
      getAllRoleApi().then(res => {
        this.roleList = res.data

      })
    },
    setSource(val) {
      console.log(val)
      this.sourceDialogVisible = true
    },
    closeSource(val) {
      this.sourceDialogVisible = false
    },
    dealTask(val) {
      this.taskDialogVisible = true
    },
    closeTask(val) {
      this.taskDialogVisible = false
    }
  }
}
</script>
<style lang="scss">
.confirmButton{
  background-color: #F23232!important;
  border-color:#F23232!important;
}
.delMessage{
  .el-message-box__btns{
    text-align: center!important;
  }
  .el-message-box__title{
    font-weight: 600;
  }
  .el-message-box__message p{
    color: #3C3C3C;
    font-weight: 500;
  }
}

</style>
<style lang="scss" scoped>
.crm_btn_style {
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.batch-import-btn {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BCBCBC;
    color: #777777;
  }
}
.delBtn{
  font-size: 12px;
  color: #F23232;
  &:hover{
    color: #d32b2b;
  }
  &:active{
    color: #d32b2b;
  }
  &:disabled{
    color: #fab6b6;
  }
}
.saveBtn{
  font-size: 12px;
  color: #ed923a;
  &:hover{
    color: #d0760c;
  }
  &:active{
    color: #d0760c;
  }
  &:disabled{
    color: #f3d19e;
  }
}
.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}
.closeBtn{
  position:fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  i{
    color: #F09933;
    font-size: 20px;
  }
}
.drawer_wrap{
  .newBuildContractWrap{
    padding:22px 0px;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    .newConstructionContract{
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
      line-height: 24px;
    }
    .el-button--warning.is-plain{
      background: none;
    }
    .saveBtn{
      border: 1px solid #F1880D;
      color: #F1880D;
      &:hover{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
      &:active{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
    }
  }
}
</style>

