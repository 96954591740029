import { render, staticRenderFns } from "./EditProduct.vue?vue&type=template&id=f7581844&scoped=true&"
import script from "./EditProduct.vue?vue&type=script&lang=js&"
export * from "./EditProduct.vue?vue&type=script&lang=js&"
import style0 from "./EditProduct.vue?vue&type=style&index=0&id=f7581844&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7581844",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f7581844')) {
      api.createRecord('f7581844', component.options)
    } else {
      api.reload('f7581844', component.options)
    }
    module.hot.accept("./EditProduct.vue?vue&type=template&id=f7581844&scoped=true&", function () {
      api.rerender('f7581844', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/crm/components/EditProduct.vue"
export default component.exports