/**回访 */
export const RETURN_VISIT_TYPE_LIST = [
  {
    id: 1,
    label: '全部'
  },
  {
    id: 2,
    label: '待回访'
  },
  {
    id: 3,
    label: '本周回访'
  }
]
export const QUERY_OBJ = {
  pageNum: 1,
  pageSize: 10,
  orderByColumn: 'createTime',
  isAsc: 'desc',
  nextFollowUpTimeStart: '',
  nextFollowUpTimeEnd: '',
  nextVisitType: undefined, // 下次访问类型（使用visit_type字典）
  searchValue: '',
  fpStatus: '2', // 任务类型 (1全部 2待回访(默认) 3本周回访)
  visitType: undefined // 下次访问类型（使用visit_type字典）
}
