<template>
  <div class="my-editor-wrap">
    <Toolbar
      style="border: 1px solid #dcdcdc;border-bottom: 0;"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"/>
    <Editor
      :style="editorStyle"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @change="onChange"
      @input="onInput"/>
  </div>

</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'
import {
  singleFileUploadApi
} from '@/modules/crm/api/system/file'
export default {
  name: 'MyEditor',
  components: {
    Editor,
    Toolbar
  },
  props: {
    editorContent: {
      type: String
    },
    editorStyle: {
      type: String,
      default: 'height: 300px; overflow-y: auto;z-index: 100;border: 1px solid #dcdcdc;'
    }
  },
  watch: {
    editorContent(newVal, oldVal) {
      this.html = newVal
    }
  },
  computed:{
  },
  data() {
    const _this = this
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams: {
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.RICH_TEXT_FILE,
        tempFlag: 1
      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],
      editor: null,
      html: '',
      toolbarConfig: {
        // toolbarKeys: [
        //   {
        //     key: 'group-image',
        //     title: '上传图片',
        //     menuKeys: ['uploadImage']
        //   }
        // ]
        excludeKeys: [
          'group-video',
          'insertVideo',
          'insertImage',
          'headerSelect',
          'blockquote',
          'bold',
          'underline',
          'italic',
          'group-more-style',
          'color',
          'bgColor',
          'fontSize',
          'fontFamily',
          'lineHeight',
          'bulletedList',
          'numberedList',
          'todo',
          'group-justify',
          'group-indent',
          'emotion',
          'insertLink',
          'insertTable',
          'codeBlock',
          'divider',
          'undo',
          'redo',
          'fullScreen'
        ]
      },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          color: {
            colors: ['#606266', '#333', '#666']
          },
          uploadImage: {   //上传图片配置
            async customUpload(file, insertFn) {
              // this.handleUpload(file, insertFn)
              let form = new FormData()
              form.append('file', file)
              form.append('fileClassify', _this.dictDataConst.SYS_FILE_CLASSIFY.RICH_TEXT_FILE)
              form.append('tempFlag', 1)
              let res = await singleFileUploadApi(form)
              console.log(res)
              const url = _this.baseURL+"/crm/sys/file/preview/"+res.data.id
              // // 从 res 中找到 url alt href ，然后插入图片
              insertFn(url)
            }
          },
          uploadVideo: {  //上传视频配置
            show: false
          }
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  async created() {
    console.log(this.editorContent)
  },
   mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
    this.$nextTick(() => {
      this.html = this.editorContent
    })
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange(val) {
      console.log('change')
      console.log(val)
    },
    onInput(val) {
      console.log('input')
      console.log(val)
      this.$emit('editorInput', val)
    },
    async handleUpload(file, insertFn) {
      let form = new FormData()
      form.append('file', file)
      form.append('fileClassify', this.dictDataConst.SYS_FILE_CLASSIFY.RICH_TEXT_FILE)
      form.append('tempFlag', 1)
      let res = await singleFileUploadApi(form)
      console.log(res)
      const url = res.filePath
      // // 从 res 中找到 url alt href ，然后插入图片
      insertFn(url)
    }
  }
}
</script>

<style lang="scss" scoped>
#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 100; /* 按需定义 */
}
#toolbar-container { border-bottom: 1px solid #ccc; }
.my-editor-wrap { 
  ::v-deep .w-e-bar-divider {
    display: none;
  }
 }
</style>
