import request from '@/modules/crm/utils/request'

//角色:获取角色列表
export function roleListApi(data) {
  return request({
    url: '/crm/sys/role/list',
    method: 'post',
    data: data
  })
}

//角色:新增
export function addRoleApi(data) {
  return request({
    url: '/crm/sys/role/addRole',
    method: 'post',
    data: data
  })
}

//角色:修改
export function editRoleApi(data) {
  return request({
    url: '/crm/sys/role/editRole',
    method: 'post',
    data: data
  })
}

//角色:获取角色的菜单下拉树列表
export function roleMenuSelectTreeApi(roleId) {
  return request({
    url: '/crm/sys/role/roleMenuSelectTree/' + roleId,
    method: 'post'
  })
}

//角色:角色详情
export function roleInfoApi(roleId) {
  return request({
    url: '/crm/sys/role/roleInfo/' + roleId,
    method: 'post'
  })
}

//角色:删除
export function delRoleApi(roleId) {
  return request({
    url: '/crm/sys/role/delRole/' + roleId,
    method: 'post'
  })
}

//角色:修改状态
export function changeRoleStatusApi(roleId, status) {
  return request({
    url: '/crm/sys/role/changeRoleStatus',
    method: 'post',
    data: { id: roleId, status }
  })
}


//角色:获取全部角色
export function getAllRoleApi() {
  return request({
    url: '/crm/sys/role/getAllRole',
    method: 'post',
  })
}
