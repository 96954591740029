<template>
  <div class="app-container" style="height:100%"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">产品管理</el-col>
            <el-col :span="12">
              <div style="float: right">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新建产品</el-button>
              </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model="queryParams.productName"
                            size="small"
                            style="width:240px;"
                            placeholder="产品名称"
                            clearable
                            maxlength="30"
                            @keydown.enter.native="handleQuery"
                  ></el-input>
                  <!--                  maxlength="30" show-word-limit-->
                </el-form-item>


                <el-form-item label="">
                  <el-select v-model="queryParams.implType" size="small"
                             style="width:150px;"
                             filterable
                             @keydown.enter.native="handleQuery"
                             placeholder="产品实施方" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in implTypeDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
                <el-button
                  type="text"
                  size="small"
                  class="resetFilterClass"
                  @click="resetSearchValue()">
                  <i class="el-icon-refresh"></i>
                </el-button>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table height="100%"
                  v-loading="tableLoading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="mainTable"
                  :default-sort="{prop: 'createTime', order: 'descending'}"
                  @sort-change="tableSortChange"

        >
          <el-table-column type="index"  width="60"></el-table-column>
          <el-table-column label="产品名称" min-width="150" sortable="custom" prop="productName">
            <template slot-scope="scope">
              <span style="color:#F1880D;cursor: pointer;" @click="handleView(scope.row)">{{scope.row.productName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="产品实施方" prop="implTypeName"  min-width="80"/>
          <el-table-column label="创建时间" prop="createTime" min-width="100" sortable="custom"/>
          <el-table-column label="更新时间" prop="updateTime" min-width="100" sortable="custom"/>
          <el-table-column label="操作人" prop="updateBy" width="120"/>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a;font-size: 12px;"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009;font-size: 12px;"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10"><div style="color:#999;line-height: 40px;font-size: 12px;">
          按住Shift+鼠标滚轮左右滑动列表
        </div></el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"
            />
          </div>
        </el-col>

      </el-footer>
    </el-container>
    <new-construction-product
      v-if="drawer"
      :addDialogTitle="addDialogTitle"
      :drawer="drawer"
      @handleDrawer="handleDrawer">
    </new-construction-product>
    <edit-product
      v-if="editDrawer"
      :editDialogTitle="editDialogTitle"
      :drawer="editDrawer"
      :product-id="editProductId"
      :edit-type="editType"
      @handleEditDrawer="handleEditDrawer">
    </edit-product>
  </div>


</template>
<script>
import { isEmpty } from '@/utils/common'
import { userListApi } from '@/modules/crm/api/system/user'
import NewConstructionProduct from '@/modules/crm/components/NewConstructionProduct'
import EditProduct from '@/modules/crm/components/EditProduct'
import { delProductApi, productClassifyDictApi, productListApi } from '@/modules/crm/api/crm/product'
import { getDictPrimaryItemId } from '@/modules/crm/utils/dict'

export default {
  name: 'crmProduct',
  components:{
    NewConstructionProduct,
    EditProduct
  },
  data() {
    return {

      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        productName:'',
        implType:undefined,

      },
      addDialogTitle: '',
      editDialogTitle: '',
      //产品实施方字典
      implTypeDict: this.getDict(this.dictConst.PRODUCT_IMPL_TYPE),
      drawer: false,
      editDrawer: false,
      editProductId:undefined,
      //编辑组件状态 1编辑 2浏览
      editType:1,
    }
  },
  created() {
    this.handleQuery()
    this.eventBus.$on(this.eventConst.product.addSucceed,()=>{
      this.drawer = false
      this.editDrawer=false
      this.handleQuery()
    });
  },
  methods: {
    handleDrawer() {
      this.drawer = false
    },
    handleEditDrawer() {
      this.editDrawer = false
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    // 重置查询条件
    resetSearchValue() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        productName:'',
        implType:undefined,
      }
      this.handleQuery()
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      productListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },

    /** 修改按钮操作 */
    handleEdit(row) {
      this.editDrawer = true
      this.editDialogTitle = '编辑合同'
      this.editProductId = row.id
      this.editType = 1
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除产品【' + row.productName + '】', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return delProductApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 新建按钮操作 */
    handleAdd() {
      this.drawer = true
      this.addDialogTitle = '新建产品'


    },
    /** 查看产品操作 */
    handleView(row) {
      this.editDrawer = true
      this.editDialogTitle = '查看产品'
      this.editProductId=row.id
      this.editType=2
    },
  }
}
</script>
<style lang="scss" scoped>


.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}
</style>


