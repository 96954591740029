<template>
  <el-drawer
    :title="addDialogTitle"
    :visible.sync="drawer"
    :before-close="handleDrawer"
    :modal="false"
    :with-header="false"
    size="980px"
  >
    <div class="drawer_wrap">
      <!--        新建产品title-->
      <el-row class="newBuildContractWrap">
        <el-col :span="12">
          <span class="newConstructionContract">新建产品</span>
        </el-col>
        <el-col :span="12" style="text-align: right;">
          <el-button plain size="mini" @click="reset">重置</el-button>
          <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
        </el-col>
      </el-row>
      <div class="newConstructionContract_wrap">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品大类" prop="classify" @change="classifyChange">
                <el-select v-model="form.classify" placeholder="请选择产品大类" @change="classifyChange" style="width: 98%;">
                  <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in productClassifyDict"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="版本">
            <span
              v-for="item in productVersionDict"
              :key="item.id"
              class="normal_btn"
              :class="{ select_btn: item.id == form.version }"
              @click="versionSelect(item.id)"
            >
              {{ item.name }}
            </span>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="产品名称" prop="productName">
                <el-input v-model.trim="form.productName" style="width: 98%;" maxlength="40" clearable show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="产品实施方" prop="implType">
            <span
              v-for="item in implTypeDict"
              :key="item.id"
              class="normal_btn"
              :class="{ select_btn: item.id == form.implType }"
              @click="implTypeSelect(item.id)"
            >
              {{ item.dictLabel }}
            </span>
          </el-form-item>
          <el-row v-show="form.extrasList.length>0">
            <el-col :span="12">
              <el-form-item label="增值包">
                <el-tag type="info" v-for="item in form.extrasList" style="margin-right: 4px;">{{ item.extrasName }}
                </el-tag>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="备注">
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入备注信息"
                  v-model="form.remark"
                  maxlength="100"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="closeBtn" @click="handleDrawer">
      <i class="iconfont">&#xe677;</i>
    </div>
  </el-drawer>

</template>

<script>
import { addProductApi, productClassifyDictApi, productVersionDictApi } from '@/modules/crm/api/crm/product'
import { isEmpty } from '@/utils/common'
import { addRoleApi, editRoleApi } from '@/modules/crm/api/system/role'
import AcrossUtil from '@/utils/acrossUtil'

export default {
  name: 'NewConstructionContract',
  props: {
    addDialogTitle: '',
    drawer: { // 遮罩层开关
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      //产品大类字典
      productClassifyDict: [],
      //产品版本字典
      productVersionDict: [],
      //产品实施方字典
      implTypeDict: this.getDict(this.dictConst.PRODUCT_IMPL_TYPE),
      //表单数据
      form: {},
      rules: {
        classify: [
          { required: true, message: '请选择产品大类', trigger: 'change' }
        ],
        productName: [
          { required: true, message: '请填写产品名称', trigger: 'blur' }
        ],
        implType: [
          { required: true, message: '请选择产品实施方', trigger: 'change' }
        ]

      }
    }
  },
  created() {
    this.reset()
    //获取产品大类字典
    this.getProductClassifyDict()
    //获取产品版本字典
    this.getProductVersionDict()

  },
  methods: {
    onSubmit() {
      console.log('submit!', this.form)
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let res = await addProductApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.eventBus.$emit(this.eventConst.product.addSucceed)
            this.reset()
          }

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /** 表单重置 */
    reset() {
      this.form = {
        //产品大类
        classify: '',
        //版本
        version: undefined,
        //产品名称
        productName: '',
        // 产品实施方
        implType: undefined,
        remark: '',
        // 增值包信息
        extrasList: []
      }
      this.form.implType = this.getDictPrimaryItemId(this.dictConst.PRODUCT_IMPL_TYPE)
      this.resetForm('form')

    },

    // 产品大类选项变化
    classifyChange(val) {
      console.log(val)
      let selectClassify = this.productClassifyDict.find(item => {
        return item.id == val
      })
      console.log(selectClassify)
      this.form.extrasList = selectClassify.extrasList

    },
    // 版本点击
    versionSelect(val) {
      this.form.version = this.form.version == val ? undefined : val
    },
    implTypeSelect(val) {
      this.form.implType = val
    },
    // 关闭弹窗
    handleDrawer() {
      this.$emit('handleDrawer')
    },
    //获取产品大类字典
    getProductClassifyDict() {
      productClassifyDictApi().then(res => {
        this.productClassifyDict = res.data
      })
    },
    //获取产品版本字典
    getProductVersionDict() {
      productVersionDictApi().then(res => {
        this.productVersionDict = res.data
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.newConstructionContract_wrap {
  padding: 20px 0;

  .productWrap {
    .productItem {
      float: left;
      width: 260px;
      padding: 6px 10px;
      height: 76px;
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;

      .productTitle {
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }

      //.productSelect{
      //  .el-select{
      //    height: 28px;
      //    line-height: 28px;
      //  }
      //}
    }
  }
}

.creatInstitution {
  margin-left: 10px;
  font-size: 14px;
  color: #F1880D;
}

::v-deep.labelSet {
  .el-form-item__label {
    line-height: 20px !important;
  }
}

.drawer_wrap {
  .newBuildContractWrap {
    padding: 22px 0px;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    .newConstructionContract {
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
      line-height: 24px;
    }

    .el-button--warning.is-plain {
      background: none;
    }

    .saveBtn {
      border: 1px solid #F1880D;
      color: #F1880D;

      &:hover {
        border: 1px solid #d0760c;
        color: #d0760c;
      }

      &:active {
        border: 1px solid #d0760c;
        color: #d0760c;
      }
    }
  }
}

.closeBtn{
  position:fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  i{
    color: #F09933;
    font-size: 20px;
  }
}
</style>
