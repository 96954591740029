<!--批量导入组件-->
<template>
  <el-dialog
    title="批量导入"
    width="900px"
    :modal="true"
    :visible.sync="dialogVisible"
    :append-to-body="false"
    :before-close="cancelBtn"
    :show-close="!loading"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    center
    class="vertical-middle-dialog batch-import-dialog">
    <div v-loading="loading">
      <el-form
        ref="uploadFilesForm"
        status-icon
        :model="uploadFilesForm"
        label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="机构文件:"
              prop="file"
              :rules="[{ required: true, message: '请选择.xls或.xlsx文件', trigger: 'change'}]">
              <el-input
                v-model="uploadFilesForm.file"
                style="min-width: 400px;"
                id="file"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                clearable type="file"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="下载模版:">
              <span class="attachment" @click="downloadFile">机构导入模板.excel</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-button
              type="primary"
              size="small"
              style="margin-left: 20px;background: #2B60F8;float:right;"
              @click="uploadFiles">
              确定
            </el-button>
            <el-button 
              style="float: right;margin-right: 10px;"
              size="small"
              @click="cancelUpload">取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import {createImportOrgReporApi, uploadAndImportCrmOrgApi} from "@/modules/crm/api/crm/org"
import {axiosDownload} from '@/utils/common'
import { downloadFileApi } from '@/modules/crm/api/system/file'
export default {
  name: "BatchImport",
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }

  },
  components: {
    draggable,
  },
  watch: {
    dialogVisible: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          //this.reset()
        }
      }
    }
  },
  computed: {},
  data() {
    return {
      loading: false,
      uploadFilesForm: {
        file: undefined
      }
    }
  },
  created() {

  },
  methods: {
    cancelBtn() {
      this.dialogVisibleFn()
    },
    dialogVisibleFn() {
      this.$emit('close', false)
    },
    /*取消上传文件*/
    cancelUpload() {
      this.$refs['uploadFilesForm'].resetFields()
      this.dialogVisibleFn()
    },
    /*执行上传文件*/
    uploadFiles() {
      this.$refs['uploadFilesForm'].validate(valid => {
        //this.stopTimer()
        if (valid) {
          this.loading = true
          const form = new FormData()
          console.log("document.querySelector('#file').files[0]",document.querySelector('#file').files[0])
          let file=document.querySelector('#file').files[0]
          if(file.size>10*1024*1024){
            this.msgError('文件过大，不能超过10MB,请检查文件内容是否有误')
            this.loading = false
            return
          }
          form.append('file',file)
          uploadAndImportCrmOrgApi(form).then(res => {
            // this.dialogVisibleFn()
            if (res.code == 200) {
              if (res.data.error) {
                this.msgError('存在[' + res.data.error + ']条异常数据，导入失败，详情请查看导入结果报告')
                //生成报告
                createImportOrgReporApi(res.data.batchNum).then(reportRes => {
                  this.loading = false
                  if (reportRes.type == 'application/json') {
                    this.msgError('生成导入结果报告异常')
                  } else {
                    let nowDate = this.parseTime(new Date().getTime(), '{y}{m}{d}{h}{i}{s}')
                    axiosDownload('机构批量导入结果报告' + nowDate + '.xlsx', reportRes)
                    this.msgSuccess('报告生成完成，请在浏览器下载列表中查看')
                  }
                })
              } else {
                this.loading = false
                this.msgSuccess('导入成功')
                this.$emit('close', false)
                this.$emit('refresh')
              }
            } else {
              this.loading = false
              this.msgError(res.msg)
            }
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    downloadFile(){
      let fileId = 4002 // 机构模板
      downloadFileApi(fileId).then(res=>{
        if (res.type == 'application/json') {
          this.msgError('下载失败')
        } else {
          axiosDownload('机构导入模板.xlsx', res)
          this.msgSuccess('下载完成,请在浏览器的下载列表中查看')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.batch-import-dialog {
  ::v-deep .el-dialog {
    height: 244px;
    .el-dialog__body {
      padding: 30px 20px;
      height: auto !important;
    }
  }
}
  .el-form-item {
    margin-bottom: 10px;
  }
  .attachment {
    color: #686EC5;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }
</style>
