<template>
  <div class="app-container" style="height:100%"
       v-loading.fullscreen.lock="pageLoading"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">合同管理</el-col>
            <el-col :span="12">
              <div style="float: right;display: flex;">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">新建合同</el-button>
                <el-button
                  class="crm_btn_style batch-import-btn"
                  size="small"
                  @click="handleImport">
                  <i class="iconfont"style="margin-right: 6px;">&#xe6a7;</i>
                  批量导入
                </el-button>
              </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model.trim="queryParams.searchValue"
                            size="small"
                            style="width:240px;"
                            maxlength="100"
                            clearable
                            placeholder="合同名称/合同编号/客户名称"
                            @keydown.enter.native="handleQuery"
                  ></el-input>
<!--                  maxlength="30" show-word-limit-->
                </el-form-item>


                <el-form-item label="">
                  <el-select v-model="queryParams.contractClassify" size="small"
                             style="width:150px;"
                             filterable
                             @keydown.enter.native="handleQuery"
                             placeholder="合同分类" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in contractClassifyDict"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="">
                  <el-select v-model="queryParams.contractType" size="small"
                             style="width:150px;"
                             @keydown.enter.native="handleQuery"
                             placeholder="合同类型" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in contractTypeDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="queryParams.projectClassify" size="small"
                             style="width:150px;"
                             @keydown.enter.native="handleQuery"
                             placeholder="项目分类" clearable
                  >
                    <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                               v-for="item in contractProjectClassifyDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
                <el-button
                  type="text"
                  size="small"
                  class="resetFilterClass"
                  @click="resetSearchValue()">
                  <i class="iconfont">&#xe660;</i>
                </el-button>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table height="100%"
                  v-loading="tableLoading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="mainTable"
                  :default-sort="{prop: 'createTime', order: 'ascending'}"
                  @sort-change="tableSortChange"
        >
          <el-table-column label="ID" prop="id" width="50"></el-table-column>
          <el-table-column label="合同编号" prop="contractNum" min-width="150"/>
          <el-table-column label="合同名称" min-width="150">
            <template slot-scope="scope">
              <span style="color:#F1880D;cursor: pointer;" @click="onCheck(scope.row)">{{scope.row.contractName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分类" prop="contractClassifyName" min-width="80"/>
          <el-table-column label="合同性质" prop="contractTypeName" min-width="100"/>
          <el-table-column label="项目分类" prop="projectClassifyName" min-width="100"/>
          <el-table-column label="终端客户" prop="userName" min-width="150">
            <template slot-scope="scope">
              <span v-for="(item,index) in scope.row.customerList" size="mini" style="margin-right: 4px;">
                {{ item.customerName }}<span v-if="index!==scope.row.customerList.length-1">、</span>
              </span>
            </template>
          </el-table-column>
<!--          <el-table-column label="合同金额" prop="contractPrice" min-width="80"/>-->
          <el-table-column label="下单日期" prop="orderTime" min-width="100" sortable="custom"/>
          <el-table-column label="操作时间" prop="updateTime" min-width="150" sortable="custom"/>
          <el-table-column label="操作人" prop="updateBy" min-width="80"/>
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a;font-size: 12px;"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009;font-size: 12px;"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10"><div style="color:#999;line-height: 40px;font-size: 12px;">
          按住Shift+鼠标滚轮左右滑动列表
        </div></el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"
            />
          </div>
        </el-col>

      </el-footer>
    </el-container>
    <el-drawer
      :title="editDialogTitle"
      :visible.sync="drawer"
      :modal="false"
      :with-header="false"
      :wrapperClosable="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="closeDrawer"
      size="980px">
      <div class="drawer_wrap">
      <!--  新建合同title-->
        <el-row class="newBuildContractWrap">
          <el-col :span="12">
            <span class="newConstructionContract">新建合同</span>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button plain size="mini" @click="onReset">重置</el-button>
            <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
          </el-col>
        </el-row>
        <new-construction-contract ref="form1" :isNewConstruction="isNewConstruction"></new-construction-contract>
<!--  关闭按钮-->

      </div>
      <div class="closeBtn" @click="closeDrawer">
        <i class="iconfont">&#xe677;</i>
      </div>
    </el-drawer>
    <edit-common
      v-if="editCommon"
      :editCommonTitle="editCommonTitle"
      :is-edit.sync="isEdit"
      :is-can-edit="isCanEdit"
      :drawer="editCommon"
      :activeNum="activeNum"
      :contractId="contractId"
      :projectId="projectId"
      :titleName="contractName"
      :isCanEdit="isCanEdit"
      @handleEditCommon="handleEditCommon">
    </edit-common>
    <import-contract   v-if="importTaskDialog" :dialog-visible="importTaskDialog"
                      @close="importTaskDialog=false"
                      @refresh="handleQuery"
    ></import-contract>
  </div>
</template>
<script>
import NewConstructionContract from '@/modules/crm/components/NewConstructionContract'
import {
  searchContractList,
  delContractApi,
  checkContractRelProjectTask
} from '@/modules/crm/api/crm/contract'
import EditCommon from '@/modules/crm/components/EditCommon'
import ImportContract from '@/modules/crm/components/ImportContract'

export default {
  name: 'crmContract',
  components:{
    NewConstructionContract,
    EditCommon,
    ImportContract
  },
  data() {
    return {
      //导入合同弹窗
      importTaskDialog:false,
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        searchValue: '',
        contractClassify: undefined,
        contractType: undefined,
        projectClassify: undefined
      },
      editDialogTitle: '新增角色',
      editDialogVisible: false,
      //用户部门字典
      sysUserDeptDict: this.getDict(this.dictConst.SYS_USER_DEPT),
      // 合同分类字典
      contractClassifyDict: this.getDict(this.dictConst.CONTRACT_CLASSIFY),
      // 合同类型字典
      contractTypeDict: this.getDict(this.dictConst.CONTRACT_TYPE),
      // 项目分类字典
      contractProjectClassifyDict: this.getDict(this.dictConst.CONTRACT_PROJECT_CLASSIFY),
      drawer: false,
      isNewConstruction: true,
      activeNum: 1,  // 机构
      editCommon: false,
      editCommonTitle: '',
      contractId: undefined,
      projectId: undefined,
      contractName: '',
      isEdit: true,
      isCanEdit: false // checkContractRelProjectTask
    }
  },
  created() {
    this.handleQuery()
    console.log('dictConst.CONTRACT_CLASSIFY', this.dictConst.CONTRACT_TYPE)
    this.eventBus.$on(this.eventConst.contract.addSucceed,()=>{
      this.drawer = false
      this.handleQuery()
    });
    this.eventBus.$on(this.eventConst.contract.delSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
    this.eventBus.$on(this.eventConst.contract.editSucceed,()=>{
      this.handleEditCommon()
      this.handleQuery()
    });
  },
  methods: {
    handleEditCommon() {
      this.editCommon = false
      // this.handleQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    // 重置查询条件
    resetSearchValue() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc',
        searchValue: '',
        contractClassify: undefined,
        contractType: undefined,
        projectClassify: undefined
      }
      this.handleQuery()
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      searchContractList(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    closeDrawer() {
      this.drawer = false
    },
    /** 查看按钮操作 */
    onCheck(row) {
      this.isEdit = false
      this.editCommon = true
      this.contractId = row.id
      this.projectId = row.projectId
      this.contractName = row.contractName
    },
    /** 修改按钮操作 */
    async handleEdit(row) {
      const res = await this.checkContractRelProjectTaskFn(row.id)
      const isCanEdit = res.data
      console.log(isCanEdit)
      // if (isCanEdit) {
      //   this.msgError(res.msg)
      //   return false
      // } else {
        this.isCanEdit = isCanEdit
        this.isEdit = true
        this.editCommon = true
        this.contractId = row.id
        this.projectId = row.projectId
        this.contractName = row.contractName
      // }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('确认删除合同【' + row.contractName + '】吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delContractApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 新建按钮操作 */
    handleAdd() {
      this.drawer = true
      this.isNewConstruction = true
      this.editDialogTitle = '新建合同'
      this.$nextTick(()=> {
        this.onReset()
      })
      // this.editDialogVisible = true
    },
    onReset() {
      let flag = this.$refs['form1'].reset()
      console.log(flag)
    },
    onSubmit() {
      console.log('onSubmit')
      let flag = this.$refs['form1'].onSubmit()
      console.log(flag)
      if(flag){
        this.drawer = false
        this.handleQuery()
      }
    },
    async checkContractRelProjectTaskFn(contractId) {
      let res = await checkContractRelProjectTask(contractId)
      // console.log(res)
      if (res&&res.code === 200) {
        return res
      }
    },
    /** 批量导入按钮操作 */
    handleImport(){
      this.importTaskDialog=true

    },
  }
}
</script>
<style lang="scss" scoped>
.crm_btn_style {
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.batch-import-btn {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BCBCBC;
    color: #777777;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}
.closeBtn{
  position:fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
  i{
    color: #F09933;
    font-size: 20px;
  }
}
.drawer_wrap{
  //position: relative;
  .newBuildContractWrap{
    padding:22px 0px;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    .newConstructionContract{
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
      line-height: 24px;
    }
    .el-button--warning.is-plain{
      background: none;
    }
    .saveBtn{
      border: 1px solid #F1880D;
      color: #F1880D;
      &:hover{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
      &:active{
        border: 1px solid #d0760c;
        color: #d0760c;
      }
    }
  }
}
</style>

