<template>
  <el-dialog
    title="来源配置"
    width="540px"
    :visible.sync="sourceDialogVisible"
    :before-close="cancelBtn"
    :close-on-press-escape="false"
    :close-on-click-modal="false">
    <div>
<!--      maxlength="50"-->
<!--      show-word-limit-->
      <draggable
        v-model="sourceArr"
        ghost-class="ghost">
        <transition-group>
      <el-row
        class="sourceItem"
        v-for="(item,index) in sourceArr"
        :key="item.key">
        <el-col :span="1" class="dragBtn"><i class="iconfont">&#xe679;</i></el-col>
        <el-col :span="21" class="sourceInput">
          <el-input
            v-model="item.value"
            placeholder=""></el-input>
        </el-col>
        <el-col :span="2" class="sourceDel">
          <i class="iconfont" @click="delSource(index)">&#xe672;</i>
        </el-col>
      </el-row>
        </transition-group>
      </draggable>
      <el-row>
        <el-col :span="1">
          <br/>
        </el-col>
        <el-col :span="23">
          <el-button size="mini" @click="addSource">添加</el-button>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="cancelBtn">取 消</el-button>
        <el-button type="primary" @click="confirmBtn">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: "SetSource",
  props: {
    sourceDialogVisible: { // 遮罩层开关
      type: Boolean,
      required: false
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      sourceArr: [
        {value: '',key:Math.random()*100 }
      ],
      dragIndex: '',
      enterIndex: '',
    }
  },
  // created() {
  //   this.sourceVisible = this.sourceDialogVisible
  // },
  methods: {
    cancelBtn() {
      this.sourceDialogVisibleFn()
    },
    confirmBtn() {
      this.sourceDialogVisibleFn()
    },
    sourceDialogVisibleFn() {
      this.$emit('closeSource', false)
    },
    delSource(index) {
      this.sourceArr.splice(index,1)
    },
    addSource() {
      this.sourceArr.push( {value: '',key:Math.random()*100 })
    }
  }
}
</script>

<style lang="scss" scoped>
  .ghost{
    opacity: 0.5;
    background: #f1f1f1;
  }
  .sourceItem{
    margin-bottom: 10px;
  }
  .dragBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    i{
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      &:hover{
        background: #f1f1f1;
      }
    }
  }
  .sourceDel{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    i{
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 8px;
      padding-left: 5px;
      background: #f1f1f1;
      color: #999999;
      font-size: 8px;
      cursor: pointer;
    }
  }
  ::v-deep.el-dialog__footer{
    text-align: center!important;
  }
</style>
