import store from '@/modules/crm/store/index'
import { getAllDictApi } from '@/modules/crm/api/system/dict'
import { isEmpty } from '@/utils/common'

/**
 *  系统相关方法
 *
 *
 */


// 初始化字典数据
export function initDict() {
  getAllDictApi().then(res => {
    for (let key in res.data) {
      store.dispatch('dict/addDict', { key: key, value: res.data[key] })
    }
  })

}

// 根据字典类型代码获取字典数据
export function getDict(dictTypeCode) {
  let dict = store.state.dict.dicts[dictTypeCode]
  if (isEmpty(dict)) {
    dict = []
  }
  return dict
}

// 根据字典类型代码获取字典数据的默认值
export function getDictPrimaryItem(dictTypeCode) {
  let dict = getDict(dictTypeCode)
  let primaryItem = dict.find(item => {
    return item.isPrimary == 1
  })
  return primaryItem
}

// 根据字典类型代码获取字典数据的默认值id
export function getDictPrimaryItemId(dictTypeCode) {
  let dict = getDict(dictTypeCode)
  let primaryItem = dict.find(item => {
    return item.isPrimary == 1
  })
  return primaryItem?primaryItem.id:undefined
}

// 根据字典类型代码和字典id获取字典项
export function getDictItem(dictTypeCode,dictId) {
  let dict = store.state.dict.dicts[dictTypeCode]
  if (isEmpty(dict)) {
    dict = []
  }
  let findItem = dict.find(item => {
    return item.id == dictId
  })
  return findItem?findItem:undefined
}

// 根据字典类型代码和字典id获取字典项的css类名
export function getDictItemCssClass(dictTypeCode,dictId) {
  let findItem = getDictItem(dictTypeCode,dictId)
  // console.log(findItem)
  return findItem?findItem.cssClass:''
}

