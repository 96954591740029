var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionProject_wrap" },
    [
      _vm.projectList.length > 0 || _vm.projectId
        ? _c(
            "div",
            [
              !_vm.projectId
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "400px",
                                "margin-bottom": "10px",
                              },
                              attrs: {
                                placeholder: "请选择项目",
                                size: "small",
                              },
                              on: { change: _vm.projectChange },
                              model: {
                                value: _vm.projectValue,
                                callback: function ($$v) {
                                  _vm.projectValue = $$v
                                },
                                expression: "projectValue",
                              },
                            },
                            _vm._l(_vm.projectList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.projectName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                          _c("span", { staticClass: "projectNum" }, [
                            _vm._v(
                              "共" +
                                _vm._s(
                                  _vm.projectListLen ? _vm.projectListLen : 0
                                ) +
                                "个项目"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "projectTitle" }, [_vm._v("合同信息")]),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("编号"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "span",
                          { staticClass: "editInput contractNameClass" },
                          [_vm._v(_vm._s(_vm.projectObject.contractNum))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("名称"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "span",
                          { staticClass: "editInput contractNameClass" },
                          [_vm._v(_vm._s(_vm.projectObject.contractName))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("金额"),
                        ]),
                      ]),
                      _vm.projectObject.contractPrice
                        ? _c("el-col", { attrs: { span: 20 } }, [
                            _vm.isTotalMoneyShow
                              ? _c("span", { staticClass: "editInput" }, [
                                  _vm._v(
                                    _vm._s(_vm.projectObject.contractPrice) +
                                      "万元"
                                  ),
                                ])
                              : _c("span", { staticClass: "editInput" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("starNumbers")(
                                        _vm.projectObject.contractPrice
                                      )
                                    ) + "万元"
                                  ),
                                ]),
                            _vm.contractPriceShowPermission
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      color: "#C4C4C4",
                                    },
                                  },
                                  [
                                    _vm.isTotalMoneyShow
                                      ? _c(
                                          "i",
                                          {
                                            staticClass: "iconfont",
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                _vm.isTotalMoneyShow = false
                                              },
                                            },
                                          },
                                          [_vm._v("")]
                                        )
                                      : _c(
                                          "i",
                                          {
                                            staticClass: "iconfont",
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                _vm.isTotalMoneyShow = true
                                              },
                                            },
                                          },
                                          [_vm._v("")]
                                        ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("下单时间"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.projectObject.orderTime)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("项目分类"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.projectObject.projectClassifyName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("购买方"),
                          _c("span", { staticStyle: { "font-size": "12px" } }, [
                            _vm._v("(甲方)"),
                          ]),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "editInput orgListItem contractNameClass",
                          },
                          [_vm._v(_vm._s(_vm.projectObject.purchaserName))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("终端客户"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.projectObject.customerList,
                      function (item, index) {
                        return _c(
                          "span",
                          { key: item.id, staticClass: "editInput" },
                          [
                            _c(
                              "span",
                              { staticClass: "orgListItem contractNameClass" },
                              [_vm._v(_vm._s(item.customerName))]
                            ),
                            index < _vm.projectObject.customerList.length - 1
                              ? _c("span", [_vm._v("、")])
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("经销商"),
                      _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v("(乙方)"),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _vm.projectObject.sellerTypeName
                      ? _c("span", { staticClass: "appreciationPackage" }, [
                          _vm._v(_vm._s(_vm.projectObject.sellerTypeName)),
                        ])
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "editInput orgListItem contractNameClass",
                      },
                      [_vm._v(_vm._s(_vm.projectObject.sellerName))]
                    ),
                    _vm.projectObject.distribution
                      ? _c(
                          "span",
                          {
                            staticClass: "appreciationPackage",
                            staticStyle: { margin: "0 6px 0 10px" },
                          },
                          [_vm._v("分销")]
                        )
                      : _vm._e(),
                    _vm.projectObject.distribution
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "editInput orgListItem contractNameClass",
                          },
                          [_vm._v(_vm._s(_vm.projectObject.distributionName))]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "projectTitle" }, [_vm._v("项目信息")]),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("项目名称"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "span",
                          { staticClass: "editInput contractNameClass" },
                          [_vm._v(_vm._s(_vm.projectObject.projectName))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("项目状态"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", { staticClass: "projectStatusSpan" }, [
                          _vm._v(
                            _vm._s(_vm.projectObject.projectStatusShowName)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("项目实施方"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.projectObject.implTypeName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("附件")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.getList(_vm.projectObject, "projectFileList"),
                      function (fileItem, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "attachment" },
                          [
                            _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                            _c(
                              "span",
                              {
                                staticClass: "editInput",
                                staticStyle: {
                                  "margin-left": "4px",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPreviewFile(fileItem)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(fileItem.fileName))]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("备注")]),
                  ]),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.projectObject.remark)),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "projectTitle" }, [_vm._v("人员信息")]),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("客户方"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.projectObject.purchaserContactsList,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "productWrap01" },
                          [
                            _c(
                              "div",
                              { staticClass: "productItem" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "contactName",
                                        attrs: { span: 24 },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("类型")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        item.orgId ==
                                        _vm.projectObject.purchaser
                                          ? _c(
                                              "span",
                                              { staticClass: "typeSpan" },
                                              [_vm._v("购买方")]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.projectObject.customerList,
                                          function (i) {
                                            return [
                                              item.orgId == i.customerId
                                                ? _c(
                                                    "span",
                                                    { staticClass: "typeSpan" },
                                                    [_vm._v(" 终端客户 ")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("部门")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.departmentName)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("职务")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.postName)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  item.phoneList,
                                  function (phoneItem, phoneIndex) {
                                    return _c(
                                      "el-row",
                                      { key: phoneItem.id },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [
                                            _vm._v(
                                              "电话" +
                                                _vm._s(Number(phoneIndex + 1))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(phoneItem.phone)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.email))])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("微信")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.wxAccount)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "remarkStyle",
                                            attrs: { title: item.remark },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.remark) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("乙方")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    _vm._l(
                      _vm.projectObject.sellerContactsList,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "productWrap01" },
                          [
                            _c(
                              "div",
                              { staticClass: "productItem" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "contactName",
                                        attrs: { span: 24 },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("类型")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        item.orgId === _vm.projectObject.seller
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "appreciationPackage",
                                              },
                                              [_vm._v("经销")]
                                            )
                                          : item.orgId ===
                                            _vm.projectObject.distribution
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "appreciationPackage",
                                              },
                                              [_vm._v("分销")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("部门")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.departmentName)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("职务")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.postName)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  item.phoneList,
                                  function (phoneItem, phoneIndex) {
                                    return _c(
                                      "el-row",
                                      { key: phoneItem.id },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [
                                            _vm._v(
                                              "电话" +
                                                _vm._s(Number(phoneIndex + 1))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(phoneItem.phone)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.email))])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("微信")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.wxAccount)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "remarkStyle",
                                            attrs: { title: item.remark },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.remark) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _vm.projectObject.sellerType !==
                  _vm.dictDataConst.CONTRACT_SELLER_TYPE.SELL
                    ? _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("卫心方"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.projectObject.sysUserContactsList &&
                  _vm.projectObject.sysUserContactsList.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        _vm._l(
                          _vm.projectObject.sysUserContactsList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "productWrap01" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "productItem" },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "contactName",
                                            attrs: { span: 24 },
                                          },
                                          [_vm._v(_vm._s(item.name))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("部门")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.departmentName)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("职务")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.postName)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("电话")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.phone)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("邮箱")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.email)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("微信")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.wxAccount)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 6 },
                                          },
                                          [_vm._v("备注")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 18 },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "remarkStyle",
                                                attrs: { title: item.remark },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.remark) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "projectTitle" }, [_vm._v("产品信息")]),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("相关产品"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap_contact" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    _vm._l(_vm.projectObject.productList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "productWrap02" },
                        [
                          _c(
                            "div",
                            { staticClass: "productItem" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 15 } },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            trigger: "hover",
                                            content: item.productName,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "relatedProductName",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.productName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelectMoney",
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 9 },
                                    },
                                    [
                                      item.productAmount
                                        ? _c("span", [
                                            item.isTotalMoneyShow
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "productAmountNum",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.productAmount
                                                      ) + "万元"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "productAmountNum",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("starNumbers")(
                                                          item.productAmount
                                                        )
                                                      ) + "万元"
                                                    ),
                                                  ]
                                                ),
                                            _vm.contractPriceShowPermission
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                      color: "#C4C4C4",
                                                    },
                                                  },
                                                  [
                                                    item.isTotalMoneyShow
                                                      ? _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "iconfont",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$forceUpdate()
                                                                  item.isTotalMoneyShow =
                                                                    !item.isTotalMoneyShow
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("")]
                                                        )
                                                      : _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "iconfont",
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  _vm.$forceUpdate()
                                                                  item.isTotalMoneyShow =
                                                                    !item.isTotalMoneyShow
                                                                },
                                                            },
                                                          },
                                                          [_vm._v("")]
                                                        ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    background: "#F8F8F8",
                                    padding: "0 4px",
                                    height: "180px",
                                  },
                                },
                                _vm._l(
                                  item.productStatusProgress,
                                  function (j, jIndex) {
                                    return jIndex < item.projectStatusOrder
                                      ? _c(
                                          "el-row",
                                          {
                                            key: jIndex,
                                            class:
                                              jIndex + 1 ===
                                              item.projectStatusOrder
                                                ? "currentprojectStatus"
                                                : "",
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "productTitleDate",
                                                attrs: { span: 8 },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    j.productStatusShowName
                                                  ) + ":"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass:
                                                  "productSelectDate",
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                                attrs: { span: 16 },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(j.startTime)),
                                                ]),
                                                j.productStatus ===
                                                _vm.dictDataConst
                                                  .PRODUCT_STATUS_PROGRESS
                                                  .PRODUCT_STATUS
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "至" + _vm._s(j.endTime)
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }
                                ),
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productTitle",
                                      attrs: { span: 5 },
                                    },
                                    [_vm._v("增值包")]
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "productSelect zzb",
                                      attrs: { span: 19 },
                                    },
                                    [
                                      item.extrasList &&
                                      item.extrasList.length > 0
                                        ? _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                trigger: "hover",
                                                content:
                                                  item.extrasList[0].extrasName,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "appreciationPackage appreciationPackageName",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.extrasList[0]
                                                        .extrasName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            "popper-class": "projectUl_popover",
                                            placement: "bottom",
                                            width: "220",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            item.extrasList.slice(1),
                                            function (i) {
                                              return _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#5D5D5D",
                                                    "font-size": "14px",
                                                    "line-height": "18px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(i.extrasName) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          item.extrasList.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "appreciationPackage",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    "+" +
                                                      _vm._s(
                                                        item.extrasList.length -
                                                          1
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "noDataWrap" }, [_vm._m(0)]),
      _c("preview-file", {
        attrs: {
          "is-show-dialog": _vm.showPreviewFile,
          "file-id": _vm.previewFileId,
        },
        on: {
          "update:isShowDialog": function ($event) {
            _vm.showPreviewFile = $event
          },
          "update:is-show-dialog": function ($event) {
            _vm.showPreviewFile = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noData" }, [
      _c("img", {
        staticStyle: { width: "96px", height: "60px" },
        attrs: { src: require("../assets/images/noData.png") },
      }),
      _c("div", { staticClass: "noDataText" }, [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }