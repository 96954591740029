var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("产品管理")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新建产品")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    size: "small",
                                    placeholder: "产品名称",
                                    clearable: "",
                                    maxlength: "30",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.productName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.productName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      placeholder: "产品实施方",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.implType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "implType",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.implType",
                                    },
                                  },
                                  _vm._l(_vm.implTypeDict, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.dictLabel,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "resetFilterClass",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetSearchValue()
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-refresh" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "mainTable",
                  attrs: {
                    height: "100%",
                    data: _vm.tableDataList,
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "default-sort": { prop: "createTime", order: "descending" },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "min-width": "150",
                      sortable: "custom",
                      prop: "productName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#F1880D",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleView(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.productName))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品实施方",
                      prop: "implTypeName",
                      "min-width": "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      "min-width": "100",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "更新时间",
                      prop: "updateTime",
                      "min-width": "100",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作人", prop: "updateBy", width: "120" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#ed923a",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#ff0009",
                                  "font-size": "12px",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#999",
                      "line-height": "40px",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v(" 按住Shift+鼠标滚轮左右滑动列表 ")]
                ),
              ]),
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "div",
                  [
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tableDataTotal > 0,
                          expression: "tableDataTotal>0",
                        },
                      ],
                      attrs: {
                        total: _vm.tableDataTotal,
                        page: _vm.queryParams.pageNum,
                        limit: _vm.queryParams.pageSize,
                        "page-sizes": [10, 20, 50, 100],
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.queryParams, "pageSize", $event)
                        },
                        pagination: _vm.queryList,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.drawer
        ? _c("new-construction-product", {
            attrs: { addDialogTitle: _vm.addDialogTitle, drawer: _vm.drawer },
            on: { handleDrawer: _vm.handleDrawer },
          })
        : _vm._e(),
      _vm.editDrawer
        ? _c("edit-product", {
            attrs: {
              editDialogTitle: _vm.editDialogTitle,
              drawer: _vm.editDrawer,
              "product-id": _vm.editProductId,
              "edit-type": _vm.editType,
            },
            on: { handleEditDrawer: _vm.handleEditDrawer },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }