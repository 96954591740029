<template>
  <div>
    <h2>权限测试页面</h2>
    所有权限：
    {{user.permissions}}

    <p>
    </p>

    <div>
      有 permissionsConst.test.add 权限才会显示新增按钮
      <el-button v-hasPermi="[permissionsConst.test.add]">新增</el-button>
    </div>

    <div>
      有 permissionsConst.test.edit 权限才会显示修改按钮
      <el-button v-hasPermi="[permissionsConst.test.edit]">修改</el-button>
    </div>


  </div>

</template>

<script>
import {mapState} from "vuex";
export default {
  name: 'test1',
  computed: {
    ...mapState(["user"])
  },
}
</script>

<style scoped>

</style>
