<template>
  <div class="newConstructionProject_wrap">
    <div v-if="projectList.length>0||projectId">
      <el-row v-if="!projectId">
        <el-col :span="24">
          <el-select v-model="projectValue"
                     placeholder="请选择项目"
                     style="width: 400px;margin-bottom: 10px;"
                     size="small"
                     @change="projectChange">
            <el-option v-for="item in projectList" :key="item.id" :label="item.projectName" :value="item.id"></el-option>
          </el-select>
          <span class="projectNum">共{{projectListLen?projectListLen:0}}个项目</span>
        </el-col>
      </el-row>
      <div class="projectTitle">合同信息</div>
      <el-row class="editProductWrap">
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">编号</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput contractNameClass">{{ projectObject.contractNum }}</span>
          </el-col>
        </el-col>
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">名称</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput contractNameClass">{{ projectObject.contractName }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">金额</span>
          </el-col>
          <el-col :span="20" v-if="projectObject.contractPrice">
            <span class="editInput" v-if="isTotalMoneyShow">{{ projectObject.contractPrice}}万元</span>
            <span class="editInput" v-else>{{ projectObject.contractPrice | starNumbers}}万元</span>
            <span style="margin-left: 10px;color: #C4C4C4;" v-if="contractPriceShowPermission">
<!--            睁眼-->
            <i style="cursor: pointer;" v-if="isTotalMoneyShow" class="iconfont" @click="isTotalMoneyShow=false">&#xe67d;</i>
              <!--             闭眼-->
            <i style="cursor: pointer;" v-else class="iconfont" @click="isTotalMoneyShow=true">&#xe67c;</i>
          </span>
          </el-col>
        </el-col>
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">下单时间</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput">{{ projectObject.orderTime }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">项目分类</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput">{{ projectObject.projectClassifyName }}</span>
          </el-col>
        </el-col>
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">购买方<span style="font-size:12px;">(甲方)</span></span>
          </el-col>
          <el-col :span="18">
            <span class="editInput orgListItem contractNameClass">{{ projectObject.purchaserName }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">终端客户</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput" v-for="(item,index) in  projectObject.customerList" :key="item.id">
          <span class="orgListItem contractNameClass">{{item.customerName}}</span>
          <span v-if="index<projectObject.customerList.length-1">、</span>
        </span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">经销商<span style="font-size:12px;">(乙方)</span></span>
        </el-col>
        <el-col :span="22">
          <span  class="appreciationPackage" v-if="projectObject.sellerTypeName">{{ projectObject.sellerTypeName }}</span>
          <span class="editInput orgListItem contractNameClass">{{ projectObject.sellerName }}</span>
          <span  class="appreciationPackage" v-if="projectObject.distribution" style="margin: 0 6px 0 10px;">分销</span>
          <span class="editInput orgListItem contractNameClass" v-if="projectObject.distribution">{{ projectObject.distributionName }}</span>
        </el-col>
      </el-row>
      <div class="projectTitle">项目信息</div>
      <el-row class="editProductWrap">
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">项目名称</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput contractNameClass">{{ projectObject.projectName }}</span>
          </el-col>
        </el-col>
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">项目状态</span>
          </el-col>
          <el-col :span="20">
<!--            <ul class="projectUl">-->
<!--              <el-popover-->
<!--                popper-class="projectUl_popover"-->
<!--                v-for="(item,index) in projectObject.projectStatusProgress"-->
<!--                :key="index"-->
<!--                placement="top-start"-->
<!--                width="160"-->
<!--                trigger="click">-->
<!--                  <span-->
<!--                    style="-->
<!--                    padding: 2px 4px;-->
<!--                    border-radius: 2px;-->
<!--                    border: 1px solid #DDD;-->
<!--                    color: #303133;-->
<!--                    font-size: 14px;-->
<!--                    line-height: 22px;">{{ item.projectStatusShowName}}</span>-->
<!--                <div-->
<!--                  style="-->
<!--                    color:#606266;-->
<!--                    font-size: 12px;-->
<!--                    line-height: 20px;">{{ item.startTime}}</div>-->
<!--                <li-->
<!--                  slot="reference"-->
<!--                  v-if="index+1<=projectObject.projectStatusOrder"-->
<!--                  :class="index===8 ? 'lastLi': ''">-->
<!--                </li>-->
<!--              </el-popover>-->
<!--            </ul>-->
            <span class="projectStatusSpan">{{ projectObject.projectStatusShowName }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="12">
          <el-col :span="4">
            <span class="editLabel">项目实施方</span>
          </el-col>
          <el-col :span="20">
            <span class="editInput">{{ projectObject.implTypeName }}</span>
          </el-col>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-col :span="4">-->
<!--            <span class="editLabel">建项日期</span>-->
<!--          </el-col>-->
<!--          <el-col :span="20">-->
<!--            <span class="editInput">{{ projectObject.filingTime }}</span>-->
<!--          </el-col>-->
<!--        </el-col>-->
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">附件</span>
        </el-col>
        <el-col :span="22">
          <div class="attachment"
               v-for="(fileItem, index) in getList(projectObject,'projectFileList')"
               :key="index">
            <i class="iconfont">&#xe671;</i>
            <span class="editInput" style="margin-left: 4px;cursor: pointer;" @click="onPreviewFile(fileItem)">{{ fileItem.fileName }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">备注</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{ projectObject.remark }}</span>
        </el-col>
      </el-row>
      <div class="projectTitle">人员信息</div>
      <el-row class="editProductWrap_contact">
        <el-col :span="2">
          <span class="editLabel">客户方</span>
        </el-col>
        <el-col :span="22">
          <div class="productWrap01" v-for="(item, index) in projectObject.purchaserContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">类型</el-col>
                <el-col :span="18" class="productSelect">
                  <span class="typeSpan" v-if="item.orgId ==projectObject.purchaser">购买方</span>
                  <template v-for="i in projectObject.customerList">
                    <span class="typeSpan" v-if="item.orgId==i.customerId">
                      终端客户
                    </span>
                  </template>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">
                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{phoneItem.phone}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                   <span
                     class="remarkStyle"
                     :title="item.remark">
                    {{ item.remark }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="editProductWrap_contact">
        <el-col :span="2">
          <span class="editLabel">乙方</span>
        </el-col>
        <el-col :span="22">
          <div class="productWrap01" v-for="(item, index) in projectObject.sellerContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">类型</el-col>
                <el-col :span="18" class="productSelect">
                  <span  class="appreciationPackage" v-if="item.orgId === projectObject.seller">经销</span>
                  <span  class="appreciationPackage" v-else-if="item.orgId === projectObject.distribution">分销</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">
                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{phoneItem.phone}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                   <span
                     class="remarkStyle"
                     :title="item.remark">
                    {{ item.remark }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="editProductWrap_contact">
        <el-col :span="2" v-if="projectObject.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
          <span class="editLabel">卫心方</span>
        </el-col>
        <el-col :span="22" v-if="projectObject.sysUserContactsList && projectObject.sysUserContactsList.length>0">
          <div class="productWrap01" v-for="(item, index) in projectObject.sysUserContactsList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="24" class="contactName">{{item.name}}</el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">部门</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.departmentName}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">职务</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.postName}}</span>
                </el-col>
              </el-row>
<!--              <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">-->
<!--                <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>-->
<!--                <el-col :span="18" class="productSelect">-->
<!--                  <span>{{phoneItem.phone}}</span>-->
<!--                </el-col>-->
<!--              </el-row>-->
              <el-row>
                <el-col :span="6" class="productTitle">电话</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{ item.phone }}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">邮箱</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.email}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">微信</el-col>
                <el-col :span="18" class="productSelect">
                  <span>{{item.wxAccount}}</span>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6" class="productTitle">备注</el-col>
                <el-col :span="18" class="productSelect">
                   <span
                     class="remarkStyle"
                     :title="item.remark">
                    {{ item.remark }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="projectTitle">产品信息</div>
      <el-row class="editProductWrap_contact">
        <el-col :span="24">
          <span class="editLabel">相关产品</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap_contact">
        <el-col :span="24">
          <div class="productWrap02" v-for="item in projectObject.productList" :key="item.id">
            <div class="productItem">
              <el-row>
                <el-col :span="15">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    :content="item.productName">
                    <div class="relatedProductName" slot="reference">
                      {{item.productName}}
<!--                      :disabled="item.productName.length<11"-->
<!--                      {{ item.productName.length>11 ? item.productName.substr(0, 10) + '...' : item.productName}}-->
                    </div>
                  </el-popover>
                </el-col>
                <el-col :span="9" class="productSelectMoney" style="text-align: right;">
                  <!--                  <span>{{item.productAmount?item.productAmount:0}}万元</span>-->
                  <span v-if="item.productAmount">
                    <span class="productAmountNum" v-if="item.isTotalMoneyShow">{{ item.productAmount }}万元</span>
                    <span class="productAmountNum" v-else>{{ item.productAmount | starNumbers}}万元</span>
                    <span style="margin-left: 10px;color: #C4C4C4;" v-if="contractPriceShowPermission">
                      <i style="cursor: pointer;" v-if="item.isTotalMoneyShow" class="iconfont" @click="()=>{
                        $forceUpdate();
                        item.isTotalMoneyShow=!item.isTotalMoneyShow
                      }">&#xe67d;</i>
                      <i style="cursor: pointer;" v-else class="iconfont" @click="()=>{
                        $forceUpdate();
                        item.isTotalMoneyShow=!item.isTotalMoneyShow
                      }">&#xe67c;</i>
                    </span>
                  </span>
                </el-col>
              </el-row>
              <div style="background:#F8F8F8;padding:0 4px;height: 180px;">
                <el-row  v-for="(j,jIndex) in item.productStatusProgress" :class="jIndex+1===item.projectStatusOrder?'currentprojectStatus':''" :key="jIndex" v-if="jIndex<item.projectStatusOrder">
                  <el-col :span="8" class="productTitleDate">{{ j.productStatusShowName }}:</el-col>
                  <el-col :span="16" class="productSelectDate" style="text-align: right;">
                    <span>{{j.startTime}}</span><span v-if="j.productStatus === dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS">至{{j.endTime}}</span>
                  </el-col>
                </el-row>
              </div>
              <el-row>
                <el-col :span="5" class="productTitle">增值包</el-col>
                <el-col :span="19" class="productSelect zzb">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    v-if="item.extrasList&&item.extrasList.length>0"
                    :content="item.extrasList[0].extrasName">
                    <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                  </el-popover>
                  <el-popover
                    popper-class="projectUl_popover"
                    placement="bottom"
                    width="220"
                    trigger="click">
                    <div
                      v-for="i in item.extrasList.slice(1)"
                      style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                      {{ i.extrasName }}
                    </div>
                    <span style="cursor: pointer" slot="reference" class="appreciationPackage" v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                  </el-popover>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="noDataWrap">
      <div class="noData">
        <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
        <div class="noDataText">暂无数据</div>
      </div>
    </div>
    <preview-file
      :is-show-dialog.sync="showPreviewFile"
      :file-id="previewFileId"></preview-file>
  </div>
</template>

<script>
import {
  projectListApi,
  projectInfoApi
} from '@/modules/crm/api/crm/project'
import PreviewFile from '@/modules/crm/view/crm/task/components/previewFile'
export default {
  name: "EditProject",
  props: {
    institutionId: {
      type: Number
    },
    projectId: {
      type: Number
    }
  },
  components: {
    PreviewFile
  },
  data() {
    return {
      projectListLen: null, // 项目个数
      projectList: [], // 项目列表
      projectObject: {}, // 单个项目信息
      projectValue: '',
      isTotalMoneyShow: false,
      form: {},
      //文件预览id
      previewFileId:0,
      showPreviewFile: false,
    }
  },
  computed: {
    contractPriceShowPermission() {
      return this.hasPermissions(this.permissionsConst.crmContract.contractPriceShow)
    }
  },
  filters: {
    starNumbers(val) {
      const numberOfStars = val? val.toString().length : 0;
      return '*'.repeat(numberOfStars);
    }
  },
  created() {
    if(this.institutionId) {
      this.projectListApiFn(this.institutionId)
    }
    if (this.projectId) {
      this.projectInfoFn(this.projectId)
    }
  },
  methods: {
    // 机构关联项目列表
    async projectListApiFn(val) {
      let obj = {}
      obj.orgId = val
      let res = await projectListApi(obj)
      console.log(res)
      if(res.code === 200) {
        this.projectList = res.data
        this.projectListLen = res.data.length
        if (this.projectList&&this.projectListLen>0) {
          this.projectValue = this.projectList[0].id // 默认选中第一个
          this.projectChange(this.projectValue)
          this.$emit('projectListLen',true)
        } else {
          this.$emit('projectListLen',false)
        }

      }
    },
    projectChange(val) {
      console.log(val)
      this.$emit('getProjectId',val)
      if (val) {
        this.projectInfoFn(val)
      } else {
        this.projectObject = {}
      }
    },
    // 获取相关项目详细信息
    async projectInfoFn(val) {
      let res = await projectInfoApi(val)
      console.log(res)
      if (res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.projectObject = res.data
      }
    },
    onPreviewFile(fileItem) {
      this.previewFileId = fileItem.fileId
      this.showPreviewFile = true
    }
  }
}
</script>
<style lang="scss">
.newConstructionProject_wrap{
  .el-form-item--medium .el-form-item__content{
    line-height: 14px!important;
  }
  .contractNameClass{
    word-break: break-all;
  }
}
</style>
<style lang="scss"  scoped>
.newConstructionProject_wrap{
  height: 100%;
  padding: 5px 0 20px;
  .attachment {
    margin-top: 4px;
    padding: 2px 4px;

    .editInput, i {
      color: #686EC5;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .projectUl{
    background: #EAECEF;
    border-radius: 4px;
    //width:227px;
    width:170px;
    overflow: hidden;
    float: left;
    margin-top: 7px;
    li{
      float: left;
      width: 18px;
      height: 8px;
      background: #F1880D;
      margin-right: 1px;
    }
    //.lastLi{
    //  margin-right: 0px!important;
    //}
    //.el-popover__reference-wrapper{
    //  li{
    //    float: left;
    //    width: 18px;
    //    height: 8px;
    //    background: #F1880D;
    //    margin-right: 1px;
    //  }
    //}
  }
  .typeSpan{
    display: inline-block;
    padding: 0px 6px;
    border-radius: 20px;
    background: #EBEDFE;
    color: #7281ED;
    font-size: 12px;
    margin-right: 10px;
  }
  .noDataWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .noData{
      width: 96px;
      height:102px;
      text-align: center;
      .noDataText{
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 16px;
      }
    }
  }
  .projectStatusSpan{
    color: #666;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .projectTitle{
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #3160BA;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
  }
  .projectNum{
    font-size: 15px;
    font-weight: 500;
    color: #5D5D5D;
    margin-left: 20px;
  }
  .productWrap{
    .productItem{
      float: left;
      width: 260px;
      padding:6px 10px;
      height: 76px;
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }
  }
}
.editProductWrap{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
}
.editProductWrap_contact{
  margin-bottom: 10px;
}
.editLabel{
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.editInput{
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.orgListItem{
  color: #F1880D;
  cursor: pointer;
}
.appreciationPackage{
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #DEDEDE;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 18px;
  height: 22px;
  margin-right: 4px;
  margin-top: 3px;
  &:last-child{
    margin-right: 0;
  }
}
.appreciationPackageName{
  display: inline-block;
  margin-left:10px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;    /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.productWrap01{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 280px;
  padding:6px 10px;
  display: flex;
  float: left;
  .productItem{
    float: left;
    width: 258px;
    el-row{
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .contactName{
      color:#5D5D5D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .productTitle{
      height: 20px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 20px;
    }
    .productSelect{
      //height: 20px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;
      word-wrap: break-word;
      .remarkStyle{
        width: 100%;
        display: inline-block;
        white-space: nowrap; /* 不换行 */
        overflow: hidden;    /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        cursor: pointer;
      }
    }
  }
}
.productWrap02{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 280px;
  padding:6px 10px 6px;
  display: flex;
  float: left;
  height: 240px;
  .productItem{
    float: left;
    width: 260px;
    .batchClass{
      height: 28px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 28px;
      margin-right: 4px;
    }
    ::v-deep.currentprojectStatus{
      font-weight: 600;
      font-size: 12px;
      .productTitleDate{
        color: #5779F2!important;
      }
     .productSelectDate{
       span{
         color: #5779F2!important;
       }
     }
    }
    .relatedProductName{
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #5D5D5D;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor:pointer;
    }
    .relatedProductProjectState{
      //margin: 4px 0;
      display: inline-block;
      //width: 170px;
    }
    .productTitle{
      height: 28px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 28px;
    }
    .productSelect{
      //height: 28px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 28px;
      word-wrap: break-word;
      display: flex;
    }
    .zzb{
      display: flex;
      justify-content: end;
    }
    .productSelectMoney{
      //height: 28px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;
    }
    .productTitleDate{
      height: 20px;
      font-size: 12px;
      color: #A8ABB2;
      line-height: 20px;
    }
    .productSelectDate{
      //height: 28px;
      font-size: 12px;
      color: #5D5D5D;
      line-height: 20px;
    }
  }
  .projectUl{
    background: #EAECEF;
    border-radius: 4px;
    width:170px;
    overflow: hidden;
    .lastLi{
      margin-right: 0px!important;
    }
    .el-popover__reference-wrapper{
      li{
        float: left;
        width: 18px;
        height: 8px;
        background: #F1880D;
        margin-right: 1px;
        cursor: pointer;
      }
    }
  }
}
</style>
