var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container register-container" },
    [
      _c("el-header", { staticClass: "register-header" }, [
        _c("div", [
          _c("img", {
            attrs: { src: require("../../../assets/images/logo-pdBox.png") },
          }),
          _c("span", { staticClass: "header-text" }, [
            _vm._v("欢迎注册机构用户"),
          ]),
        ]),
      ]),
      _c("el-main", { staticClass: "register-main" }, [
        _c(
          "div",
          { staticClass: "about-login-form-wrap" },
          [
            _c(
              "header",
              { staticClass: "title-header clearfix" },
              [
                _c("h3", { staticClass: "fl" }, [_vm._v("注册")]),
                _c(
                  "el-button",
                  {
                    staticClass: "fr",
                    attrs: { type: "text" },
                    on: { click: _vm.toLoginHandle },
                  },
                  [_vm._v("立即登录")]
                ),
              ],
              1
            ),
            _c(
              "el-form",
              {
                ref: "registerForm",
                attrs: {
                  "status-icon": "",
                  model: _vm.registerFormData,
                  "label-width": "95px",
                  "label-position": "left",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "真实姓名",
                      prop: "name",
                      rules: [
                        {
                          required: true,
                          message: "请输入真实姓名",
                          trigger: "blur",
                        },
                        {
                          min: 2,
                          max: 10,
                          message: "长度在 2 到 10 个字符",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "380px" },
                      attrs: {
                        placeholder: "请输入用户名称",
                        maxlength: "10",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.registerFormData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerFormData, "name", $$v)
                        },
                        expression: "registerFormData.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "手机号",
                      prop: "phone",
                      rules: [
                        {
                          required: true,
                          message: "请输入手机号",
                          trigger: "blur",
                        },
                        {
                          min: 11,
                          max: 11,
                          message: "手机号长度在11个字符",
                          trigger: "blur",
                        },
                        {
                          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                          message: "请输入正确的手机号码",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "380px" },
                      attrs: {
                        placeholder: "请输入手机号",
                        maxlength: "11",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.registerFormData.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerFormData, "phone", $$v)
                        },
                        expression: "registerFormData.phone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "短信验证码",
                      prop: "verificationCode",
                      rules: [
                        {
                          required: true,
                          message: "请输入短信验证码",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 17 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入短信验证码",
                            maxlength: "6",
                            clearable: "",
                          },
                          model: {
                            value: _vm.registerFormData.verificationCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.registerFormData,
                                "verificationCode",
                                $$v
                              )
                            },
                            expression: "registerFormData.verificationCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text", disabled: _vm.disabled },
                            on: { click: _vm.getCode },
                          },
                          [
                            _c("span", { staticClass: "Time" }, [
                              _vm._v(_vm._s(_vm.btnTxt)),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "邮箱",
                      prop: "email",
                      rules: [
                        {
                          required: true,
                          message: "请输入邮箱",
                          trigger: "blur",
                        },
                        {
                          min: 6,
                          max: 50,
                          message: "邮箱长度在6-50个字符",
                          trigger: "blur",
                        },
                        {
                          type: "email",
                          message: "请输入正确的邮箱地址",
                          trigger: ["blur", "change"],
                        },
                      ],
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "380px" },
                      attrs: {
                        placeholder: "请输入邮箱",
                        maxlength: "50",
                        clearable: "",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.registerFormData.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerFormData, "email", $$v)
                        },
                        expression: "registerFormData.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "registerBtn",
                        attrs: { type: "primary" },
                        on: { click: _vm.doRegister },
                      },
                      [_vm._v("注册")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }