<!--规则执行结果-->
<template>
  <div class="app-container" style="height:100%"
       v-loading.fullscreen.lock="pageLoading"
  >
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">角色管理</el-col>
            <el-col :span="12">
              <div style="float: right">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAddRole">
                  新建角色
                </el-button>
              </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">

                <el-form-item label="">
                  <el-input v-model="queryParams.searchValue" size="small" style="width:400px;"
                            maxlength="30" show-word-limit
                            placeholder="角色名称" clearable
                            @keydown.enter.native="handleQuery"
                  ></el-input>
                </el-form-item>
                <el-input v-show="false"></el-input>

                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table border stripe height="100%" v-loading="tableloading"
                  :data="tableDataList"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="roleTable"
                  :default-sort="{prop: 'createTime', order: 'descending'}"
                  @sort-change="tableSortChange"

        >
          <el-table-column label="角色名称" prop="roleName" min-width="100"/>
          <el-table-column label="角色状态" align="center" width="120">
            <template slot-scope="scope">
              <el-switch
                class="switch"
                active-color="#F1880D"
                active-text="启用"
                inactive-text="停用"
                inactive-color="#B5CCF5"
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="handleStatusChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" sortable="custom" width="180"/>
          <el-table-column label="负责区域" align="center" min-width="250">
            <template slot-scope="scope">
              <el-tag type="info" style="margin-right: 4px;margin-bottom: 2px;" v-for="item in scope.row.areaList">{{item.name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <pagination
          v-show="tableDataTotal>0"
          :total="tableDataTotal"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="queryRoleList"
          :page-sizes="[10, 20, 50, 100]"
        />
      </el-footer>
    </el-container>

    <el-dialog :title="editRoleDialogTitle" width="60%"
               :visible.sync="editRoleDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="roleEditForm" status-icon :model="roleEditFormData" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="角色名称:" prop="roleName"
                          :rules="[
                            { required: true, message: '请输入角色名称', trigger: 'blur'},
                            { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
                            ]"
            >
              <el-input placeholder="请输入角色名称" maxlength="30" clearable show-word-limit
                        v-model.trim="roleEditFormData.roleName"

              />
            </el-form-item>

            <el-form-item label="状态" v-if="roleEditFormData.id">
              <el-radio-group v-model="roleEditFormData.status">
                <el-radio :label=1>正常</el-radio>
                <el-radio :label=0>停用</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="菜单权限" prop="menuIdList" :rules="[
              { required: true,validator: validateMenuIdList, trigger: 'blur'}
              ]"
            >
              <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
              <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选/全不选</el-checkbox>
              <el-checkbox v-model="menuCheckStrictly"
                           @change="handleCheckedTreeConnect($event, 'menu')"
              >父子联动
              </el-checkbox>
              <el-tree
                class="tree-border"
                :data="menuOptions"
                show-checkbox
                ref="menu"
                node-key="id"
                :check-strictly="!menuCheckStrictly"
                empty-text="加载中，请稍后"
                :props="defaultProps"
              ></el-tree>
            </el-form-item>



              <el-form-item label="负责区域:" prop="provinceCodeList" style="width: 100%"
                            :rules="[{ required: true, message: '请选择负责区域', trigger: 'blur'}]"
              >
                <el-select v-model="roleEditFormData.provinceCodeList"
                           multiple
                           style="width:100%;"
                           filterable
                           placeholder="请选择负责区域" clearable
                >
                  <el-option :label="item.name" :value="item.code" :key="item.code" v-for="item in provinceDict"
                  ></el-option>
                </el-select>
              </el-form-item>


            <el-form-item label="备注信息:" prop="remark"
            >
              <el-input type="textarea" :rows="2" placeholder="请输入备注信息"
                        v-model="roleEditFormData.remark"
                        maxlength="400" clearable show-word-limit

              />
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="roleCancelEdit">取 消</el-button>
        <el-button type="primary" @click="roleConfirmEdit" style="background-color: #F1880D;border-color: #F1880D;"
        >确 定</el-button>
      </span>
    </el-dialog>

  </div>


</template>
<script>
import {
  addRoleApi, changeRoleStatusApi,
  delRoleApi,
  editRoleApi,
  roleInfoApi,
  roleListApi,
  roleMenuSelectTreeApi
} from '@/modules/crm/api/system/role'
import { isEmpty } from '@/utils/common'
import {
  provinceListApi,
} from '@/modules/crm/api/crm/org'

export default {
  name: 'sysRole',
  data() {
    return {
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableloading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'createTime',
        isAsc: 'desc'
      },
      editRoleDialogTitle: '新增角色',
      editRoleDialogVisible: false,
      roleEditFormData: {
        roleName: '',
        status: 1,
        remark: '',
        menuIdList: [],
        provinceCodeList:[]
      },
      // 菜单列表
      menuOptions: [],
      menuExpand: false,
      menuNodeAll: false,
      // 父子联动
      menuCheckStrictly: false,
      defaultProps: {
        children: 'children',
        label: 'menuName'
      },
      //省份字典
      provinceDict:[]

    }
  },
  created() {
    this.handleQuery()
    //获取省份列表
    this.getProvinceListFn()
  },
  methods: {
    // 省份列表
    async getProvinceListFn() {
      let res = await provinceListApi()
      console.log(res)
      if(res.code === 200) {
        this.provinceDict = res.data
      }
    },
    // 获取所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryRoleList(1)
    },
    /** 查询角色列表 */
    queryRoleList() {
      this.tableloading = true
      let _this = this

      roleListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.roleTable.doLayout()
        })
        this.tableloading = false
      })
    },
    /** 查询菜单树结构 */
    getMenuSelectTree(roleId) {
      if (isEmpty(roleId)) {
        roleId = 0
      }
      roleMenuSelectTreeApi(roleId).then(res => {
        this.menuOptions = res.data.menus
        this.$nextTick(() => {
          let checkedKeys = res.data.checkedKeys
          checkedKeys.forEach((v) => {
            this.$nextTick(() => {
              this.$refs.menu.setChecked(v, true, false)
            })
          })
        })
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status == 1 ? '启用' : '停用'
      this.$confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return changeRoleStatusApi(row.id, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status == 1 ? 0 : 1
      })
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      this.editRoleDialogTitle = '编辑角色'
      this.pageLoading = true
      this.reset()
      const roleId = row.id
      this.getMenuSelectTree(roleId)
      this.editRoleDialogVisible = true
      roleInfoApi(roleId).then(res => {
        this.roleEditFormData = res.data
        this.$set(this.roleEditFormData, 'provinceCodeList', [])
        if(res.data.areaList){
          res.data.areaList.forEach(item=>{
            this.roleEditFormData.provinceCodeList.push(item.code)
          })
        }
        this.pageLoading = false
      })

    },
    validateMenuIdList(rule, value, callback, index) {
      if (isEmpty(this.roleEditFormData.menuIdList)) {
        callback(new Error('角色的菜单不能为空'))
      } else {
        callback()
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除角色【' + row.roleName + '】?', '警告', {
        confirmButtonText: '确定',
        customClass: 'crm-delete-role-confirm',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return delRoleApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 新建角色按钮操作 */
    handleAddRole() {
      this.editRoleDialogTitle = '新建角色'
      this.reset()
      this.getMenuSelectTree()
      this.editRoleDialogVisible = true

    },
    /** 取消编辑 */
    roleCancelEdit() {
      this.editRoleDialogVisible = false
      this.reset()
    },
    /** 确定编辑 */
    roleConfirmEdit() {
      this.roleEditFormData.menuIdList = this.getMenuAllCheckedKeys()
      console.log('this.roleEditFormData', this.roleEditFormData)
      this.$refs['roleEditForm'].validate(async valid => {
        if (valid) {
          let res
          if (this.roleEditFormData.id) {
            res = await editRoleApi(this.roleEditFormData)
          } else {
            res = await addRoleApi(this.roleEditFormData)
          }
          if (res.code == 200) {
            this.msgSuccess()
            this.handleQuery()
            this.reset()
            this.editRoleDialogVisible = false
          }

        } else {
          this.msgError('请规范填写')
        }
      })

    },
    /** 表单重置 */
    reset() {
      this.roleEditFormData = {
        roleName: '',
        status: 1,
        remark: '',
        menuIdList: [],
        provinceCodeList:[]
      }
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([])
      }
      this.menuExpand = false
      this.menuNodeAll = false
      this.deptExpand = true
      this.deptNodeAll = false
      this.resetForm('roleEditForm')
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        let treeList = this.menuOptions
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions : [])
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.menuCheckStrictly = value ? true : false
      }
    }

  }
}
</script>

<style lang="scss">
.crm-delete-role-confirm {
  .el-button--primary {
    background-color: #F1880D !important;
    border-color: #F1880D !important;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner{
  background-color: #F1880D !important;
  border-color: #F1880D !important;
}
.el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #F1880D !important;
  border-color: #F1880D !important;
}

.is-checked .el-checkbox__label{
  color: #F1880D !important;
}
</style>
<style lang="scss" scoped>


.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}


</style>
