<template>
  <el-dialog
    :title="'处理任务：'+taskInfo.taskName"
    width="900px"
    :visible.sync="taskDialogVisible"
    :before-close="cancelBtn"
    :close-on-press-escape="false"
    :close-on-click-modal="false">
    <el-form label-width="80px"  ref="form"  :model="form">
      <el-form-item
        label="处理结果"
        prop="operationType"
        :rules="[
               { required: true, message: '请选择处理结果', trigger: 'change'},
              ]">
        <el-select v-model="form.operationType" placeholder="请选择处理结果" @change="operationTypeChange" style="width: 214px">
          <el-option
            :label="item.dictLabel"
            :value="item.id"
            :key="item.id"
            v-for="item in taskOperationTypeDict"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="服务类型" prop="serveType"  :rules="[
               { required: true, message: '请选择服务类型', trigger: 'change'},
              ]">
        <el-select v-model="form.serveType" placeholder="请选择服务类型" style="width: 214px;">
          <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                     v-for="item in taskServeTypeDict"
          ></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="解决方法" prop="solution" style="width:99%;" :rules="[
               { required: true, message: '请输入解决方法', trigger: 'blur'},
              ]">
        <!-- <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入处理的详情内容"
          v-model="form.solution"
          maxlength="200"
          show-word-limit
        >
        </el-input> -->
        <my-editor
          :editor-content="form.solution"
          editor-style="height: 200px; overflow-y: auto;z-index: 100;border: 1px solid #dcdcdc;"
          @editorInput="onEditorInput"></my-editor>
        <el-upload
          class="upload-demo"
          :action="baseURL+'/crm/sys/file/uploadFile'"
          :data="uploadFileParams"
          :on-change="handleChange"
          style="margin-top: 10px;"
          :file-list="fileList"
          :auto-upload="true"
          :before-upload="beforeUpload"
          accept=".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx"
          :on-success="uploadSuccess"
          :on-remove="handleFileRemove"
          :limit="10"
          :on-exceed="handleOnExceed">
          <el-button size="mini">添加附件</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="指派给" prop="assignUser" :rules="[
               { required: true, message: '请选择指派人', trigger: 'change'},
              ]">
        <el-select
          v-model="form.assignUser"
          :disabled="form.operationType==dictDataConst.TASK_OPERATION_TYPE.PROCESSED"
          remote
          filterable
          clearable
          placeholder="请选择指派人员"
          style="width: 214px"
          :remote-method="assignUserSearch">
          <el-option
            :label="item.nickName"
            :value="item.id"
            :key="item.id"
            v-for="item in userList"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" style="margin-right:20px;">
      <el-button @click="cancelBtn">取 消</el-button>
      <el-button  v-show="taskInfo.createUser==userInfo.userId"
                  :disabled="userInfo.userId!=form.assignUser || form.operationType!=dictDataConst.TASK_OPERATION_TYPE.PROCESSED"
                  @click="onSubmit(1)">已解决</el-button>
      <el-button type="primary" @click="onSubmit(0)" style="background-color: #F1880D;border-color: #F1880D;">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import { allUserListApi } from '@/modules/crm/api/system/user'
import { addTaskApi, dealtTaskApi, editTaskApi } from '@/modules/crm/api/crm/task'
import { mapGetters } from 'vuex'
import MyEditor from '@/modules/crm/components/Editor'
export default {
  name: "DealTask",
  props: {
    taskDialogVisible: { // 遮罩层开关
      type: Boolean,
      required: false
    },
    taskInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    draggable,
    MyEditor
  },
  watch: {
    taskDialogVisible:{
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal){
          this.reset()
        }
      }
    }
  },
  computed: {

    userInfo: {
      get() {
        return this.$store.state.user
      },
    }
  },
  data() {
    return {
      //任务操作类型
      taskOperationTypeDict: this.getDict(this.dictConst.TASK_OPERATION_TYPE),
      //任务服务类型字典
      taskServeTypeDict: this.getDict(this.dictConst.TASK_SERVE_TYPE),
      //用户列表
      userList: [],
      form: {
      },
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams: {
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.TASK_FILE,
        tempFlag: 1
      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],

    }
  },
  created() {
    this.getAllUser()
    this.reset()
  },
  methods: {
    /** 表单重置 */
    reset() {
      this.resetForm('form')
      this.form = {
        taskId:this.taskInfo.id,
        operationType:undefined,
        serveType:undefined,
        solution:'',
        assignUser:undefined,
        taskFileIdList:[],
        finished:0

      }
      this.fileList=[]
      this.uploadFileList=[]
    },
    cancelBtn() {
      this.taskDialogVisibleFn()
    },

    taskDialogVisibleFn() {
      this.$emit('closeTask', false)
    },
    onSubmit(type) {
      console.log('submit!',type, this.form)
      console.log('fileList!', this.uploadFileList)
      this.$refs['form'].validate(async valid => {
        if (valid) {

          //添加文件id列表
          this.form.taskFileIdList=[]
          this.uploadFileList.forEach(item => {
            this.form.taskFileIdList.push(item.fileId)
          })
          this.form.finished=type
          dealtTaskApi(this.form).then(res=>{
            this.msgSuccess()
            this.cancelBtn()
            this.eventBus.$emit(this.eventConst.task.editSucceed)
          })

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    onEditorInput(val) {
      this.form.solution = val
    },
    handleChange(file, fileList) {
      // this.fileList = fileList.slice(-3);
    },
    //文件超出个数限制时
    handleOnExceed(file, fileList){
     this.msgError("文件个数最大10个")
    },
    async assignUserSearch(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.userList = res.data
      })
    },
    //web按上传前的校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传附件大小不能超过 50MB')
        return false
      }
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {

      if (res.code == 200) {
        this.msgSuccess('上传成功')
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: 'success',
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除文件
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileList.splice(i, 1)
            return true
          }
        })
      }

    },
    //移除文件
    handleFileRemove(file, fileList) {
      console.log('移除文件', file, fileList)
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          this.uploadFileList.splice(i, 1)
          return true
        }
      })

    },
    //校验指派人
    // validateAssignUser(rule, value, callback, index){
    //   //当处理结果不是已完成的时候，必须要选择指派人
    //   if(this.form.operationType!=this.dictDataConst.TASK_OPERATION_TYPE.FINISHED){
    //     callback(new Error('请选择指派人'))
    //   }else{
    //     callback()
    //   }
    // },
    //处理结果选项变化
    operationTypeChange(){
      console.log("operationTypeChange",this.form.operationType,this.dictDataConst.TASK_OPERATION_TYPE.PROCESSED)
      // 当处理结果是已处理的时候，指派给填写任务发出人
      if(this.form.operationType==this.dictDataConst.TASK_OPERATION_TYPE.PROCESSED){
        this.form.assignUser=this.taskInfo.createUser
      }else{
        this.form.assignUser=undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost{
  opacity: 0.5;
  background: #f1f1f1;
}
.taskItem{
  margin-bottom: 10px;
}
.dragBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  i{
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    &:hover{
      background: #f1f1f1;
    }
  }
}
.taskDel{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  i{
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    padding-left: 5px;
    background: #f1f1f1;
    color: #999999;
    font-size: 8px;
    cursor: pointer;
  }
}
::v-deep.el-dialog__footer{
  text-align: center!important;
}
</style>
