<template>
  <div class="newConstructionContract_wrap">
    <div v-if="contractList.length>0 || contractId">
      <el-row v-if="!contractId">
        <el-col :span="24">
          <el-select v-model="contractValue"
                     placeholder="请选择合同编号"
                     style="width: 400px;margin-bottom: 10px;"
                     size="small"
                     @change="contractChange">
            <el-option v-for="item in contractList" :key="item.id" :label="item.contractName" :value="item.id"></el-option>
          </el-select>
          <span class="projectNum">共{{contractListLen?contractListLen:0}}个合同</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">分类</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.contractClassifyName}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">合同性质</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">
            {{contractObject.contractTypeName}}
            <span v-if="contractObject.contractType===getDict(this.dictConst.CONTRACT_TYPE)[1].id">-({{contractObject.serveTypeName}})</span>
          </span>
        </el-col>
      </el-row>
      <el-row
        class="editProductWrap"
        v-if="contractObject.serveType!==getDict(this.dictConst.CONTRACT_SERVE_TYPE)[0].id &&contractObject.contractType===getDict(this.dictConst.CONTRACT_TYPE)[1].id">
        <el-col :span="2">
          <span class="editLabel">服务时间</span>
        </el-col>
        <el-col :span="22">
          <span v-if="contractObject.serveStartTime" class="editInput">{{contractObject.serveStartTime}}至{{contractObject.serveEndTime}}</span>
        </el-col>
      </el-row>
      <el-row
        class="editProductWrap"
        v-if="contractObject.contractType===getDict(this.dictConst.CONTRACT_TYPE)[1].id">
        <el-col :span="2">
          <span class="editLabel">备注</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.remark}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">项目分类</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.projectClassifyName}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">购买方<span style="font-size:12px;">(甲方)</span></span>
        </el-col>
        <el-col :span="22">
        <span class="editInput">
          <span class="orgListItem">{{contractObject.purchaserName}}</span>
        </span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">终端客户</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput" v-for="(item,index) in contractObject.customerList" :key="item.id">
            <span class="orgListItem">{{item.customerName}}</span>
            <span v-if="index<contractObject.customerList.length-1">、</span>
          </span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">乙方</span>
        </el-col>
        <el-col :span="22">
          <span v-if="contractObject.sellerTypeName" class="appreciationPackage">{{contractObject.sellerTypeName}}</span>
          <span class="orgListItem"> {{ contractObject.sellerName }}</span>
          <span v-if="contractObject.distribution" class="appreciationPackage" style="margin: 0px 6px 0px 10px;">分销</span>
          <span v-if="contractObject.distribution" class="orgListItem">{{contractObject.distributionName}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">名称</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.contractName}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">编号</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.contractNum}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">金额</span>
        </el-col>
        <el-col :span="22" v-if="contractObject.contractPrice">
          <span class="editInput" v-if="isTotalMoneyShow">{{  contractObject.contractPrice }}万元</span>
          <span class="editInput" v-else>{{  contractObject.contractPrice | starNumbers}}万元</span>
          <span style="margin-left: 10px;color: #C4C4C4;"  v-if="contractPriceShowPermission">
<!--            睁眼-->
            <i style="cursor: pointer;" v-if="isTotalMoneyShow" class="iconfont" @click="isTotalMoneyShow=false">&#xe67d;</i>
<!--             闭眼-->
            <i style="cursor: pointer;" v-else class="iconfont" @click="isTotalMoneyShow=true">&#xe67c;</i>
          </span>

        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">下单日期</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.orderTime}}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">维护期限</span>
        </el-col>
        <el-col :span="22">
          <span class="editInput">{{contractObject.maintenanceDeadline}}年</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="2">
          <span class="editLabel">附件</span>
        </el-col>
        <el-col :span="22">
          <div class="attachment"
               v-for="(fileItem, index) in getList(contractObject,'contractFileList')"
               :key="index">
            <i class="iconfont">&#xe671;</i>
            <span class="editInput" style="margin-left: 4px;cursor: pointer;" @click="onPreviewFile(fileItem)">{{ fileItem.fileName }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="editProductWrap_contact">
        <el-col :span="2">
          <span class="editLabel">产品</span>
        </el-col>
        <el-col :span="22">
          <div class="productWrap02" v-for="item in contractObject.productList" :key="item.id">
            <div class="productItem">
              <div class="relatedProductName">{{item.productName}}</div>
              <el-row>
                <el-col :span="24" class="productTitle">
                  <span class="productTitleName">增值包</span>
<!--                  <span class="appreciationPackage appreciationPackageName" v-if="item.extrasList.length>0">{{item.extrasList[0].extrasName}}</span>-->
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    v-if="item.extrasList&&item.extrasList.length>0"
                    :content="item.extrasList[0].extrasName">
                    <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                  </el-popover>
                  <el-popover
                    popper-class="projectUl_popover"
                    placement="bottom"
                    width="220"
                    trigger="click">
                    <div
                      v-for="i in item.extrasList.slice(1)"
                      style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                      {{ i.extrasName }}
                    </div>
                    <span style="cursor: pointer"
                          slot="reference"
                          class="appreciationPackage"
                          v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                  </el-popover>
                </el-col>
                <!--              <el-col :span="16" class="productSelect">-->
                <!--                -->
                <!--              </el-col>-->
              </el-row>
              <el-row>
                <el-col :span="24" class="productTitle">
                  <span class="productTitleName">产品金额</span>
<!--                  <span class="productAmountNum">{{item.productAmount?item.productAmount:0}}万元</span>-->
                  <span v-if="item.productAmount">
                    <span class="productAmountNum" v-if="item.isTotalMoneyShow">{{ item.productAmount }}万元</span>
                    <span class="productAmountNum" v-else>{{ item.productAmount | starNumbers}}万元</span>
                    <span style="margin-left: 10px;color: #C4C4C4;" v-if="contractPriceShowPermission">
                      <i style="cursor: pointer;" v-if="item.isTotalMoneyShow" class="iconfont" @click="()=>{
                        $forceUpdate();
                        item.isTotalMoneyShow=!item.isTotalMoneyShow
                      }">&#xe67d;</i>
                      <i style="cursor: pointer;" v-else class="iconfont" @click="()=>{
                        $forceUpdate();
                        item.isTotalMoneyShow=!item.isTotalMoneyShow
                      }">&#xe67c;</i>
                    </span>
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="noDataWrap">
      <div class="noData">
        <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
        <div class="noDataText">暂无数据</div>
      </div>
    </div>
    <preview-file
      :is-show-dialog.sync="showPreviewFile"
      :file-id="previewFileId"></preview-file>
  </div>
</template>

<script>
import {
  contractListApi
}  from '@/modules/crm/api/crm/org'
import {
  contractInfo
} from '@/modules/crm/api/crm/contract'
import PreviewFile from '@/modules/crm/view/crm/task/components/previewFile'
export default {
  name: "EditContract",
  props: {
    institutionId: {
      type: Number
    },
    contractId: {
      type: Number
    }
  },
  components: {
    PreviewFile
  },
  data() {
    return {
      contractListLen: null, // 合同个数
      contractList: [], // 合同列表
      contractObject: {}, // 单个合同信息
      contractValue: '',
      isTotalMoneyShow: false,
      form: {},
      //文件预览id
      previewFileId:0,
      showPreviewFile: false
    }
  },
  computed: {
    contractPriceShowPermission() {
      return this.hasPermissions(this.permissionsConst.crmContract.contractPriceShow)
    }
  },
  filters: {
    starNumbers(val) {
      const numberOfStars = val? val.toString().length : 0;
      return '*'.repeat(numberOfStars);
    }
  },
  created() {
    if(this.institutionId) {
      this.orgInfoApiFn(this.institutionId)
    }
    console.log(this.contractId)
    if (this.contractId) {
      this.contractInfoFn(this.contractId)
    }
  },
  methods: {
    // 机构关联合同列表
    async orgInfoApiFn(val) {
      let obj = {}
      obj.orgId = val
      let res = await contractListApi(obj)
      console.log(res)
      if(res.code === 200) {
        this.contractList = res.data
        this.contractListLen = res.data.length
        if (this.contractList&&this.contractListLen>0) {
          this.contractValue = this.contractList[0].id // 默认选中第一个
          this.contractChange(this.contractValue)
          this.$emit('contractListLen',true)
        } else {
          this.$emit('contractListLen',false)
        }
      }
    },
    contractChange(val) {
      console.log(val)
      this.$emit('getContractId',val)
      if (val) {
        this.contractInfoFn(val)
      } else {
        this.contractObject = {}
      }
    },
    // 获取合同详细信息
    async contractInfoFn(val) {
      let res = await contractInfo(val)
      console.log(res)
      if(res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.contractObject = res.data
        // // 客户list
        // let khArr = [this.contractInfoList.purchaser]
        // if(this.contractInfoList.customerList.length>0) {
        //   this.contractInfoList.customerList.forEach((item) => {
        //     khArr.push(item.customerId)
        //   })
        // }
        // let khArray = Array.from(new Set(khArr)); // 将Set转换为数组并赋值给khArray
        // console.log(khArray)
        // this.pclArr = await this.searchContactsListFn(khArray)
        // console.log(this.pclArr)
        // // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
        // if (this.contractInfoList.sellerType === this.getDict(this.dictConst.CONTRACT_SELLER_TYPE)[0].id) { // 自营
        //   await this.getAllUser()
        // } else {
        //   let sclArray = [this.contractInfoList.seller]
        //   this.sclArr = await this.searchContactsListFn(sclArray)
        // }

      }
    },
    onPreviewFile(fileItem) {
      this.previewFileId = fileItem.fileId
      this.showPreviewFile = true
    }
  }
}
</script>

<style lang="scss"  scoped>
.newConstructionContract_wrap{
  height: 100%;
  padding: 5px 0 20px;
  .attachment {
    margin-top: 4px;
    padding: 2px 4px;

    .editInput, i {
      color: #686EC5;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .projectNum{
    font-size: 15px;
    font-weight: 500;
    color: #5D5D5D;
    margin-left: 20px;
  }
  .noDataWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .noData{
      width: 96px;
      height:102px;
      text-align: center;
      .noDataText{
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 16px;
      }
    }
  }
  .productWrap{
    .productItem{
      float: left;
      width: 260px;
      padding:6px 10px;
      height: 76px;
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }
  }
}
.editProductWrap{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
}
.editProductWrap_contact{
  margin-bottom: 20px;
}
.editLabel{
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.editInput{
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.orgListItem{
  color: #F1880D;
  cursor: pointer;
  font-size: 14px;
}
.productWrap01{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 223px;
  padding:6px 10px;
  display: flex;
  .productItem{
    float: left;
    width: 203px;
    el-row{
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .contactName{
      color:#5D5D5D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .productTitle{
      height: 20px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 20px;
    }
    .productSelect{
      //height: 20px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;
      .remarkStyle{
        width: 100%;
        display: inline-block;
        white-space: nowrap; /* 不换行 */
        overflow: hidden;    /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        cursor: pointer;
      }
    }
  }
}
.appreciationPackage{
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #DEDEDE;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 18px;
  height: 22px;
  margin-right: 4px;
  margin-top: 3px;
  &:last-child{
    margin-right: 0;
  }
}
.sellerName{
  padding: 2px 4px;
  align-items: center;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 18px;
  height: 22px;
  margin-left: 4px;
  margin-top: 3px;
}
.appreciationPackageName{
  display: inline-block;
  //margin-left:10px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;    /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.productWrap02{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 240px;
  padding:6px 10px;
  display: flex;
  float: left;
  .productItem{
    float: left;
    width: 230px;

    .relatedProductName{
      //height: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #5D5D5D;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    .productTitle{
      height: 28px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 28px;
      display: flex;
      justify-content: flex-start;
      .productTitleName{
        width: 65px;
        display: inline-block;
      }
      .productAmountNum{
        font-size: 14px;
        color: #5D5D5D;
      }
    }
    .productSelect{
      height: 28px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 28px;
    }
  }
  .projectUl{
    background: #EAECEF;
    border-radius: 4px;
    width:227px;
    overflow: hidden;
    li{
      float: left;
      width: 18px;
      height: 8px;
      background: #F1880D;
      margin-right: 1px;
      &:last-child{
        margin-right: 0px;
      }
    }
  }
}
</style>

