<template>
  <div class="newConstructionContract_wrap">
    <el-row v-if="!isNewConstruction&&contractList.length>0">
      <el-col :span="24">
        <el-select v-model="contractValue"
                   placeholder="请选择合同编号"
                   style="width: 400px;margin-bottom: 10px;"
                   size="mini"
                   :disabled="!isNewConstruction"
                   @change="contractChange">
          <el-option v-for="item in contractList" :key="item.id" :label="item.contractName" :value="item.id"></el-option>
        </el-select>
        <span class="projectNum">共{{contractListLen?contractListLen:0}}个合同</span>
      </el-col>
    </el-row>
    <div v-if="!isNewConstruction&&contractId==null&&!contractValue"  class="noDataWrap">
      <div class="noData">
        <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
        <div class="noDataText">暂无数据</div>
      </div>
    </div>
    <div v-else class="content-wrap">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="left" class="form-el">
        <el-form-item label="分类" prop="contractClassify">
        <span
          v-for="(item,index) in contractClassify"
          :key="item.id"
          class="normal_btn"
          :class="{ select_btn: item.id == form.contractClassify }"
          @click="contractClassifySelect(item.id)">
          {{ item.dictLabel }}
        </span>
        </el-form-item>
        <el-form-item label="合同性质" prop="contractType">
        <span
          v-for="(item,index) in contractTypeDict"
          :key="item.id"
          class="normal_btn"
          :class="{ select_btn: item.id == form.contractType, 'select_stop': isCanEdit,}"
          @click="contractTypeSelect(item.id)">
          {{ item.dictLabel }}
        </span>
          <el-radio-group v-if="form.contractType==this.getDict(this.dictConst.CONTRACT_TYPE)[1].id" v-model="form.serveType">
            <el-radio
              v-for="item in serveTypeDict"
              :key="item.id"
              :disabled="isCanEdit"
              :label="item.id">{{item.dictLabel}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务时间" prop="serveTime" v-if="form.contractType==this.getDict(this.dictConst.CONTRACT_TYPE)[1].id&&form.serveType==this.getDict(this.dictConst.CONTRACT_SERVE_TYPE)[1].id">
          <el-date-picker
            v-model="form.serveTime"
            type="daterange"
            size="mini"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @blur="timeChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" v-if="form.contractType==this.getDict(this.dictConst.CONTRACT_TYPE)[1].id">
          <el-input type="textarea"v
                    v-model.trim="form.remark"
                    :rows="4"
                    size="mini"
                    maxlength="500"
                    clearable
                    show-word-limit
                    placeholder="请输入备注内容"></el-input>
        </el-form-item>
        <el-form-item label="项目分类" prop="projectClassify">
        <span
          v-for="(item,index) in projectClassify"
          :key="item.id"
          class="normal_btn"
          :class="{ select_btn: item.id == form.projectClassify }"
          @click="projectClassifySelect(item.id)">
          {{ item.dictLabel }}
        </span>
        </el-form-item>
        <el-form-item label="购买方(甲方)" prop="purchaser" class="labelSet">
          <el-select
            v-model="form.purchaser"
            filterable
            remote
            size="mini"
            reserve-keyword
            placeholder="请选择购买方(甲方)"
            :remote-method="remoteMethod"
            style="width: 372px;"
            clearable
            :disabled="isCanEdit"
            :loading="loading">
            <el-option
              v-for="item in purchaserDict"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId">
            </el-option>
          </el-select>
          <!--        <el-button class="creatInstitution" type="text">新建</el-button>-->
        </el-form-item>
        <el-form-item label="终端客户" prop="customerIdList">
          <el-select
            v-model="form.customerIdList"
            multiple
            filterable
            remote
            size="mini"
            reserve-keyword
            placeholder="请选择终端客户"
            :remote-method="customerIdListRemoteMethod"
            @change="$forceUpdate()"
            :disabled="isCanEdit"
            style="width: 800px;"
            :loading="loading">
            <el-option
              v-for="item in customerIdListDict"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId">
            </el-option>
          </el-select>
          <!--        <el-button class="creatInstitution" type="text">新建</el-button>-->
        </el-form-item>
        <el-form-item label="乙方" required>
          <el-col :span="5">
            <el-form-item prop="sellerType">
              <el-radio-group v-model="form.sellerType">
                <el-radio
                  v-for="(item,index) in sellerTypeDict"
                  :key="item.id"
                  :label="item.id"
                  :disabled="isCanEdit">
                  {{ item.dictLabel }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="19" v-if="form.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
            <el-col :span="8">
              <el-form-item prop="seller">
                <el-select
                  ref="elementSelect"
                  v-model="form.seller"
                  filterable
                  remote
                  size="mini"
                  reserve-keyword
                  placeholder="请选择经销商"
                  :remote-method="sellerRemoteMethod"
                  style="width: 216px;"
                  :disabled="isCanEdit"
                  clearable
                  :loading="loading"
                  @input.native="trimInput">
                  <el-option
                    v-for="item in sellerDict"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgId">
                  </el-option>
                </el-select>
                <!--          <el-button v-if="form.seller==1" class="creatInstitution" type="text">新建</el-button>-->
              </el-form-item>
            </el-col>
            <el-col :span="2" style="text-align:right;margin-right: 3px;">分销</el-col>
            <el-col :span="10">
              <el-form-item prop="distribution">
                <el-select
                  ref="elementDistributionSelect"
                  v-model="form.distribution"
                  filterable
                  remote
                  size="mini"
                  reserve-keyword
                  placeholder="请选择分销商"
                  :remote-method="distributionRemoteMethod"
                  style="width: 216px;"
                  :disabled="isCanEdit"
                  clearable
                  :loading="loading"
                  @input.native="trimDistributionInput">
                  <el-option
                    v-for="item in distributionDict"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
        </el-form-item>
        <el-row>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="名称" prop="contractName">-->
<!--              <el-input-->
<!--                class="setInput"-->
<!--                v-model.trim="form.contractName"-->
<!--                style="width: 98%;"-->
<!--                size="mini"-->
<!--                maxlength="100"-->
<!--                clearable-->
<!--                show-word-limit></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="编号" prop="contractNum">
              <el-input
                class="setInput"
                v-model.trim="form.contractNum"
                :disabled="isCanEdit"
                style="width: 100%;"
                size="mini"
                maxlength="100"
                clearable
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="金额" prop="contractPrice">
              <span v-if="form.contractPrice>=0">{{form.contractPrice}}万元</span>
<!--              | cpExchange-->
<!--              <el-input v-model.trim="form.contractPrice"-->
<!--                        disabled="disabled"-->
<!--                        maxlength="15"-->
<!--                        style="width: 98%;"-->
<!--                        @input="limitInput($event,'contractPrice')" ></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="下单时间" prop="orderTime">
              <el-date-picker
                v-model="form.orderTime"
                value-format="yyyy-MM-dd"
                clearable
                size="mini"
                type="date"
                placeholder="选择日期"
                style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="维护期限" prop="maintenanceDeadline">
              <el-input v-model.trim="form.maintenanceDeadline"
                        maxlength="5"
                        size="mini"
                        style="width: 98%;"
                        @input="handleOninput($event,'maintenanceDeadline')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span style="line-height: 36px;color: #606266;font-size: 14px;">年</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" prop="taskFileIdList">
              <el-upload
                class="upload-demo"
                :action="baseURL+'/crm/sys/file/uploadFile'"
                :on-change="handleFileChange"
                :file-list="fileList"
                :data="uploadFileParams"
                :auto-upload="true"
                :before-upload="beforeUpload"
                accept=".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx"
                :on-success="uploadSuccess"
                :on-remove="handleFileRemove"
              >
                <el-button size="mini">添加附件</el-button>
                <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="产品" required style="border-bottom: 1px dotted #F0F0F0;">
          <div style="text-align: right;">
            <!--          <el-button class="creatInstitution" type="text">新建</el-button>-->
            <el-button size="mini" @click="addProduct">添加</el-button>
          </div>
        </el-form-item>

        <div class="productWrap">
          <div class="productItem" v-for="(productItem, productIndex) in form.productList" :key="productIndex">
            <el-row style="height: 100%;">
              <el-col :span="21" class="productItem_left">
                <el-row style="margin-bottom: 4px;">
                  <el-col :span="6" class="productTitle">产品</el-col>
                  <el-col :span="18" class="productSelect">
                    <el-form-item
                      :prop="`productList[${productIndex}].productSingle`"
                      :rules=" [
                  { required: true, message: '请选择产品', trigger: 'change' }
                ]"
                      :show-message="false"
                      class="contract_product"
                      label-width="40">
                      <el-cascader
                        :ref="`productRef_${productIndex}`"
                        v-model="productItem.productSingle"
                        :props="defaultParams"
                        :options="productTreeDict"
                        size="mini"
                        placeholder=""
                        :disabled="isCanEdit&&productIndex+1<=productListLength"
                        style="width:100%;"
                        :show-all-levels="false"
                        @change="handleChange($event,productIndex)"
                        @visible-change="visibleHandler($event, productIndex)">
                      </el-cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row style="margin-bottom: 4px;">
                  <el-col :span="6" class="productTitle">增值包</el-col>
                  <el-col :span="18" class="productSelect">
                    <el-select
                      :disabled="productItem.productSingle.length==0||(isCanEdit&&productIndex+1<=productListLength)"
                      v-model="productItem.extrasIdList"
                      size="mini"
                      placeholder=""
                      multiple
                      collapse-tags
                      clearable
                      style="width:100%;"
                      @change="$forceUpdate()">
                      <el-option
                        v-for="item in productItem.zzbArr"
                        :key="item.extrasId"
                        :label="item.extrasName"
                        :value="item.extrasId">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">产品金额</el-col>
                  <el-col :span="18" class="productSelect">
                    <el-form-item
                      :prop="`productList[${productIndex}].productAmount`"
                      :rules=" [
                  { required: true, message: '请输入产品金额', trigger: 'blur' },
                  { pattern: /^\d{1,4}(\.\d{1,6})?$/, message: '请输入正确的格式,小于10000且最多可保留六位小数' }
                ]"
                      :show-message="false"
                      class="contract_product"
                      label-width="40">
                      <el-input
                        :disabled="productItem.productSingle.length==0||(isCanEdit&&productIndex+1<=productListLength)"
                        v-model.trim="productItem.productAmount"
                        maxlength="15"
                        style="width:80%;"
                        size="mini"
                        clearable
                        @input="productAmountInput($event,productIndex)"
                      ></el-input>
                      <span style="font-size: 14px;color: #5D5D5D;margin-left: 4px;">万元</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col v-if="(!isCanEdit&&form.productList.length>1)||(isCanEdit&&productIndex+1>productListLength)" :span="2" class="productClose"><i class="iconfont" @click="delProductItem(productItem, productIndex)">&#xe672;</i></el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  searchOrgList,
  contractListApi
}  from '@/modules/crm/api/crm/org'
import {
  productTree,
  addContractApi,
  contractInfo,
  editContractApi
} from '@/modules/crm/api/crm/contract'
import {
  ITEM_OBJ
} from '@/modules/crm/utils/org'
export default {
  name: "NewConstructionContract" ,
  props: {
    isNewConstruction: { // 遮罩层开关
      type: Boolean,
      required: true
    },
    institutionId: {
      type: Number
    },
    contractId: {
      type: Number
    },
    formObj: {
      type: Object
    },
    currentContractId: {
      type: Number
    },
    isCanEdit: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    cpExchange(num) {
      console.log(num)
      const numStr = num.toString();
      let numLen = 0
      // 查找小数点
      const decimalIndex = numStr.indexOf('.');
      console.log(decimalIndex)
      // 如果没有找到小数点，则返回0
      if (decimalIndex === -1) {
        numLen = 0
      } else {
        // 返回小数点后的字符数量
        numLen = numStr.length - decimalIndex - 1;
      }
      console.log(numLen)
      if (numLen === 0) {
        return num
      } else if (numLen === 1) {
         return num.toFixed(1)
      } else if (numLen === 2) {
        return num.toFixed(2)
      } else if (numLen === 3) {
        return num.toFixed(3)
      } else if (numLen === 4) {
        return num.toFixed(4)
      } else if (numLen === 5) {
        return num.toFixed(5)
      } else if (numLen >= 6) {
        return num.toFixed(6)
      }
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams:{
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.TASK_FILE,
        tempFlag: 1
      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],
      contractListLen: null, // 合同个数
      contractList: [], // 合同列表
      contractObject: {}, // 单个合同信息
      contractValue: '',
      contractClassifyList: [
        '内任单',
        '订单',
        '合同'
      ],
      contractTypeList: [
        '销售合同',
        '服务合同'
      ],
      projectClassifyList: [
        '机构项目',
        '区域项目'
      ],
      // 合同分类字典
      contractClassify: this.getDict(this.dictConst.CONTRACT_CLASSIFY),
      // 合同类型字典
      contractTypeDict: this.getDict(this.dictConst.CONTRACT_TYPE),
      // 项目分类字典
      projectClassify: this.getDict(this.dictConst.CONTRACT_PROJECT_CLASSIFY),
      // 乙方类型字典
      sellerTypeDict: this.getDict(this.dictConst.CONTRACT_SELLER_TYPE),
      purchaserDict: [], // 甲方
      customerIdListDict: [], // 终端客户
      sellerDict: [], // 乙方
      distributionDict: [], // 分销
      // 服务类型字典
      serveTypeDict: this.getDict(this.dictConst.CONTRACT_SERVE_TYPE),
      loading: false,
      defaultParams: {
        label: "productName",
        value: "productId",
        children: 'childrenList'
      },
      productTreeDict: [],
      zzbArr: [], // 增值包
      form: this.$deepClone(ITEM_OBJ),
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        serveTime: [
          { type: 'array', required: true, message: '请选择服务时间', trigger: 'change' }
        ],
        orderTime: [
          { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        purchaser: [
          { required: true, message: '请选择购买方(甲方)', trigger: 'change' }
        ],
        customerIdList:  [
          { required: true, message: '请选择终端客户', trigger: 'change' }
        ],
        sellerType: [
          { required: true, message: '请选择乙方类型', trigger: 'change' }
        ],
        // contractName: [
        //   { required: true, message: '请填写合同名称', trigger: 'blur' },
        //   { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        // ],
        contractNum: [
          { required: true, message: '请填写合同编号', trigger: 'blur' },
          { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],
        seller: [
          { required: true, message: '请选择经销商', trigger: 'change' }
        ],
        maintenanceDeadline:[
          { required: true, message: '请填写维护期限', trigger: 'blur' },
          { pattern: /^\d{1,2}(\.\d)?$/, message: '请输入正确的格式,小于100且最多可保留一位小数' }
        ]
        // contractPrice: [
          // { required: true, message: '请填写合同金额', trigger: 'blur' },
          // { pattern: /^\d{1,4}(\.\d\d\d\d\d\d)?$/, message: '请输入正确的格式,小于10000且最多可保留六位小数' }
        // ]
      },
      productListLength: 0// 合同对应原始产品数量
    }
  },
  async created() {
    this.reset()
    await this.getProductTreeFn()
    if(this.institutionId) {
      await this.orgInfoApiFn(this.institutionId)
    }
    if(this.contractId) {
      await this.contractInfoFn(this.contractId)
    }
  },
  methods: {
    handleFileChange(file, fileList) {
      // this.fileList = fileList.slice(-3)
    },
    //we你按上传前的校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传附件大小不能超过 50MB');
        return false;
      }
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {

      if (res.code == 200) {
        this.msgSuccess("上传成功")
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: "success",
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除文件
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileList.splice(i, 1)
            return true
          }
        })
      }

    },
    //移除文件
    handleFileRemove(file, fileList) {
      console.log("移除文件",file, fileList)
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          this.uploadFileList.splice(i, 1)
          return true
        }
      })

    },
    // 输入金额计算总金额
    productAmountInput(value,index) {
      //console.log(value,index)
      this.form.productList[index].productAmount = value.replace(/[^\-\d.]/g, '').replace(/^(\-)*(\d+)\.(\d\d\d\d\d\d).*$/, '$1$2.$3')
      this.form.contractPrice = 0
      let amountLenArr = this.form.productList.map(item => String(item.productAmount).split('.')[1] ? String(item.productAmount).split('.')[1].length : 0)  //取每个金额的小数位数的长度，没有小数位数就返回0
      let maxLen = Math.max(...amountLenArr) // 拿到小数位数的最大值
      let contractPrice = this.form.productList.reduce((sum, item) => {  //求和
        return parseFloat(item.productAmount)?sum + parseFloat(item.productAmount):sum
      }, 0)
      this.form.contractPrice = contractPrice.toFixed(maxLen)
    },
    //正则验证
    handleOninput(value, name) {
      this.form[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, "")
          .replace(/^0+(\d)/, "$1")
          .replace(/^\./, "0.")
          .match(/^\d*(\.?\d{0,1})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到1位小数
    },
    limitInput(value, name) {
      this.form[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    timeChange(val) {
      //console.log(val)
      this.$forceUpdate();
      // if(val=== null) {
      //   this.form.serveTime = []
      // }else {
      //   this.form.serveTime = val
      // }
      // console.log(this.form.serveTime)
      // this.$forceUpdate()
    },
    // 获取级联选择器对应产品
    search(object, value) {
      for (var key in object) {
        if (object[key].productId == value) return [object[key].productId];
        if(object[key].childrenList && Object.keys(object[key].childrenList).length > 0){
          var temp = this.search(object[key].childrenList, value);
          if (temp) return [object[key].productId, temp].flat();
        }
      }
    },
    // 获取对应的增值包
    searchZzb(object, value) {
      for (var key in object) {
        if (object[key].productId == value) return [object[key].extrasList];
        if(object[key].childrenList && Object.keys(object[key].childrenList).length > 0){
          var temp = this.searchZzb(object[key].childrenList, value);
          if (temp) return [object[key].extrasList, temp].flat();
        }
      }
    },
    // 机构关联合同列表
    async orgInfoApiFn(val) {
      let obj = {}
      obj.orgId = val
      let res = await contractListApi(obj)
      //console.log(res)
      if(res.code === 200) {
        this.contractList = res.data
        this.contractListLen = res.data.length
        //console.log(this.currentContractId)
        if (this.currentContractId) {
          this.contractValue = this.currentContractId
        } else {
          if (this.contractListLen>0) {
            this.contractValue = this.contractList[0].id // 默认选中第一个
          }
        }
        this.contractChange(this.contractValue)
      }
    },
    contractChange(val) {
      //console.log(val)
      if (val) {
        this.contractInfoFn(val)
      } else {
        this.contractObject = {}
      }
    },
    // 获取合同详细信息
    async contractInfoFn(val) {
      let res = await contractInfo(val)
      //console.log(res)
      if(res.code === 200) {
        this.loading = false
        //console.log(res.data)
        this.form=this.$deepClone(res.data)
        Object.assign(this.form,res.data)
        this.productListLength = res.data.productList.length?res.data.productList.length:0
        // this.form = res.data
        this.form.productList?.forEach((item,index)=> {
          item.productSingle = this.search(this.productTreeDict,item.productId)
          // console.log(this.search(this.productTreeDict,item.productId))
          // console.log(this.searchZzb(this.productTreeDict,item.productId))
          let zzbArr = this.filteredArray(this.searchZzb(this.productTreeDict,item.productId))
          //console.log(zzbArr)
          item.zzbArr = zzbArr
          item.extrasIdList = []
          item.extrasList.forEach((i)=> {
            item.extrasIdList.push(i.extrasId)
          })
        })
        if(this.form.serveStartTime&&this.form.serveEndTime){
          this.form.serveTime = [this.form.serveStartTime, this.form.serveEndTime]
        }
        this.purchaserDict = [
          {
            orgId: this.form.purchaser,
            orgName: this.form.purchaserName
          }
        ]
        this.customerIdListDict= []
        this.form.customerIdList = []
        this.form.customerList.forEach(item=>{
          let obj = {
            orgId: item.customerId,
            orgName: item.customerName
          }
          this.customerIdListDict.push(obj)
          this.form.customerIdList.push(item.customerId)
        })
        if (this.form.sellerName) {
          this.sellerDict = [
            {
              orgId: this.form.seller,
              orgName: this.form.sellerName
            }
          ]
        }
        if (this.form.distribution) {
          this.distributionDict = [
            {
              orgId: this.form.distribution,
              orgName: this.form.distributionName
            }
          ]
        }
        this.fileList = []
        res.data.contractFileList?.forEach(item => {
          let fileItem = {
            fileId:  item.fileId,
            name:item.fileName,
            status: 'success',
            uid: item.fileId
          }
          this.fileList.push(fileItem)
          this.uploadFileList.push(fileItem)
        })
      }
    },
    // 删除产品item
    delProductItem(item, index) {
      //console.log(item,index)
      this.delHandler(index)
      this.form.productList.splice(index, 1)
      this.form.contractPrice = 0
      let amountLenArr = this.form.productList.map(item => String(item.productAmount).split('.')[1] ? String(item.productAmount).split('.')[1].length : 0)  //取每个金额的小数位数的长度，没有小数位数就返回0
      let maxLen = Math.max(...amountLenArr) // 拿到小数位数的最大值
      let contractPrice = this.form.productList.reduce((sum, item) => {  //求和
        return parseFloat(item.productAmount)?sum + parseFloat(item.productAmount):sum
      }, 0)
      this.form.contractPrice = contractPrice.toFixed(maxLen)
    },
    filteredArray(arr) {
      return arr.filter(item => item !== null); // 通过filter函数过滤掉null元素
    },
    remoteMethod(query) {
      //console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 2
          obj.searchValue = query
          let res = await searchOrgList(obj)
          //console.log(res)
          if(res.code === 200) {
            this.loading = false
            //console.log(res.data)
            this.purchaserDict = res.data
          }
        }, 200);
      } else {
        this.purchaserDict = [];
      }
    },
    customerIdListRemoteMethod(query) {
      //console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 2
          obj.searchValue = query
          let res = await searchOrgList(obj)
          //console.log(res)
          if(res.code === 200) {
            this.loading = false
            //console.log(res.data)
            this.customerIdListDict = res.data
          }
        }, 200);
      } else {
        this.customerIdListDict = [];
      }
    },
    trimDistributionInput() {
      this.$refs.elementDistributionSelect.$data.selectedLabel =
        this.$refs.elementDistributionSelect.$data.selectedLabel.trim()
    },
    trimInput() {
      this.$refs.elementSelect.$data.selectedLabel =
        this.$refs.elementSelect.$data.selectedLabel.trim()
    },
    sellerRemoteMethod(query) {
      //console.log(query)
      if (query !== ''&&query !== ' ') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 3
          obj.searchValue = query
          let res = await searchOrgList(obj)
          //console.log(res)
          if(res.code === 200) {
            this.loading = false
            //console.log(res.data)
            this.sellerDict = res.data
          }
        }, 200);
      } else {
        this.sellerDict = [];
      }
    },
    // 分销商
    distributionRemoteMethod(query) {
      //console.log(query)
      if (query !== ''&&query !== ' ') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 4
          obj.searchValue = query
          let res = await searchOrgList(obj)
          //console.log(res)
          if(res.code === 200) {
            this.loading = false
            //console.log(res.data)
            this.distributionDict = res.data
          }
        }, 200);
      } else {
        this.distributionDict = [];
      }
    },
    // 产品字典树形结构
    async getProductTreeFn() {
      let res = await productTree()
      //console.log(res)
      if(res.code === 200) {
        // this.productTreeDict = res.data
        this.productTreeDict = this.$deepClone(res.data)
        this.productTreeDict.forEach((item,index)=> {
          item.productId = item.productId *1000
          // if(item.childrenList && item.childrenList.length>0) {
          //   item.childrenList.forEach((j,jIndex)=> {
          //     j.productId = j.productId * (index+1)
          //   })
          // }
        })
      }
    },
    //过滤产品字典，设置已选过产品不能再选
    filterProductTreeDict(productIndex){
      let nowProduct=this.form.productList[productIndex]
      let selectedProduct=[]
      //所有已选择的产品id
      this.form.productList.forEach(productItem=>{
        selectedProduct.push(productItem.productId)
      })
      this.filterProductTreeDictItem(nowProduct.productId,selectedProduct,this.productTreeDict)
    },
    filterProductTreeDictItem(nowProductId,selectedProduct,sourceItem){
      if(Array.isArray(sourceItem)){
        sourceItem.forEach(item=>{
          this.filterProductTreeDictItem(nowProductId,selectedProduct,item)
        })
      }else if(!this.isEmpty(sourceItem.childrenList)){
        this.filterProductTreeDictItem(nowProductId,selectedProduct,sourceItem.childrenList)
      }else{
        if(sourceItem.productId==nowProductId){
          this.$set(sourceItem,"disabled",false)
        }else if(selectedProduct.includes(sourceItem.productId)){
          this.$set(sourceItem,"disabled",true)
        }else{
          this.$set(sourceItem,"disabled",false)
        }
      }



    },
    visibleHandler (val, index) { // val是 true 或 false
      if (val){
        this.filterProductTreeDict(index)
      }
    },
    // 删除
    delHandler(index) {
      const _this = this
      // _this.form.productList.splice(index, 1)
      const nodesInfo = _this.$refs['productRef_'+ index][0].getCheckedNodes()
      nodesInfo.forEach((item) => {
        _this.$set(item.data, 'disabled', false)
        if (item.parent) { // 如果是市，那么对应的省也要变为可选
          _this.$set(item.parent.data, 'disabled', false)
        }
      })
    },
    // 获取选择产品
    handleChange(value,index) {
      console.log(value,index);
      // this.delHandler(index)
      //console.log(this.$refs['productRef_'+ index][0])
      let nodesInfo = this.$refs['productRef_'+ index][0].getCheckedNodes()
      //console.log(nodesInfo)
      //console.log(this.form.productList[index].productSingle)
      this.$set(this.form.productList,index,{
        productId: '',
        productSingle: value,
        extrasIdList: undefined,
        productAmount: null
      })
      // console.log(nodesInfo[0].parent.parent.data.extrasList)
      if (value.length === 3) {
        this.form.productList[index].zzbArr = nodesInfo[0].parent.parent.data.extrasList
        this.form.productList[index].productId = this.form.productList[index].productSingle[2]
      } else if (value.length === 2) {
        this.form.productList[index].zzbArr = nodesInfo[0].parent.data.extrasList
        this.form.productList[index].productId = this.form.productList[index].productSingle[1]
      } else if (value.length === 1) {
        this.form.productList[index].zzbArr = nodesInfo[0].data.extrasList
        this.form.productList[index].productId = this.form.productList[index].productSingle[0]
      } else {
        this.form.productList[index].zzbArr = []
        this.form.productList[index].productId = ''
      }
      // 切换产品增值包清空
      // this.form.productList[index].extrasIdList = []
      // this.form.productList[index].productAmount = undefined
      this.$forceUpdate()
      //console.log(this.form.productList)
      this.form.contractPrice = 0
      let amountLenArr = this.form.productList.map(item => String(item.productAmount).split('.')[1] ? String(item.productAmount).split('.')[1].length : 0)  //取每个金额的小数位数的长度，没有小数位数就返回0
      let maxLen = Math.max(...amountLenArr) // 拿到小数位数的最大值
      let contractPrice = this.form.productList.reduce((sum, item) => {  //求和
        return parseFloat(item.productAmount)?sum + parseFloat(item.productAmount):sum
      }, 0)
      this.form.contractPrice = contractPrice.toFixed(maxLen)
    },
    /** 表单重置 */
    reset() {
      this.form = {
        serveTime: [],
        contractClassify: this.getDictPrimaryItemId(this.dictConst.CONTRACT_CLASSIFY), // 合同分类
        contractType: this.getDictPrimaryItemId(this.dictConst.CONTRACT_TYPE), // 合同类型
        serveType: this.getDictPrimaryItemId(this.dictConst.CONTRACT_SERVE_TYPE),
        remark: '',
        projectClassify: this.getDictPrimaryItemId(this.dictConst.CONTRACT_PROJECT_CLASSIFY), // 项目分类
        purchaser: '',
        customerIdList: [],
        seller: '',
        // sellerName: '',
        orderTime: '',
        contractNum: '',
        // contractName: '',
        contractPrice: 0,
        sellerType: this.getDictPrimaryItemId(this.dictConst.CONTRACT_SELLER_TYPE), // 乙方分类
        serveStartTime: '',
        serveEndTime: '',
        distribution: '',
        contractFileList: [],
        productList: [{
          productId: '',
          productSingle: [],
          extrasIdList: undefined,
          productAmount: undefined
        }]
      }
      this.fileList = []
      this.resetForm('form')

    },
    // 编辑重置
    async editReset() {
      // this.reset()
      await this.getProductTreeFn()
      if(this.institutionId) {
        await this.orgInfoApiFn(this.institutionId)
      }
      //console.log(this.contractId)
      if (this.contractId) {
        await this.contractInfoFn(this.contractId)
      }
    },
    onSubmit() {
      console.log(this.uploadFileList)
      let contractFileList = []
      if(this.uploadFileList&&this.uploadFileList.length>0) {
        this.uploadFileList.forEach((item)=> {
          contractFileList.push(item.fileId)
        })
      }
      this.form.contractFileList = contractFileList
      //console.log('submit!', this.form)
      this.form.serveStartTime = this.form.serveTime[0]
      this.form.serveEndTime = this.form.serveTime[1]
      this.form.productList.forEach((item)=>{
        item.productAmount?parseFloat(item.productAmount):0
      })
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          flag = true
          let res = await addContractApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
            this.eventBus.$emit(this.eventConst.contract.addSucceed)
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      // return flag
    },
    editContract() {
      console.log(this.uploadFileList)
      let contractFileList = []
      if(this.uploadFileList&&this.uploadFileList.length>0) {
        this.uploadFileList.forEach((item)=> {
          contractFileList.push(item.fileId)
        })
      }
     this.form.contractFileList = contractFileList
      //console.log('editContract!', this.form)
      if(this.form.serveTime&&this.form.serveTime.length>0) {
        this.form.serveStartTime = this.form.serveTime[0]
        this.form.serveEndTime = this.form.serveTime[1]
      }
      this.form.productList.forEach((item)=>{
        item.productAmount?parseFloat(item.productAmount):0
      })
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          flag = true
          let res = await editContractApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
            this.eventBus.$emit(this.eventConst.contract.editSucceed)
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      // return flag
    },
    addProduct() {
      this.form.productList.push({
        productId: '',
        productSingle: [],
        extrasIdList: undefined,
        productAmount: undefined
      })
    },
    contractClassifySelect(val) {
      this.form.contractClassify = val
      //console.log(val)
    },
    contractTypeSelect(val) {
      if(!this.isCanEdit) {
        this.form.contractType = val
      }
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    }
  }
}
</script>

<style lang="scss"  scoped>
  .newConstructionContract_wrap{
    padding: 5px 0 120px;
    height: 100%;
    overflow: auto;
    ::v-deep .setInput .el-input__inner {
      padding-right: 80px !important;
    }
    .noDataWrap{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .noData{
        width: 96px;
        height:102px;
        text-align: center;
        .noDataText{
          margin-top: 15px;
          color: rgba(0, 0, 0, 0.25);
          font-size: 16px;
        }
      }
    }
    .content-wrap {
      overflow: auto;
      .form-el {
        overflow: auto;
      }
    }
    .projectNum{
      font-size: 15px;
      font-weight: 500;
      color: #5D5D5D;
      margin-left: 20px;
    }
    .contract_product{
      margin-bottom: 0!important;
      line-height:25px!important;
      ::v-deep.el-form-item__content{
        line-height: 25px!important;
      }
    }
    .productWrap{
      .productItem{
        float: left;
        width: 300px;
        padding:6px 0px 6px 10px;
        height: 105px;
        background: #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        margin: 0 9px 9px 0;
        ::v-deep.el-select__tags-text{
          max-width: 92px;
        }
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 28px;
        }
        ::v-deep.productClose{
          border-left: 1px solid #EAECFD;
          margin-left: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          //text-align: right!important;
          height: 100%;
          .iconfont{
            font-size: 8px!important;
            color: #F23232!important;
            line-height: 16px!important;
            text-align: center;
            cursor: pointer;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            background: #EBEBEB;
          }
        }
      }
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
</style>
