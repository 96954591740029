var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("test2页面")]),
      _c("el-input", {
        model: {
          value: _vm.num,
          callback: function ($$v) {
            _vm.num = $$v
          },
          expression: "num",
        },
      }),
      _c("br"),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("el-button", [_vm._v("默认按钮")]),
          _c("el-button", { attrs: { type: "primary" } }, [_vm._v("主要按钮")]),
          _c("el-button", { attrs: { type: "success" } }, [_vm._v("成功按钮")]),
          _c("el-button", { attrs: { type: "info" } }, [_vm._v("信息按钮")]),
          _c("el-button", { attrs: { type: "warning" } }, [_vm._v("警告按钮")]),
          _c("el-button", { attrs: { type: "danger" } }, [_vm._v("危险按钮")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }