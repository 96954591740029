<template>
  <div class="newConstructionContract_wrap">
    <el-row class="editProductWrap">
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">属性</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.orgTypeName}}</span>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">机构名称</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.orgName}}</span>
        </el-col>
      </el-col>
    </el-row>
    <el-row class="editProductWrap">
      <el-col :span="2">
        <span class="editLabel">别名</span>
      </el-col>
      <el-col :span="22">
        <span class="editInput" v-for="(item,index) in institutionList.aliasList" :key="item.id">
          {{item.aliasName}}
          <span v-if="index<institutionList.aliasList.length-1">、</span>
        </span>
      </el-col>
    </el-row>
    <el-row class="editProductWrap">
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">社会统一信用代码</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.uscCode}}</span>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">机构等级</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.orgLevelName}}</span>
        </el-col>
      </el-col>
    </el-row>
    <el-row class="editProductWrap">
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">所属省份</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.provinceName}}</span>
        </el-col>
      </el-col>
      <el-col :span="12">
        <el-col :span="4">
          <span class="editLabel">所属城市</span>
        </el-col>
        <el-col :span="20">
          <span class="editInput">{{institutionList.cityName}}</span>
        </el-col>
      </el-col>
    </el-row>
    <el-row class="editProductWrap">
      <el-col :span="2">
          <span class="editLabel">备注</span>
        </el-col>
      <el-col :span="22">
        <span class="editInput">{{ institutionList.remark }}</span>
      </el-col>
    </el-row>
    <!-- <el-row class="editProductWrap">
      <el-col :span="2">
        <span class="editLabel">关联机构</span>
      </el-col>
      <el-col :span="22">
        <span class="editInput" v-for="(item,index) in institutionList.relOrgList" :key="item.relOrgId">
          <span class="orgListItem">{{item.relOrgName}}</span>
          <span v-if="index<institutionList.relOrgList.length-1">、</span>
        </span>
      </el-col>
    </el-row> -->
    <el-row class="editProductWrap_contact">
      <el-col :span="2">
        <span class="editLabel">联系人</span>
      </el-col>
      <el-col :span="22">
        <div class="productWrap01" v-for="(item,index) in institutionList.contactsList" :key="item.id">
          <div class="productItem">
            <el-row>
              <el-col :span="16" class="contactName">{{ item.name }}</el-col>
              <el-col :span="8" style="text-align: right;">
                <el-button type="text" v-if="item.isPrimary==1" style="padding: 0;float: right;" size="mini">
                  <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                    <use xlink:href="#icon-shouyao"></use>
                  </svg>
                </el-button>
                <el-button type="text" v-else style="padding: 0;float: right;" size="mini">
                  <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                    <use xlink:href="#icon-bushishouyao"></use>
                  </svg>
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">部门</el-col>
              <el-col :span="18" class="productSelect">
                <span>{{ item.departmentName }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">职务</el-col>
              <el-col :span="18" class="productSelect">
                <span>{{ item.postName }}</span>
              </el-col>
            </el-row>
            <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneItem.id">
              <el-col :span="6" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
              <el-col :span="18" class="productSelect">
                <span>{{phoneItem.phone}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">邮箱</el-col>
              <el-col :span="18" class="productSelect">
                <span>{{item.email}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">微信</el-col>
              <el-col :span="18" class="productSelect">
                <span>{{item.wxAccount}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">备注</el-col>
              <el-col :span="18" class="productSelect">
                <span
                  class="remarkStyle"
                  :title="item.remark">
                  {{ item.remark }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  orgInfoApi
}  from '@/modules/crm/api/crm/org'
import {
  ITEM_OBJ
} from '@/modules/crm/utils/org'
export default {
  name: "EditOrg",
  props: {
    institutionId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      institutionList: this.$deepClone(ITEM_OBJ) // 机构信息
    }
  },
  created() {
    if(this.institutionId) {
      this.orgInfoApiFn(this.institutionId)
    }
  },
  methods: {
    // 机构详细信息
    async orgInfoApiFn(val) {
      let res = await orgInfoApi(val)
      if(res.code === 200) {
        this.institutionList = res.data
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
.newConstructionContract_wrap{
  padding: 20px 0;
    .productWrap{
      .productItem{
        float: left;
        width: 260px;
        padding:6px 10px;
        height: 76px;
        background: #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        margin-right: 10px;
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
      }
    }
  }
.editProductWrap{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
}
.editProductWrap_contact{
  margin-bottom: 20px;
}
.editLabel{
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.editInput{
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.orgListItem{
  color: #F1880D;
  cursor: pointer;
}
.productWrap01{
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin:0 10px 10px 0;
  width: 223px;
  padding:6px 10px;
  display: flex;
  float: left;
  .productItem{
    float: left;
    width: 203px;
    el-row{
      margin-bottom: 4px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .contactName{
      color:#5D5D5D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .productTitle{
      height: 20px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 20px;
    }
    .productSelect{
      //height: 20px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;
      .remarkStyle{
        width: 100%;
        display: inline-block;
        white-space: nowrap; /* 不换行 */
        overflow: hidden;    /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        cursor: pointer;
      }
    }
  }
}
</style>
