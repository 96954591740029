<!--规则执行结果-->
<template>
  <el-container class="layout-container register-container">
    <el-header class="register-header">
      <div>
        <img src="../../../assets/images/logo-pdBox.png" />
        <span class="header-text">欢迎注册机构用户</span>
      </div>
    </el-header>
    <el-main class="register-main">
        <div class="about-login-form-wrap">
          <header class="title-header clearfix">
            <h3 class="fl">注册</h3>
            <el-button type="text" @click="toLoginHandle" class="fr">立即登录</el-button>
          </header>
          <el-form ref="registerForm" status-icon :model="registerFormData" label-width="95px" label-position="left">
            <el-form-item
              label="真实姓名"
              prop="name"
              :rules="[
                      { required: true, message: '请输入真实姓名', trigger: 'blur'},
                      { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
                      ]"
            >
              <el-input
                placeholder="请输入用户名称"
                maxlength="10"
                clearable
                show-word-limit
                style="width:380px;"
                v-model="registerFormData.name"
              />
            </el-form-item>
            <el-form-item
              label="手机号"
              prop="phone"
              :rules="[
                        { required: true, message: '请输入手机号', trigger: 'blur'},
                        { min: 11, max: 11, message: '手机号长度在11个字符', trigger: 'blur' },
                        { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: '请输入正确的手机号码',trigger: 'blur'}
                      ]"
            >
              <el-input
                placeholder="请输入手机号"
                maxlength="11"
                clearable
                show-word-limit
                style="width:380px;"
                v-model="registerFormData.phone"
              />

            </el-form-item>

<!--            <div style="color:#ea8a3d;font-weight: bold;font-size: 12px;height: 20px;margin-bottom: 4px;">*手机号就是登录账号</div>-->
            <el-form-item
              label="短信验证码"
              prop="verificationCode"
              :rules="[{ required: true, message: '请输入短信验证码', trigger: 'blur'}]"
            >
              <el-col :span="17">
                <el-input
                  placeholder="请输入短信验证码"
                  maxlength="6"
                  clearable
                  v-model="registerFormData.verificationCode"
                />
              </el-col>
              <el-col :span="7">
                <el-button
                  style="margin-left: 10px;"
                  type="text"
                  :disabled="disabled"
                  @click="getCode">
                  <span class="Time">{{btnTxt}}</span>
                </el-button>
              </el-col>
            </el-form-item>
            <el-form-item
              label="邮箱"
              prop="email"
              :rules="[
                      { required: true, message: '请输入邮箱', trigger: 'blur'},
                      { min: 6, max: 50, message: '邮箱长度在6-50个字符', trigger: 'blur' },
                      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                      ]"
            >
              <el-input
                placeholder="请输入邮箱"
                maxlength="50"
                clearable
                show-word-limit
                style="width:380px;"
                v-model="registerFormData.email"
              />
            </el-form-item>
            <el-form-item>
<!--              <el-button-->
<!--                type="primary"-->
<!--                :class="submitBtn"-->
<!--                @click="$event.currentTarget.disabled ? '' : onRegister()"-->
<!--                v-preventReClick>注册</el-button>-->
              <el-button class="registerBtn" type="primary" @click="doRegister">注册</el-button>
            </el-form-item>
          </el-form>
        </div>
      <!-- <el-form
        ref="registerForm"
        :model="formObj"
        :rules="rules"
        label-width="120px"
        class="register-form">
        <el-form-item prop="orgName" label="机构名称">
          <el-input v-model="formObj.orgName" />
        </el-form-item>
        <el-form-item prop="contactName" label="联系人姓名">
          <el-input v-model="formObj.contactName" />
        </el-form-item>
        <el-form-item prop="contactCell" label="手机号">
          <el-input v-model="formObj.contactCell" />
        </el-form-item>
        <el-form-item prop="captcha" label="验证码">
          <el-input v-if="!isShowCaptcha" v-model="formObj.captcha">
            <el-button slot="append" :disabled="captchaDisabled" @click="onGetcaptcha">获取验证码</el-button>
          </el-input>
          <el-input v-else v-model="formObj.captcha">
            <el-button disabled slot="append">{{ countdown }}后重新获取</el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="contactEmail" label="联系人电子邮箱">
          <el-input v-model="formObj.contactEmail" />
        </el-form-item>
        <el-form-item prop="orgType" label="机构类型">
          <el-radio-group v-model="formObj.orgType" @change="onOrgTypeChange">
            <el-radio v-for="item in orgList" :key="item.code" :label="item.code">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-show="serviceList.length > 0" prop="servicePack" label="所选服务包">
          <el-radio-group v-model="formObj.servicePack">
            <el-radio v-for="item in serviceList" :key="item.packId" :label="item.packId">
              {{ item.packName}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="register-btn" @click="onRegister">注册</el-button>
        </el-form-item>
      </el-form> -->
    </el-main>
  </el-container>
</template>
<script>
import {
  addUserApi,
  editUserApi,
  registerApprovalApi, registerUserApi,
  registerUserListApi,
  sendRegisterVerificationCode
} from '@/modules/crm/api/system/user'
import { isEmpty } from '@/utils/common'
import { addRoleApi, editRoleApi } from '@/modules/crm/api/system/role'

export default {
  name: 'userRegister',
  data() {
    return {
      // 表格的加载层
      tableLoading: true,
      //注册表单
      registerFormData: {
        name: '',
        phone: '',
        verificationCode: undefined,
        email: ''
      },
      btnTxt: '获取验证码',
      time: '',
      disabled: false
    }
  },
  created() {
  },
  methods: {
    //注册
    doRegister() {
      this.$refs['registerForm'].validate(valid => {
        if (valid) {
          registerUserApi(this.registerFormData).then(res=>{
            this.msgSuccess("注册成功，等待管理员审批中。请留意您的邮箱，审批通过后会发送邮件通知")
            // 返回登录页面
            this.toLoginHandle()
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    toLoginHandle() {
      this.$router.push('/login')
    },
    async getCode() {
      // 校验手机号码
      if (!this.registerFormData.phone) {
        //号码校验不通过
        this.$message({
          message: '请输入手机号',
          type: 'warning',
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/1[35789]\d{9}/.test(this.registerFormData.phone)) {
        // 失去焦点后自动触发校验手机号规则
      } else {
        await this.sendRegisterVerificationCodeFn()
        this.time = 60;
        this.disabled = true;
        //调用倒计时方法
        this.timer();
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        // console.log(this.time);
        this.btnTxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnTxt = "获取验证码";
        this.disabled = false;
      }
    },
    // 获取验证码
    async sendRegisterVerificationCodeFn() {
      let obj = {}
      obj.phone = this.registerFormData.phone
      let res = await sendRegisterVerificationCode(obj)
      console.log(res)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/login.scss';
//.baNum:hover{
//  text-decoration:underline;
//}
::v-deep.about-login-form-wrap{
  margin-top: 128px!important;
  width: 569px!important;
  height: 454px!important;
  .el-form{
    .el-form-item__content{
      width: 390px;
    }
  }
}
.registerBtn{
  width: 320px;
  height: 36px;
  border-radius: 20px;
  background: linear-gradient(90deg, #F0A433 0%, #EE870E 100%);
  box-shadow: 0px 4px 8px 0px rgba(240, 153, 51, 0.30);
  border: none;
  //margin-left: 80px;
  &.disabled {
    background: linear-gradient(90deg, #F0A433 0%, #EE870E 100%);
    box-shadow: 0px 4px 8px 0px rgba(240, 153, 51, 0.30);
    opacity: 0.5;
    border: none;
  }
}
.register-container {
  .register-header {
    padding: 0 20px !important;
    height: 50px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(189, 192, 199, 0.7);
    img {
      vertical-align: middle;
    }
    .header-text {
      display: inline-block;
      border-left: 2px solid #C8D9FA;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
  .register-main {
    .register-form {
      width: 50%;
      margin: 0 auto;
      ::v-deep.register-btn {
        width: 100%;
        border: 1px solid #DCDFE6 !important;
        background: linear-gradient(90deg, #00AAFF, #2B60F8);
        border-radius: 20px;
        &.disabled {
          background: linear-gradient(90deg, #00AAFF, #2B60F8);
          box-shadow: 0px 4px 8px 0px rgba(40, 128, 215, 0.5);
          opacity: 0.5;
          border: none;
        }
      }
    }
  }
  .register-footer {
    height: 40px!important;
    line-height: 40px;
    width: 100%;
    height: 40px;
    background:#EBEFF7;
    opacity: 0.8;
    /* position: fixed; */
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #999999;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

</style>
