<!--文件预览、下载组件-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :modal="false"
      :close-on-click-modal="false"
      @close="handleClose"
      width="70%">
      <!-- <img :src="getImgUrl()" class="viewImg"> -->
      <el-image 
        fit="fill"
        :src="getImgUrl()" 
        :preview-src-list="getImgUrlList()">
      </el-image>
    </el-dialog>
  </div>

</template>

<script>
import { previewFileApi,fileInfoApi,downloadFileApi } from '@/modules/crm/api/system/file'
import { axiosDownload } from '@/utils/common'
export default {
  name: 'previewFile',
  props: {
    fileId: {
      type: Number,
      required: true
    },
    isShowDialog: {
      type: Boolean,
      default: false
    },
    
  },
  components: {
  },
  watch: {
    fileId: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.dialogVisible = false
        this.fileIdChange = true
        this.$emit('update:isShowDialog', false)
        if(newVal>0){
          this.handleFile()
        }
      }
    },
    isShowDialog(newVal, oldVal) {
      if (!this.fileIdChange && newVal && this.fileId > 0) {
        this.handleFile()
      }
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      fileInfo: {},
      fileIdChange: false
    }
  },
  created() {
    console.log('previewFIle created')
  },
  methods:{
    getImgUrl(){
      return this.baseURL+"/crm/sys/file/preview/"+this.fileId
    },
    getImgUrlList(){
      const list = [this.baseURL+"/crm/sys/file/preview/"+this.fileId]
      return list
    },
    handleClose(){
      this.$emit('close')
    },
    handleFile(){
      fileInfoApi(this.fileId).then(res=>{
        this.fileInfo=res.data
        if(!this.fileInfo.id){
          this.msgError("文件信息获取失败")
        }
        if(["bmp","gif","jpg","jpeg","png"].indexOf(this.fileInfo.fileType)>-1){
          //如果图片类型，预览
          this.dialogVisible = true
          this.$emit('update:isShowDialog', false)
          this.fileIdChange = false
        }else{
          //执行下载
          this.dialogVisible = false
          this.$emit('update:isShowDialog', false)
          this.fileIdChange = false
          downloadFileApi(this.fileId).then(res=>{
            if (res.type == 'application/json') {
              this.msgError('下载失败')
            }else{
              axiosDownload(this.fileInfo.fileName, res)
              this.msgSuccess('下载完成,请在浏览器的下载列表中查看')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.viewImg {
  width: 100%;
}
</style>
