<template>
  <div class="newConstructionInstitution_wrap">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :validate-on-rule-change="false"
      label-width="80px"
      label-position="left">
      <el-row>
        <el-col :span="12">
          <el-form-item label="属性" prop="orgType">
            <span
              v-for="(item, index) in orgTypeDict"
              :key="item.id"
              class="normal_btn"
              :class="{ select_btn: item.id == form.orgType }"
              @click="contractClassifySelect(item.id, index)">
              {{ item.dictLabel }}
            </span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构名称" prop="orgName">
            <el-input
              v-model.trim="form.orgName"
              placeholder="请输入机构名称"
              style="width: 98%;"
              maxlength="30"
              clearable
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-for="(item,index) in form.aliasList" :key="index">
        <el-col :span="12">
          <el-form-item
            :label="'别名'+ Number(index+1)"
            :prop="`aliasList[${index}].aliasName`"
            :rules="rules.aliasName">
            <el-input
              v-model.trim="item.aliasName"
              style="width: 98%;"
              maxlength="30"
              clearable
              show-word-limit
              @change="(val) => aliasNameChane(val, item, index)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="----来源">
            <el-select 
              v-model="item.aliasSource"
              placeholder="请选择来源"
              style="width: 280px;"
              filterable
              clearable>
              <el-option
                :label="item.dictLabel"
                :value="item.id"
                :key="item.id"
                v-for="item in orgAliasSourceDict"></el-option>
            </el-select>
            <i
              v-if="index===0"
              class="iconfont addBtn"
              style="float: right;"
              @click="addAliasClick">&#xe65e;</i>
            <i v-else class="iconfont addBtn" style="float: right;" @click="delAliasClick(item, index)">&#xe665;</i>
<!--            <i class="iconfont setBtn" style="float: right;" @click="setSourceClick">&#xe66d;</i>-->
            <el-button @click="changePrimary(item,0)" v-if="item.isPrimary === 1" type="text" style="margin:5px 0 0 10px;padding: 0;float: right;">
              <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                <use xlink:href="#icon-shouyao"></use>
              </svg>
            </el-button>
            <el-button @click="changePrimary(item,1)" v-if="item.isPrimary === 0 && form.aliasList.length > 1" type="text" style="margin:5px 0 0 10px;padding: 0;float: right;">
              <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                <use xlink:href="#icon-bushishouyao"></use>
              </svg>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="labelSet">
          <el-form-item
            :class="{'is-error': uscCodeError}"
            label="社会统一信用代码"
            prop="uscCode">
            <el-input
              ref="uscCodeInput"
              v-model.trim="form.uscCode"
              style="width: 98%;"
              maxlength="18"
              clearable
              show-word-limit
              @blur="uscCodeBlur"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="机构等级" prop="orgLevel">
            <span
              v-for="(item) in orgLevelDict"
              :key="item.id"
              class="normal_btn"
              :class="{ select_btn: item.id == form.orgLevel }"
              @click="contractTypeSelect(item.id)">
              {{ item.dictLabel }}
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="所属省份/城市" prop="provinceCityTreeValue" class="labelSet">
        <el-cascader
          v-model="form.provinceCityTreeValue"
          :props="defaultParams"
          :options="provinceCityTreeDict"
          placeholder="请选择所属省份/城市"
          style="width: 376px;"
          clearable
          @change="handleChange">
        </el-cascader>
      </el-form-item>
      <el-form-item label="备注" prop="remark" class="labelSet">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          maxlength="200"
          show-word-limit
          placeholder="请输入内容"
          v-model="form.remark">
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="关联机构" v-if="isRelOrgIdListShow">
        <el-select
          v-model="form.relOrgIdList"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请选择关联机构"
          :remote-method="remoteMethod"
          style="width: 830px;"
          :loading="loading">
          <el-option
            v-for="item in orgOptions"
            :key="item.orgId"
            :label="item.orgName"
            :value="item.orgId">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="联系人" style="border-bottom: 1px dotted #F0F0F0;">
        <div style="text-align: right;">
          <el-button size="mini" @click="addContactsClick(form.contactsList)">添加</el-button>
        </div>
      </el-form-item>
      <div class="contactList">
        <div
          class="productWrap"
          v-for="(item,index) in form.contactsList"
          :key="index">
          <div class="productItem">
            <el-row>
              <el-form-item
                class="phoneStyle"
                :prop="`contactsList[${index}].name`"
                :rules="rules.contactsName"
                label-width="25%"
                label="姓名"
                size="mini"
                :show-message="false">
                <!-- <el-col :span="6">姓名</el-col> -->
                <!-- <el-col :span="24"> -->
                  <el-col :span="16" class="productSelect">
                    <el-input
                      v-model.trim="item.name"
                      size="mini"
                      maxlength="30"
                      clearable
                      show-word-limit></el-input>
                  </el-col>
                <el-col :span="8">
                  <el-button @click="changeContactPrimary(item,0)" v-if="item.isPrimary === 1" type="text" style="margin:4px 0 0 0px;padding: 0;float: right;">
                    <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                      <use xlink:href="#icon-shouyao"></use>
                    </svg>
                  </el-button>
                  <el-button @click="changeContactPrimary(item,1)" v-else type="text" style="margin:4px 0 0 0px;padding: 0;float: right;">
                    <svg class="icon svg-icon bushishouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                      <use xlink:href="#icon-bushishouyao"></use>
                    </svg>
                  </el-button>
                </el-col>
                <!-- </el-col> -->
              </el-form-item>
            </el-row>
            <el-row>
              <!-- <el-col :span="6" class="productTitle">部门</el-col> -->
              <el-col :span="24" class="productSelect">
                <!-- <el-select
                  v-model="item.department"
                  placeholder="请选择部门"
                  filterable
                  clearable
                  size="mini">
                  <el-option
                    :label="item.dictLabel"
                    :value="item.id"
                    :key="item.id"
                    v-for="item in orgContactsDepartmentDict"></el-option>
                </el-select> -->
                <el-form-item
                  :prop="`contactsList[${index}].department`"
                  :rules="[{ required: true, message: '请选择部门', trigger: 'blur' }]"
                  :show-message="false"
                  label-width="25%"
                  label="部门"
                  size="mini"
                  required
                  class="phoneStyle">
                  <el-select
                    v-model="item.department"
                    placeholder="请选择部门"
                    filterable
                    clearable
                    size="mini"
                    @change="(val) =>departmentChange(val, index)">
                    <el-option
                      :label="item.dictLabel"
                      :value="item.id"
                      :key="item.id"
                      v-for="item in item.departmentOptions"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">职务</el-col>
              <el-col :span="18" class="productSelect">
                <el-select
                  v-model="item.post"
                  placeholder="请选择职务"
                  filterable
                  clearable
                  size="mini">
                  <el-option
                    :label="item.dictLabel"
                    :value="item.id"
                    :key="item.id"
                    v-for="item in item.postOptions"></el-option>
                </el-select>
                <!-- <el-cascader
                  v-model="item.post"
                  :options="postOptions"
                  :props="{label: 'dictLabel', value: 'id'}"
                  @change="handlePostChange"></el-cascader> -->
              </el-col>
            </el-row>
            <el-row v-for="(phoneItem,phoneIndex) in item.phoneList" :key="phoneIndex">
              <el-form-item
                class="phoneStyle"
                :label="'电话'+ Number(phoneIndex+1)"
                :prop="`contactsList[${index}].phoneList[${phoneIndex}].phone`"
                :rules="[{ validator:(rule,value,callback) => validatorPhone(rule,value,callback,index,phoneIndex), trigger:['change'] }]"
                label-width="25%"
                size="mini"
                :show-message="false">
<!--              <el-col :span="5" class="productTitle">电话{{Number(phoneIndex+1)}}</el-col>-->
              <el-col :span="16" class="productSelect">
                <el-input
                  v-model.trim="phoneItem.phone"
                  size="mini"
                  clearable
                  class="no-word-limit-input"></el-input>
              </el-col>
              <el-col :span="8">
                <i v-if="phoneIndex!==0" class="iconfont delBtn" @click="delPhone(item.phoneList,phoneIndex)">&#xe665;</i>
                <i class="iconfont addBtn" @click="addPhone(item.phoneList)">&#xe65e;</i>
              </el-col>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">邮箱</el-col>
              <el-col :span="18" class="productSelect">
                <el-form-item
                label-width="0px"
                class="phoneStyle"
                :prop="`contactsList[${index}].email`"
                :rules="[
                  { pattern: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,message: '请输入正确的邮箱',trigger: 'blur'}
                ]"
                :show-message="false"
                size="mini">
                  <el-input
                    v-model.trim="item.email"
                    size="mini"
                    maxlength="30"
                    clearable
                    show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">微信号</el-col>
              <el-col :span="18" class="productSelect">
                <el-input
                  v-model.trim="item.wxAccount"
                  size="mini"
                  maxlength="50"
                  clearable
                  show-word-limit></el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">备注</el-col>
              <el-col :span="18" class="productSelect">
                <el-input
                  v-model.trim="item.remark"
                  size="mini"
                  maxlength="200"
                  clearable
                  show-word-limit></el-input>
              </el-col>
            </el-row>
          </div>
          <div class="delBtnWrap" v-if="form.contactsList.length > 0">
            <i class="iconfont closeBtn" @click="delContactsClick(item, index)">&#xe672;</i>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  provinceCityTree,
  searchOrgList,
  addOrgApi,
  editOrgApi,
  getPostTreeApi,
  checkUscCodeApi
} from '@/modules/crm/api/crm/org'
import {
  ITEM_OBJ,
  CONTACTS_ITEM,
  duplicates
} from '@/modules/crm/utils/org'
export default {
  name: "NewConstructionInstitution",
  props: {
    editForm: {
      type: Object
    },
    componentSource: {
      type: String,
      default: 'add'
    },
    orgType: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      // 别名来源字典
      orgAliasSourceDict: this.getDict(this.dictConst.ORG_ALIAS_SOURCE),
      // 机构类型字典(属性)
      orgTypeDict: this.getDict(this.dictConst.ORG_TYPE),
      // 机构等级
      orgLevelDict: this.getDict(this.dictConst.ORG_LEVEL),
      isRelOrgIdListShow: true, // 关联机构选项是否显示
      form: this.$deepClone(ITEM_OBJ),
      originForm: this.$deepClone(ITEM_OBJ),
      provinceCityTreeDict: [],
      uscCodeError: false,
      defaultParams: {
        label: "name",
        value: "code"
      },
      // 科室部门字典
      orgContactsDepartmentDict: this.getDict(this.dictConst.ORG_CONTACTS_DEPARTMENT),
      // 职务字典
      orgContactsPostDict: this.getDict(this.dictConst.ORG_CONTACTS_POST),
      orgOptions: [],
      postOptions: [
        // {
        //   dictLabel: '客户',
        //   id: 2,
        //   children: []
        // },
        // {
        //   dictLabel: '经销商',
        //   id: 3,
        //   children: []
        // }
      ],
      departmentOptions: [],
      list: [],
      loading: false,
      rules: {
        orgType: [
          { required: true, message: '请选择机构类型', trigger: 'change' }
        ],
        orgName: [
          { required: true, message: '请填写机构名称', trigger: 'blur' },
          { min: 2, max: 30, message: '至少 2 个字符', trigger: 'blur' },
        ],
        uscCode: [
          { required: true, message: '请填写社会统一信用代码', trigger: 'blur' },
          { min: 18, max: 18, message: '请填写18位数字或大写英文字母（不使用I、O、S、V）', trigger: 'blur' },
          {
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              console.log(value)
              // var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/
              // var reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/
              // let reg = /^[A-H-J-N-P-R-T-U-W-Z0-9]+$/
              let reg = /^[A-HJ-NP-RT-UW-Z0-9]+$/
              if (!reg.test(value)) {
                callback(new Error('社会统一信用代码必须由数字或大写字母组合（不使用I、O、S、V）'))
              } else {
                callback()
              }
            }
          }
        ],
        orgLevel: [
          { required: true, message: '请选择机构等级', trigger: 'change' }
        ],
        provinceCityTreeValue: [
          { required: true, message: '请选择所属省份/城市', trigger: 'change' }
        ],
        // 联系人姓名
        contactsName: [
          { required: true, message: '请填写名称', trigger: 'blur' },
          { min: 2, max: 30, message: '至少 2 个字符', trigger: 'blur' },
          { trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              /* this.$refs.form.clearValidate('contactsList')
              let arrList = this.form.contactsList
              let arr =  []
              for (let j = 0; j < arrList.length; j++) {
                const item = arrList[j]
                arr.push(item.name)
              }
              let reArr = []
              let NewList = []
              for(let i = 0;i<arr.length;i++){
                if(reArr.indexOf(JSON.stringify(arr[i])) === -1){
                  reArr.push(JSON.stringify(arr[i]))
                  NewList.push(arr[i])
                }
              }
              if(arrList.length === NewList.length){
                callback()
              } else {
                callback(new Error('联系人姓名不可重复'))
              } */
              let sameArr = []
              let diffArr = []
              let obj = duplicates(this.form.contactsList, 'name')
              sameArr = obj.sameArr
              diffArr = obj.diffArr
              for (let i = 0; i < diffArr.length; i++) {
                let index = diffArr[i]
                this.$refs.form.clearValidate(`contactsList[${index}].name`)
              }
              const index = this.form.contactsList.findIndex(item => item.name === value)
              const isExit = sameArr.some(item => item.indexOf(index) > -1 )
              if (sameArr.length > 0 && isExit) {
                callback(new Error('联系人姓名不可重复'))
              } else {
                callback()
              }
            }
          }
        ],
        phoneRules: [
          // { required: true, message: '请输入电话号码', trigger: ['blur', 'change']},
          { min: 7, max: 15, message: '电话号码长度在15个字符', trigger: ['blur', 'change'] },
          // { pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: '请输入正确的手机号码',trigger: 'blur'}, -->
          {
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              // const isPhone = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/ //11位合法手机号码 --> -->
              // const isMob1 = /0\d{2,3}\d{7,8}/ //不带-的座机：02985111752 -->
              // const isMob2 = /^([0-9]{3,4}-)?[0-9]{7,8}$/ //带一个-：029-85111752
              // const isMob3 = /^\d{3,4}-\d{3,4}-\d{3,4}$/ //带三个-： 0752-250-520
              // if (isMob2.test(value) || isMob3.test(value)) {
              //  callback()
              //} else {
              //  callback('座机号格式为：0000-0000000')
              //}
              if (value === '') {
                callback()
              }
              let regPone = null
              let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
              let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
              if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
                regPone = tel     
              } else {          
                regPone = mobile
              }
              if (!regPone.test(value)) {
                callback(new Error('请填写联系人电话(座机格式区号-座机号码)'))
              } else {
                callback()
              }
              
            }
          }
        ],
        // 别名
        aliasName: [
          { trigger: ['change'],
            validator: (rule, value, callback) => {
              /* this.$refs.form.clearValidate('aliasList')
              let arrList = this.form.aliasList
              let arr =  []
              for (let j = 0; j < arrList.length; j++) {
                const item = arrList[j]
                arr.push(item.aliasName)
              }
              let reArr = []
              let NewList = []
              for(let i = 0;i<arr.length;i++){
                if(reArr.indexOf(JSON.stringify(arr[i])) === -1){
                  reArr.push(JSON.stringify(arr[i]))
                  NewList.push(arr[i])
                }
              }
              console.log(arrList)
              console.log(NewList)
              if(arrList.length === NewList.length){
                callback()
              } else {
                callback(new Error('别名不可重复'))
              } */
              let sameArr = []
              let diffArr = []
              let obj = duplicates(this.form.aliasList, 'aliasName')
              sameArr = obj.sameArr
              diffArr = obj.diffArr
              for (let i = 0; i < diffArr.length; i++) {
                let index = diffArr[i]
                this.$refs.form.clearValidate(`aliasList[${index}].aliasName`)
              }
              const index = this.form.aliasList.findIndex(item => item.aliasName === value)
              const isExit = sameArr.some(item => item.indexOf(index) > -1 )
              if (sameArr.length > 0 && isExit) {
                callback(new Error('别名不可重复'))
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    orgType(newVal, oldVal) {
      if (this.componentSource === 'add') {
        console.log('add')
        this.form.orgType = newVal
      }
    },
    'form.orgType'(newVal, oldVal) {
      this.handleDeptAndPostData()
      this.form.contactsList.forEach((item, index) => {
        item.department = ''
        item.post = ''
      })
    }
  },
  async created() {
    console.log('org created')
    console.log(this.form.orgType)
    console.log(this.$store.state.dict)
    console.log(this.orgType)
    this.reset()
    await this.getprovinceCityTreeFn()
    await this.$store.dispatch('dict/fetchDeptAndPostTree')
    // await this.fetchPostTreeData()
    if (this.componentSource === 'edit') {
      // 编辑
      let obj = {}
      obj.orgType = 2
      let res = await searchOrgList(obj)
      if(res.code === 200) {
        this.orgOptions = res.data || []
      }
      this.form = Object.assign(this.form, this.editForm)
      const provinceCode = Number(this.form.provinceCode)
      const cityCode = Number(this.form.cityCode)
      // this.form.provinceCityTreeValue[0] = Number(this.form.provinceCode)
      // this.form.provinceCityTreeValue[1] = Number(this.form.cityCode)
      this.form.provinceCityTreeValue = [provinceCode, cityCode]
      console.log(this.form)
      this.form.relOrgList.forEach(item => {
        this.form.relOrgIdList.push(item.relOrgId)
      })
      if ((this.form.aliasList && this.form.aliasList.length === 0) || !this.form.aliasList) {
        this.form.aliasList = [{
          aliasName: "",
          aliasSource: '',
          id: '',
          isPrimary: 1
        }]
      }
      this.originForm = this.$deepClone(this.form)
    } else if (this.componentSource === 'add') {
      this.form.orgType = this.orgType
    }
    this.handleDeptAndPostData()
    // console.log(this.form)
  },
  activated() {
    console.log('org activated')
  },
  methods: {
    validatorPhone (rule, value, callback, index, phoneIndex) {
      if (value === '') {
        callback()
      }
      let regPone = null
      let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
      let tel = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/; //座机
      if (value.charAt(0) == 0) {    // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
        regPone = tel     
      } else {          
        regPone = mobile
      }
      if (!regPone.test(value)) {
        callback(new Error('请填写联系人电话(座机格式区号-座机号码)'))
      }
      let arrList = this.form.contactsList
      let arr =  []
      for (let i = 0; i < this.form.contactsList.length; i++) {
        let item = this.form.contactsList[i]
        for (let j = 0; j < item.phoneList.length; j++) {
          let phoneItem = item.phoneList[j]
          phoneItem.indexOuter = i
          phoneItem.indexInner = j
          arr.push(phoneItem)
        }
      }
      let obj = duplicates(arr, 'phone', true)
      let sameArr = obj.sameArr
      let diffArr = obj.diffArr
      for (let i = 0; i < diffArr.length; i++) {
        let item = diffArr[i]
        const {indexOuter, indexInner} = item
        this.$refs.form.clearValidate(`contactsList[${indexOuter}].phoneList[${indexInner}].phone`)
      }
      const isExit = sameArr.some(itemArr => {
        console.log(itemArr)
        return itemArr.some(item => item.indexOuter === index && item.indexInner === phoneIndex)
      })
      if (isExit) {
        callback(new Error('电话号码不可重复'))
      } else {
        callback()
      }
    },
    async handleDeptAndPostData() {
      if (this.form.orgType === 100) {
        // 客户
        // await this.fetchPostTreeData(2)
        this.departmentOptions = this.$store.state.dict.customDeptPostTree
        // this.postOptions = []
      } else if (this.form.orgType === 101) {
        // 经销商
        // await this.fetchPostTreeData(3)
        this.departmentOptions = this.$store.state.dict.dealerDeptPostTree
        // this.postOptions = []
      } else if (this.form.orgType === 102) {
        // 分销商
        // await this.fetchPostTreeData(3)
        this.departmentOptions = this.$store.state.dict.distributorDeptPostTree
        // this.postOptions = []
      }
      console.log(this.departmentOptions)
      this.form.contactsList.forEach((item, index) => {
        item.departmentOptions = this.$deepClone(this.departmentOptions)
        const deptItem = item.departmentOptions.find(each => each.id === item.department)
        // this.postOptions = deptItem ? deptItem.children : []
        item.postOptions = deptItem ? deptItem.children : []
      })
    },
    // 职务树形结构
    async fetchPostTreeData(type) {
      // const res2 = getPostTreeApi(2)
      // const res3 = getPostTreeApi(3)
      // Promise.all([res2, res3]).then(res => {
      //   this.postOptions[0].children = res[0].data || []
      //   this.postOptions[1].children = res[1].data || []
      // })
      let res = await getPostTreeApi(type)
      if (res.code === 200) {
        this.departmentOptions = res.data || []
      }
    },
    // 行政区域字典树形结构
    async getprovinceCityTreeFn() {
      let res = await provinceCityTree()
      console.log(res)
      if(res.code === 200) {
        this.provinceCityTreeDict = res.data || []
      }
    },
    // 获取选择的省市区
    handleChange(value) {
      console.log(value)
      this.form.provinceCode = this.form.provinceCityTreeValue[0]
      this.form.cityCode = this.form.provinceCityTreeValue[1]
    },
    // 部门 change
    departmentChange(val, index) {
      const deptItem = this.departmentOptions.find(item => item.id === val)
      // this.postOptions = deptItem ? deptItem.children : []
      this.form.contactsList[index].postOptions = deptItem ? deptItem.children : []
      this.form.contactsList[index].post = ''
      console.log(this.form.contactsList)
    },
    // 职务 change
    handlePostChange(value) {
      console.log(value)
      console.log(this.form.contactsList)
    },
    // 别名 change
    aliasNameChane(val, item, index) {

    },
    addPhone(arr) {
      return arr.push({
        phone: ''
      })
    },
    delPhone(arr,index) {
      return arr.splice(index,1)
    },
    /* 模版段落检索*/
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 2
          obj.searchValue = query
          let res = await searchOrgList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.orgOptions = res.data
          }
        }, 200);
      } else {
        this.orgOptions = [];
      }
    },
    addContactsClick(arr) {
      let item = this.$deepClone(CONTACTS_ITEM)
      item.departmentOptions = this.departmentOptions
      /* arr.push({
        isPrimary: 0,
        phoneList: [
          {
            phone: ''
          }
        ]
      }) */
      if (this.form.contactsList.length === 0) {
        item.isPrimary = 1
      }
      arr.push(item)
    },
    delContactsClick(item, index) {
      this.form.contactsList.splice(index, 1)
      if (item.isPrimary === 1 && this.form.contactsList[0]) {
        this.form.contactsList[0].isPrimary = this.form.contactsList[0] ? 1 : 0
      }
    },
    changePrimary(item,val) {
      console.log(val)
      if(val === 1) {
        this.form.aliasList.forEach((i)=> {
          i.isPrimary = 0
        })
        return item.isPrimary = val
      }

    },
    changeContactPrimary(item,val) {
      if(val === 1) {
        this.form.contactsList.forEach((i)=> {
          i.isPrimary = 0
        })
        return item.isPrimary = val
      }
    },
    onSubmit() {
      console.log('submit!', this.form)
      let flag = null
      const aliasPrimaryItem = this.form.aliasList.find(item => item.isPrimary === 1)
      const len = this.form.aliasList.length
      if (aliasPrimaryItem && aliasPrimaryItem.aliasName === '' && len > 1) {
        this.$alert('首要别名不能为空', '警告', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `action: ${ action }`
            })
          }
        })
        return
      }
      return new Promise ((resolve, reject) => {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res = await addOrgApi(this.form)
            if (res.code == 200) {
              this.msgSuccess()
              this.reset()
              flag = true
              resolve(true)
            } else {
              // this.msgError(res.msg)
              flag = false
              resolve(false)
            }
          } else {
            this.msgError('请规范填写')
            flag = false
            resolve(false)
          }
        })
      })
      /* this.$refs['form'].validate(async (valid) => {
        if (valid) {
          flag = true
          let res = await addOrgApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      return flag */
    },
    /** 表单重置 */
    reset() {
      /* this.form = {
        orgName: '',
        aliasList: [
          {aliasName: '',aliasSource: '',isPrimary: 1}
        ],
        uscCode: '',
        relOrgIdList: [],
        provinceCityTreeValue:[],
        provinceCode: '',
        cityCode: '',
        orgType: undefined,
        orgLevel: undefined,
        contactsList: [
          {
            isPrimary: 1,
            phoneList: [
              {
                phone: ''
              }
            ]
          }
        ]
      } */
      if (this.componentSource === 'edit') {
        this.form = this.$deepClone(this.originForm)
      } else {
        this.form = this.$deepClone(ITEM_OBJ)
      }
      console.log(this.$refs.form)
      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.clearValidate()
      })
      // this.$refs.form && this.$refs.form.clearValidate()
      // this.$refs.form && this.$refs.form.resetFields()
      // this.resetForm('form')
    },
    // 编辑重置
    async editReset() {
      this.form = this.$deepClone(this.originForm)
    },
    contractClassifySelect(id, index) {
      this.form.orgType = id
      this.form.relOrgIdList = []
      if (index === 0) { // 客户
        this.isRelOrgIdListShow = true
      } else {
        // 经销商
        this.isRelOrgIdListShow = false
      }
    },
    contractTypeSelect(id) {
      this.form.orgLevel = this.form.orgLevel == id ? undefined : id
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    },
    setSourceClick() {
      this.$emit('setSource', true)
    },
    addAliasClick() {
      this.form.aliasList.push({aliasName: '',aliasSource: '',isPrimary: 0})
      const isPrimaryItem = this.form.aliasList.some(item => item.isPrimary === 1)
      if (!isPrimaryItem) {
        this.form.aliasList[0].isPrimary = this.form.aliasList[0] ? 1 : 0
      }
    },
    delAliasClick(item, index) {
      this.form.aliasList.splice(index, 1)
      if (item.isPrimary === 1) {
        this.form.aliasList[0].isPrimary = this.form.aliasList[0] ? 1 : 0
      }
    },
    // 机构修改
    async editOrgSave() {
      
     /*  await this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res = await editOrgApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
            flag = true
          } else {
            this.msgError(res.msg)
            flag = false
          }
        } else {
          this.msgError('请规范填写')
          console.log(123)
          flag = false
        }
      })
      return flag */
      const aliasPrimaryItem = this.form.aliasList.find(item => item.isPrimary === 1)
      const len = this.form.aliasList.length
      if (aliasPrimaryItem && aliasPrimaryItem.aliasName === '' && len > 1) {
        this.$alert('首要别名不能为空', '警告', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'warning',
              message: `action: ${ action }`
            })
          }
        })
        return
      }
      return new Promise ((resolve, reject)=> {
        let flag = null
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let res = await editOrgApi(this.form)
            if (res.code == 200) {
              this.msgSuccess()
              this.reset()
              flag = true
              resolve(true)
            } else {
              this.msgError(res.msg)
              flag = false
              resolve(false)
            }
          } else {
            this.msgError('请规范填写')
            console.log(123)
            flag = false
            resolve(flag)
          }
        })
        // resolve(flag)
      })
    },
    async uscCodeBlur(event) {
      const val = this.form.uscCode
      if (val === '' || val.length !== 18) {
        return
      }
      const obj = {
        uscCode: this.form.uscCode,
        orgId: ''
      }
      try {
        let res = await checkUscCodeApi(obj)
        console.log(res)
        if (res.code === 500) {
          console.log(this.$refs.uscCodeInput)
          this.$refs.uscCodeInput.focus()
          this.uscCodeError = true
        } else {
          this.uscCodeError = false
        }
      } catch(err) {
        console.log(this.$refs.uscCodeInput)
        console.log(err.code)
        this.$refs.uscCodeInput.focus()
        console.log(err)
        this.uscCodeError = true
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
  .newConstructionInstitution_wrap{
    padding: 20px 0;
    .contactList{
      display: flex;
      flex-wrap: wrap;
    }
    .phoneStyle{
      margin-bottom: 0!important;
    }
    ::v-deep .el-input--suffix .el-input__inner {
      padding-right: 58px !important;
    }
    ::v-deep .el-input__clear {
      width: 18px !important;
    }
    ::v-deep .el-input .el-input__count .el-input__count-inner {
      padding: 0 3px !important;
    }
    ::v-deep .no-word-limit-input .el-input__inner {
      padding-right: 30px !important;
    }
    ::v-deep.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
      margin-right: 2px !important;
    }
    .productWrap{
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin:0 10px 10px 0;
      width: 280px;
      padding:6px 0;
      display: flex;
      .productItem{
        float: left;
        width: 260px;
        padding:0 10px;
        border-right: 1px solid #EAECFD;
        .el-row{
          margin-bottom: 4px;
        }
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #606266;
          font-weight: 600;
          line-height: 28px;
        }
      }
      .delBtnWrap{
        padding:0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  .shouyao{
    //margin-top: 9px;
  }
  .setBtn,.addBtn,.delBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f1f1f1;
    line-height: 16px;
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 8px 5px 0;
    cursor: pointer;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .closeBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #EBEBEB;
    line-height: 16px;
    color: #F23232;
    font-size: 8px;
    text-align: center;
    cursor: pointer;
  }
</style>
