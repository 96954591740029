<template>
  <div class="drawer_wrap">
    <slot></slot>
    <el-row class="newBuildContractWrap" v-if="type==2">
      <el-col :span="12">
        <span class="newConstructionContract" >{{type==2?'新建任务':'编辑任务'}}</span>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-button plain size="mini" @click="reset()" v-show="type==2">重置</el-button>
        <el-button class="saveBtn" plain size="mini" @click="onSubmit">保存</el-button>
      </el-col>
    </el-row>
    <!--编辑任务或者新增任务-->
    <div v-if="type>1">
      <div class="task_wrap">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          label-position="left">
          <el-row>
            <el-col :span="12">
              <el-form-item label="任务标题" prop="taskName">
                <el-input v-model.trim="form.taskName" style="width: 98%;" maxlength="40" clearable show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="终端客户" prop="customerId">
                <el-select
                  v-model="form.customerId"
                  :remote-method="queryContractCustomerList"
                  filterable
                  remote
                  clearable
                  placeholder="请输入关键字检索终端客户"
                  style="width: 98%;"
                  @change="customerChange"
                >
                  <el-option :label="item.orgName" :value="item.orgId" :key="item.orgId"
                             v-for="item in contractCustomerList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="labelSet">
              <el-form-item label="产品名称" prop="productKey">
                <el-select
                  v-model="form.productKey"
                  :disabled="isEmpty(form.customerId)"
                  :placeholder="isEmpty(form.customerId)?'请先选择终端客户':'请选择产品名称'" style="width: 98%;"
                  @change="productChange">
                  <el-option
                    v-for="item in productList"
                    :label="item.productName"
                    :value="item.productKey"
                    :key="item.productKey"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="getList(productInfo,'extrasList').length>0">
              <el-form-item label="增值包">
            <span
              v-for="(item,index) in getList(productInfo,'extrasList')"
              :key="item.id"
              class="normal_btn"
              @click="contractTypeSelect(index)"
            >
              {{ item.extrasName }}
            </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="form.productId">
            <el-col :span="12">
              <el-form-item label="项目名称" >
                <span>{{productInfo.projectName ||'无'}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" v-show="form.productKey">
              <el-form-item label="提出人" prop="feedbackUser">
                <div class="">
                  <el-button
                    class="plain-primary-btn"
                    @click="onAddCustom">
                    新建客户联系人
                  </el-button>
                </div>
                <div class="productWrap01">
                  <div class="productItem">
                    <el-row>
                      <el-col :span="24" class="productSelect">
                        <!-- <el-select
                          v-model="form.feedbackUserKey"
                          placeholder="请选择提出人"
                          size="mini"
                          @change="feedbackUserChange"
                          style="width: 100%;">
                          <el-option
                            :label="item.name"
                            :value="item.contactsKey"
                            :key="item.contactsKey"
                            v-for="item in feedbackUserList"
                          ></el-option>
                        </el-select> -->
                        <el-select
                          v-model="form.feedbackUserKey"
                          filterable
                          remote
                          clearable
                          size="small"
                          style="width: 100%;"
                          placeholder="请选择提出人"
                          :remote-method="fetchProposer"
                          @change="feedbackUserChange">
                          <el-option
                            v-for="item in proposerList"
                            :label="item.name"
                            :value="item.contactsKey"
                            :key="item.contactsKey"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="productTitle">部门</el-col>
                      <el-col :span="16" class="productSelect">
                        <span>{{ feedbackUserMsg.departmentName }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="productTitle">职务</el-col>
                      <el-col :span="16" class="productSelect">
                        <span>{{ feedbackUserMsg.postName }}</span>
                      </el-col>
                    </el-row>
                    <el-row
                      v-for="(item,index) in getList(feedbackUserMsg,'phoneList')"
                      :key="index">
                      <el-col :span="8" class="productTitle">电话{{ index + 1 }}</el-col>
                      <el-col :span="16" class="productSelect">
                        <span class="break-word">{{ item.phone }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="productTitle">邮箱</el-col>
                      <el-col :span="16" class="productSelect">
                        <span class="break-word">{{ feedbackUserMsg.email }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="productTitle">微信</el-col>
                      <el-col :span="16" class="productSelect">
                        <span class="break-word">{{ feedbackUserMsg.wxAccount }}</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="productTitle">备注</el-col>
                      <el-col :span="16" class="productSelect">
                        <span class="break-word">{{ feedbackUserMsg.remark }}</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" class="labelSet">
              <el-form-item label="紧急状态" prop="taskUrgencyLevel">
                <el-select v-model="form.taskUrgencyLevel" placeholder="请选择紧急级别" style="width: 98%;">
                  <el-option :label="item.dictLabel" :value="item.id" :key="item.id"
                             v-for="item in taskUrgencyLevelDict"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>

            <el-col :span="12">
              <el-form-item label="指派给" prop="assignUser">
                <el-select
                  v-model="form.assignUser"
                  filterable
                  remote
                  clearable
                  style="width: 98%;"
                  placeholder="请选择指派人"
                  :remote-method="assignUserSearch">
                  <el-option
                    :label="item.nickName"
                    :value="item.id"
                    :key="item.id"
                    v-for="item in userList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="问题类型" prop="problemTypeList">
            <el-select v-model="form.problemTypeList" multiple :disabled="isEmpty(form.productId)"
                       :placeholder="isEmpty(form.productId)?'请先选择产品':'请选择问题类型'"
                       style="width: 99%;"
                       @change="problemTypeChange"
            >
              <el-option :label="item.problemName" :value="item.id" :key="item.id"
                         v-for="item in taskProblemDict"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务描述" prop="taskDesc" style="width:99%;" class="task-desc-form-item">
            <!-- <el-input
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
              v-model="form.taskDesc"
              maxlength="200"
              show-word-limit
            >
            </el-input> -->
            <my-editor
              v-if="visible"
              :editor-content="form.taskDesc"
              @editorInput="onEditorInput"></my-editor>
          </el-form-item>

          <el-form-item label="" prop="taskFileIdList" style="width:99%;">
            <el-upload
              class="upload-demo"
              :action="baseURL+'/crm/sys/file/uploadFile'"
              :on-change="handleChange"
              style="margin-top: 10px;"
              :file-list="fileList"
              :data="uploadFileParams"
              :auto-upload="true"
              :before-upload="beforeUpload"
              accept=".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,.docx"
              :on-success="uploadSuccess"
              :on-remove="handleFileRemove"
              :limit="10"
              :on-exceed="handleOnExceed"
            >
              <el-button size="mini">添加附件</el-button>
              <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
            </el-upload>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="截止时间" prop="endTime">
                <el-date-picker
                  v-model="form.endTime"
                  type="date"
                  :clearable="false"
                  :append-to-body="true"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  style="width: 100%;"
                  :picker-options="{
                    disabledDate: (time) => {
                      return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                  }">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="" prop="endTimeExt" label-width="10px">
                <el-select v-model="form.endTimeExt" style="width: 100%;">
                  <el-option label="上午" value="上午"></el-option>
                  <el-option label="下午" value="下午"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!--查看任务-->
    <view-task :task-id="taskId" v-if="type==1"></view-task>
    <add-custom-vue
      v-if="showAddCustom"
      :customer-id="form.customerId"
      :show-add-custom.sync="showAddCustom"
      @addCustom="addCustomCallback"></add-custom-vue>
  </div>

</template>

<script>
import { projectInfoApi, queryProjectListApi,projectContactsListApi } from '@/modules/crm/api/crm/project'
import { allUserListApi } from '@/modules/crm/api/system/user'
import {
  addTaskApi,
  getProblemDictApi,
  taskInfoApi,
  editTaskApi,
  contractContactsListApi } from '@/modules/crm/api/crm/task'
import { TASK_OBJ } from '@/modules/crm/utils/task'
import { addProductApi } from '@/modules/crm/api/crm/product'
import { delFile } from '@/api/system/fileUpload'
import ViewTask from '@/modules/crm/view/crm/task/components/viewTask'
import { queryContractCustomerListApi, queryCustomerProductListApi } from '../../../../api/crm/contract'
import MyEditor from '@/modules/crm/components/Editor'
import AddCustomVue from '@/modules/crm/view/crm/task/components/AddCustom.vue'
export default {
  name: 'NewConstructionTask',
  components: {
    ViewTask,
    MyEditor,
    AddCustomVue
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    taskId: {
      type: Number,
      required: false
    },
    taskInfo: {
      type: Object,
      required: false
    },
    // 1查看 2新增 3编辑
    type: {
      type: Number,
      required: true
    }
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal){
          this.reset()
          this.initData()
        }
      }
    }
  },
  computed:{
    //当前选择的提出人信息
    feedbackUserMsg(){
      let find = this.feedbackUserList.find(item => {
        return item.contactsKey == this.form.feedbackUserKey
      })
      console.log(find)
      return find?find:{}
    }
  },
  data() {
    var validateEndTimeExt=(rule, value, callback)=>{
      let now=new Date()
      let endTime=new Date(this.form.endTime+' '+(value=='上午'?'12:00:00':'23:59:59'));
      if(endTime>=now){
        callback();
      }else{
        callback(new Error('时间必须大于当前时间'));
      }
    };
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      //文件上传的额外参数
      uploadFileParams: {
        fileClassify: this.dictDataConst.SYS_FILE_CLASSIFY.TASK_FILE,
        tempFlag: 1
      },
      //任务服务类型字典
      taskServeTypeDict: this.getDict(this.dictConst.TASK_SERVE_TYPE),
      //任务紧急级别字典
      taskUrgencyLevelDict: this.getDict(this.dictConst.TASK_URGENCY_LEVEL),
      //任务问题类型字典
      taskProblemDict: [],
      //用户列表
      userList: [],
      //终端客户列表
      contractCustomerList:[],
      //当前终端客户关联的产品列表
      productList:[],
      //当前选择的产品信息
      productInfo: {},
      //当前全部提出人列表
      feedbackUserList:[],

      form: this.$deepClone(TASK_OBJ),
      rules: {
        taskName: [
          { required: true, message: '请输入任务标题', trigger: 'change' }
        ],
        customerId: [
          { required: true, message: '请选择终端客户', trigger: 'change' }
        ],
        productKey: [
          { required: true, message: '请选择产品', trigger: 'change' }
        ],
        taskUrgencyLevel: [
          { required: true, message: '请选择任务紧急级别', trigger: 'change' }
        ],
        assignUser: [
          { required: true, message: '请选择指派人', trigger: 'change' }
        ],
        problemTypeList: [
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        taskDesc: [
          { required: true, message: '请输入问题描述', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择截止时间', trigger: 'change' }
        ],
        endTimeExt: [
          { validator: validateEndTimeExt, trigger: 'blur' }
        ]

      },
      //当前选择的文件列表
      fileList: [],
      //上传成功的文件列表
      uploadFileList: [],
      proposerList: [],
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      showAddCustom: false
    }
  },
  async created() {
    console.log('editTask')
    console.log(this.taskInfo)
    this.initData()
  },
   mounted() {
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onAddCustom() {
      this.showAddCustom = true
    },
    initData() {
      this.form.contractId = this.taskInfo?.contractId
      this.getAllUser()
      //发布任务弹窗关闭事件
      this.eventBus.$on(this.eventConst.task.closeAddDrawer, () => {
        this.reset()
      })
      this.fetchProposer()
    },
    handleChange(file, fileList) {
      //this.fileList = fileList.slice(-3)
    },
    onEditorInput(val) {
      this.form.taskDesc = val
    },
    /** 表单重置 */
   async reset() {
      //taskId>0 是编辑
      if (this.taskId > 0) {
        let res = await taskInfoApi(this.taskId);
        if(res.data.feedbackUser){
          res.data.feedbackUserKey = res.data.feedbackUserType+"#"+res.data.feedbackUser.id
        }
        res.data.productKey=res.data.contractId+"#"+res.data.productId

        this.form = this.$deepClone(res.data)
        Object.assign(this.form,res.data)
        this.form.feedbackUser=res.data.feedbackUser?.id

        //终端客户下拉列表
        this.contractCustomerList = [{
          orgId: res.data.customerId,
          orgName: res.data.customerName,
        }]
        //获取项目关联的全部联系人信息，生成提出人下拉列表
        // this.refreshProjectContactsList()

        //根据终端客户id，填充产品下拉列表
        queryCustomerProductListApi(res.data.customerId).then(res=>{
          this.productList = res.data
          this.productList.forEach(item=>{
            //因为机构下可能会有两个相同的产品，但是分属于不同的项目
            item.productKey=item.contractId+"#"+item.productId
            if(item.contractId==this.form.contractId && item.productId==this.form.productId){
              this.productInfo=item
            }
          })
        })

        this.getTaskProblemDict()
        this.$set(this.form,"problemTypeList",[])
        res.data.problemList.forEach(item => {
          this.form.problemTypeList.push(item.problemId)
        })
        this.fileList = []
        res.data.taskFileList.forEach(item => {
          let fileItem = {
            fileId:  item.fileId,
            name:item.fileName,
            status: 'success',
            uid: item.fileId
          }
          this.fileList.push(fileItem)
          this.uploadFileList.push(fileItem)
        })
        this.clearFormValidate('form')
        return
      }

      //新增
      this.resetForm('form')
      this.fileList=[]
      this.uploadFileList=[]
      this.productInfo={}
      this.form = this.$deepClone(TASK_OBJ)
      //默认截止时间
      let defEndTime = new Date(new Date().getTime() + 0 * 24 * 60 * 60 * 1000)
      this.form.endTime = this.parseTime(defEndTime, '{y}-{m}-{d}')
      this.form.taskUrgencyLevel=this.getDictPrimaryItemId(this.dictConst.TASK_URGENCY_LEVEL)
    },
    onSubmit() {
      console.log('submit!', this.form)
      console.log('fileList!', this.uploadFileList)
      this.$refs['form'].validate(async valid => {
        if (valid) {

          //添加文件id列表
          this.form.taskFileIdList=[]
          this.uploadFileList.forEach(item => {
            this.form.taskFileIdList.push(item.fileId)
          })

          let res
          if(this.taskId>0){
            res = await editTaskApi(this.form)
            this.eventBus.$emit(this.eventConst.task.editSucceed)
          }else{
            res = await addTaskApi(this.form)
            this.eventBus.$emit(this.eventConst.task.addSucceed)
            this.reset()
          }
          this.msgSuccess()
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    contractClassifySelect(val) {
      this.form.contractClassify = val
    },
    contractTypeSelect(val) {
      this.form.contractType = val
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    },

    //查询终端客户名称列表
    queryContractCustomerList(searchValue) {
      if (!this.isEmpty(searchValue)) {
        queryContractCustomerListApi({ searchValue }).then(res => {
          this.contractCustomerList = res.data
        })
      } else {
        this.contractCustomerList = []
      }
    },
    async assignUserSearch(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.userList = res.data
      })
    },
    // 获取提出人
    async fetchProposer(val) {
      console.log('获取提出人')
      if (this.form.contractId === '' || !this.form.contractId) {
        return
      }
      const obj = {
        contractId: this.form.contractId,
        searchValue: val ? val : ''
      }
      let res = await contractContactsListApi(obj)
      if (res.code === 200) {
        res.data.forEach(item=>{
          item.contactsKey=item.contactsType + "#" + item.id
        })
        this.proposerList = res.data || []
        if (val === '' || !val) {
          this.feedbackUserList = res.data || []
        }
      }
    },
    //获取问题类型
    getTaskProblemDict() {
      getProblemDictApi(this.form.productId).then(res => {
        this.taskProblemDict = res.data
        if (this.isEmpty(this.taskProblemDict)) {
          this.msgInfo('当前产品没有配置关联的问题类型')
        }
      })
    },
    //终端客户变化
    customerChange(customerId){
      if (customerId === '') {
        return
      }
     //根据终端客户id查询关联的全部产品信息
      queryCustomerProductListApi(customerId).then(res=>{
        this.productList=res.data
        if (this.isEmpty(this.productList)) {
          this.msgInfo('当前终端客户没有关联的产品')
        }
        this.productList.forEach(item=>{
          //因为机构下可能会有两个相同的产品，但是分属于不同的项目
          item.productKey=item.contractId+"#"+item.productId
        })
      })
    },
    //刷新项目关联的全部联系人信息
    refreshProjectContactsList(){
      //获取当前项目关联的全部联系人信息
      let projectId=this.form.projectId
      if(!projectId){
        this.feedbackUserList=[]
        return
      }
      projectContactsListApi(projectId).then(res=>{
        if (this.isEmpty(res.data)) {
          this.msgInfo('当前项目未设置客户方联系人')
        }

        res.data.forEach(item=>{
          item.contactsKey=item.contactsType+"#"+item.id
        })
        this.feedbackUserList = res.data
      })
    },
    //提出人变化
    feedbackUserChange(feedbackUser) {
      console.log('0000')
      console.log(feedbackUser)
      console.log(this.feedbackUserList)
      let find= this.feedbackUserList.find(item => {
        return item.contactsKey == feedbackUser
      })
      if(find){
        this.form.feedbackUser = find.id
        this.form.feedbackUserType = find.contactsType
        this.form.feedbackUserKey = find.contactsKey
      }else{
        this.form.feedbackUser = undefined
        this.form.feedbackUserType = undefined
        this.form.feedbackUserKey = undefined
      }

    },
    //产品变化
    async productChange(productKey) {
     console.log("this.productList",this.productList)

     //根据 productKey 查询产品列表
      this.productInfo = this.productList.find(item => {
        return item.productKey == productKey
      })
      this.form.projectId = this.productInfo.projectId
      this.form.productId = this.productInfo.productId
      this.form.contractId = this.productInfo.contractId
      //刷新问题类型字典
      this.getTaskProblemDict()
      //刷新项目关联的全部联系人信息
      // this.refreshProjectContactsList()
      await this.fetchProposer()
      //清空旧的提出人信息
      this.feedbackUserChange()
      //清空问题类型，因为不同产品对应的问题类型字典不一致
      this.$set(this.form,"problemTypeList",[])
    },
    //问题类型变化
    problemTypeChange(problemTypeList) {
      let taskDescStr = ''
      this.taskProblemDict.forEach(item => {
        if (problemTypeList.indexOf(item.id) >= 0  &&  !this.isEmpty(item.problemDesc)) {
          taskDescStr += item.problemName
          taskDescStr += '\r\n'
          taskDescStr += item.problemDesc
          taskDescStr += '\r\n'
        }
      })
      this.form.taskDesc = taskDescStr
    },
    //web按上传前的校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传附件大小不能超过 50MB')
        return false
      }
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {

      if (res.code == 200) {
        this.msgSuccess('上传成功')
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: 'success',
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除文件
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileList.splice(i, 1)
            return true
          }
        })
      }

    },
    //文件超出个数限制时
    handleOnExceed(file, fileList){
     this.msgError("文件个数最大10个")
    },
    //移除文件
    handleFileRemove(file, fileList) {
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          this.uploadFileList.splice(i, 1)
          return true
        }
      })

    },
    //上午是否可选
    endTimeExtDisabled(){
      let now=new Date()
      if(this.form.endTime>now){
        return false;
      }
      return now.getHours()>12?true:false
    },
    // 新建客户联系人回调成功
    async addCustomCallback() {
      await this.fetchProposer()
    }

  }
}
</script>

<style lang="scss" scoped>

.drawer_wrap {
  padding: 0 !important;
  .plain-primary-btn {
    padding: 4px 12px !important;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #F1880D;
    font-weight: 400;
    font-size: 12px;
    color: #F1880D;
    &:hover {
      background-color: #F1880D;
      color: #fff;
    }
  }
  .newBuildContractWrap {
    padding: 22px 0px;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
    .newConstructionContract {
      font-size: 24px;
      font-weight: 600;
      color: #3C3C3C;
      line-height: 24px;
    }

    .el-button--warning.is-plain {
      background: none;
    }

    .saveBtn {
      border: 1px solid #F1880D;
      color: #F1880D;

      &:hover {
        border: 1px solid #d0760c;
        color: #d0760c;
      }

      &:active {
        border: 1px solid #d0760c;
        color: #d0760c;
      }
    }
  }
}


.task_wrap {
  padding: 20px 0;

  .contactList {
    display: flex;
    flex-wrap: wrap;
  }

  .productWrap {
    background: #F8F8F8;
    border-radius: 4px 4px 4px 4px;
    margin: 0 10px 10px 0;
    width: 280px;
    padding: 6px 0;
    display: flex;

    .productItem {
      float: left;
      width: 260px;
      padding: 0 10px;
      border-right: 1px solid #EAECFD;

      .el-row {
        margin-bottom: 4px;
      }

      .productTitle {
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }

    .delBtnWrap {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .productWrap01 {
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #EFEFEF;
    margin: 0 10px 10px 0;
    width: 223px;
    padding: 6px 10px;
    display: flex;

    .productItem {
      float: left;

      .productTitle {
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }

      .productSelect {
        // height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #5D5D5D;
      }
    }
  }
  
}

.creatInstitution {
  margin-left: 10px;
  font-size: 14px;
  color: #F1880D;
}

.shouyao {
  //margin-top: 9px;
}

.setBtn, .addBtn, .delBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f1f1f1;
  line-height: 16px;
  color: #999;
  font-size: 12px;
  text-align: center;
  margin: 8px 5px 0;
  cursor: pointer;
}

::v-deep.labelSet {
  .el-form-item__label {
    line-height: 20px !important;
  }
}

.closeBtn {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #EBEBEB;
  line-height: 16px;
  color: #F23232;
  font-size: 8px;
  text-align: center;
  cursor: pointer;
}

.break-word{
  word-wrap: break-word;
  display: inline-block;
  width: 100%;
}
</style>
