var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionContract_wrap" },
    [
      _c("div", { staticClass: "editTaskWrap" }, [
        _c(
          "div",
          {
            staticClass: "editTaskLeft",
            staticStyle: { width: "calc(100% - 258px)" },
          },
          [
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("任务标题"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.taskName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("任务编号"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.id)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [
                    _vm._v("项目名称"),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 21 } }, [
                  _c("span", { staticClass: "editInput break-word" }, [
                    _vm._v(_vm._s(_vm.form.projectName)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [
                    _vm._v("终端客户"),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 21 } }, [
                  _c("span", { staticClass: "editInput break-word" }, [
                    _vm._v(_vm._s(_vm.form.customerName)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.feedbackUser.name,
                    expression: "form.feedbackUser.name",
                  },
                ],
                staticClass: "editProductWrap_contact",
              },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [_vm._v("提出人")]),
                ]),
                _c("el-col", { attrs: { span: 21 } }, [
                  _c("div", { staticClass: "productWrap01" }, [
                    _c(
                      "div",
                      { staticClass: "productItem" },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "contactName break-word",
                                attrs: { span: 12 },
                              },
                              [_vm._v(_vm._s(_vm.form.feedbackUser.name))]
                            ),
                            _c(
                              "el-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.form.feedbackUser.isPrimary,
                                    expression: "form.feedbackUser.isPrimary",
                                  },
                                ],
                                staticStyle: { "text-align": "right" },
                                attrs: { span: 12 },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      padding: "0",
                                      float: "right",
                                    },
                                    attrs: { type: "text", size: "mini" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "icon svg-icon shouyao",
                                        staticStyle: {
                                          width: "38px",
                                          height: "20px",
                                        },
                                        attrs: { "aria-hidden": "true" },
                                      },
                                      [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href": "#icon-shouyao",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productTitle",
                                attrs: { span: 6 },
                              },
                              [_vm._v("部门")]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 18 },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.feedbackUser.departmentName)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productTitle",
                                attrs: { span: 6 },
                              },
                              [_vm._v("职务")]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 18 },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.feedbackUser.postName)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.getList(_vm.form.feedbackUser, "phoneList"),
                          function (item, index) {
                            return _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "productTitle",
                                    attrs: { span: 6 },
                                  },
                                  [_vm._v("电话" + _vm._s(index + 1))]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "productSelect",
                                    attrs: { span: 18 },
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.phone))])]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productTitle",
                                attrs: { span: 6 },
                              },
                              [_vm._v("邮箱")]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 18 },
                              },
                              [
                                _c("span", { staticClass: "break-word" }, [
                                  _vm._v(_vm._s(_vm.form.feedbackUser.email)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productTitle",
                                attrs: { span: 6 },
                              },
                              [_vm._v("微信")]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 18 },
                              },
                              [
                                _c("span", { staticClass: "break-word" }, [
                                  _vm._v(
                                    _vm._s(_vm.form.feedbackUser.wxAccount)
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productTitle",
                                attrs: { span: 6 },
                              },
                              [_vm._v("备注")]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 18 },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "remarkStyle",
                                    attrs: {
                                      title: _vm.form.feedbackUser.remark,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.form.feedbackUser.remark) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("产品名称"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.productName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getList(_vm.form, "extrasList").length > 0,
                        expression: "getList(form,'extrasList').length>0",
                      },
                    ],
                    attrs: { span: 12 },
                  },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("增值包"),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      _vm._l(
                        _vm.getList(_vm.form, "extrasList"),
                        function (item, index) {
                          return _c(
                            "span",
                            {
                              key: item.id,
                              staticClass: "editInput",
                              staticStyle: { "padding-right": "4px" },
                              on: {
                                click: function ($event) {
                                  return _vm.contractTypeSelect(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.extrasName) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("产品状态"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.productStatusName)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("服务类型"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.serveTypeName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("当前指派"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.assignUserName)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("状态"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 21 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.taskStatusName)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("紧急程度"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.taskUrgencyLevelName)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [
                    _vm._v("问题类型"),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 21 } },
                  _vm._l(
                    _vm.getList(_vm.form, "problemList"),
                    function (item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "editInput",
                          staticStyle: { "padding-right": "4px" },
                        },
                        [_vm._v(_vm._s(item.problemName))]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap_contact" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [
                    _vm._v("任务描述"),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 21 } }, [
                  _c("div", {
                    ref: "taskDescRef",
                    staticClass: "editInput task-desc-wrap",
                    domProps: { innerHTML: _vm._s(_vm.form.taskDesc) },
                    on: { click: _vm.test },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("span", { staticClass: "editLabel" }, [_vm._v("附件")]),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 21 } },
                  _vm._l(
                    _vm.getList(_vm.form, "taskFileList"),
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "attachment" },
                        [
                          _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                          _c(
                            "span",
                            {
                              staticClass: "editInput",
                              staticStyle: {
                                "margin-left": "4px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onPreviewFile(item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.fileName))]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "editProductWrap" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("开始时间"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.startTime)),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "editInput",
                          staticStyle: { "padding-left": "4px" },
                        },
                        [_vm._v(_vm._s(_vm.form.startTimeExt))]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("span", { staticClass: "editLabel" }, [
                        _vm._v("截止时间"),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c("span", { staticClass: "editInput" }, [
                        _vm._v(_vm._s(_vm.form.endTime)),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "editInput",
                          staticStyle: { "padding-left": "4px" },
                        },
                        [_vm._v(_vm._s(_vm.form.endTimeExt))]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "editTaskRight" },
          [
            _c(
              "div",
              { staticClass: "historyRecord" },
              [
                _c("span", [_vm._v("历史记录")]),
                _c(
                  "el-button",
                  {
                    staticClass: "order-btn",
                    attrs: { size: "mini" },
                    on: { click: _vm.orderClick },
                  },
                  [
                    _vm.isAscending
                      ? _c(
                          "svg",
                          {
                            staticClass: "icon svg-icon ascending-sort",
                            staticStyle: {
                              width: "14px",
                              height: "14px",
                              "fill-color": "#616161",
                              display: "flex",
                              "align-items": "center",
                            },
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-ascending-sort" },
                            }),
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            staticClass: "icon svg-icon descending-icon",
                            staticStyle: {
                              width: "14px",
                              height: "14px",
                              "fill-color": "#616161",
                              display: "flex",
                              "align-items": "center",
                            },
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-descending-icon" },
                            }),
                          ]
                        ),
                  ]
                ),
              ],
              1
            ),
            _vm._l(_vm.taskHistory, function (item, index) {
              return _c("div", { key: index, staticClass: "toDoDeal" }, [
                _c("div", { staticClass: "lineWrap" }, [
                  _c(
                    "span",
                    {
                      staticClass: "line_circle",
                      class: _vm.getDictItemCssClass(
                        _vm.dictConst.TASK_STATUS,
                        item.taskStatus
                      ),
                    },
                    [_vm._v("●")]
                  ),
                  _c("span", { staticClass: "line_segment" }),
                ]),
                _c(
                  "div",
                  { staticClass: "toDoDealContent" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "statusType",
                        class: _vm.getDictItemCssClass(
                          _vm.dictConst.TASK_STATUS,
                          item.taskStatus
                        ),
                      },
                      [_vm._v(_vm._s(item.taskStatusName))]
                    ),
                    _c("div", { staticClass: "timeStyle" }, [
                      _vm._v(_vm._s(item.createTime)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "peopleToPeople" },
                      [
                        [
                          _vm.dictDataConst.TASK_STEP_TYPE.OVER_NO_RESOLVED ==
                          item.stepType
                            ? _c("p", [_vm._v(" 由系统创建 ")])
                            : _c(
                                "p",
                                [
                                  _vm._v(" 由"),
                                  _c("span", [_vm._v(_vm._s(item.createBy))]),
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.stepType ==
                                            _vm.dictDataConst.TASK_STEP_TYPE
                                              .REDEPLOY
                                            ? "转派"
                                            : "创建"
                                        ) +
                                        " "
                                    ),
                                  ],
                                  _vm._v(" ，指派给"),
                                  _c("span", [
                                    _vm._v(_vm._s(item.assignUserName)),
                                  ]),
                                ],
                                2
                              ),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.serveType > 0,
                                  expression: "item.serveType>0",
                                },
                              ],
                            },
                            [_vm._v("服务类型:" + _vm._s(item.serveTypeName))]
                          ),
                        ],
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isEmpty(item.solution),
                            expression: "!isEmpty(item.solution)",
                          },
                        ],
                        staticClass: "remark",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "link-solution",
                            on: {
                              click: function ($event) {
                                return _vm.onShowSolution(item)
                              },
                            },
                          },
                          [_vm._v("点击查看解决办法")]
                        ),
                      ]
                    ),
                    _vm._l(item.taskFileList, function (fileItem, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "attachment" },
                        [
                          _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                          _c(
                            "span",
                            {
                              staticClass: "editInput",
                              staticStyle: {
                                "margin-left": "4px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onPreviewFile(fileItem)
                                },
                              },
                            },
                            [_vm._v(_vm._s(fileItem.fileName))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            }),
          ],
          2
        ),
      ]),
      _c("preview-file", {
        attrs: {
          "is-show-dialog": _vm.showPreviewFile,
          "file-id": _vm.previewFileId,
        },
        on: {
          "update:isShowDialog": function ($event) {
            _vm.showPreviewFile = $event
          },
          "update:is-show-dialog": function ($event) {
            _vm.showPreviewFile = $event
          },
        },
      }),
      _c("show-solution-vue", {
        attrs: {
          "is-show-dialog": _vm.showSolution,
          solution: _vm.currentSolution,
          "current-item": _vm.currentHistory,
          "show-type": _vm.showType,
          "image-url": _vm.imageUrl,
        },
        on: { close: _vm.closeSolutionDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }