var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "el-form",
        { ref: "queryForm", attrs: { model: _vm.queryParams, inline: true } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "searchValue" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: "请输入菜单名称/权限代码",
                  clearable: "",
                  maxlength: "30",
                  size: "small",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.searchValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "searchValue", $$v)
                  },
                  expression: "queryParams.searchValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择菜单状态",
                    clearable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.queryParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "status", $$v)
                    },
                    expression: "queryParams.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (dict) {
                  return _c("el-option", {
                    key: dict.dictValue,
                    attrs: { label: dict.dictLabel, value: dict.dictValue },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-search", size: "small" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("检索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "crm_btn_style",
                  attrs: { icon: "el-icon-circle-plus-outline", size: "small" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 新增 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.menuList,
            border: "",
            stripe: "",
            "header-cell-style": { "text-align": "center" },
            "row-key": "id",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "menuName",
              label: "菜单名称",
              align: "left",
              "min-width": "160",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: "菜单ID",
              width: "100",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "perms",
              label: "权限代码",
              "min-width": "120",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "menuType",
              label: "类型",
              width: "80",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.menuType == "M"
                            ? "目录"
                            : scope.row.menuType == "C"
                            ? "菜单"
                            : "权限"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "component", label: "组件路径", "min-width": "120" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNum",
              label: "排序",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "path",
              label: "路由地址",
              align: "left",
              "min-width": "160",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              width: "60",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c("el-tag", [_vm._v("正常")])
                      : _vm._e(),
                    scope.row.status == 0
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("停用"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              prop: "createTime",
              align: "center",
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "220",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#ed923a" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#ed923a" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd(scope.row)
                          },
                        },
                      },
                      [_vm._v("新增 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#ff0009" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "80%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.menuOptions,
                              clearable: false,
                              normalizer: _vm.normalizer,
                              "show-count": true,
                              placeholder: "选择上级",
                            },
                            model: {
                              value: _vm.form.parentId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parentId", $$v)
                              },
                              expression: "form.parentId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型", prop: "menuType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.menuType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "menuType", $$v)
                                },
                                expression: "form.menuType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "M" } }, [
                                _vm._v("目录"),
                              ]),
                              _c("el-radio", { attrs: { label: "C" } }, [
                                _vm._v("菜单"),
                              ]),
                              _c("el-radio", { attrs: { label: "F" } }, [
                                _vm._v("权限"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "图标" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-start",
                                    width: "460",
                                    trigger: "click",
                                  },
                                  on: {
                                    show: function ($event) {
                                      return _vm.$refs["iconSelect"].reset()
                                    },
                                  },
                                },
                                [
                                  _c("IconSelect", {
                                    ref: "iconSelect",
                                    on: { selected: _vm.selected },
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        placeholder: "点击选择图标",
                                        readonly: "",
                                      },
                                      slot: "reference",
                                      model: {
                                        value: _vm.form.icon,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "icon", $$v)
                                        },
                                        expression: "form.icon",
                                      },
                                    },
                                    [
                                      _vm.form.icon
                                        ? _c("svg-icon", {
                                            staticClass: "el-input__icon",
                                            staticStyle: {
                                              height: "32px",
                                              width: "16px",
                                            },
                                            attrs: {
                                              slot: "prefix",
                                              "icon-class": _vm.form.icon,
                                            },
                                            slot: "prefix",
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-search el-input__icon",
                                            attrs: { slot: "prefix" },
                                            slot: "prefix",
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "menuName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入名称" },
                            model: {
                              value: _vm.form.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuName", $$v)
                              },
                              expression: "form.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示排序", prop: "orderNum" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.orderNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderNum", $$v)
                              },
                              expression: "form.orderNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "路由地址", prop: "path" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入路由地址" },
                                model: {
                                  value: _vm.form.path,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "path", $$v)
                                  },
                                  expression: "form.path",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.form.menuType == "C"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "组件路径", prop: "component" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入组件路径" },
                                model: {
                                  value: _vm.form.component,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "component", $$v)
                                  },
                                  expression: "form.component",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "显示状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.visible,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "visible", $$v)
                                    },
                                    expression: "form.visible",
                                  },
                                },
                                _vm._l(_vm.visibleOptions, function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.dictValue,
                                      attrs: { label: dict.dictValue },
                                    },
                                    [_vm._v(_vm._s(dict.dictLabel) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.dictValue,
                                  attrs: { label: dict.dictValue },
                                },
                                [_vm._v(_vm._s(dict.dictLabel) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "background-color": "#F1880D",
                    "border-color": "#F1880D",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }