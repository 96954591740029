var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newConstructionContract_wrap" }, [
    _c("div", { staticClass: "editTaskWrap" }, [
      _c(
        "div",
        { staticClass: "editTaskLeft" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "任务标题",
                            prop: "contractClassify",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "98%" },
                            model: {
                              value: _vm.form.contractName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractName", $$v)
                              },
                              expression: "form.contractName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "任务编号" } }, [
                        _c("span", [_vm._v("124")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "purchaser" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "98%" },
                              attrs: { placeholder: "请选择机构名称" },
                              model: {
                                value: _vm.form.purchaser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "purchaser", $$v)
                                },
                                expression: "form.purchaser",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "区域一", value: "shanghai" },
                              }),
                              _c("el-option", {
                                attrs: { label: "区域二", value: "beijing" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "终端客户" } }, [
                        _c("span", [_vm._v("上海市嘉定区安亭医院")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提出人", prop: "purchaser" } },
                        [
                          _c("div", { staticClass: "productWrap01" }, [
                            _c(
                              "div",
                              { staticClass: "productItem" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("姓名")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择机构名称",
                                              size: "mini",
                                            },
                                            model: {
                                              value: _vm.form.purchaser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "purchaser",
                                                  $$v
                                                )
                                              },
                                              expression: "form.purchaser",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "区域一",
                                                value: "shanghai",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "区域二",
                                                value: "beijing",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("部门")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("精神内科")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("职务")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("药师")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("电话1")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("152334538909")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("123242@163.com")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("微信")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("zhangzen")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [_c("span", [_vm._v("备注内容")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "labelSet", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "contractName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "98%" },
                              attrs: { placeholder: "请选择机构名称" },
                              model: {
                                value: _vm.form.purchaser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "purchaser", $$v)
                                },
                                expression: "form.purchaser",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "区域一", value: "shanghai" },
                              }),
                              _c("el-option", {
                                attrs: { label: "区域二", value: "beijing" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "增值包" } },
                        _vm._l(["基因检测增值包"], function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "normal_btn",
                              on: {
                                click: function ($event) {
                                  return _vm.contractTypeSelect(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "labelSet", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "服务类型", prop: "contractName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "98%" },
                              attrs: { placeholder: "请选择机构名称" },
                              model: {
                                value: _vm.form.purchaser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "purchaser", $$v)
                                },
                                expression: "form.purchaser",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "区域一", value: "shanghai" },
                              }),
                              _c("el-option", {
                                attrs: { label: "区域二", value: "beijing" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "指派给", prop: "contractName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "98%" },
                              attrs: { placeholder: "请选择机构名称" },
                              model: {
                                value: _vm.form.purchaser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "purchaser", $$v)
                                },
                                expression: "form.purchaser",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "区域一", value: "shanghai" },
                              }),
                              _c("el-option", {
                                attrs: { label: "区域二", value: "beijing" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "问题类型", prop: "purchaser" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "99%" },
                      attrs: { placeholder: "请选择购买方(甲方)" },
                      model: {
                        value: _vm.form.purchaser,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "purchaser", $$v)
                        },
                        expression: "form.purchaser",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "区域一", value: "shanghai" },
                      }),
                      _c("el-option", {
                        attrs: { label: "区域二", value: "beijing" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "99%" },
                  attrs: { label: "任务描述", prop: "orderTime" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入内容",
                      maxlength: "500",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  }),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "on-change": _vm.handleChange,
                        "file-list": _vm.fileList,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "mini" } }, [
                        _vm._v("添加附件"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "截止时间", prop: "orderTime" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "date", placeholder: "选择日期" },
                            model: {
                              value: _vm.form.orderTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderTime", $$v)
                              },
                              expression: "form.orderTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "状态" } }, [
                        _c("span", [_vm._v("待处理")]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "editTaskRight" }, [
      _c("div", { staticClass: "historyRecord" }, [_vm._v("历史记录")]),
      _c("div", { staticClass: "toDoDeal" }, [
        _c("div", { staticClass: "lineWrap" }, [
          _c("span", { staticClass: "line_circle" }),
          _c("span", { staticClass: "line_segment" }),
        ]),
        _c("div", { staticClass: "toDoDealContent" }, [
          _c("div", { staticClass: "statusType" }, [_vm._v("待处理")]),
          _c("div", { staticClass: "timeStyle" }, [
            _vm._v("2023-03-02 14:53:07"),
          ]),
          _c("div", { staticClass: "peopleToPeople" }, [
            _vm._v("由"),
            _c("span", [_vm._v("周芸")]),
            _vm._v("创建，指派给"),
            _c("span", [_vm._v("张沪生")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "processed" }, [
        _c("div", { staticClass: "lineWrap" }, [
          _c("span", { staticClass: "line_circle" }),
          _c("span", { staticClass: "line_segment" }),
        ]),
        _c("div", { staticClass: "toDoDealContent" }, [
          _c("div", { staticClass: "statusType" }, [_vm._v("已处理")]),
          _c("div", { staticClass: "timeStyle" }, [
            _vm._v("2023-03-02 14:53:07"),
          ]),
          _c("div", { staticClass: "peopleToPeople" }, [
            _vm._v("由"),
            _c("span", [_vm._v("周芸")]),
            _vm._v("创建，指派给"),
            _c("span", [_vm._v("张沪生")]),
          ]),
          _c("div", { staticClass: "remark" }, [
            _vm._v(
              " 这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的 "
            ),
          ]),
          _c("div", { staticClass: "attachment" }, [
            _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
            _c(
              "span",
              {
                staticClass: "editInput",
                staticStyle: { "margin-left": "4px" },
              },
              [_vm._v("附件名称附件附件名称附件名称附件名称名称附件名称.doc")]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "resolved" }, [
        _c("div", { staticClass: "lineWrap" }, [
          _c("span", { staticClass: "line_circle" }),
          _c("span", { staticClass: "line_segment" }),
        ]),
        _c("div", { staticClass: "toDoDealContent" }, [
          _c("div", { staticClass: "statusType" }, [_vm._v("已解决")]),
          _c("div", { staticClass: "timeStyle" }, [
            _vm._v("2023-03-02 14:53:07"),
          ]),
          _c("div", { staticClass: "peopleToPeople" }, [
            _vm._v("由"),
            _c("span", [_vm._v("周芸")]),
            _vm._v("创建，指派给"),
            _c("span", [_vm._v("张沪生")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "TimeoutUnresolved" }, [
        _c("div", { staticClass: "lineWrap" }, [
          _c("span", { staticClass: "line_circle" }),
          _c("span", { staticClass: "line_segment" }),
        ]),
        _c("div", { staticClass: "toDoDealContent" }, [
          _c("div", { staticClass: "statusType" }, [_vm._v("超时未解决")]),
          _c("div", { staticClass: "timeStyle" }, [
            _vm._v("2023-03-02 14:53:07"),
          ]),
          _c("div", { staticClass: "peopleToPeople" }, [
            _vm._v("由"),
            _c("span", [_vm._v("周芸")]),
            _vm._v("创建，指派给"),
            _c("span", [_vm._v("张沪生")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }