<!--任务查看-->
<template>
  <div class="newConstructionContract_wrap">
    <div class="editTaskWrap">
      <div class="editTaskLeft" style="width:calc(100% - 258px)">
        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">任务标题</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.taskName }}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">任务编号</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.id }}</span>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">项目名称</span>
          </el-col>
          <el-col :span="21">
            <span class="editInput break-word" >{{ form.projectName }}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">终端客户</span>
          </el-col>
          <el-col :span="21">
            <span class="editInput break-word" >{{ form.customerName }}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap_contact" v-show="form.feedbackUser.name">
          <el-col :span="3">
            <span class="editLabel">提出人</span>
          </el-col>
          <el-col :span="21">
            <div class="productWrap01">
              <div class="productItem">
                <el-row>
                  <el-col :span="12" class="contactName break-word">{{ form.feedbackUser.name }}</el-col>
                  <el-col :span="12" style="text-align: right;" v-show="form.feedbackUser.isPrimary">
                    <el-button type="text" style="padding: 0;float: right;" size="mini">
                      <svg class="icon svg-icon shouyao" aria-hidden="true" style="width: 38px;height: 20px;">
                        <use xlink:href="#icon-shouyao"></use>
                      </svg>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">部门</el-col>
                  <el-col :span="18" class="productSelect">
                    <span>{{ form.feedbackUser.departmentName }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">职务</el-col>
                  <el-col :span="18" class="productSelect">
                    <span>{{ form.feedbackUser.postName }}</span>
                  </el-col>
                </el-row>
                <el-row
                  v-for="(item,index) in getList(form.feedbackUser,'phoneList')"
                  :key="index">
                  <el-col :span="6" class="productTitle">电话{{ index + 1 }}</el-col>
                  <el-col :span="18" class="productSelect">
                    <span>{{ item.phone }}</span>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="6" class="productTitle">邮箱</el-col>
                  <el-col :span="18" class="productSelect">
                    <span class="break-word">{{ form.feedbackUser.email }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">微信</el-col>
                  <el-col :span="18" class="productSelect">
                    <span class="break-word">{{ form.feedbackUser.wxAccount }}</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="productTitle">备注</el-col>
                  <el-col :span="18" class="productSelect">
                <span
                  class="remarkStyle"
                  :title="form.feedbackUser.remark"
                >
                  {{ form.feedbackUser.remark }}
                </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">产品名称</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.productName }}</span>
            </el-col>
          </el-col>


          <el-col :span="12" v-show="getList(form,'extrasList').length>0">
            <el-col :span="6">
              <span class="editLabel">增值包</span>
            </el-col>
            <el-col :span="18">
             <span
               v-for="(item,index) in getList(form,'extrasList')"
               :key="item.id"
               class="editInput" style="padding-right: 4px;"
               @click="contractTypeSelect(index)"
             >
              {{ item.extrasName }}
            </span>
            </el-col>
          </el-col>


        </el-row>

        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">产品状态</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.productStatusName }}</span>
            </el-col>
          </el-col>
        </el-row>

        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">服务类型</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.serveTypeName }}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">当前指派</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.assignUserName }}</span>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="3">
              <span class="editLabel">状态</span>
            </el-col>
            <el-col :span="21">
              <span class="editInput">{{ form.taskStatusName }}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">紧急程度</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.taskUrgencyLevelName }}</span>
            </el-col>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">问题类型</span>
          </el-col>
          <el-col :span="21">
            <span
              v-for="(item, index) in getList(form,'problemList')"
              :key="index"
              class="editInput"
              style="padding-right: 4px;">{{ item.problemName }}</span>
          </el-col>
        </el-row>
        <el-row class="editProductWrap_contact">
          <el-col :span="3">
            <span class="editLabel">任务描述</span>
          </el-col>
          <el-col :span="21">
            <!-- <span class="editInput">{{ form.taskDesc }}</span> -->
            <div
              ref="taskDescRef"
              v-html="form.taskDesc"
              class="editInput task-desc-wrap"
              @click="test"></div>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="3">
            <span class="editLabel">附件</span>
          </el-col>
          <el-col :span="21">
            <div
              v-for="(item, index) in getList(form,'taskFileList')"
              :key="index"
              class="attachment">
              <i class="iconfont">&#xe671;</i>
              <span class="editInput" style="margin-left: 4px;cursor: pointer;" @click="onPreviewFile(item)">{{ item.fileName }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="editProductWrap">
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">开始时间</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.startTime }}</span>
              <span class="editInput" style="padding-left: 4px;">{{ form.startTimeExt }}</span>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-col :span="6">
              <span class="editLabel">截止时间</span>
            </el-col>
            <el-col :span="18">
              <span class="editInput">{{ form.endTime }}</span>
              <span class="editInput" style="padding-left: 4px;">{{ form.endTimeExt }}</span>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <div class="editTaskRight">
        <div class="historyRecord">
          <span>历史记录</span>
          <el-button
            class="order-btn"
            size="mini"
            @click="orderClick">
            <svg
              v-if="isAscending"
              class="icon svg-icon ascending-sort"
              aria-hidden="true"
              style="width: 14px;height: 14px;fill-color: #616161;display: flex;align-items:center;">
              <use xlink:href="#icon-ascending-sort"></use>
            </svg>
            <svg
              v-else
              class="icon svg-icon descending-icon"
              aria-hidden="true"
              style="width: 14px;height: 14px;fill-color: #616161;display: flex;align-items:center;">
              <use xlink:href="#icon-descending-icon"></use>
            </svg>
          </el-button>
        </div>
        <div
          v-for="(item, index) in taskHistory"
          :key="index"
          class="toDoDeal">
          <div class="lineWrap">
            <span class="line_circle" :class="getDictItemCssClass(dictConst.TASK_STATUS,item.taskStatus)" >●</span>
            <span class="line_segment"></span>
          </div>
          <div class="toDoDealContent">
            <div class="statusType"
                 :class="getDictItemCssClass(dictConst.TASK_STATUS,item.taskStatus)">{{ item.taskStatusName }}</div>
            <div class="timeStyle">{{ item.createTime }}</div>
            <div class="peopleToPeople">
              <template>
                <p v-if="dictDataConst.TASK_STEP_TYPE.OVER_NO_RESOLVED==item.stepType">
                  由系统创建
                </p>
                <p v-else>
                  由<span>{{ item.createBy }}</span>
                  <template>
                    {{ item.stepType==dictDataConst.TASK_STEP_TYPE.REDEPLOY?'转派':'创建' }}
                  </template>
                  ，指派给<span>{{ item.assignUserName }}</span>
                </p>
                <p v-show="item.serveType>0">服务类型:{{item.serveTypeName}}</p>
              </template>

            </div>
            <div class="remark" v-show="!isEmpty(item.solution)">
              <!-- <p>{{ item.solution }}</p> -->
              <!-- <div v-html="item.solution"></div> -->
              <div class="link-solution" @click="onShowSolution(item)">点击查看解决办法</div>
            </div>
            <div
              v-for="(fileItem, index) in item.taskFileList"
              :key="index"
              class="attachment">
              <i class="iconfont">&#xe671;</i>
              <span class="editInput" style="margin-left: 4px;cursor: pointer;" @click="onPreviewFile(fileItem)">{{fileItem.fileName}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <preview-file
      :is-show-dialog.sync="showPreviewFile"
      :file-id="previewFileId"></preview-file>
    <show-solution-vue
      :is-show-dialog="showSolution"
      :solution="currentSolution"
      :current-item="currentHistory"
      :show-type="showType"
      :image-url="imageUrl"
      @close="closeSolutionDialog"></show-solution-vue>
  </div>
</template>

<script>
import { taskHistoryOrderApi, taskInfoApi } from '@/modules/crm/api/crm/task'
import PreviewFile from '@/modules/crm/view/crm/task/components/previewFile'
import ShowSolutionVue from '@/modules/crm/view/crm/task/components/ShowSolution.vue'
export default {
  name: 'ViewTask',
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  components: {
    PreviewFile,
    ShowSolutionVue
  },
  data() {
    return {
      //任务历史记录
      taskHistory: [],
      form: {
        feedbackUser: {}
      },
      //文件预览id
      previewFileId:0,
      showPreviewFile: false,
      currentSolution: '',
      showSolution: false,
      currentHistory: {},
      showType: 'solution',
      imageUrl: '',
      isAscending: true
    }
  },
  watch: {
    taskId: {
      deep: true,
      immediate: true,
     async  handler(newVal, oldVal) {
        await this.reset()
        await this.getTaskHistory()
        // this.$nextTick(() => {
        //   console.log(6)
        //   this.bindEvent()
        // })
      }
    }
  },
  created() {
  },
  mounted() {
    console.log('mounted')
    this.$nextTick(() => {
      setTimeout(() => {
        this.bindEvent()
      }, 1000)
    })
    // this.$nextTick().then(() => {
    //   setTimeout(() => {
    //     this.bindEvent()
    //   }, 0)
    // })
  },
  beforeDestroy() {
    // 移除动态绑定事件
    const elements = document.querySelectorAll('.task-desc-wrap img')
    // const elements = wrapDoms.getElementsByTagName('img')
    // console.log(wrapDoms)
    // console.log(wrapDoms[0])
    console.log(elements)
    for (let i = 0; i < elements.length; i++) {
      item.onclick = null
    }
  },
  methods: {
    /** 表单重置 */
    reset() {
      taskInfoApi(this.taskId).then(res => {
        if(!res.data.feedbackUser){
          res.data.feedbackUser={}
        }
        this.form = res.data
      })
    },
    getTaskHistory(orderType = 'ASCENDING') {
      // DESCENDING
      if (this.taskId > 0) {
        const obj = {
          taskId: this.taskId,
          orderType
        }
        taskHistoryOrderApi(obj).then(res => {
          this.taskHistory = res.data
        })
      }
    },
    onPreviewFile(fileItem) {
      this.previewFileId = fileItem.fileId
      this.showPreviewFile = true
    },
    onShowSolution(item) {
      console.log(item)
      this.currentSolution = item.solution
      this.currentHistory = this.$deepClone(item)
      this.showType = 'solution'
      this.showSolution = true
    },
    closeSolutionDialog() {
      this.showSolution = false
    },
    // 给v-html内的元素绑定事件
    bindEvent() {
      const elements = document.querySelectorAll('.task-desc-wrap img')
      // const elements = wrapDoms.getElementsByTagName('img')
      // console.log(wrapDoms)
      // console.log(wrapDoms[0])
      console.log(elements)
      console.log(this.$refs.taskDescRef)
      console.log(document.querySelectorAll('img'))
      for (let i = 0; i < elements.length; i++) {
        const item = elements[i]
        //事件动态绑定
        const url = item.src
        const html = item.outerHTML
        item.onclick = () => {
          this.handleTaskDescImgs(url, html)
        }
      }
    },
    handleTaskDescImgs(url, html) {
      console.log('click')
      this.showSolution = true
      this.showType = 'image'
      this.imageUrl = url
      this.imageHtml = html
    },
    async orderClick() {
      this.isAscending = !this.isAscending
      if (!this.isAscending) {
        await this.getTaskHistory('DESCENDING')
      } else {
        await this.getTaskHistory()
      }
    },
    test (event) {
      /* console.log(event.target.nodeName) // p
      console.log(event.target)
      const node = event.target
      console.log(node.src)
      if (event.target.nodeName === 'IMG') {
        // 获取触发事件对象的属性
        alert('a')
      }     */
    }
  }
}
</script>

<style lang="scss" scoped>
.newConstructionContract_wrap {
  padding: 20px 0;

  .editTaskWrap {
    display: flex;
  }

  .editTaskLeft {
    flex: 1;
  }

  .editTaskRight {
    width: 258px;
    padding: 10px;
    border: 1px solid #F0F0F0;

    .historyRecord {
      height: 18px;
      font-size: 14px;
      font-weight: 500;
      color: #3C3C3C;
      line-height: 18px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .order-btn {
      width: 22px;
      height: 22px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #DEDEDE;
      margin-left: 10px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .toDoDeal {
      padding: 0 0 10px 0px;
      display: flex;

      .lineWrap {
        width: 9px;
        height: auto;
        //background: #ffba00;
        display: flex;
        flex-direction: column;

        .line_circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-top: 8px;
        }

        .line_segment {
          margin-left: 2px;
          display: inline-block;
          width: 0px;
          flex: 1;
          border-left: 1px dashed #E6E6E6;
        }
      }

      .toDoDealContent {
        flex: 1;
        padding-left: 4px;

        .statusType {
          height: 18px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        .timeStyle {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;
        }

        .peopleToPeople {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;

          span {
            color: #5D5D5D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 0px 4px;
          }
        }
      }
    }

    .processed {
      padding: 0 0 10px 0px;
      display: flex;

      .lineWrap {
        width: 9px;
        height: auto;
        //background: #ffba00;
        display: flex;
        flex-direction: column;

        .line_circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-top: 8px;
        }

        .line_segment {
          margin-left: 2px;
          display: inline-block;
          width: 0px;
          flex: 1;
          border-left: 1px dashed #E6E6E6;
        }
      }

      .toDoDealContent {
        flex: 1;
        padding-left: 4px;

        .statusType {
          height: 18px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        .timeStyle {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;
        }

        .peopleToPeople {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;

          span {
            color: #5D5D5D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 0px 4px;
          }
        }



        .attachment {
          margin-top: 4px;
          padding: 2px 4px;

          .editInput, i {
            color: #686EC5;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .TimeoutUnresolved {
      padding: 0 0 10px 0px;
      display: flex;

      .lineWrap {
        width: 9px;
        height: auto;
        //background: #ffba00;
        display: flex;
        flex-direction: column;

        .line_circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-top: 8px;
        }

        .line_segment {
          margin-left: 2px;
          display: inline-block;
          width: 0px;
          flex: 1;
          border-left: 1px dashed #E6E6E6;
        }
      }

      .toDoDealContent {
        flex: 1;
        padding-left: 4px;

        .statusType {
          height: 18px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        .timeStyle {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;
        }

        .peopleToPeople {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;

          span {
            color: #5D5D5D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 0px 4px;
          }
        }
      }
    }

    .resolved {
      padding: 0 0 10px 0px;
      display: flex;

      .lineWrap {
        width: 9px;
        height: auto;
        //background: #ffba00;
        display: flex;
        flex-direction: column;

        .line_circle {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-top: 8px;
        }

        .line_segment {
          margin-left: 2px;
          display: inline-block;
          width: 0px;
          flex: 1;
          border-left: 1px dashed #E6E6E6;
        }
      }

      .toDoDealContent {
        flex: 1;
        padding-left: 4px;

        .statusType {
          height: 18px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        .timeStyle {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;
        }

        .peopleToPeople {
          color: #5D5D5D;
          font-size: 14px;
          line-height: 18px;

          span {
            color: #5D5D5D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 0px 4px;
          }
        }
      }
    }
  }

  .productWrap {
    .productItem {
      float: left;
      width: 260px;
      padding: 6px 10px;
      height: 76px;
      background: #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;

      .productTitle {
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }
  }
}

.editProductWrap {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .attachment {
    //margin-top: 4px;
    padding: 2px 4px;

    .editInput, i {
      color: #686EC5;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.editProductWrap_contact {
  margin-bottom: 20px;
}
.link-solution {
  color: #686EC5;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}
.editLabel {
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.editInput {
  color: #5D5D5D;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.task-desc-wrap {
  ::v-deep img {
    max-width: 100px;
    max-height: 100px;
    cursor: pointer;
  }
}
.orgListItem {
  color: #F1880D;
  cursor: pointer;
}

.productWrap01 {
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EFEFEF;
  margin: 0 10px 10px 0;
  width: 223px;
  padding: 6px 10px;
  display: flex;

  .productItem {
    float: left;
    width: 203px;

    el-row {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .contactName {
      color: #5D5D5D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .productTitle {
      height: 20px;
      font-size: 14px;
      color: #A8ABB2;
      line-height: 20px;
    }

    .productSelect {
      //height: 20px;
      font-size: 14px;
      color: #5D5D5D;
      line-height: 20px;

      .remarkStyle {
        width: 100%;
        display: inline-block;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        cursor: pointer;
      }
    }
  }
}

.remark {

  padding: 10px;
  word-wrap: break-word;
  width: 220px;
  background: #F7F7F7;
  color: #5D5D5D;
  font-size: 14px;
  line-height: 18px;
}

.break-word{
  word-wrap: break-word;
  display: inline-block;
  width: 100%;
}
</style>
