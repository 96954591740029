<!--批量导入项目组件-->
<template>
  <el-dialog
    title="项目导入"
    width="900px"
    :modal="true"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :before-close="cancelBtn"
    :show-close="!loading"
    :close-on-press-escape="false"
    :close-on-click-modal="false">
    <div v-loading="loading">
      <el-form ref="uploadFilesForm" status-icon :model="uploadFilesForm" label-width="120px">

        <el-row>
          <el-col :span="24">
            <el-form-item label="项目文件:" prop="file"
                          :rules="[{ required: true, message: '请选择.xls或.xlsx文件', trigger: 'change'}]"
            >
              <el-input v-model="uploadFilesForm.file" style="min-width: 400px;" id="file"
                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        clearable type="file"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="下载模版:">
              <span class="attachment" @click="downloadFile">项目导入模版.xlsx</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-button type="primary"
                       size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="uploadFiles"

            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small"
                       @click="cancelUpload"
            >取消
            </el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>

  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import {createImportReportApi, uploadAndImportProjectApi} from "../api/crm/project";
import {axiosDownload} from '@/utils/common'
import { downloadFileApi } from '@/modules/crm/api/system/file'

export default {
  name: "ImportProject",
  props: {
    dialogVisible: {
      type: Boolean,
      required: true
    }

  },
  components: {
    draggable,
  },
  watch: {
    dialogVisible: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          //this.reset()
        }
      }
    }
  },
  computed: {},
  data() {
    return {
      loading: false,
      uploadFilesForm: {
        file: undefined
      }


    }
  },
  created() {

  },
  methods: {
    cancelBtn() {
      this.dialogVisibleFn()
    },
    dialogVisibleFn() {
      this.$emit('close', false)
    },
    /*取消上传文件*/
    cancelUpload() {
      this.$refs['uploadFilesForm'].resetFields()
      this.dialogVisibleFn()
    },
    /*执行上传文件*/
    uploadFiles() {

      this.$refs['uploadFilesForm'].validate(valid => {
        //this.stopTimer()
        if (valid) {
          this.loading = true
          const form = new FormData()
          console.log("document.querySelector('#file').files[0]",document.querySelector('#file').files[0])
          let file=document.querySelector('#file').files[0]
          if(file.size>10*1024*1024){
            this.msgError('文件过大，不能超过10MB,请检查文件内容是否有误');
            this.loading = false
            return
          }
          form.append('file',file)
          uploadAndImportProjectApi(form).then(res => {

            this.dialogVisibleFn()
            if (res.code == 200) {
              if (res.data.error) {
                this.msgError('存在[' + res.data.error + ']条异常数据，导入失败，详情请查看导入结果报告')
                //生成报告
                createImportReportApi(res.data.batchNum).then(reportRes => {
                  this.loading = false
                  if (reportRes.type == 'application/json') {
                    this.msgError('生成导入结果报告异常')
                  } else {
                    let nowDate = this.parseTime(new Date().getTime(), '{y}{m}{d}{h}{i}{s}')
                    axiosDownload('项目批量导入结果报告' + nowDate + '.xlsx', reportRes)
                    this.msgSuccess('报告生成完成，请在浏览器下载列表中查看')
                  }
                })

              } else {
                this.loading = false
                this.msgSuccess('导入成功')
                this.$emit('refresh')
              }
            } else {
              this.loading = false
              this.msgError(res.msg)
            }


          })

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    downloadFile(){
      let fileId = 4003 // 项目模板
      downloadFileApi(fileId).then(res=>{
        if (res.type == 'application/json') {
          this.msgError('下载失败')
        }else{
          axiosDownload('项目导入模板.xlsx', res)
          this.msgSuccess('下载完成,请在浏览器的下载列表中查看')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 10px;
  }
  .attachment {
    color: #686EC5;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }
</style>
