// 产品管理
import request from '@/modules/crm/utils/request'


//产品:查询产品列表
export function productListApi(data) {
  return request({
    url: '/crm/crmProduct/productList',
    method: 'post',
    data: data
  })
}

//产品:新增
export function addProductApi(data) {
  return request({
    url: '/crm/crmProduct/addProduct',
    method: 'post',
    data: data
  })
}

//产品:修改
export function editProductApi(data) {
  return request({
    url: '/crm/crmProduct/editProduct',
    method: 'post',
    data: data
  })
}


//产品:产品详情
export function productInfoApi(productId) {
  return request({
    url: '/crm/crmProduct/productInfo/' + productId,
    method: 'post'
  })
}

//产品:删除
export function delProductApi(productId) {
  return request({
    url: '/crm/crmProduct/delProduct/' + productId,
    method: 'post'
  })
}

//产品:产品大类字典
export function productClassifyDictApi() {
  return request({
    url: '/crm/crmProduct/productClassifyDict',
    method: 'post',
  })
}

//产品:产品版本字典
export function productVersionDictApi() {
  return request({
    url: '/crm/crmProduct/productVersionDict',
    method: 'post',
  })
}
// 判断该产品是否关联合同
export function checkProContractApi(productId) {
  return request({
    url: '/crm/crmProduct/checkProductContract/' + productId,
    method: 'post',
  })
}
