var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: false,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("el-image", {
            attrs: {
              fit: "fill",
              src: _vm.getImgUrl(),
              "preview-src-list": _vm.getImgUrlList(),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }