var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "服务报告预览",
        width: "90%",
        "append-to-body": "",
        visible: _vm.dialogVisible,
        top: "5vh",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "6px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "crm_btn_style",
                  attrs: { icon: "el-icon-download", size: "small" },
                  on: { click: _vm.tableToExcel },
                },
                [_vm._v("下载服务报告")]
              ),
            ],
            1
          ),
          _c("div", { ref: "htmlToPdf", attrs: { id: "html-content" } }, [
            _c("div", {}, [
              _c("div", {}, [_c("span")]),
              _c(
                "table",
                {
                  staticStyle: {
                    width: "100%",
                    "font-size": "14px",
                    border: "0.3px solid #0e0d0d",
                    padding: "8px",
                    "text-align": "center",
                    "border-collapse": "collapse",
                  },
                },
                [
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                      },
                    },
                    [
                      _c("td", { attrs: { colspan: "12" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "font-size": "20px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("服务报告")]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "padding-right": "20px",
                            },
                          },
                          [_vm._v("服务报告日期:" + _vm._s(_vm.a1.reportDate))]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "120px",
                          },
                          attrs: { rowspan: "11" },
                        },
                        [_vm._v("客户档案项目")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "120px",
                          },
                        },
                        [_vm._v("客户名称")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "10" },
                        },
                        [_vm._v(_vm._s(_vm.a1.customerName) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("联系人")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(_vm._s(_vm.a1.contactName) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("合同")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "7" },
                        },
                        [_vm._v(_vm._s(_vm.a1.contract))]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("卫宁联系人")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" " + _vm._s(_vm.a1.winningContactName) + " ")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "220px",
                          },
                        },
                        [_vm._v("卫心联系人")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "7" },
                        },
                        [_vm._v(" " + _vm._s(_vm.a1.weixinContactName) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { rowspan: "7" },
                        },
                        [_vm._v("维保期服务")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("沟通方式")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [_vm._v(" " + _vm._s(_vm.a1.communicationMethod) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("沟通频率")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.a1.communicationFrequency) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("数据更新计划")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [_vm._v(_vm._s(_vm.a1.dataUpdatePlan) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("公共数据库账号")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.a1.publicDatabaseAccount) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("卫心产品服务群")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.a1.weixinProductServiceGroup) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("头号药师群邀请")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [_vm._v(" " + _vm._s(_vm.a1.thysGroupInvitation) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v("增值服务项")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "8" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.a1.valueAddedServiceItem) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("备注")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { colspan: "10" },
                        },
                        [_vm._v(" " + _vm._s(_vm.a1.remark) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        border: "0.3px solid #0e0d0d",
                        padding: "8px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { rowspan: _vm.a2.length + 1 },
                        },
                        [_vm._v("服务记录项")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          attrs: { rowspan: _vm.a2.length + 1 },
                        },
                        [_vm._v("问题清单")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("提出日期")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "80px",
                          },
                        },
                        [_vm._v("提出人")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("产品名称")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "120px",
                          },
                        },
                        [_vm._v("问题类型")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("任务描述")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("承诺解决日期")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("实际解决日期")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("解决方法")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("核实日期")]
                      ),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                            width: "120px",
                          },
                        },
                        [_vm._v("核实情况")]
                      ),
                    ]
                  ),
                  _vm._l(_vm.a2, function (item, index) {
                    return _c(
                      "tr",
                      {
                        staticStyle: {
                          border: "0.3px solid #0e0d0d",
                          padding: "8px",
                          "text-align": "center",
                        },
                        style: {
                          background: index % 2 == 0 ? "#ffffff" : "#ececec",
                        },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.startTime))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.questionUser))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.productName))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.problemName))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.filterTaskDesc(item.taskDesc)
                                ),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.endTime))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.solveTime))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.filterTaskDesc(item.solveSolution)
                                ),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.checkTime))]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              border: "0.3px solid #0e0d0d",
                              padding: "8px",
                              "text-align": "center",
                            },
                          },
                          [
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.filterTaskDesc(item.checkSolution)
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm.isEmpty(_vm.a2)
                    ? _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                                color: "#878686",
                              },
                              attrs: { colspan: "12" },
                            },
                            [_vm._v("暂无服务记录")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isEmpty(_vm.a3)
                    ? _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v(" 满意度调查项 ")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v(" 回访清单 ")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                                color: "#878686",
                              },
                              attrs: { colspan: "10" },
                            },
                            [_vm._v("暂无回访记录")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.a3, function (a3Item, a3Index) {
                    return [
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          style: {
                            background:
                              a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                          },
                        },
                        [
                          a3Index == 0
                            ? _c(
                                "td",
                                {
                                  staticStyle: {
                                    border: "0.3px solid #0e0d0d",
                                    padding: "8px",
                                    "text-align": "center",
                                  },
                                  attrs: { rowspan: _vm.a31Rowspan },
                                },
                                [_vm._v("满意度调查项 ")]
                              )
                            : _vm._e(),
                          a3Index == 0
                            ? _c(
                                "td",
                                {
                                  staticStyle: {
                                    border: "0.3px solid #0e0d0d",
                                    padding: "8px",
                                    "text-align": "center",
                                  },
                                  attrs: { rowspan: _vm.a31Rowspan },
                                },
                                [_vm._v("回访清单 ")]
                              )
                            : _vm._e(),
                          a3Item.productScore
                            ? [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      border: "0.3px solid #0e0d0d",
                                      padding: "8px",
                                      "text-align": "center",
                                    },
                                    attrs: {
                                      colspan: 2,
                                      rowspan: a3Item.productScore.length,
                                    },
                                  },
                                  [_vm._v("产品满意评分 ")]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      border: "0.3px solid #0e0d0d",
                                      padding: "8px",
                                      "text-align": "center",
                                    },
                                    attrs: { colspan: 3 },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          a3Item.productScore[0].productName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      border: "0.3px solid #0e0d0d",
                                      padding: "8px",
                                      "text-align": "center",
                                    },
                                    attrs: { colspan: "5" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          a3Item.productScore[0].ratingName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._l(
                        a3Item.productScore && a3Item.productScore.length > 1
                          ? a3Item.productScore.slice(1)
                          : [],
                        function (productScoreItem, productScoreIndex) {
                          return _c(
                            "tr",
                            {
                              style: {
                                background:
                                  a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    border: "0.3px solid #0e0d0d",
                                    padding: "8px",
                                    "text-align": "center",
                                  },
                                  attrs: { colspan: 3 },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(productScoreItem.productName) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    border: "0.3px solid #0e0d0d",
                                    padding: "8px",
                                    "text-align": "center",
                                  },
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(productScoreItem.ratingName) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      a3Item.productSuggest
                        ? _vm._l(
                            a3Item.productSuggest ? a3Item.productSuggest : [],
                            function (productSuggestItem, productSuggestIndex) {
                              return _c(
                                "tr",
                                {
                                  style: {
                                    background:
                                      a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                                  },
                                },
                                [
                                  productSuggestIndex == 0
                                    ? _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            border: "0.3px solid #0e0d0d",
                                            padding: "8px",
                                            "text-align": "center",
                                          },
                                          attrs: {
                                            colspan: 2,
                                            rowspan:
                                              a3Item.productSuggest.length,
                                          },
                                        },
                                        [_vm._v("产品建议 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        border: "0.3px solid #0e0d0d",
                                        padding: "8px",
                                        "text-align": "center",
                                      },
                                      attrs: { colspan: 3 },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            productSuggestItem.productName
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        border: "0.3px solid #0e0d0d",
                                        padding: "8px",
                                        "text-align": "center",
                                      },
                                      attrs: { colspan: "5" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            productSuggestItem.suggestion
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          )
                        : _vm._e(),
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          style: {
                            background:
                              a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("服务满意度评分")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "8" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(a3Item.serviceRatingName) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          style: {
                            background:
                              a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("服务建议")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "8" },
                            },
                            [_vm._v(_vm._s(a3Item.serviceSuggestion))]
                          ),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          style: {
                            background:
                              a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("回访时间")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "3" },
                            },
                            [_vm._v(_vm._s(a3Item.followUpTime))]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("回访人")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "3" },
                            },
                            [_vm._v(_vm._s(a3Item.followUpBy))]
                          ),
                        ]
                      ),
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            border: "0.3px solid #0e0d0d",
                            padding: "8px",
                            "text-align": "center",
                          },
                          style: {
                            background:
                              a3Index % 2 == 0 ? "#ffffff" : "#ececec",
                          },
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("客户回执证明")]
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                border: "0.3px solid #0e0d0d",
                                padding: "8px",
                                "text-align": "center",
                              },
                              attrs: { colspan: "8" },
                            },
                            [_vm._v(_vm._s(a3Item.receiptProof))]
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }