var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showAddCustom,
        "show-close": false,
        "append-to-body": true,
        "custom-class": "center-dialog new-custom-dialog",
      },
    },
    [
      _c(
        "div",
        { staticClass: "header-wrap", attrs: { slot: "title" }, slot: "title" },
        [
          _c("div", { staticClass: "title-txt" }, [_vm._v("新建客户联系人")]),
          _c(
            "div",
            { staticClass: "add-btn-wrap" },
            [
              _c(
                "el-button",
                { staticClass: "add-btn", on: { click: _vm.onAdd } },
                [_vm._v("添 加")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "form-view" },
        [
          _c(
            "el-form",
            {
              ref: "formRef",
              attrs: {
                model: _vm.addCustomObj,
                rules: _vm.rules,
                "label-width": "50px",
                "label-position": "left",
              },
            },
            _vm._l(_vm.contactsList2DArray, function (row, rowIndex) {
              return _c(
                "el-row",
                { key: rowIndex, attrs: { gutter: 10 } },
                _vm._l(row, function (col, colIndex) {
                  return _c(
                    "el-col",
                    {
                      key: rowIndex + colIndex,
                      staticClass: "col-form-item",
                      attrs: { span: _vm.spanNum },
                    },
                    [
                      _c("div", { staticClass: "col-item-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-wrap" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "contactsList[" +
                                    (rowIndex * 3 + colIndex) +
                                    "].name",
                                  rules: _vm.rules.name,
                                  label: "姓名",
                                  size: "mini",
                                  "show-message": false,
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticStyle: { "margin-bottom": "0" } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            maxlength: "30",
                                            clearable: "",
                                            "show-word-limit": "",
                                          },
                                          model: {
                                            value:
                                              _vm.addCustomObj.contactsList[
                                                rowIndex * 3 + colIndex
                                              ].name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.addCustomObj.contactsList[
                                                  rowIndex * 3 + colIndex
                                                ],
                                                "name",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "addCustomObj.contactsList[rowIndex * 3 + colIndex].name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ].isPrimary === 1
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "4px 0 0 0px",
                                                  padding: "0",
                                                  float: "right",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeContactPrimary(
                                                      col,
                                                      0,
                                                      rowIndex,
                                                      colIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "icon svg-icon shouyao",
                                                    staticStyle: {
                                                      width: "38px",
                                                      height: "20px",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-shouyao",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "4px 0 0 0px",
                                                  padding: "0",
                                                  float: "right",
                                                },
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeContactPrimary(
                                                      col,
                                                      1,
                                                      rowIndex,
                                                      colIndex
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass:
                                                      "icon svg-icon bushishouyao",
                                                    staticStyle: {
                                                      width: "38px",
                                                      height: "20px",
                                                    },
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-bushishouyao",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "phoneStyle",
                                attrs: {
                                  prop:
                                    "contactsList[" +
                                    (rowIndex * 3 + colIndex) +
                                    "].department",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择部门",
                                      trigger: "blur",
                                    },
                                  ],
                                  "show-message": false,
                                  label: "部门",
                                  size: "mini",
                                  required: "",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择部门",
                                      filterable: "",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.departmentChange(
                                          val,
                                          rowIndex,
                                          colIndex
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ].department,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addCustomObj.contactsList[
                                            rowIndex * 3 + colIndex
                                          ],
                                          "department",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addCustomObj.contactsList[rowIndex * 3 + colIndex].department",
                                    },
                                  },
                                  _vm._l(
                                    _vm.addCustomObj.contactsList[
                                      rowIndex * 3 + colIndex
                                    ].departmentOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "职务", size: "mini" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择职务",
                                      filterable: "",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    model: {
                                      value:
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ].post,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addCustomObj.contactsList[
                                            rowIndex * 3 + colIndex
                                          ],
                                          "post",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addCustomObj.contactsList[rowIndex * 3 + colIndex].post",
                                    },
                                  },
                                  _vm._l(
                                    _vm.addCustomObj.contactsList[
                                      rowIndex * 3 + colIndex
                                    ].postOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              col.phoneList,
                              function (phoneItem, phoneIndex) {
                                return _c(
                                  "el-row",
                                  {
                                    key: phoneIndex,
                                    staticStyle: { "margin-bottom": "0" },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "phoneStyle",
                                        attrs: {
                                          label:
                                            "电话" + Number(phoneIndex + 1),
                                          prop:
                                            "contactsList[" +
                                            (rowIndex * 3 + colIndex) +
                                            "].phoneList[" +
                                            phoneIndex +
                                            "].phone",
                                          rules: [
                                            {
                                              validator: function (
                                                rule,
                                                value,
                                                callback
                                              ) {
                                                return _vm.validatorPhone(
                                                  rule,
                                                  value,
                                                  callback,
                                                  rowIndex,
                                                  colIndex,
                                                  phoneIndex
                                                )
                                              },
                                              trigger: ["change"],
                                            },
                                          ],
                                          size: "mini",
                                          "show-message": false,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 16 },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass:
                                                "no-word-limit-input",
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.addCustomObj.contactsList[
                                                    rowIndex * 3 + colIndex
                                                  ].phoneList[phoneIndex].phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addCustomObj
                                                      .contactsList[
                                                      rowIndex * 3 + colIndex
                                                    ].phoneList[phoneIndex],
                                                    "phone",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "addCustomObj.contactsList[rowIndex * 3 + colIndex].phoneList[phoneIndex].phone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("el-col", { attrs: { span: 8 } }, [
                                          phoneIndex !== 0
                                            ? _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "iconfont delBtn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delPhone(
                                                        col.phoneList,
                                                        phoneIndex,
                                                        rowIndex,
                                                        colIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "i",
                                            {
                                              staticClass: "iconfont addBtn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addPhone(
                                                    col.phoneList,
                                                    rowIndex,
                                                    colIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "phoneStyle",
                                attrs: {
                                  label: "邮箱",
                                  prop:
                                    "contactsList[" +
                                    (rowIndex * 3 + colIndex) +
                                    "].email",
                                  rules: [
                                    {
                                      pattern:
                                        /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
                                      message: "请输入正确的邮箱",
                                      trigger: "blur",
                                    },
                                  ],
                                  "show-message": false,
                                  size: "mini",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value:
                                      _vm.addCustomObj.contactsList[
                                        rowIndex * 3 + colIndex
                                      ].email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ],
                                        "email",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "addCustomObj.contactsList[rowIndex * 3 + colIndex].email",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "微信", size: "mini" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "50",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value:
                                      _vm.addCustomObj.contactsList[
                                        rowIndex * 3 + colIndex
                                      ].wxAccount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ],
                                        "wxAccount",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "addCustomObj.contactsList[rowIndex * 3 + colIndex].wxAccount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", size: "mini" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "200",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value:
                                      _vm.addCustomObj.contactsList[
                                        rowIndex * 3 + colIndex
                                      ].remark,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addCustomObj.contactsList[
                                          rowIndex * 3 + colIndex
                                        ],
                                        "remark",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "addCustomObj.contactsList[rowIndex * 3 + colIndex].remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm.addCustomObj.contactsList.length > 0
                          ? _c("div", { staticClass: "del-item-btn-wrap" }, [
                              _c(
                                "i",
                                {
                                  staticClass: "iconfont closeBtn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delContactsClick(
                                        col,
                                        rowIndex,
                                        colIndex
                                      )
                                    },
                                  },
                                },
                                [_vm._v("")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.onCancel } }, [_vm._v("取消")]),
          _c("el-button", { on: { click: _vm.onConfirm } }, [_vm._v("确定")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }