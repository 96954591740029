<template>
  <!--查看回访内容-->
  <div>
    <el-row class="rrTimeWrap">
      <el-col :span="12" class="rrTime">
        <b>回访时间</b><span>{{ ssiArr.followUpTime }}</span>
      </el-col>
      <el-col :span="12">
        <div style="float: right">
          <el-button
            class="crm_btn_style"
            @click="editSurvey"
            size="mini">编辑</el-button>
          <el-button
            class="crm_del_btn_style"
            @click="delSurvey"
            size="mini">删除</el-button>
        </div>
      </el-col>
    </el-row>
    <div style="background: #fff;margin: 10px;padding: 6px;">
      <div class="suggestMark">产品满意评分</div>
      <div v-for="(item,index) in ssiArr.fpProductRatingList" :key="item.id">
        <div class="suggestTitle">
          <el-row>
            <el-col :span="12">
              <div style="line-height: 24px;display: flex;">
                <span>
                  {{index+1}}.{{ item.productName}}
                </span>
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  v-if="item.extrasList&&item.extrasList.length>0"
                  :content="item.extrasList[0].extrasName">
                  <span slot="reference" class="appreciationPackage appreciationPackageName">{{item.extrasList[0].extrasName}}</span>
                </el-popover>
                <el-popover
                    popper-class="projectUl_popover"
                    placement="bottom"
                    width="220"
                    trigger="click">
                    <div
                      v-for="i in item.extrasList.slice(1)"
                      style="color:#5D5D5D;
                        font-size: 14px;
                        line-height: 18px;">
                      {{ i.extrasName }}
                    </div>
                  <span style="cursor: pointer" slot="reference" class="appreciationPackage" v-if="item.extrasList.length>1">+{{item.extrasList.length-1}}</span>
                </el-popover>
              </div>
            </el-col>
            <el-col :span="12">
              <span class="mark">{{ item.ratingName }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="suggestContent" v-if="item.suggestion">{{item.suggestion}}</div>
      </div>
    </div>
    <div style="background: #fff;margin: 10px;padding: 6px;">
      <div class="suggestMark">
        <el-row>
          <el-col :span="12">
            <span>服务满意评分</span>
          </el-col>
          <el-col :span="12">
            <span class="mark">{{ ssiArr.serviceRatingName }}</span>
          </el-col>
        </el-row>
      </div>
      <el-row class="editProductWrap">
        <el-col :span="3">
          <span class="editLabel">建议</span>
        </el-col>
        <el-col :span="21">
          <span class="editInput suggestion" v-if="ssiArr.serviceSuggestion">{{ ssiArr.serviceSuggestion }}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="3">
          <span class="editLabel">客户回执证明</span>
        </el-col>
        <el-col :span="21">
          <!--                  <span class="editInput">附件名称附件名称附件名称.txt</span>-->
          <div class="attachment" v-for="(fileItem, index) in ssiArr.fpFileList" :key="fileItem.id">
            <i class="iconfont">&#xe671;</i>
            <span class="editInput" style="margin-left: 4px;cursor: pointer;" @click="onPreviewFile(fileItem)">{{fileItem.fileName}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="3">
          <span class="editLabel">本次回访类型</span>
        </el-col>
        <el-col :span="21">
          <span class="editInput">{{ ssiArr.visitTypeName }}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="3">
          <span class="editLabel">后续无需服务</span>
        </el-col>
        <el-col :span="21" style="line-height: 26px;">
          <el-switch
            v-model="ssiArr.laterServiceStatus?true:false"
            disabled
            active-color="#13ce66"
            inactive-color="#d3d5d8">
          </el-switch>
        </el-col>
      </el-row>
      <el-row class="editProductWrap" v-if="!ssiArr.laterServiceStatus">
        <el-col :span="3">
          <span class="editLabel">下次回访时间</span>
        </el-col>
        <el-col :span="21">
          <span class="editInput">{{ ssiArr.nextFollowUpTime }}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap" v-if="!ssiArr.laterServiceStatus">
        <el-col :span="3">
          <span class="editLabel">下次回访类型</span>
        </el-col>
        <el-col :span="21">
          <span class="editInput">{{ ssiArr.nextVisitTypeName }}</span>
        </el-col>
      </el-row>
      <el-row class="editProductWrap">
        <el-col :span="3">
          <span class="editLabel">回访人</span>
        </el-col>
        <el-col :span="21">
          <span class="editInput">{{ ssiArr.followUpBy }}</span>
        </el-col>
      </el-row>
    </div>
    <preview-file
      :is-show-dialog.sync="showPreviewFile"
      :file-id="previewFileId"></preview-file>
  </div>
</template>

<script>
import {
  satisfactionSurveyInfo
} from '@/modules/crm/api/crm/returnVisit'
import PreviewFile from '@/modules/crm/view/crm/task/components/previewFile'
export default {
  name: "LookReturnVisitContent",
  props: {
    followUpTime: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    PreviewFile
  },
  data() {
    return {
      value: false,
      ssiArr: {},
      //文件预览id
      previewFileId:0,
      showPreviewFile: false
    }
  },
  watch: {
    followUpTime(val, oldVal) {
      console.log(val, oldVal)
      this.satisfactionSurveyInfoFn(val)
    },
  },
  async created() {
    console.log(this.followUpTime,this.id)
    await this.satisfactionSurveyInfoFn(this.followUpTime)
  },
  methods: {
    // 满意度调查详细信息
    async satisfactionSurveyInfoFn(date) {
      let obj = {
        archiveId: this.id,
        followUpTime: date
      }
      let res = await satisfactionSurveyInfo(obj)
      console.log(res)
      if (res.code === 200) {
        console.log(res.data)
        if(res.data) {
          this.ssiArr = res.data
        }
        // this.ssiArr.fpProductRatingList.forEach((item,index)=> {
        //   item.extrasList = [
        //     {
        //       "id": 117,
        //       "contractId": 3954,
        //       "productId": 33,
        //       "extrasId": 505,
        //       "extrasName": "抗菌药物专项审方增值包"
        //     },
        //     {
        //       "id": 118,
        //       "contractId": 3954,
        //       "productId": 33,
        //       "extrasId": 504,
        //       "extrasName": "重点审方增值包"
        //     },
        //     {
        //       "id": 119,
        //       "contractId": 3954,
        //       "productId": 33,
        //       "extrasId": 506,
        //       "extrasName": "电子病历评审增值包"
        //     }
        //   ]
        // })
      }
    },
    // 编辑单条满意度调查数据
    editSurvey() {
      this.$emit('editSurveyData', this.followUpTime)
    },
    // 删除单条数据
    delSurvey() {
      this.$emit('delSurveyData', this.ssiArr.id)
    },
    onPreviewFile(fileItem) {
      this.previewFileId = fileItem.fileId
      this.showPreviewFile = true
    }
  }
}
</script>

<style lang="scss"  scoped>
.mark{
  float: right;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #F1880D;
  border-radius: 50px 50px 50px 50px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;
}
.appreciationPackage{
  padding: 2px 4px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #DEDEDE;
  color: #5D5D5D;
  font-weight: 400!important;
  font-size: 14px;
  line-height: 18px;
  height: 22px;
  margin-right: 4px;
  //margin-top: 3px;
  &:last-child{
    margin-right: 0;
  }
}
.appreciationPackageName{
  display: inline-block;
  margin-left:2px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;    /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.attachment {
  //margin-top: 4px;
  padding: 2px 4px;

  .editInput, i {
    color: #686EC5!important;
    font-size: 14px;
    line-height: 18px;
  }
}
.rrTimeWrap{
  padding:0 10px;
  .rrTime{
    b{
      display: inline-block;
      width: 92px;
      height: 28px;
      font-size: 14px;
      color: #3C3C3C;
      line-height: 28px;
    }
    span{
      font-size: 14px;
      color: #5D5D5D;
      line-height: 16px;
    }
  }
}
.suggestMark{
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  color: #3C3C3C;
  line-height: 24px;
  margin-bottom: 12px;
}
.suggestTitle{
  font-weight: 600;
  font-size: 14px;
  color: #5D5D5D;
  line-height: 14px;
}
.suggestContent{
  font-size: 14px;
  color: #5D5D5D;
  line-height: 16px;
  padding: 11px 0;
  line-height: 20px;
}
.editProductWrap{
  margin-bottom: 12px;
  .editLabel{
    height: 28px;
    font-weight: 600;
    font-size: 14px;
    color: #3C3C3C;
    line-height: 28px;
  }
  .editInput{
    font-size: 14px;
    color: #5D5D5D;
    //line-height: 16px;
    line-height: 28px;
  }
  .suggestion{
    //line-height: 16px!important;
    line-height: 28px!important;
  }
}
</style>
