var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.editDialogTitle,
        visible: _vm.drawer,
        "before-close": _vm.handleEditCommon,
        modal: false,
        "with-header": false,
        wrapperClosable: false,
        size: "980px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer_common_wrap" },
        [
          _c(
            "el-row",
            { staticClass: "newBuildContractWrap" },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "newConstructionContract" }, [
                    _vm._v(_vm._s(_vm.titleName)),
                  ]),
                ]
              ),
              !_vm.isEditShow
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _vm.activeName == 5 && !_vm.isHidden && _vm.taskHandleAuth
                        ? _c(
                            "el-button",
                            {
                              staticClass: "saveBtn",
                              attrs: { plain: "", size: "mini" },
                              on: { click: _vm.toDealClick },
                            },
                            [_vm._v("去处理")]
                          )
                        : _vm._e(),
                      _vm.isNeedEdit && !_vm.isHidden && _vm.taskEditAuth
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  (!_vm.addPermission ||
                                    !_vm.editPermission ||
                                    !_vm.delPermission) &&
                                  _vm.activeNum === 0 &&
                                  (Number(_vm.activeName) !== 0 ||
                                    (!_vm.editBasePermission &&
                                      !_vm.editContactsPermission) ||
                                    ((_vm.editBasePermission ||
                                      _vm.editContactsPermission) &&
                                      !_vm.editPermission)),
                                plain: "",
                                size: "mini",
                              },
                              on: { click: _vm.onEdit },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.taskDelAuth
                        ? _c(
                            "el-button",
                            {
                              staticClass: "delBtn",
                              attrs: {
                                disabled:
                                  !_vm.delPermission && _vm.activeNum === 0,
                                plain: "",
                                size: "mini",
                              },
                              on: { click: _vm.delectClick },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      !_vm.isHidden
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.editBasePermission &&
                                  !_vm.editContactsPermission &&
                                  _vm.activeNum === 0,
                                plain: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onReset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                      !_vm.isHidden
                        ? _c(
                            "el-button",
                            {
                              staticClass: "saveBtn",
                              attrs: {
                                disabled:
                                  !_vm.editBasePermission &&
                                  !_vm.editContactsPermission &&
                                  _vm.activeNum === 0,
                                plain: "",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onEditSubmit()
                                },
                              },
                            },
                            [_vm._v("保存")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          _vm.orgType
            ? _c(
                "div",
                [
                  _vm.orgType == _vm.getDict(this.dictConst.ORG_TYPE)[0].id
                    ? _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _vm.activeNum == 0
                            ? _c("el-tab-pane", {
                                attrs: { label: "机构", name: "0" },
                              })
                            : _vm._e(),
                          _c("el-tab-pane", {
                            attrs: { label: "合同", name: "1" },
                          }),
                          _c("el-tab-pane", {
                            attrs: { label: "项目", name: "2" },
                          }),
                          _vm.activeNum !== 0
                            ? _c("el-tab-pane", {
                                attrs: { label: "人员", name: "3" },
                              })
                            : _vm._e(),
                          _vm.activeNum == 5
                            ? _c("el-tab-pane", {
                                attrs: { label: "任务", name: "5" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _vm.activeNum == 0
                        ? _c("el-tab-pane", {
                            attrs: { label: "机构", name: "0" },
                          })
                        : _vm._e(),
                      _c("el-tab-pane", {
                        attrs: { label: "合同", name: "1" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "项目", name: "2" },
                      }),
                      _vm.activeNum !== 0
                        ? _c("el-tab-pane", {
                            attrs: { label: "人员", name: "3" },
                          })
                        : _vm._e(),
                      _vm.activeNum == 5
                        ? _c("el-tab-pane", {
                            attrs: { label: "任务", name: "5" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "div",
            { staticStyle: { flex: "1" } },
            [
              _vm.activeName == 0 && !_vm.isEditShow
                ? _c("edit-org", { attrs: { institutionId: _vm.orgId } })
                : _vm._e(),
              _vm.activeName == 0 && _vm.isEditShow
                ? _c("edit-construction-institution", {
                    ref: "editOrgRef",
                    attrs: { "edit-form": _vm.row, "component-source": "edit" },
                  })
                : _vm._e(),
              _vm.activeName == 1 && !_vm.isEditShow
                ? _c("edit-contract", {
                    attrs: {
                      institutionId: _vm.orgId,
                      contractId: _vm.contractId,
                    },
                    on: {
                      getContractId: _vm.getContractId,
                      contractListLen: _vm.contractListLen,
                    },
                  })
                : _vm._e(),
              _vm.activeName == 1 && _vm.isEditShow
                ? _c("new-construction-contract", {
                    ref: "editContractRef",
                    attrs: {
                      institutionId: _vm.orgId,
                      contractId: _vm.contractId,
                      isNewConstruction: _vm.isNewConstruction,
                      currentContractId: _vm.currentContractId,
                      isCanEdit: _vm.isCanFinallEdit,
                    },
                  })
                : _vm._e(),
              _vm.activeName == 2 && !_vm.isEditShow
                ? _c("edit-project", {
                    attrs: {
                      institutionId: _vm.orgId,
                      projectId: _vm.projectId,
                    },
                    on: {
                      getProjectId: _vm.getProjectId,
                      projectListLen: _vm.projectListLen,
                    },
                  })
                : _vm._e(),
              _vm.activeName == 2 && _vm.isEditShow
                ? _c("new-construction-project", {
                    ref: "editProjectRef",
                    attrs: {
                      institutionId: _vm.orgId,
                      projectId: _vm.projectId,
                      isNewConstruction: _vm.isNewConstruction,
                      currentProjectId: _vm.currentProjectId,
                      isProjectCorrelation: _vm.isCorrelation,
                    },
                  })
                : _vm._e(),
              _vm.activeName == 3 && !_vm.isEditShow
                ? _c("edit-people", { attrs: { projectId: _vm.projectId } })
                : _vm._e(),
              _vm.activeName == 3 && _vm.isEditShow
                ? _c("modify-people", {
                    ref: "modifyPeopleRef",
                    attrs: { projectId: _vm.projectId },
                  })
                : _vm._e(),
              _vm.activeName == 5 && !_vm.isEditShow
                ? _c("edit-task", {
                    attrs: { "task-id": _vm.taskId, type: 1, visible: false },
                  })
                : _vm._e(),
              _vm.activeName == 5 && _vm.isEditShow
                ? _c("edit-task", {
                    ref: "editTaskRef",
                    attrs: {
                      "task-id": _vm.taskId,
                      "task-info": _vm.taskInfo,
                      type: 3,
                      visible: _vm.editDrawer,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "closeBtn", on: { click: _vm.handleEditCommon } },
        [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }