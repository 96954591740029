var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.editDialogTitle,
        visible: _vm.drawer,
        "before-close": _vm.handleEditDrawer,
        modal: false,
        "with-header": false,
        size: "980px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer_wrap" },
        [
          _c(
            "el-row",
            { staticClass: "newBuildContractWrap" },
            [
              _c(
                "el-col",
                { staticStyle: { display: "flex" }, attrs: { span: 12 } },
                [
                  _c("span", { staticClass: "newConstructionContract" }, [
                    _vm._v(_vm._s(_vm.form.productName)),
                  ]),
                  false
                    ? _c(
                        "div",
                        { staticClass: "newConstructionContractChange" },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "iconfont",
                              staticStyle: {
                                "font-size": "24px",
                                color: "#E1E7F5",
                                "margin-left": "10px",
                              },
                            },
                            [_vm._v("")]
                          ),
                          _c(
                            "i",
                            {
                              staticClass: "iconfont",
                              staticStyle: {
                                "font-size": "24px",
                                color: "#B3BDD6",
                                "margin-left": "10px",
                              },
                            },
                            [_vm._v("")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              !_vm.isEdit
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.isEdit = true
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "mini" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "saveBtn",
                          attrs: { plain: "", size: "mini" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "newConstructionContract_wrap" },
                [
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("产品大类"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.form.classifyName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("版本"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.form.versionName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("产品名称"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.form.productName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("产品实施方"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.form.implTypeName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("增值包"),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        _vm._l(_vm.form.extrasList, function (item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              staticStyle: { "margin-right": "4px" },
                              attrs: { type: "info" },
                            },
                            [_vm._v(_vm._s(item.extrasName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("span", { staticClass: "editLabel" }, [
                          _vm._v("备注"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 22 } }, [
                        _c("span", { staticClass: "editInput" }, [
                          _vm._v(_vm._s(_vm.form.remark)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "newConstructionContract_wrap" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "100px",
                        "label-position": "left",
                        size: "small",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品大类",
                                    prop: "classify",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "98%" },
                                      attrs: {
                                        disabled: _vm.isRelContract,
                                        size: "small",
                                        placeholder: "请选择产品大类",
                                      },
                                      on: { change: _vm.classifyChange },
                                      model: {
                                        value: _vm.form.classify,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "classify", $$v)
                                        },
                                        expression: "form.classify",
                                      },
                                    },
                                    _vm._l(
                                      _vm.productClassifyDict,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "版本" } },
                        _vm._l(_vm.productVersionDict, function (item) {
                          return _c(
                            "span",
                            {
                              key: item.id,
                              staticClass: "normal_btn",
                              class: {
                                select_btn: item.id == _vm.form.version,
                                "disabled-btn": _vm.isRelContract,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.versionSelect(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "产品名称",
                                    prop: "productName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "98%" },
                                    attrs: {
                                      disabled: _vm.isRelContract,
                                      maxlength: "40",
                                      clearable: "",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.productName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "productName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.productName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品实施方",
                            prop: "projectClassify",
                          },
                        },
                        _vm._l(_vm.implTypeDict, function (item) {
                          return _c(
                            "span",
                            {
                              key: item.id,
                              staticClass: "normal_btn",
                              class: {
                                select_btn: item.id == _vm.form.implType,
                                "disabled-btn": _vm.isRelContract,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.implTypeSelect(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "增值包" } },
                                _vm._l(
                                  _vm.form.extrasList,
                                  function (item, index) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: index,
                                        staticStyle: { "margin-right": "4px" },
                                        attrs: { type: "info" },
                                      },
                                      [_vm._v(_vm._s(item.extrasName) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: "请输入备注信息",
                                      maxlength: "100",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "closeBtn", on: { click: _vm.handleEditDrawer } },
        [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }