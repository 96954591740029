var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "rrTimeWrap" },
        [
          _c("el-col", { staticClass: "rrTime", attrs: { span: 12 } }, [
            _c("b", [_vm._v("回访时间")]),
            _c("span", [_vm._v(_vm._s(_vm.ssiArr.followUpTime))]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticStyle: { float: "right" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "crm_btn_style",
                    attrs: { size: "mini" },
                    on: { click: _vm.editSurvey },
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "crm_del_btn_style",
                    attrs: { size: "mini" },
                    on: { click: _vm.delSurvey },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { background: "#fff", margin: "10px", padding: "6px" } },
        [
          _c("div", { staticClass: "suggestMark" }, [_vm._v("产品满意评分")]),
          _vm._l(_vm.ssiArr.fpProductRatingList, function (item, index) {
            return _c("div", { key: item.id }, [
              _c(
                "div",
                { staticClass: "suggestTitle" },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "line-height": "24px",
                              display: "flex",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(index + 1) +
                                  "." +
                                  _vm._s(item.productName) +
                                  " "
                              ),
                            ]),
                            item.extrasList && item.extrasList.length > 0
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      trigger: "hover",
                                      content: item.extrasList[0].extrasName,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "appreciationPackage appreciationPackageName",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.extrasList[0].extrasName)
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  "popper-class": "projectUl_popover",
                                  placement: "bottom",
                                  width: "220",
                                  trigger: "click",
                                },
                              },
                              [
                                _vm._l(item.extrasList.slice(1), function (i) {
                                  return _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#5D5D5D",
                                        "font-size": "14px",
                                        "line-height": "18px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(i.extrasName) + " ")]
                                  )
                                }),
                                item.extrasList.length > 1
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "appreciationPackage",
                                        staticStyle: { cursor: "pointer" },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _vm._v(
                                          "+" +
                                            _vm._s(item.extrasList.length - 1)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("span", { staticClass: "mark" }, [
                          _vm._v(_vm._s(item.ratingName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              item.suggestion
                ? _c("div", { staticClass: "suggestContent" }, [
                    _vm._v(_vm._s(item.suggestion)),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { background: "#fff", margin: "10px", padding: "6px" } },
        [
          _c(
            "div",
            { staticClass: "suggestMark" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", [_vm._v("服务满意评分")]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "mark" }, [
                      _vm._v(_vm._s(_vm.ssiArr.serviceRatingName)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "editProductWrap" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("建议")]),
              ]),
              _c("el-col", { attrs: { span: 21 } }, [
                _vm.ssiArr.serviceSuggestion
                  ? _c("span", { staticClass: "editInput suggestion" }, [
                      _vm._v(_vm._s(_vm.ssiArr.serviceSuggestion)),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "editProductWrap" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "editLabel" }, [
                  _vm._v("客户回执证明"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 21 } },
                _vm._l(_vm.ssiArr.fpFileList, function (fileItem, index) {
                  return _c(
                    "div",
                    { key: fileItem.id, staticClass: "attachment" },
                    [
                      _c("i", { staticClass: "iconfont" }, [_vm._v("")]),
                      _c(
                        "span",
                        {
                          staticClass: "editInput",
                          staticStyle: {
                            "margin-left": "4px",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onPreviewFile(fileItem)
                            },
                          },
                        },
                        [_vm._v(_vm._s(fileItem.fileName))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "editProductWrap" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "editLabel" }, [
                  _vm._v("本次回访类型"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.ssiArr.visitTypeName)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "editProductWrap" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "editLabel" }, [
                  _vm._v("后续无需服务"),
                ]),
              ]),
              _c(
                "el-col",
                { staticStyle: { "line-height": "26px" }, attrs: { span: 21 } },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: "",
                      "active-color": "#13ce66",
                      "inactive-color": "#d3d5d8",
                    },
                    model: {
                      value: _vm.ssiArr.laterServiceStatus ? true : false,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ssiArr,
                          "laterServiceStatus?true:false",
                          $$v
                        )
                      },
                      expression: "ssiArr.laterServiceStatus?true:false",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.ssiArr.laterServiceStatus
            ? _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("下次回访时间"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.ssiArr.nextFollowUpTime)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          !_vm.ssiArr.laterServiceStatus
            ? _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("下次回访类型"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.ssiArr.nextVisitTypeName)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "editProductWrap" },
            [
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", { staticClass: "editLabel" }, [_vm._v("回访人")]),
              ]),
              _c("el-col", { attrs: { span: 21 } }, [
                _c("span", { staticClass: "editInput" }, [
                  _vm._v(_vm._s(_vm.ssiArr.followUpBy)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("preview-file", {
        attrs: {
          "is-show-dialog": _vm.showPreviewFile,
          "file-id": _vm.previewFileId,
        },
        on: {
          "update:isShowDialog": function ($event) {
            _vm.showPreviewFile = $event
          },
          "update:is-show-dialog": function ($event) {
            _vm.showPreviewFile = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }