var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newConstructionInstitution_wrap" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "validate-on-rule-change": false,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "属性", prop: "orgType" } },
                    _vm._l(_vm.orgTypeDict, function (item, index) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          staticClass: "normal_btn",
                          class: { select_btn: item.id == _vm.form.orgType },
                          on: {
                            click: function ($event) {
                              return _vm.contractClassifySelect(item.id, index)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "机构名称", prop: "orgName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "98%" },
                        attrs: {
                          placeholder: "请输入机构名称",
                          maxlength: "30",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.orgName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "orgName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.orgName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.form.aliasList, function (item, index) {
            return _c(
              "el-row",
              { key: index },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "别名" + Number(index + 1),
                          prop: "aliasList[" + index + "].aliasName",
                          rules: _vm.rules.aliasName,
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "98%" },
                          attrs: {
                            maxlength: "30",
                            clearable: "",
                            "show-word-limit": "",
                          },
                          on: {
                            change: function (val) {
                              return _vm.aliasNameChane(val, item, index)
                            },
                          },
                          model: {
                            value: item.aliasName,
                            callback: function ($$v) {
                              _vm.$set(
                                item,
                                "aliasName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item.aliasName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "----来源" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "280px" },
                            attrs: {
                              placeholder: "请选择来源",
                              filterable: "",
                              clearable: "",
                            },
                            model: {
                              value: item.aliasSource,
                              callback: function ($$v) {
                                _vm.$set(item, "aliasSource", $$v)
                              },
                              expression: "item.aliasSource",
                            },
                          },
                          _vm._l(_vm.orgAliasSourceDict, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.dictLabel, value: item.id },
                            })
                          }),
                          1
                        ),
                        index === 0
                          ? _c(
                              "i",
                              {
                                staticClass: "iconfont addBtn",
                                staticStyle: { float: "right" },
                                on: { click: _vm.addAliasClick },
                              },
                              [_vm._v("")]
                            )
                          : _c(
                              "i",
                              {
                                staticClass: "iconfont addBtn",
                                staticStyle: { float: "right" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAliasClick(item, index)
                                  },
                                },
                              },
                              [_vm._v("")]
                            ),
                        item.isPrimary === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  margin: "5px 0 0 10px",
                                  padding: "0",
                                  float: "right",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePrimary(item, 0)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon svg-icon shouyao",
                                    staticStyle: {
                                      width: "38px",
                                      height: "20px",
                                    },
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: { "xlink:href": "#icon-shouyao" },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        item.isPrimary === 0 && _vm.form.aliasList.length > 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  margin: "5px 0 0 10px",
                                  padding: "0",
                                  float: "right",
                                },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changePrimary(item, 1)
                                  },
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon svg-icon bushishouyao",
                                    staticStyle: {
                                      width: "38px",
                                      height: "20px",
                                    },
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-bushishouyao",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "labelSet", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      class: { "is-error": _vm.uscCodeError },
                      attrs: { label: "社会统一信用代码", prop: "uscCode" },
                    },
                    [
                      _c("el-input", {
                        ref: "uscCodeInput",
                        staticStyle: { width: "98%" },
                        attrs: {
                          maxlength: "18",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        on: { blur: _vm.uscCodeBlur },
                        model: {
                          value: _vm.form.uscCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "uscCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.uscCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "机构等级", prop: "orgLevel" } },
                    _vm._l(_vm.orgLevelDict, function (item) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          staticClass: "normal_btn",
                          class: { select_btn: item.id == _vm.form.orgLevel },
                          on: {
                            click: function ($event) {
                              return _vm.contractTypeSelect(item.id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "labelSet",
              attrs: { label: "所属省份/城市", prop: "provinceCityTreeValue" },
            },
            [
              _c("el-cascader", {
                staticStyle: { width: "376px" },
                attrs: {
                  props: _vm.defaultParams,
                  options: _vm.provinceCityTreeDict,
                  placeholder: "请选择所属省份/城市",
                  clearable: "",
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.provinceCityTreeValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "provinceCityTreeValue", $$v)
                  },
                  expression: "form.provinceCityTreeValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "labelSet",
              attrs: { label: "备注", prop: "remark" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  maxlength: "200",
                  "show-word-limit": "",
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "border-bottom": "1px dotted #F0F0F0" },
              attrs: { label: "联系人" },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.addContactsClick(_vm.form.contactsList)
                        },
                      },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "contactList" },
            _vm._l(_vm.form.contactsList, function (item, index) {
              return _c("div", { key: index, staticClass: "productWrap" }, [
                _c(
                  "div",
                  { staticClass: "productItem" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "phoneStyle",
                            attrs: {
                              prop: "contactsList[" + index + "].name",
                              rules: _vm.rules.contactsName,
                              "label-width": "25%",
                              label: "姓名",
                              size: "mini",
                              "show-message": false,
                            },
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "productSelect",
                                attrs: { span: 16 },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: item.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                item.isPrimary === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          margin: "4px 0 0 0px",
                                          padding: "0",
                                          float: "right",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeContactPrimary(
                                              item,
                                              0
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "icon svg-icon shouyao",
                                            staticStyle: {
                                              width: "38px",
                                              height: "20px",
                                            },
                                            attrs: { "aria-hidden": "true" },
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href": "#icon-shouyao",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          margin: "4px 0 0 0px",
                                          padding: "0",
                                          float: "right",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeContactPrimary(
                                              item,
                                              1
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "icon svg-icon bushishouyao",
                                            staticStyle: {
                                              width: "38px",
                                              height: "20px",
                                            },
                                            attrs: { "aria-hidden": "true" },
                                          },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon-bushishouyao",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "phoneStyle",
                                attrs: {
                                  prop:
                                    "contactsList[" + index + "].department",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择部门",
                                      trigger: "blur",
                                    },
                                  ],
                                  "show-message": false,
                                  "label-width": "25%",
                                  label: "部门",
                                  size: "mini",
                                  required: "",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择部门",
                                      filterable: "",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.departmentChange(val, index)
                                      },
                                    },
                                    model: {
                                      value: item.department,
                                      callback: function ($$v) {
                                        _vm.$set(item, "department", $$v)
                                      },
                                      expression: "item.department",
                                    },
                                  },
                                  _vm._l(
                                    item.departmentOptions,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("职务")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择职务",
                                  filterable: "",
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: item.post,
                                  callback: function ($$v) {
                                    _vm.$set(item, "post", $$v)
                                  },
                                  expression: "item.post",
                                },
                              },
                              _vm._l(item.postOptions, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.dictLabel,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(item.phoneList, function (phoneItem, phoneIndex) {
                      return _c(
                        "el-row",
                        { key: phoneIndex },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "phoneStyle",
                              attrs: {
                                label: "电话" + Number(phoneIndex + 1),
                                prop:
                                  "contactsList[" +
                                  index +
                                  "].phoneList[" +
                                  phoneIndex +
                                  "].phone",
                                rules: [
                                  {
                                    validator: function (
                                      rule,
                                      value,
                                      callback
                                    ) {
                                      return _vm.validatorPhone(
                                        rule,
                                        value,
                                        callback,
                                        index,
                                        phoneIndex
                                      )
                                    },
                                    trigger: ["change"],
                                  },
                                ],
                                "label-width": "25%",
                                size: "mini",
                                "show-message": false,
                              },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "productSelect",
                                  attrs: { span: 16 },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "no-word-limit-input",
                                    attrs: { size: "mini", clearable: "" },
                                    model: {
                                      value: phoneItem.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          phoneItem,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "phoneItem.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 8 } }, [
                                phoneIndex !== 0
                                  ? _c(
                                      "i",
                                      {
                                        staticClass: "iconfont delBtn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delPhone(
                                              item.phoneList,
                                              phoneIndex
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "i",
                                  {
                                    staticClass: "iconfont addBtn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPhone(item.phoneList)
                                      },
                                    },
                                  },
                                  [_vm._v("")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("邮箱")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "phoneStyle",
                                attrs: {
                                  "label-width": "0px",
                                  prop: "contactsList[" + index + "].email",
                                  rules: [
                                    {
                                      pattern:
                                        /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
                                      message: "请输入正确的邮箱",
                                      trigger: "blur",
                                    },
                                  ],
                                  "show-message": false,
                                  size: "mini",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: item.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "email",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("微信号")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                maxlength: "50",
                                clearable: "",
                                "show-word-limit": "",
                              },
                              model: {
                                value: item.wxAccount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "wxAccount",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.wxAccount",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "productTitle", attrs: { span: 6 } },
                          [_vm._v("备注")]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "productSelect", attrs: { span: 18 } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                maxlength: "200",
                                clearable: "",
                                "show-word-limit": "",
                              },
                              model: {
                                value: item.remark,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "remark",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm.form.contactsList.length > 0
                  ? _c("div", { staticClass: "delBtnWrap" }, [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont closeBtn",
                          on: {
                            click: function ($event) {
                              return _vm.delContactsClick(item, index)
                            },
                          },
                        },
                        [_vm._v("")]
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }