<!--文件预览、下载组件-->
<template>
  <div>
    <el-dialog
      :visible="isShowDialog"
      :modal="false"
      :close-on-click-modal="false"
      @close="handleClose"
      width="70%">
      <span slot="title" class="dialog-header">
        <div v-if="showType === 'solution'">
          <span :class="getDictItemCssClass(dictConst.TASK_STATUS,currentItem.taskStatus)">{{ currentItem.taskStatusName }}</span>
          <span class="txt">/</span>
          <span class="txt">{{ currentItem.createTime }}</span>
          <span class="txt">/</span>
          <span class="txt">
            <span v-if="dictDataConst.TASK_STEP_TYPE.OVER_NO_RESOLVED==currentItem.stepType">
              由系统创建
            </span>
            <span v-else class="txt">
              由<span>{{ currentItem.createBy }}</span>创建，指派给<span>{{ currentItem.assignUserName }}</span>
            </span>
            <span class="txt">/</span>
            <span v-show="currentItem.serveType>0" class="txt">服务类型:{{currentItem.serveTypeName}}</span>
          </span>
        </div>
        <div v-else-if="showType === 'image'" class="txt">
          {{ title }}
        </div>
      </span>
      <my-editor
        v-if="isShowDialog && showType === 'solution'"
        :editor-content="solution"></my-editor>
      <!-- <div v-html=""></div> -->
      <el-image 
        v-else-if="isShowDialog && showType === 'image'"
        fit="fill"
        :src="getImgUrl()" 
        :preview-src-list="getImgUrlList()">
      </el-image>
    </el-dialog>
  </div>

</template>

<script>
import MyEditor from '@/modules/crm/components/Editor'
export default {
  name: 'ShowTaskSolution',
  props: {
    isShowDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '任务描述'
    },
    // 显示附件还是解决办法
    showType: {
      type: String,
      default: 'solution'
    },
    currentItem: {
      type: Object
    },
    solution: {
      type: String
    },
    html: {
      type: String
    },
    imageUrl: {
      type: String
    }
  },
  components: {
    MyEditor
  },
  watch: {
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
    }
  },
  created() {
  },
  methods:{
    handleClose(){
      // this.$emit('close')
      this.$emit('close', false)
    },
    getImgUrl(){
      // return this.baseURL+"/crm/sys/file/preview/"+this.fileId
      return this.imageUrl
    },
    getImgUrlList(){
      // const list = [this.baseURL+"/crm/sys/file/preview/"+this.fileId]
      const list = [this.imageUrl]
      return list
    },
  }
}
</script>

<style scoped lang="scss">
.viewImg {
  width: 100%;
}
.dialog-header {
  .txt {
    color: #5D5D5D;
    font-size: 14px;
  }
}
</style>
