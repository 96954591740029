var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.pageLoading,
          expression: "pageLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("角色管理")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "crm_btn_style",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAddRole },
                            },
                            [_vm._v(" 新建角色 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    size: "small",
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    placeholder: "角色名称",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                            }),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableloading,
                      expression: "tableloading",
                    },
                  ],
                  ref: "roleTable",
                  attrs: {
                    border: "",
                    stripe: "",
                    height: "100%",
                    data: _vm.tableDataList,
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "default-sort": { prop: "createTime", order: "descending" },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "角色名称",
                      prop: "roleName",
                      "min-width": "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "角色状态", align: "center", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              staticClass: "switch",
                              attrs: {
                                "active-color": "#F1880D",
                                "active-text": "启用",
                                "inactive-text": "停用",
                                "inactive-color": "#B5CCF5",
                                "active-value": 1,
                                "inactive-value": 0,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.handleStatusChange(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      prop: "createTime",
                      sortable: "custom",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "负责区域",
                      align: "center",
                      "min-width": "250",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(scope.row.areaList, function (item) {
                            return _c(
                              "el-tag",
                              {
                                staticStyle: {
                                  "margin-right": "4px",
                                  "margin-bottom": "2px",
                                },
                                attrs: { type: "info" },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          })
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#ed923a" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#ff0009" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableDataTotal > 0,
                    expression: "tableDataTotal>0",
                  },
                ],
                attrs: {
                  total: _vm.tableDataTotal,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.queryRoleList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editRoleDialogTitle,
            width: "60%",
            visible: _vm.editRoleDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editRoleDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleEditForm",
              attrs: {
                "status-icon": "",
                model: _vm.roleEditFormData,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "角色名称:",
                            prop: "roleName",
                            rules: [
                              {
                                required: true,
                                message: "请输入角色名称",
                                trigger: "blur",
                              },
                              {
                                min: 2,
                                max: 30,
                                message: "长度在 2 到 30 个字符",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入角色名称",
                              maxlength: "30",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.roleEditFormData.roleName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.roleEditFormData,
                                  "roleName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "roleEditFormData.roleName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.roleEditFormData.id
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.roleEditFormData.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.roleEditFormData,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "roleEditFormData.status",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("正常"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("停用"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "菜单权限",
                            prop: "menuIdList",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validateMenuIdList,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckedTreeExpand(
                                    $event,
                                    "menu"
                                  )
                                },
                              },
                              model: {
                                value: _vm.menuExpand,
                                callback: function ($$v) {
                                  _vm.menuExpand = $$v
                                },
                                expression: "menuExpand",
                              },
                            },
                            [_vm._v("展开/折叠")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckedTreeNodeAll(
                                    $event,
                                    "menu"
                                  )
                                },
                              },
                              model: {
                                value: _vm.menuNodeAll,
                                callback: function ($$v) {
                                  _vm.menuNodeAll = $$v
                                },
                                expression: "menuNodeAll",
                              },
                            },
                            [_vm._v("全选/全不选")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckedTreeConnect(
                                    $event,
                                    "menu"
                                  )
                                },
                              },
                              model: {
                                value: _vm.menuCheckStrictly,
                                callback: function ($$v) {
                                  _vm.menuCheckStrictly = $$v
                                },
                                expression: "menuCheckStrictly",
                              },
                            },
                            [_vm._v("父子联动 ")]
                          ),
                          _c("el-tree", {
                            ref: "menu",
                            staticClass: "tree-border",
                            attrs: {
                              data: _vm.menuOptions,
                              "show-checkbox": "",
                              "node-key": "id",
                              "check-strictly": !_vm.menuCheckStrictly,
                              "empty-text": "加载中，请稍后",
                              props: _vm.defaultProps,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "负责区域:",
                            prop: "provinceCodeList",
                            rules: [
                              {
                                required: true,
                                message: "请选择负责区域",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                placeholder: "请选择负责区域",
                                clearable: "",
                              },
                              model: {
                                value: _vm.roleEditFormData.provinceCodeList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.roleEditFormData,
                                    "provinceCodeList",
                                    $$v
                                  )
                                },
                                expression: "roleEditFormData.provinceCodeList",
                              },
                            },
                            _vm._l(_vm.provinceDict, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注信息:", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入备注信息",
                              maxlength: "400",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.roleEditFormData.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.roleEditFormData, "remark", $$v)
                              },
                              expression: "roleEditFormData.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.roleCancelEdit } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "background-color": "#F1880D",
                    "border-color": "#F1880D",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.roleConfirmEdit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }