var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer_wrap" },
    [
      _vm._t("default"),
      _vm.type == 2
        ? _c(
            "el-row",
            { staticClass: "newBuildContractWrap" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "newConstructionContract" }, [
                  _vm._v(_vm._s(_vm.type == 2 ? "新建任务" : "编辑任务")),
                ]),
              ]),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.type == 2,
                          expression: "type==2",
                        },
                      ],
                      attrs: { plain: "", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "saveBtn",
                      attrs: { plain: "", size: "mini" },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.type > 1
        ? _c("div", [
            _c(
              "div",
              { staticClass: "task_wrap" },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "80px",
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "任务标题", prop: "taskName" },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "98%" },
                                  attrs: {
                                    maxlength: "40",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.form.taskName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "taskName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.taskName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "终端客户",
                                  prop: "customerId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "98%" },
                                    attrs: {
                                      "remote-method":
                                        _vm.queryContractCustomerList,
                                      filterable: "",
                                      remote: "",
                                      clearable: "",
                                      placeholder: "请输入关键字检索终端客户",
                                    },
                                    on: { change: _vm.customerChange },
                                    model: {
                                      value: _vm.form.customerId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "customerId", $$v)
                                      },
                                      expression: "form.customerId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.contractCustomerList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.orgId,
                                        attrs: {
                                          label: item.orgName,
                                          value: item.orgId,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "labelSet", attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "产品名称",
                                  prop: "productKey",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "98%" },
                                    attrs: {
                                      disabled: _vm.isEmpty(
                                        _vm.form.customerId
                                      ),
                                      placeholder: _vm.isEmpty(
                                        _vm.form.customerId
                                      )
                                        ? "请先选择终端客户"
                                        : "请选择产品名称",
                                    },
                                    on: { change: _vm.productChange },
                                    model: {
                                      value: _vm.form.productKey,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "productKey", $$v)
                                      },
                                      expression: "form.productKey",
                                    },
                                  },
                                  _vm._l(_vm.productList, function (item) {
                                    return _c("el-option", {
                                      key: item.productKey,
                                      attrs: {
                                        label: item.productName,
                                        value: item.productKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.getList(_vm.productInfo, "extrasList")
                                    .length > 0,
                                expression:
                                  "getList(productInfo,'extrasList').length>0",
                              },
                            ],
                            attrs: { span: 12 },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "增值包" } },
                              _vm._l(
                                _vm.getList(_vm.productInfo, "extrasList"),
                                function (item, index) {
                                  return _c(
                                    "span",
                                    {
                                      key: item.id,
                                      staticClass: "normal_btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.contractTypeSelect(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.extrasName) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.productId,
                            expression: "form.productId",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "项目名称" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.productInfo.projectName || "无")
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.productKey,
                                expression: "form.productKey",
                              },
                            ],
                            attrs: { span: 12 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "提出人",
                                  prop: "feedbackUser",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "plain-primary-btn",
                                        on: { click: _vm.onAddCustom },
                                      },
                                      [_vm._v(" 新建客户联系人 ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "productWrap01" }, [
                                  _c(
                                    "div",
                                    { staticClass: "productItem" },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 24 },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    remote: "",
                                                    clearable: "",
                                                    size: "small",
                                                    placeholder: "请选择提出人",
                                                    "remote-method":
                                                      _vm.fetchProposer,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.feedbackUserChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.feedbackUserKey,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "feedbackUserKey",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.feedbackUserKey",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.proposerList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.contactsKey,
                                                      attrs: {
                                                        label: item.name,
                                                        value: item.contactsKey,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productTitle",
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v("部门")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 16 },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.feedbackUserMsg
                                                      .departmentName
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productTitle",
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v("职务")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 16 },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.feedbackUserMsg.postName
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._l(
                                        _vm.getList(
                                          _vm.feedbackUserMsg,
                                          "phoneList"
                                        ),
                                        function (item, index) {
                                          return _c(
                                            "el-row",
                                            { key: index },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "productTitle",
                                                  attrs: { span: 8 },
                                                },
                                                [
                                                  _vm._v(
                                                    "电话" + _vm._s(index + 1)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "productSelect",
                                                  attrs: { span: 16 },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "break-word",
                                                    },
                                                    [_vm._v(_vm._s(item.phone))]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productTitle",
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v("邮箱")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 16 },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "break-word" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.feedbackUserMsg.email
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productTitle",
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v("微信")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 16 },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "break-word" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.feedbackUserMsg
                                                        .wxAccount
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productTitle",
                                              attrs: { span: 8 },
                                            },
                                            [_vm._v("备注")]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "productSelect",
                                              attrs: { span: 16 },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "break-word" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.feedbackUserMsg.remark
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "labelSet", attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "紧急状态",
                                  prop: "taskUrgencyLevel",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "98%" },
                                    attrs: { placeholder: "请选择紧急级别" },
                                    model: {
                                      value: _vm.form.taskUrgencyLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "taskUrgencyLevel",
                                          $$v
                                        )
                                      },
                                      expression: "form.taskUrgencyLevel",
                                    },
                                  },
                                  _vm._l(
                                    _vm.taskUrgencyLevelDict,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "指派给", prop: "assignUser" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "98%" },
                                    attrs: {
                                      filterable: "",
                                      remote: "",
                                      clearable: "",
                                      placeholder: "请选择指派人",
                                      "remote-method": _vm.assignUserSearch,
                                    },
                                    model: {
                                      value: _vm.form.assignUser,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "assignUser", $$v)
                                      },
                                      expression: "form.assignUser",
                                    },
                                  },
                                  _vm._l(_vm.userList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nickName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "问题类型", prop: "problemTypeList" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "99%" },
                            attrs: {
                              multiple: "",
                              disabled: _vm.isEmpty(_vm.form.productId),
                              placeholder: _vm.isEmpty(_vm.form.productId)
                                ? "请先选择产品"
                                : "请选择问题类型",
                            },
                            on: { change: _vm.problemTypeChange },
                            model: {
                              value: _vm.form.problemTypeList,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "problemTypeList", $$v)
                              },
                              expression: "form.problemTypeList",
                            },
                          },
                          _vm._l(_vm.taskProblemDict, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.problemName,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "task-desc-form-item",
                        staticStyle: { width: "99%" },
                        attrs: { label: "任务描述", prop: "taskDesc" },
                      },
                      [
                        _vm.visible
                          ? _c("my-editor", {
                              attrs: { "editor-content": _vm.form.taskDesc },
                              on: { editorInput: _vm.onEditorInput },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "99%" },
                        attrs: { label: "", prop: "taskFileIdList" },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            staticStyle: { "margin-top": "10px" },
                            attrs: {
                              action: _vm.baseURL + "/crm/sys/file/uploadFile",
                              "on-change": _vm.handleChange,
                              "file-list": _vm.fileList,
                              data: _vm.uploadFileParams,
                              "auto-upload": true,
                              "before-upload": _vm.beforeUpload,
                              accept:
                                ".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,.docx",
                              "on-success": _vm.uploadSuccess,
                              "on-remove": _vm.handleFileRemove,
                              limit: 10,
                              "on-exceed": _vm.handleOnExceed,
                            },
                          },
                          [
                            _c("el-button", { attrs: { size: "mini" } }, [
                              _vm._v("添加附件"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "截止时间", prop: "endTime" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    clearable: false,
                                    "append-to-body": true,
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd",
                                    "picker-options": {
                                      disabledDate: function (time) {
                                        return (
                                          time.getTime() <
                                          Date.now() - 3600 * 1000 * 24
                                        )
                                      },
                                    },
                                  },
                                  model: {
                                    value: _vm.form.endTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "endTime", $$v)
                                    },
                                    expression: "form.endTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "",
                                  prop: "endTimeExt",
                                  "label-width": "10px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    model: {
                                      value: _vm.form.endTimeExt,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endTimeExt", $$v)
                                      },
                                      expression: "form.endTimeExt",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "上午", value: "上午" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "下午", value: "下午" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.type == 1
        ? _c("view-task", { attrs: { "task-id": _vm.taskId } })
        : _vm._e(),
      _vm.showAddCustom
        ? _c("add-custom-vue", {
            attrs: {
              "customer-id": _vm.form.customerId,
              "show-add-custom": _vm.showAddCustom,
            },
            on: {
              "update:showAddCustom": function ($event) {
                _vm.showAddCustom = $event
              },
              "update:show-add-custom": function ($event) {
                _vm.showAddCustom = $event
              },
              addCustom: _vm.addCustomCallback,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }