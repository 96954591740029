var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.pageLoading,
          expression: "pageLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", { staticClass: "header" }, [
            _c(
              "div",
              { ref: "headerQuery", staticStyle: { width: "100%" } },
              [
                _c(
                  "el-row",
                  { staticClass: "titleClass" },
                  [
                    _c(
                      "el-col",
                      { staticClass: "module-title", attrs: { span: 12 } },
                      [_vm._v("注册审核")]
                    ),
                    _c("el-col", { attrs: { span: 12 } }),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            staticClass: "query-form",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    size: "small",
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    clearable: "",
                                    placeholder: "用户名/手机号/邮箱",
                                  },
                                  nativeOn: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleQuery($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "130px" },
                                    attrs: {
                                      size: "small",
                                      filterable: "",
                                      placeholder: "请选择状态",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.registerStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "registerStatus",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.registerStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.registerStatusDict,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: { click: _vm.handleQuery },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  ref: "roleTable",
                  attrs: {
                    border: "",
                    stripe: "",
                    height: "100%",
                    data: _vm.tableDataList,
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    "default-sort": {
                      prop: "registerTime",
                      order: "descending",
                    },
                  },
                  on: { "sort-change": _vm.tableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名称",
                      prop: "name",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "手机号",
                      prop: "phone",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邮箱", prop: "email", "min-width": "150" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "状态",
                      prop: "registerStatusName",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "申请时间",
                      prop: "registerTime",
                      "min-width": "150",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "审批时间",
                      prop: "approvalTime",
                      "min-width": "150",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "拒绝理由",
                      prop: "refuseReason",
                      "min-width": "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.allowApproval == 1,
                                    expression: "scope.row.allowApproval==1",
                                  },
                                ],
                                staticStyle: { color: "#ed923a" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApproval(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("通过 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.allowApproval == 1,
                                    expression: "scope.row.allowApproval==1",
                                  },
                                ],
                                staticStyle: { color: "#ff0009" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRefuse(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("拒绝 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tableDataTotal == 0
                    ? _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-left-data.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tableDataTotal > 0,
                    expression: "tableDataTotal>0",
                  },
                ],
                attrs: {
                  total: _vm.tableDataTotal,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                  "page-sizes": [10, 20, 50, 100],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.queryList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审批",
            width: "500px",
            visible: _vm.approvalDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.approvalDialogVisible = $event
            },
          },
        },
        [
          _vm._v(" 拒绝理由(将通过邮件发送至用户) "),
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "status-icon": "",
                model: _vm.editFormData,
                "label-width": "0px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    prop: "refuseReason",
                    rules: [
                      {
                        required: true,
                        message: "请填写拒绝理由",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请填写拒绝理由",
                      maxlength: "200",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.editFormData.refuseReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.editFormData, "refuseReason", $$v)
                      },
                      expression: "editFormData.refuseReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.approvalDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "background-color": "#F1880D",
                    "border-color": "#F1880D",
                  },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doRefuse()
                    },
                  },
                },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }