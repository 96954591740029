<template>
  <div
    class="app-container"
    style="height:100%"
    v-loading.fullscreen.lock="pageLoading">
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="titleClass">
            <el-col :span="12" class="module-title">任务管理</el-col>
            <el-col :span="12">
              <div style="float: right;display: flex;">
                <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">
                  发布任务
                </el-button>
                <el-button
                  class="crm_btn_style export-btn"
                  size="small"
                  @click="onExport">
                  <svg
                    class="icon svg-icon export"
                    aria-hidden="true"
                    style="width: 16px;height: 16px;margin-right: 6px;"
                    fill="#777777">
                    <use xlink:href="#icon-export"></use>
                  </svg>
                  导出任务
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">
                <el-form-item label="" class="block-form-item">
                  <el-tabs v-model="queryParams.tabCode" @tab-click="tabClick">
                    <el-tab-pane
                      v-for="(item) in tabList"
                      :key="item.id"
                      :label="item.dictLabel"
                      :name="item['id'].toString()">
                    </el-tab-pane>
                  </el-tabs>
                </el-form-item>
                <el-form-item label="">
                  <el-input
                    v-model="queryParams.searchValue"
                    size="small"
                    style="width:365px;"
                    maxlength="30"
                    clearable
                    placeholder="任务编号/任务标题/客户名称/项目名称/产品名称/提出人"
                    @keydown.enter.native="handleQuery"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-select
                    v-model="queryParams.provinceCodeList"
                    size="small"
                    style="width:150px;"
                    filterable
                    multiple
                    collapse-tags
                    @keydown.enter.native="handleQuery"
                    placeholder="请选择省份"
                    clearable>
                    <el-option
                      :label="item.name"
                      :value="item.code"
                      :key="item.code"
                      v-for="item in provinceList"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-cascader
                    v-model="queryParams.productIdArr"
                    :props="defaultParams"
                    :options="productTreeDict"
                    size="small"
                    placeholder="请选择产品名称"
                    style="width:100%;"
                    :show-all-levels="false"
                    @change="changeProduct">
                  </el-cascader>
                </el-form-item>
                <el-form-item label="">
                  <el-select
                    v-model="queryParams.assignUser"
                    size="small"
                    style="width:100px;"
                    filterable
                    remote
                    clearable
                    placeholder="指派给"
                    :remote-method="assignUserSearch">
                    <el-option
                      :label="item.nickName"
                      :value="item.id"
                      :key="item.id"
                      v-for="item in userList"></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="">
                  <el-select
                    v-model="queryParams.taskStatusList"
                    size="small"
                    style="width:150px;"
                    filterable
                    multiple
                    collapse-tags
                    @keydown.enter.native="handleQuery"
                    placeholder="产品状态"
                    clearable>
                    <el-option
                      :label="item.dictLabel"
                      :value="item.id"
                      :key="item.id"
                      v-for="item in taskStatusDict"></el-option>
                  </el-select>
                </el-form-item> -->
                
                <el-form-item>
                  <el-button icon="el-icon-search" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
                <el-button
                  type="text"
                  size="small"
                  class="resetFilterClass"
                  @click="resetSearchValue()">
                  <i class="iconfont">&#xe660;</i>
                </el-button>
                <el-button
                  v-if="isFold"
                  type="text"
                  size="small"
                  @click="queryParamsFoldClick">
                  <span>更多筛选</span>
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </el-button>
                <el-button
                  v-else
                  type="text"
                  size="small"
                  @click="queryParamsFoldClick">
                  <span>收起筛选</span>
                  <i class="el-icon-caret-top el-icon--right"></i>
                </el-button>
              </el-form>
            </el-col>
          </el-row>
          <el-row v-if="!isFold">
            <el-col :span="24">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true">
                <el-form-item label="">
                  <el-select
                    v-model="queryParams.productStatusList"
                    size="small"
                    style="width:200px;"
                    filterable
                    multiple
                    collapse-tags
                    @keydown.enter.native="handleQuery"
                    placeholder="产品状态"
                    clearable>
                    <el-option
                      :label="item.dictLabel"
                      :value="item.id"
                      :key="item.id"
                      v-for="item in projectStatusDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-select
                    v-model="queryParams.serveType"
                    size="small"
                    style="width:150px;"
                    filterable
                    @keydown.enter.native="handleQuery"
                    placeholder="服务类型"
                    clearable>
                    <el-option
                      :label="item.dictLabel"
                      :value="item.id"
                      :key="item.id"
                      v-for="item in taskServeTypeDict"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table
          height="100%"
          v-loading="tableLoading"
          :data="tableDataList"
          border
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :row-class-name="tableRowClassName"
          ref="mainTable"
          :default-sort="{prop: 'startTime', order: 'descending'}"
          @sort-change="tableSortChange">
          <el-table-column label="任务编号" prop="id" width="80"/>
          <el-table-column label="紧急状态" prop="taskUrgencyLevelName" width="80"/>
          <el-table-column label="任务标题" prop="taskName" min-width="150">
            <template slot-scope="scope">
              <span style="color:#F1880D;cursor: pointer;" @click="onCheck(scope.row)">{{ scope.row.taskName }}</span>
              <!--              @click="handleView(scope.row)"-->
            </template>
          </el-table-column>


          <el-table-column label="终端客户" prop="customerName" min-width="100"/>

          <!-- <el-table-column label="项目名称" prop="projectName" min-width="100"/> -->
          <el-table-column label="产品名称" prop="productName" min-width="100"/>
          <el-table-column label="服务类型" prop="serveTypeName" min-width="100"/>
          <el-table-column label="提出人" prop="feedbackUser" width="100">
            <template slot-scope="scope">
              {{ scope.row.feedbackUser?scope.row.feedbackUser.name:"" }}
            </template>
          </el-table-column>
          <el-table-column label="指派给" prop="assignUserName" width="100"/>
          <el-table-column label="任务状态" prop="taskStatusName" width="100">
            <template slot-scope="scope">
              <span :class="getDictItemCssClass(dictConst.TASK_STATUS,scope.row.taskStatus)">
                {{ scope.row.taskStatusName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" prop="startTime" width="130" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.startTime}}<span style="padding-right: 4px;"></span>{{ scope.row.startTimeExt }}
            </template>
          </el-table-column>
          <el-table-column label="截止时间" prop="endTime" width="130" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.endTime}}<span style="padding-right: 4px;"></span>{{ scope.row.endTimeExt }}
            </template>
          </el-table-column>
          <el-table-column label="任务发出人" prop="createBy" width="100"/>
          <el-table-column label="操作" min-width="130" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a;font-size: 12px;"
                v-if="dictDataConst.TASK_STATUS.RESOLVED!=scope.row.taskStatus"
                @click="handleDispose(scope.row)"
              >去处理
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ed923a;font-size: 12px;"
                v-show="scope.row.createUser==userInfo.userId"
                @click="handleEdit(scope.row)"
              >编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                style="color:#ff0009;font-size: 12px;"
                v-show="scope.row.createUser==userInfo.userId && dictDataConst.TASK_STATUS.RESOLVED!==scope.row.taskStatus"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <el-col :span="10">
          <div style="color:#999;line-height: 40px;font-size: 12px;">
            按住Shift+鼠标滚轮左右滑动列表
          </div>
        </el-col>
        <el-col :span="14">
          <div>
            <pagination
              v-show="tableDataTotal>0"
              :total="tableDataTotal"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="queryList"
              :page-sizes="[10, 20, 50, 100]"/>
          </div>
        </el-col>
      </el-footer>
    </el-container>
    <!--新建任务-->
    <el-drawer
      :visible.sync="editDrawer"
      :modal="false"
      :with-header="false"
      :wrapperClosable="false"
      :append-to-body="true"
      :before-close="((done)=>{closeDrawer(2)})"
      size="980px">
      <div class="drawer_wrap">
        <edit-task
          :task-id="editTaskInfo.id"
          :type="editType"
          :task-info="editTaskInfo"
          :visible="editDrawer">
        </edit-task>
      </div>
      <div class="closeBtn" @click="closeDrawer(2)">
        <i class="iconfont">&#xe677;</i>
      </div>
    </el-drawer>
    <deal-task
      v-if="dealTaskDialog"
      :task-info="editTaskInfo"
      :task-dialog-visible="dealTaskDialog"
      @closeTask="closeDealTaskDialog"></deal-task>
    <!--直接在表格的操作列点击编辑按钮-->
    <edit-common
      v-if="editCommon"
      :editCommonTitle="editCommonTitle"
      :is-edit.sync="isEdit"
      :drawer="editCommon"
      :activeNum="activeNum"
      :contractId="contractId"
      :projectId="projectId"
      :titleName="taskName"
      :taskId="taskId"
      :task-info="editTaskInfo"
      :task-edit-auth="taskEditAuth"
      :task-del-auth="taskDelAuth"
      :task-handle-auth="taskHandleAuth"
      @handleEditCommon="handleEditCommon"
      @dealTask="dealTask">
    </edit-common>
  </div>
</template>
<script>
import { isEmpty } from '@/utils/common'
import { allUserListApi, userListApi } from '@/modules/crm/api/system/user'
import { getAllRoleApi } from '@/modules/crm/api/system/role'
import { delTaskApi, taskListApi, taskDataReportApi } from '@/modules/crm/api/crm/task'
import EditTask from '@/modules/crm/view/crm/task/components/editTask'
import DealTask from '@/modules/crm/view/crm/task/components/dealTask'
import EditCommon from '@/modules/crm/components/EditCommon'
import {
  QUERY_OBJ,
  TASK_TYPE_LIST
} from '@/modules/crm/utils/task'
import { getDictItemCssClass } from '../../../utils/dict'
import {axiosDownload} from '@/utils/common'
import {
  provinceListApi,
} from '@/modules/crm/api/crm/org'
import {
  productTree,
} from '@/modules/crm/api/crm/contract'
export default {
  name: 'crmTask',
  components: {
    EditTask,
    DealTask,
    EditCommon
  },
  data() {
    return {
      //整页加载层
      pageLoading: false,
      // 表格的加载层
      tableLoading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: this.$deepClone(QUERY_OBJ),
      editDialogVisible: false,
      isFold: true, // 更多筛选
      //任务服务类型字典
      taskServeTypeDict: this.getDict(this.dictConst.TASK_SERVE_TYPE),
      // 产品状态
      projectStatusDict: this.getDict(this.dictConst.PROJECT_SHOW_STATUS),
      //任务状态
      taskStatusDict: this.getDict(this.dictConst.TASK_STATUS),
      taskTypeList: this.$deepClone(TASK_TYPE_LIST),
      tabList: [],
      //用户列表
      userList: [],
      provinceList: [],
      defaultParams: {
        label: "productName",
        value: "productId",
        children: 'childrenList'
      },
      productTreeDict: [], // 产品名称
      //用户部门字典
      sysUserDeptDict: this.getDict(this.dictConst.SYS_USER_DEPT),
      editDrawer: false,
      //当前编辑任务信息
      editTaskInfo: {},
      //编辑组件状态 1仅查看 2新增 3编辑
      editType: 2,
      //任务处理弹窗
      dealTaskDialog:false,
      activeNum: 5,  // 任务
      editCommon: false,
      editCommonTitle: '',
      contractId: undefined,
      projectId: undefined,
      taskId: undefined,
      taskName: '',
      isEdit: true,
    }
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.state.user
      },
    },
    taskEditAuth() {
      return this.editTaskInfo.createUser === this.userInfo.userId
    },
    // 去处理权限
    taskHandleAuth() {
      const taskFlag = this.dictDataConst.TASK_STATUS.RESOLVED !== this.editTaskInfo.taskStatus
      // const createUserFlag = this.editTaskInfo.createUser === this.userInfo.userId
      return taskFlag
    },
    // 删除权限
    taskDelAuth() {
      const taskFlag = this.dictDataConst.TASK_STATUS.RESOLVED !== this.editTaskInfo.taskStatus
      const createUserFlag = this.editTaskInfo.createUser === this.userInfo.userId
      return taskFlag && createUserFlag
    }
  },
  async created() {
    // tab数据处理
    this.tabList = this.taskTypeList.concat(this.taskStatusDict)
    console.log(this.tabList)
    console.log(this.taskTypeList)
    console.log(this.taskStatusDict)
    this.handleQuery()
    this.getAllUser()
    await this.fetchProvinceData()
    await this.fetchProductTreeData()
    this.eventBus.$on(this.eventConst.task.addSucceed,()=>{
      this.editDrawer=false
      this.handleQuery()
    });

    this.eventBus.$on(this.eventConst.task.editSucceed,()=>{
      this.editDrawer = false
      this.editCommon = false
      // this.queryParams.pageNum = 1
      // this.handleEditCommon()
      this.queryList()
    });
    this.eventBus.$on(this.eventConst.task.delSucceed,()=>{
      // this.handleEditCommon()
      this.editCommon = false
      this.editDrawer = false
      this.handleQuery()
    });
  },
  methods: {
    async onExport() {
      let res = await taskDataReportApi(this.queryParams)
      if (res.type == 'application/json') {
        this.msgError('导出任务失败')
      } else {
        axiosDownload('导出任务.xlsx', res)
        this.msgSuccess('导出任务完成, 请在浏览器的下载列表中查看')
      }
      console.log(res)
    },
    handleEditCommon() {
      this.editCommon = false
      // this.handleQuery()
      this.queryList()
    },
    tabClick(tab, event) {
      console.log(tab)
      console.log(event)
      if (tab.name === '0') {
        // 进行中
        this.queryParams.taskStatusList = ['1600', '1601', '1603']
        this.queryParams.taskType = 1
      } else if (tab.name === '1' || tab.name === '2' || tab.name === '3') {
        this.queryParams.taskStatusList = []
        this.queryParams.taskType = tab.name
      } else {
        this.queryParams.taskStatusList = [tab.name]
        this.queryParams.taskType = 1
      }
      this.handleQuery()
    },
    /** 关闭抽屉操作 1新增 2修改 */
    closeDrawer(type) {
      console.log('closeDrawer', type)
      if (type == 1) {
        //this.addDrawer = false
        //发布任务弹窗关闭事件
        this.eventBus.$emit(this.eventConst.task.closeAddDrawer)
      } else {
        this.editDrawer = false
      }

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.queryList(1)
    },
    changeProduct(val) {
      console.log(val)
      if (val && val.length > 0) {
        this.queryParams.productId = val[val.length - 1]
      }
      console.log(this.queryParams.productId)
    },
    // 重置查询条件
    resetSearchValue() {
      // this.queryParams = {
      //   pageNum: 1,
      //   pageSize: 10,
      //   orderByColumn: 'createTime',
      //   isAsc: 'asc'
      // }
      const taskType = this.queryParams.taskType
      this.queryParams = this.$deepClone(QUERY_OBJ)
      this.queryParams.taskType = taskType
      this.handleQuery()
    },
    queryParamsFoldClick() {
      this.isFold = !this.isFold
    },
    /** 查询列表 */
    queryList() {
      this.tableLoading = true
      let _this = this

      taskListApi(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.mainTable && _this.$refs.mainTable.doLayout()
        })
        this.tableLoading = false
      })
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /** 查看按钮操作 */
    onCheck(row) {
      this.isEdit = false
      this.editCommon = true
      this.taskId = row.id
      this.contractId = row.contractId
      this.projectId = row.projectId
      this.taskName = row.taskName
      this.editTaskInfo = row
      console.log(this.editTaskInfo)
    },
    /** 修改按钮操作 */
    handleEdit(row) {
      this.isEdit = true
      this.editCommon = true
      this.taskId = row.id
      this.contractId = row.contractId
      this.projectId = row.projectId
      this.taskName = row.taskName
      this.editTaskInfo = row
    },
    /** 修改按钮操作 */
    // handleEdit(row) {
    //   this.editDrawer = true
    //   this.editTaskInfo = row
    //   this.editType = 3
    // },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除任务【' + row.taskName + '】?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return delTaskApi(row.id)
      }).then(() => {
        this.handleQuery()
        this.msgSuccess('删除成功')
      })
    },
    /** 发布任务操作 */
    handleAdd() {
      console.log('handleAdd')
      this.editDrawer = true
      this.editTaskInfo = {}
      this.editType = 2
    },
    /** 去处理操作 */
    handleDispose(row) {
      //this.drawer = true
      this.editTaskInfo = row
      this.dealTaskDialog = true
    },
    dealTask() {
      this.dealTaskDialog = true
    },
    /** 查看任务操作 */
    handleView(row) {
      console.log('handleView', row)
      this.editTaskInfo = row
      this.editDrawer = true
      this.editType = 1
    },
    async assignUserSearch(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.userList = res.data
      })
    },
    // 获取省份数据
    async fetchProvinceData() {
      let res = await provinceListApi()
      if (res.code === 200) {
        this.provinceList = res.data || []
      }
    },
    // 产品字典树形结构
    async fetchProductTreeData() {
      let res = await productTree()
      if(res.code === 200) {
        this.productTreeDict = res.data || []
      }
    },
    //关闭处理任务弹窗
    closeDealTaskDialog(){
      console.log("关闭处理任务弹窗")
      this.dealTaskDialog=false

    },
    //修改表格行的背景色
    tableRowClassName({row, rowIndex}) {
      return this.getDictItemCssClass(this.dictConst.TASK_URGENCY_LEVEL,row.taskUrgencyLevel)
    }

  }
}
</script>
<style lang="scss" scoped>
.crm_btn_style {
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::v-deep span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.export-btn {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #BCBCBC;
    color: #777777;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;
      &.block-form-item {
        display: block;
      }
      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.el-footer {
  padding: 0 20px 0 0;
}

.closeBtn {
  position: fixed;
  right: 980px;
  top: 50%;
  display: flex;
  height: 60px;
  padding: 20px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 10px 0px 0px 10px;
  background: #FFF;
  /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;

  i {
    color: #F09933;
    font-size: 20px;
  }
}

</style>

<style >
.el-table .task-very-urgent-row {
  /*background: #FFF3F3;*/
}

.el-table .task-urgent-row {
  /* background: #FFFEF3; */
}
.el-table .task-normal-row {
  /* background: #FFFFFF; */
}
</style>


