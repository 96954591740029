<template>
  <div class="NewConstructionPeople_wrap">
    <div v-if="projectId">
      <el-form ref="form" :model="form" :rules="rules" label-width="85px" label-position="left">
      <!--      style="border-bottom: 1px dotted #F0F0F0;"-->
<!--      <el-form-item label="客户方" required>-->
<!--        <div style="text-align: right;">-->
<!--          <el-button size="mini" @click="addPurchaserContactsList">添加</el-button>-->
<!--        </div>-->
<!--      </el-form-item>-->
      <div class="projectTitle">
        <el-row>
          <el-col :span="12" style="text-align: left;">客户方</el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button size="mini" @click="addPurchaserContactsList">添加</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="contactList">
        <div class="productWrap01"
             v-for="(item, index) in form.purchaserContactsListArr"
             :key="item.id">
          <div class="productItem">
            <el-row>
              <el-col :span="21" class="productSelect">
                <el-form-item
                  :prop="`purchaserContactsListArr[${index}].id`"
                  :show-message="false"
                  class="contract_product"
                  label-width="40">
                  <el-select
                    v-model="item.id"
                    placeholder="请先选择客户方"
                    size="mini"
                    style="width: 100%;"
                    :disabled="!form.contractId"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteKHFMethod"
                    @change="purchaserContactsListChange($event,index)">
                    <el-option
                      v-for="i in pclArr"
                      :disabled="setDisabled(i, index,0)"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="productClose">
                <i class="iconfont" @click="delPurchaserContactsList(index)">&#xe672;</i>
<!--                v-if="form.purchaserContactsListArr.length > 1"-->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="productTitle">类型</el-col>
              <el-col :span="18" class="productSelect">
                <span class="typeSpan" v-if="item.pclObject.orgId && item.pclObject.orgId===contractInfoList.purchaser">购买方</span>
                <template v-for="i in contractInfoList.customerList">
                    <span class="typeSpan" v-if="item.pclObject.orgId==i.customerId">
                      终端客户
                    </span>
                </template>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">部门</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.pclObject.departmentName}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">职务</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.pclObject.postName}}</span>
              </el-col>
            </el-row>
            <el-row v-for="(phoneItem,phoneIndex) in item.pclObject.phoneList" :key="phoneItem.id">
              <el-col :span="5" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{phoneItem.phone}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">邮箱</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.pclObject.email}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">微信</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.pclObject.wxAccount}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">备注</el-col>
              <el-col :span="19" class="productSelect">
                 <span
                   class="remarkStyle tipClass"
                   :title="item.pclObject.remark">
                  {{ item.pclObject.remark }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
<!--      <el-form-item label="乙方" required>-->
<!--        <div style="text-align: right;">-->
<!--          <el-button size="mini" @click="addSellerContactsList">添加</el-button>-->
<!--        </div>-->
<!--      </el-form-item>-->
      <div class="projectTitle">
        <el-row>
          <el-col :span="12" style="text-align: left;">乙方</el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button size="mini" @click="addSellerContactsList">添加</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="contactList">
        <div class="productWrap01"
             v-for="(item, index) in form.sellerContactsListArr"
             :key="item.id">
          <div class="productItem">
            <el-row>
              <el-col :span="21" class="productSelect">
<!--                :rules=" [-->
<!--                { required: true, message: '请选择乙方', trigger: 'change' }-->
<!--                ]"-->
                <el-form-item
                  :prop="`sellerContactsListArr[${index}].id`"
                  :show-message="false"
                  class="contract_product"
                  label-width="40">
                  <el-select
                    v-if="contractInfoList.sellerType === dictDataConst.CONTRACT_SELLER_TYPE.SELL"
                    v-model="item.id"
                    placeholder="请先选择乙方"
                    size="mini"
                    style="width: 100%;"
                    :disabled="!form.contractId"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteWXFMethod"
                    @change="sellerContactsListArrChange($event,index,userList)">
                    <el-option
                      v-for="i in userList"
                      :disabled="setDisabled(i, index,1)"
                      :key="i.id"
                      :label="i.nickName"
                      :value="i.id">
                    </el-option>
                  </el-select>
                  <el-select
                    v-else
                    v-model="item.id"
                    placeholder="请先选择乙方"
                    size="mini"
                    style="width: 100%;"
                    :disabled="!form.contractId"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteYFMethod"
                    @change="sellerContactsListArrChange($event,index,sclArr)">
                    <el-option
                      v-for="i in sclArr"
                      :disabled="setDisabled(i, index,2)"
                      :key="i.id"
                      :label="i.name"
                      :value="i.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="productClose">
                <i class="iconfont" @click="delSellerContactsList(index)">&#xe672;</i>
<!--                v-if="form.sellerContactsListArr.length > 1"-->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">类型</el-col>
              <el-col :span="19" class="productSelect">
                <span  class="appreciationPackage" v-if="contractInfoList.sellerTypeName">{{ contractInfoList.sellerTypeName }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">部门</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.departmentName}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">职务</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.postName}}</span>
              </el-col>
            </el-row>
            <el-row
              v-for="(phoneItem,phoneIndex) in item.sclObject.phoneList"
              :key="phoneItem.id">
              <el-col :span="5" class="productTitle">电话{{ Number(phoneIndex+1)}}</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{phoneItem.phone}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">邮箱</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.email}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">微信</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.wxAccount}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">备注</el-col>
              <el-col :span="19" class="productSelect">
                 <span
                   class="remarkStyle tipClass"
                   :title="item.sclObject.remark">
                  {{ item.sclObject.remark }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
<!--      <el-form-item-->
<!--        label="卫心方"-->
<!--        required-->
<!--        v-if="contractInfoList.sellerType!==contractSellerTypeDict[0].id">-->
<!--        <div style="text-align: right;">-->
<!--          <el-button size="mini" @click="addSysUserContactsList">添加</el-button>-->
<!--        </div>-->
<!--      </el-form-item>-->
      <div class="projectTitle" v-if="contractInfoList.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
        <el-row>
          <el-col :span="12" style="text-align: left;">卫心方</el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button size="mini" @click="addSysUserContactsList">添加</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="contactList" v-if="contractInfoList.sellerType!==dictDataConst.CONTRACT_SELLER_TYPE.SELL">
        <div class="productWrap01" v-for="(item, index) in form.sysUserContactsListArr" :key="item.id">
          <div class="productItem">
            <el-row>
              <el-col :span="21" class="productSelect">
<!--                :rules=" [-->
<!--                { required: true, message: '请选择卫心方', trigger: 'change' }-->
<!--                ]"-->
                <el-form-item
                  :prop="`sysUserContactsListArr[${index}].id`"
                  :show-message="false"
                  class="contract_product"
                  label-width="40">
                  <el-select
                    v-model="item.id"
                    placeholder="请先选择卫宁方"
                    size="mini"
                    style="width: 100%;"
                    :disabled="!form.contractId"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="remoteWXFMethod"
                    @change="sysUserContactsListArrChange($event,index,userList)">
                    <el-option
                      v-for="i in userList"
                      :disabled="setDisabled(i, index,3)"
                      :key="i.id"
                      :label="i.nickName"
                      :value="i.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3" class="productClose">
                <i class="iconfont" @click="delSysUserContactsList(index)">&#xe672;</i>
<!--                v-if="form.sysUserContactsListArr.length > 1"-->
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">部门</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.departmentName}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">职务</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.postName}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">电话</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.phone}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">邮箱</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.email}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">微信</el-col>
              <el-col :span="19" class="productSelect">
                <span>{{item.sclObject.wxAccount}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5" class="productTitle">备注</el-col>
              <el-col :span="19" class="productSelect">
                 <span
                   class="remarkStyle tipClass"
                   :title="item.sclObject.remark">
                  {{ item.sclObject.remark }}
                </span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-form>
    </div>
    <div v-else class="noDataWrap">
      <div class="noData">
        <img src="../assets/images/noData.png" style="width: 96px;height: 60px;"/>
        <div class="noDataText">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryContractList,
  contractInfo
} from '@/modules/crm/api/crm/contract'
import {
  searchContactsList
} from '@/modules/crm/api/crm/org'
import { allUserListApi } from '@/modules/crm/api/system/user'
import {
  projectListApi,
  projectInfoApi,
  addProjectApi,
  editProjectContract
} from "@/modules/crm/api/crm/project";
export default {
  name: "NewConstructionProject" ,
  props: {
    // isNewConstruction: { // 遮罩层开关
    //   type: Boolean,
    //   required: true
    // },
    // institutionId: {
    //   type: Number
    // },
    projectId: {
      type: Number
    }
  },
  data() {
    return {
      contractDict: [], // 合同列表
      loading: false,
      contractInfoList: {}, // 合同详情信息
      contractSellerTypeDict: [],
      pclArr: [], // 甲方
      sclArr: [], // 乙方
      wxArr: [], // 卫心方
      userList: [], // 全部用户列表
      // 项目状态字典
      projectStatusDict: this.getDict(this.dictConst.PROJECT_STATUS),
      value: true,
      form: {},
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        filingTime: [
          { type: 'string', required: true, message: '请选择建项日期', trigger: 'change' }
        ],
        contractId: [
          { required: true, message: '请选择合同编号', trigger: 'change' }
        ],
        purchaserContactsList: [
          { required: true, message: '请选择客户方', trigger: 'change' }
        ],
        sellerContactsList: [
          { required: true, message: '请选择乙方', trigger: 'change' }
        ],
        sysUserContactsList: [
          { required: true, message: '请选择卫心方', trigger: 'change' }
        ]
      },
      projectListLen: null, // 项目个数
      projectList: [], // 项目列表
      projectObject: {}, // 单个项目信息
      projectValue: '',
      khfOrgIdList: [], // 客户方orgIdList
      yfOrgIdList: [], // 乙方orgIdList
      khfLength: null, // 客户方可添加最大人数
      yfLength: null, // 乙方可添加最大人数
      wxfLength: null // 卫心方可添加最大人数
    }
  },
  computed:{
    setDisabled () {
      return function (opt, index,val) {
        if (val === 0) {
          // 客户方
          const selectedArr = this.form.purchaserContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id)
        } else if (val === 1) {
          // 已方（自营）
          const selectedArr = this.form.sellerContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id) || opt.status===0
        } else if (val === 2) {
          // 已方（经销）
          const selectedArr = this.form.sellerContactsListArr.map(item => item.id)
          selectedArr.splice(index, 1)
          return selectedArr.includes(opt.id)
        } else if (val === 3) {
          // 卫心方
          // opt为每项option，index为el-select索引
          // 全部已选择
          const selectedArr = this.form.sysUserContactsListArr.map(item => item.id)
          // 删除自己的已选
          selectedArr.splice(index, 1)
          // 其它的已选，禁用
          return selectedArr.includes(opt.id) || opt.status===0
        }
      }
    }
  },
  async created() {
    this.contractSellerTypeDict = this.getDict(this.dictConst.CONTRACT_SELLER_TYPE)
    this.getAllUser()
    this.reset()
    // if(this.institutionId) {
    //   await this.projectListApiFn(this.institutionId)
    // }
    if (this.projectId) {
      await this.projectInfoFn(this.projectId)
    }
  },
  methods: {
    // 机构关联项目列表
    async projectListApiFn(val) {
      let obj = {}
      obj.orgId = val
      let res = await projectListApi(obj)
      console.log(res)
      if(res.code === 200) {
        this.projectList = res.data
        this.projectListLen = res.data.length
        this.projectValue = this.projectList[0].id // 默认选中第一个
        this.projectChange(this.projectValue)
      }
    },
    projectChange(val) {
      console.log(val)
      if (val) {
        this.projectInfoFn(val)
      } else {
        this.projectObject = {}
      }
    },
    // 客户方
    async remoteKHFMethod(query) {
      // console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          obj.orgIdList = this.khfOrgIdList
          let res = await searchContactsList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.pclArr = res.data
          }
        }, 200);
      } else {
        this.pclArr = [];
      }
    },
    // 乙方
    async remoteYFMethod(query) {
      // console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.searchValue = query
          obj.orgIdList = this.yfOrgIdList
          let res = await searchContactsList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.sclArr = res.data
          }
        }, 200);
      } else {
        this.sclArr = [];
      }
    },
    // 获取相关项目详细信息
    async projectInfoFn(val) {
      let res = await projectInfoApi(val)
      console.log(res)
      if (res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.projectObject = res.data
        this.form =this.$deepClone(res.data)
        Object.assign(this.form,res.data)
        console.log(this.form)
        this.contractDict = [
          {
            contractId: this.form.contractId,
            contractName: this.form.contractName,
            contractNum: this.form.contractNum
          }
        ]
        this.contractInfoList =this.$deepClone(res.data)
        Object.assign(this.contractInfoList,res.data)
        this.form.productListArr = res.data.productList
        // 客户list
        this.form.purchaserContactsListArr = []
        this.form.purchaserContactsList.forEach((item,index)=> {
          let obj = {}
          obj.id = item.id
          obj.pclObject = item
          this.form.purchaserContactsListArr.push(obj)
        })
        let khArr = [this.contractInfoList.purchaser]
        if(this.contractInfoList.customerList.length>0) {
          this.contractInfoList.customerList.forEach((item) => {
            khArr.push(item.customerId)
          })
        }
        let khArray = Array.from(new Set(khArr)); // 将Set转换为数组并赋值给khArray
        console.log(khArray)
        this.khfOrgIdList = khArray
        this.pclArr = await this.searchContactsListFn(khArray)
        if (this.pclArr&&this.pclArr.length>0) {
          this.khfLength = this.pclArr.length
        } else {
          this.khfLength = 0
        }
        console.log(this.pclArr)
        // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
        if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
          // 乙方list
          this.form.sellerContactsListArr = []
          this.form.sellerContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sellerContactsListArr.push(obj)
          })
          if (this.userList&&this.userList.length>0) {
            this.yfLength = this.userList.length
          } else {
            this.yfLength = 0
          }
          // await this.getAllUser()
        } else {
          // 乙方list
          this.form.sellerContactsListArr = []
          this.form.sellerContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sellerContactsListArr.push(obj)
          })
          this.form.sysUserContactsListArr = []
          this.form.sysUserContactsList.forEach((item,index)=> {
            let obj = {}
            obj.id = item.id
            obj.sclObject = item
            this.form.sysUserContactsListArr.push(obj)
          })
          let sclArray = []
          if (this.contractInfoList.distribution) {
            sclArray = [this.contractInfoList.seller,this.contractInfoList.distribution]
          } else {
            sclArray = [this.contractInfoList.seller]
          }
          this.yfOrgIdList = sclArray
          this.sclArr = await this.searchContactsListFn(sclArray)
          console.log(this.sclArr)
          if (this.sclArr&&this.sclArr.length>0) {
            this.yfLength = this.sclArr.length
          } else {
            this.yfLength = 0
          }
          if (this.userList&&this.userList.length>0) {
            this.wxfLength = this.userList.length
          } else {
            this.wxfLength = 0
          }
          console.log(this.sclArr)
          this.$forceUpdate()
        }
      }
    },
    modifyPeople() {
      console.log('editProject!', this.form)
      this.form.productList = []
      this.form.productListArr.forEach((item,index)=> {
        let object = {}
        object.productId = item.productId
        object.productStatus = item.productStatus
        object.batchFlag = item.batchFlag?1:0
        this.form.productList.push(object)
      })
      this.form.purchaserContactsList = []
      this.form.purchaserContactsListArr.forEach((item,index)=> {
        if (item.id) {
          this.form.purchaserContactsList.push(item.id)
        }
      })
      this.form.sellerContactsList = []
      this.form.sellerContactsListArr.forEach((item,index)=> {
        if (item.id) {
          this.form.sellerContactsList.push(item.id)
        }
      })
      // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
      if (this.contractInfoList.sellerType !== this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 经销
        this.form.sysUserContactsList = []
        this.form.sysUserContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.sysUserContactsList.push(item.id)
          }
        })
      }
      console.log(this.form)
      let obj = {}
      obj.id = this.form.id
      obj.purchaserContactsList = this.form.purchaserContactsList
      obj.sellerContactsList = this.form.sellerContactsList
      obj.sysUserContactsList = this.form.sysUserContactsList
      console.log(obj)
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          flag = true
          let res = await editProjectContract(obj)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      return flag
    },
    // 编辑重置
    async editReset() {
      this.contractSellerTypeDict = this.getDict(this.dictConst.CONTRACT_SELLER_TYPE)
      this.getAllUser()
      this.reset()
      // if(this.institutionId) {
      //   await this.projectListApiFn(this.institutionId)
      // }
      if (this.projectId) {
        await this.projectInfoFn(this.projectId)
      }
    },
    onSubmit() {
      console.log('submit!', this.form)
      this.form.productList = []
      this.form.productListArr.forEach((item,index)=> {
        let object = {}
        object.productId = item.productId
        object.productStatus = item.productStatus
        object.batchFlag = item.batchFlag?1:0
        this.form.productList.push(object)
      })
      this.form.purchaserContactsList = []
      this.form.purchaserContactsListArr.forEach((item,index)=> {
        if (item.id) {
          this.form.purchaserContactsList.push(item.id)
        }
      })
      this.form.sellerContactsList = []
      this.form.sellerContactsListArr.forEach((item,index)=> {
        if (item.id) {
          this.form.sellerContactsList.push(item.id)
        }
      })
      // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
      if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 经销
        this.form.sysUserContactsList = []
        this.form.sysUserContactsListArr.forEach((item,index)=> {
          if (item.id) {
            this.form.sysUserContactsList.push(item.id)
          }
        })
      }
      let flag = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          flag = true
          let res = await addProjectApi(this.form)
          if (res.code == 200) {
            this.msgSuccess()
            this.reset()
          } else {
            flag = false
            this.msgError(res.msg)
            return false
          }
        } else {
          this.msgError('请规范填写')
          flag = false
        }
      });
      return flag
    },
    /** 表单重置 */
    reset() {
      this.form = {
        contractId: '',
        filingTime: '', // 建项日期
        contractClassify: 0, // 合同分类
        contractType: 0, // 合同类型
        serverType: 0,
        purchaserContactsListArr: [
          {
            id: '',
            pclObject:  {}
          }
        ],
        sellerContactsListArr:  [
          {
            id: '',
            sclObject:  {}
          }
        ],
        sysUserContactsListArr:  [
          {
            id: '',
            sclObject:  {}
          }
        ],
        projectClassify: 0, // 机构类型
        seller: 0,
        sellerName: '',
        productListArr: [],
        productList: [],
        purchaserContactsList: [],
        sellerContactsList: [],
        sysUserContactsList: []
      }
      this.resetForm('form')
      this.contractInfoList = {} // 合同详情信息
    },
    contractClassifySelect(val) {
      this.form.contractClassify = val
    },
    contractTypeSelect(val) {
      this.form.contractType = val
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    },
    async remoteWXFMethod(query) {
      await this.getAllUser(query)
    },
    //获取用户列表
    getAllUser(query = '') {
      const obj = {searchValue: query}
      allUserListApi(obj).then(res => {
        this.userList = res.data
      })
    },
    // 查询全部合同列表
    remoteMethod(query) {
      console.log(query)
      if (query !== '') {
        this.loading = true;
        setTimeout(async () => {
          let obj = {}
          obj.orgType = 2
          obj.searchValue = query
          let res = await queryContractList(obj)
          console.log(res)
          if(res.code === 200) {
            this.loading = false
            console.log(res.data)
            this.contractDict = res.data
          }
        }, 200);
      } else {
        this.contractDict = [];
      }
    },
    contractInfoChange(val) {
      console.log(val)
      if(val)  {
        this.contractInfoFn(val)
      }
    },
    // 获取合同详细信息
    async contractInfoFn(val) {
      let res = await contractInfo(val)
      console.log(res)
      if(res.code === 200) {
        this.loading = false
        console.log(res.data)
        this.contractInfoList = res.data
        this.$set(this.form, 'productListArr', res.data.productList)
        // this.form.productListArr = res.data.productList
        this.form.productListArr.forEach((item)=> {
          // 已转售后
          if (item.productStatus === this.dictDataConst.PRODUCT_STATUS_PROGRESS.PRODUCT_STATUS) {
            item.rangTime = [item.startTime, item.endTime]
          }
        })
        // 客户list
        let khArr = [this.contractInfoList.purchaser]
        if(this.contractInfoList.customerList.length>0) {
          this.contractInfoList.customerList.forEach((item) => {
            khArr.push(item.customerId)
          })
        }
        let khArray = Array.from(new Set(khArr)); // 将Set转换为数组并赋值给khArray
        console.log(khArray)
        this.khfOrgIdList = khArray
        this.pclArr = await this.searchContactsListFn(khArray)
        if (this.pclArr&&this.pclArr.length>0) {
          this.khfLength = this.pclArr.length
        } else {
          this.khfLength = 0
        }
        console.log(this.pclArr)
        this.form.purchaserContactsListArr = []
        this.pclArr.forEach((item,index) =>{
          let obj = {
          }
          obj.id = item.id
          obj.pclObject = item
          this.form.purchaserContactsListArr.push(obj)
        })
        // 如果是经销，乙方等于通过id查询机构联系人列表；如果是自营，乙方等于卫心方，卫心方选项隐藏(查询全部用户列表)
        if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
          // await this.getAllUser()
          this.form.sellerContactsListArr = []
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          if (this.userList&&this.userList.length>0) {
            this.yfLength = this.userList.length
          } else {
            this.yfLength = 0
          }
        } else {
          let sclArray = []
          if (this.contractInfoList.distribution) {
            sclArray = [this.contractInfoList.seller,this.contractInfoList.distribution]
          } else {
            sclArray = [this.contractInfoList.seller]
          }
          this.yfOrgIdList = sclArray
          this.sclArr = await this.searchContactsListFn(sclArray)
          if (this.sclArr&&this.sclArr.length>0) {
            this.yfLength = this.sclArr.length
          } else {
            this.yfLength = 0
          }
          this.form.sellerContactsListArr = []
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          if (this.userList&&this.userList.length>0) {
            this.wxfLength = this.userList.length
          } else {
            this.wxfLength = 0
          }
          // 卫心方等于全部用户列表
          this.form.sysUserContactsListArr = []
          this.form.sysUserContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
        }

      }
    },
    purchaserContactsListChange(val, index) {
      console.log(val, index)
      if(val) {
        let pclObject = this.pclArr.find(item => item.id === val)
        console.log(pclObject)
        this.form.purchaserContactsListArr[index].pclObject = pclObject
      } else {
        this.form.purchaserContactsListArr[index].pclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加客户方
    addPurchaserContactsList() {
      if(this.form.purchaserContactsListArr.length<this.khfLength) {
        this.form.purchaserContactsListArr.push(
          {
            id: '',
            pclObject:  {}
          }
        )
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'warning',
          message: '操作失败,已添加全部联系人！',
          duration: '1000'
        })
        return false
      }
    },
    delPurchaserContactsList(index) {
      this.form.purchaserContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    sellerContactsListArrChange(val, index,arr) {
      console.log(val, index)
      if(val) {
        let sclObject = arr.find(item => item.id === val)
        console.log(sclObject)
        this.form.sellerContactsListArr[index].sclObject = sclObject
      } else {
        this.form.sellerContactsListArr[index].sclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加乙方
    addSellerContactsList() {
      if (this.contractInfoList.sellerType === this.dictDataConst.CONTRACT_SELLER_TYPE.SELL) { // 自营
        // 乙方list
        if(this.form.sellerContactsListArr.length<this.yfLength) {
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          this.$forceUpdate()
        } else {
          this.$message({
            type: 'warning',
            message: '操作失败,已添加全部联系人！',
            duration: '1000'
          })
          return false
        }
      } else {
        // 乙方list
        if(this.form.sellerContactsListArr.length<this.yfLength) {
          this.form.sellerContactsListArr.push(
            {
              id: '',
              sclObject:  {}
            }
          )
          this.$forceUpdate()
        } else {
          this.$message({
            type: 'warning',
            message: '操作失败,已添加全部联系人！',
            duration: '1000'
          })
          return false
        }
      }
    },
    delSellerContactsList(index) {
      this.form.sellerContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    // 卫心方
    sysUserContactsListArrChange(val, index,arr) {
      console.log(val, index)
      if(val) {
        let sclObject = arr.find(item => item.id === val)
        console.log(sclObject)
        this.form.sysUserContactsListArr[index].sclObject = sclObject
      } else {
        this.form.sysUserContactsListArr[index].sclObject =  {}
      }
      this.$forceUpdate()
    },
    // 添加卫心方
    addSysUserContactsList() {
      if(this.form.sysUserContactsListArr.length<this.wxfLength) {
        this.form.sysUserContactsListArr.push(
          {
            id: '',
            sclObject:  {}
          }
        )
        this.$forceUpdate()
      } else {
        this.$message({
          type: 'warning',
          message: '操作失败,已添加全部联系人！',
          duration: '1000'
        })
        return false
      }
    },
    delSysUserContactsList(index) {
      this.form.sysUserContactsListArr.splice(index,1)
      this.$forceUpdate()
    },
    // 机构联系人列表查询
    async searchContactsListFn(arr,query = '') {
      const obj = {
        searchValue: query,
        orgIdList: arr
      }
      let res = await searchContactsList(obj)
      console.log(res)
      return res.data
    },
    // 新增项目时修改产品状态
    productStatusChange(val,index) {
      console.log(val,index)
      let order = null
      this.projectStatusDict.forEach((item,i)=> {
        if(item.id === val) {
          order = i+1
        }
      })
      this.form.productListArr[index].projectStatusOrder = order
    }
  }
}
</script>

<style lang="scss"  scoped>
.NewConstructionPeople_wrap{
  height: 100%;
  padding: 5px 0 20px;
  ::v-deep.productClose{
    text-align: right!important;
    .iconfont{
      font-size: 12px!important;
      color: #F23232!important;
      line-height: 28px!important;
      cursor: pointer;
    }
  }
  .typeSpan{
    display: inline-block;
    padding: 0px 6px;
    border-radius: 20px;
    background: #EBEDFE;
    color: #7281ED;
    font-size: 12px;
    margin-right: 10px;
    line-height: 20px;
  }
  ::v-deep.tipClass{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .noDataWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .noData{
      width: 96px;
      height:102px;
      text-align: center;
      .noDataText{
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 16px;
      }
    }
  }
  .contract_product{
    margin-bottom: 0!important;
    line-height:25px!important;
    ::v-deep.el-form-item__content{
      line-height: 25px!important;
    }
  }
  .appreciationPackage{
    padding: 2px 4px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #DEDEDE;
    color: #5D5D5D;
    font-size: 14px;
    line-height: 18px;
    height: 22px;
    margin-right: 4px;
    margin-top: 3px;
    &:last-child{
      margin-right: 0;
    }
  }
  ::v-deep.appreciationPackageName{
    display: inline-block;
    margin-left:10px;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;    /* 超出部分隐藏 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  //.zzbSpan{
  //  display: inline-block;
  //  padding: 2px 4px;
  //  color: #5D5D5D;
  //  font-size: 14px;
  //  line-height: 18px;
  //  align-items: center;
  //  //gap: 4px;
  //  border-radius: 2px;
  //  border: 1px solid #DEDEDE;
  //}
  .projectNum{
    font-size: 15px;
    font-weight: 500;
    color: #5D5D5D;
    margin-left: 20px;
  }
  .projectTitle{
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 8px;
    margin-bottom: 20px;
    color: #3160BA;
    border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
  }
  .contactList{
    display: flex;
    flex-wrap: wrap;
  }
  .productWrap{
    background: #F8F8F8;
    border-radius: 4px 4px 4px 4px;
    margin:0 10px 10px 0;
    width: 280px;
    padding:6px 0;
    display: flex;
    .productItem{
      float: left;
      width: 260px;
      padding:0 10px;
      border-right: 1px solid #EAECFD;
      .el-row{
        margin-bottom: 4px;
      }
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
    }
    .delBtnWrap{
      padding:0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .productWrap01{
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #EFEFEF;
    margin:0 10px 10px 0;
    width: 280px;
    padding:6px 10px;
    display: flex;
    .productItem{
      float: left;
      width: 100%;
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
      .productSelect{
        //height: 28px;
        font-size: 14px;
        color: #5D5D5D;
        line-height: 28px;
        word-wrap: break-word;
      }
    }
  }
  .productWrap02{
    background: #f8f8f8;
    border-radius: 4px 4px 4px 4px;
    margin:0 10px 10px 0;
    width: 250px;
    padding:6px 10px;
    display: flex;
    float: left;
    .productItem{
      float: left;
      .batchClass{
        display: inline-block;
        height: 20px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 20px;
        margin-right: 4px;
      }
      .relatedProductName{
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #5D5D5D;
        line-height: 20px;
      }
      .relatedProductProjectState{
        margin: 4px 0;
      }
      .productTitle{
        height: 28px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 28px;
      }
      .productSelect{
        //height: 28px;
        font-size: 14px;
        color: #5D5D5D;
        line-height: 28px;
        word-wrap: break-word;
        display: flex;
      }
    }
    .projectUl{
      background: #EAECEF;
      border-radius: 4px;
      width:227px;
      overflow: hidden;
      li{
        float: left;
        width: 18px;
        height: 8px;
        background: #F1880D;
        margin-right: 1px;
        &:last-child{
          margin-right: 0px;
        }
      }
    }
  }
}
.creatInstitution{
  margin-left: 10px;
  font-size: 14px;
  color: #F1880D;
}
.shouyao{
  //margin-top: 9px;
}
.setBtn,.addBtn,.delBtn{
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f1f1f1;
  line-height: 16px;
  color: #999;
  font-size: 12px;
  text-align: center;
  margin: 8px 5px 0;
  cursor: pointer;
}
::v-deep.labelSet{
  .el-form-item__label{
    line-height: 20px!important;
  }
}
.closeBtn{
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #EBEBEB;
  line-height: 16px;
  color: #F23232;
  font-size: 8px;
  text-align: center;
  cursor: pointer;
}
/* switch按钮样式 */
::v-deep.batchSwitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
::v-deep.batchSwitch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
::v-deep.batchSwitch .el-switch__label--right span{
  width: 25px;
  margin-right: 28px;
  font-size: 12px!important;
}
/*关闭时文字位置设置*/
::v-deep.batchSwitch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
::v-deep.batchSwitch .el-switch__label--left span{
  width: 25px;
  margin-left: 16px;
  font-size: 12px !important;
  float: left;
  line-height: 20px;
}
/*显示文字*/
::v-deep.batchSwitch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
::v-deep.batchSwitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 48px !important;
  margin: 0;
}
</style>
