<template>
  <div class="newConstructionContract_wrap">
    <div class="editTaskWrap">
      <div class="editTaskLeft">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="left">
          <el-row>
            <el-col :span="12">
              <el-form-item label="任务标题" prop="contractClassify">
                <el-input v-model="form.contractName" style="width: 98%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务编号">
                <span>124</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="项目名称" prop="purchaser">
                <el-select v-model="form.purchaser" placeholder="请选择机构名称" style="width: 98%;">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="终端客户">
                <span>上海市嘉定区安亭医院</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="提出人" prop="purchaser">
                <div class="productWrap01">
                  <div class="productItem">
                    <el-row>
                      <el-col :span="5" class="productTitle">姓名</el-col>
                      <el-col :span="19" class="productSelect">
                        <el-select v-model="form.purchaser" placeholder="请选择机构名称" size="mini" style="width: 100%;">
                          <el-option label="区域一" value="shanghai"></el-option>
                          <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">部门</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>精神内科</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">职务</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>药师</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">电话1</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>152334538909</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">邮箱</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>123242@163.com</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">微信</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>zhangzen</span>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="5" class="productTitle">备注</el-col>
                      <el-col :span="19" class="productSelect">
                        <span>备注内容</span>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="labelSet">
              <el-form-item label="产品名称" prop="contractName">
                <el-select v-model="form.purchaser" placeholder="请选择机构名称" style="width: 98%;">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="增值包">
            <span
              v-for="(item,index) in ['基因检测增值包']"
              :key="index"
              class="normal_btn"
              @click="contractTypeSelect(index)">
              {{ item }}
            </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="labelSet">
              <el-form-item label="服务类型" prop="contractName">
                <el-select v-model="form.purchaser" placeholder="请选择机构名称" style="width: 98%;">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="指派给" prop="contractName">
                <el-select v-model="form.purchaser" placeholder="请选择机构名称" style="width: 98%;">
                  <el-option label="区域一" value="shanghai"></el-option>
                  <el-option label="区域二" value="beijing"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="问题类型" prop="purchaser">
            <el-select v-model="form.purchaser" placeholder="请选择购买方(甲方)" style="width: 99%;">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务描述" prop="orderTime" style="width:99%;">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="text"
              maxlength="500"
              show-word-limit
            >
            </el-input>
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-change="handleChange"
              style="margin-top: 10px;"
              :file-list="fileList">
              <el-button size="mini">添加附件</el-button>
              <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
            </el-upload>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="截止时间" prop="orderTime">
                <el-date-picker
                  v-model="form.orderTime"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%;">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="状态">
                <span>待处理</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="editTaskRight">
        <div class="historyRecord">历史记录</div>
        <div class="toDoDeal">
          <div class="lineWrap">
            <span class="line_circle"></span><span class="line_segment"></span>
          </div>
          <div class="toDoDealContent">
            <div class="statusType">待处理</div>
            <div class="timeStyle">2023-03-02 14:53:07</div>
            <div class="peopleToPeople">由<span>周芸</span>创建，指派给<span>张沪生</span></div>
          </div>
        </div>
        <div class="processed">
          <div class="lineWrap">
            <span class="line_circle"></span><span class="line_segment"></span>
          </div>
          <div class="toDoDealContent">
            <div class="statusType">已处理</div>
            <div class="timeStyle">2023-03-02 14:53:07</div>
            <div class="peopleToPeople">由<span>周芸</span>创建，指派给<span>张沪生</span></div>
            <div class="remark">
              这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的解决方案文字内容这里是填写的
            </div>
            <div class="attachment">
              <i class="iconfont">&#xe671;</i>
              <span class="editInput" style="margin-left: 4px;">附件名称附件附件名称附件名称附件名称名称附件名称.doc</span>
            </div>
          </div>
        </div>
        <div class="resolved">
          <div class="lineWrap">
            <span class="line_circle"></span><span class="line_segment"></span>
          </div>
          <div class="toDoDealContent">
            <div class="statusType">已解决</div>
            <div class="timeStyle">2023-03-02 14:53:07</div>
            <div class="peopleToPeople">由<span>周芸</span>创建，指派给<span>张沪生</span></div>
          </div>
        </div>
        <div class="TimeoutUnresolved">
          <div class="lineWrap">
            <span class="line_circle"></span><span class="line_segment"></span>
          </div>
          <div class="toDoDealContent">
            <div class="statusType">超时未解决</div>
            <div class="timeStyle">2023-03-02 14:53:07</div>
            <div class="peopleToPeople">由<span>周芸</span>创建，指派给<span>张沪生</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModifyTask" ,
  data() {
    return {
      propertyList: [
        '客户',
        '经销商'
      ],
      institutionHierarchyList: [
        '一级',
        '二级',
        '三级',
        '区域',
        '其他'
      ],
      projectClassifyList: [
        '机构项目',
        '区域项目'
      ],
      form: {
        contractClassify: 0, // 合同分类
        contractType: 0, // 合同类型
        serverType: 0,
        value1: '',
        remark: '',
        projectClassify: 0, // 机构类型
        purchaser: '',
        seller: 0,
        sellerName: '',
        orderTime: '',
        contractNum: '',
        contractName: '',
        contractPrice: '',
        productList: []
      },
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        value1: [
          { type: 'daterange', required: true, message: '请选择日期', trigger: 'change' }
        ],
        orderTime: [
          { type: 'data', required: true, message: '请选择日期', trigger: 'change' }
        ],
        purchaser: [
          { required: true, message: '请选择购买方(甲方)', trigger: 'change' }
        ],
        contractNum: [
          { required: true, message: '请填写合同编号', trigger: 'blur' }
        ],
        contractName: [
          { required: true, message: '请填写合同名称', trigger: 'blur' }
        ],
        contractPrice: [
          { required: true, message: '请填写合同金额', trigger: 'blur' }
        ],
        productList: [
          { required: true, message: '请添加产品', trigger: 'change' }
        ]
      },
      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }, {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }]
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    onSubmit() {
      console.log('submit!');
    },
    contractClassifySelect(val) {
      this.form.contractClassify = val
    },
    contractTypeSelect(val) {
      this.form.contractType = val
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    }
  }
}
</script>

<style lang="scss"  scoped>
  //.newConstructionInstitution_wrap{
  //  padding: 20px 0;
  //  .contactList{
  //    display: flex;
  //    flex-wrap: wrap;
  //  }
  //  .productWrap{
  //    background: #F8F8F8;
  //    border-radius: 4px 4px 4px 4px;
  //    margin:0 10px 10px 0;
  //    width: 280px;
  //    padding:6px 0;
  //    display: flex;
  //    .productItem{
  //      float: left;
  //      width: 260px;
  //      padding:0 10px;
  //      border-right: 1px solid #EAECFD;
  //      .el-row{
  //        margin-bottom: 4px;
  //      }
  //      .productTitle{
  //        height: 28px;
  //        font-size: 14px;
  //        color: #A8ABB2;
  //        line-height: 28px;
  //      }
  //    }
  //    .delBtnWrap{
  //      padding:0 10px;
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //    }
  //  }
  //  .productWrap01{
  //    background: #FFFFFF;
  //    border-radius: 4px 4px 4px 4px;
  //    border: 1px solid #EFEFEF;
  //    margin:0 10px 10px 0;
  //    width: 223px;
  //    padding:6px 10px;
  //    display: flex;
  //    .productItem{
  //      float: left;
  //      .productTitle{
  //        height: 28px;
  //        font-size: 14px;
  //        color: #A8ABB2;
  //        line-height: 28px;
  //      }
  //      .productSelect{
  //        height: 28px;
  //        font-size: 14px;
  //        color: #5D5D5D;
  //        line-height: 28px;
  //      }
  //    }
  //  }
  //}
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  .shouyao{
    //margin-top: 9px;
  }
  .setBtn,.addBtn,.delBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #f1f1f1;
    line-height: 16px;
    color: #999;
    font-size: 12px;
    text-align: center;
    margin: 8px 5px 0;
    cursor: pointer;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .closeBtn{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #EBEBEB;
    line-height: 16px;
    color: #F23232;
    font-size: 8px;
    text-align: center;
    cursor: pointer;
  }
  .newConstructionContract_wrap{
    padding: 20px 0;
    .editTaskWrap{
      display: flex;
    }
    .editTaskLeft{
      flex: 1;
    }
    .editTaskRight{
      width: 258px;
      padding: 10px;
      border: 1px solid #F0F0F0;
      .historyRecord{
        height: 18px;
        font-size: 14px;
        font-weight: 500;
        color: #3C3C3C;
        line-height: 18px;
        margin-bottom: 10px;
      }
      .toDoDeal{
        padding: 0 0 10px 0px;
        display: flex;
        .lineWrap{
          width: 9px;
          height: auto;
          //background: #ffba00;
          display: flex;
          flex-direction: column;
          .line_circle{
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #D8A113;
            border-radius:3px;
            margin-top: 8px;
          }
          .line_segment{
            margin-left: 2px;
            display: inline-block;
            width: 0px;
            flex: 1;
            border-left: 1px dashed #E6E6E6;
          }
        }
        .toDoDealContent{
          flex: 1;
          padding-left: 4px;
          .statusType{
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: #D8A113;
            line-height: 18px;
          }
          .timeStyle{
            color: #5D5D5D;
            font-size: 14px;
            line-height: 18px;
          }
          .peopleToPeople{
            color:#5D5D5D;
            font-size: 14px;
            line-height: 18px;
            span{
              color: #5D5D5D;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              padding: 0px 4px;
            }
          }
        }
      }
      .processed{
        padding: 0 0 10px 0px;
        display: flex;
        .lineWrap{
          width: 9px;
          height: auto;
          //background: #ffba00;
          display: flex;
          flex-direction: column;
          .line_circle{
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #3C56DF;
            border-radius:3px;
            margin-top: 8px;
          }
          .line_segment{
            margin-left: 2px;
            display: inline-block;
            width: 0px;
            flex: 1;
            border-left: 1px dashed #E6E6E6;
          }
        }
        .toDoDealContent{
          flex: 1;
          padding-left: 4px;
          .statusType{
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: #3C56DF;
            line-height: 18px;
          }
          .timeStyle{
            color: #5D5D5D;
            font-size: 14px;
            line-height: 18px;
          }
          .peopleToPeople{
            color:#5D5D5D;
            font-size: 14px;
            line-height: 18px;
            span{
              color: #5D5D5D;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              padding: 0px 4px;
            }
          }
          .remark{
            display: flex;
            padding: 10px;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            background: #F7F7F7;
            color: #5D5D5D;
            font-size: 14px;
            line-height: 18px;
          }
          .attachment{
            margin-top: 4px;
            padding: 2px 4px;
            .editInput,i{
              color: #686EC5;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      .TimeoutUnresolved{
        padding: 0 0 10px 0px;
        display: flex;
        .lineWrap{
          width: 9px;
          height: auto;
          //background: #ffba00;
          display: flex;
          flex-direction: column;
          .line_circle{
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #FF5757;
            border-radius:3px;
            margin-top: 8px;
          }
          .line_segment{
            margin-left: 2px;
            display: inline-block;
            width: 0px;
            flex: 1;
            border-left: 1px dashed #E6E6E6;
          }
        }
        .toDoDealContent{
          flex: 1;
          padding-left: 4px;
          .statusType{
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: #FF5757;
            line-height: 18px;
          }
          .timeStyle{
            color: #5D5D5D;
            font-size: 14px;
            line-height: 18px;
          }
          .peopleToPeople{
            color:#5D5D5D;
            font-size: 14px;
            line-height: 18px;
            span{
              color: #5D5D5D;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              padding: 0px 4px;
            }
          }
        }
      }
      .resolved{
        padding: 0 0 10px 0px;
        display: flex;
        .lineWrap{
          width: 9px;
          height: auto;
          //background: #ffba00;
          display: flex;
          flex-direction: column;
          .line_circle{
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #30C250;
            border-radius:3px;
            margin-top: 8px;
          }
          .line_segment{
            margin-left: 2px;
            display: inline-block;
            width: 0px;
            flex: 1;
            border-left: 1px dashed #E6E6E6;
          }
        }
        .toDoDealContent{
          flex: 1;
          padding-left: 4px;
          .statusType{
            height: 18px;
            font-size: 14px;
            font-weight: 500;
            color: #30C250;
            line-height: 18px;
          }
          .timeStyle{
            color: #5D5D5D;
            font-size: 14px;
            line-height: 18px;
          }
          .peopleToPeople{
            color:#5D5D5D;
            font-size: 14px;
            line-height: 18px;
            span{
              color: #5D5D5D;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              padding: 0px 4px;
            }
          }
        }
      }
    }
    .productWrap{
      .productItem{
        float: left;
        width: 260px;
        padding:6px 10px;
        height: 76px;
        background: #F8F8F8;
        border-radius: 4px 4px 4px 4px;
        margin-right: 10px;
        .productTitle{
          height: 28px;
          font-size: 14px;
          color: #A8ABB2;
          line-height: 28px;
        }
      }
    }
  }
  .editProductWrap{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .attachment{
      //margin-top: 4px;
      padding: 2px 4px;
      .editInput,i{
        color: #686EC5;
        font-size: 14px;
        line-height: 18px;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .editProductWrap_contact{
    margin-bottom: 20px;
  }
  .editLabel{
    color: #3C3C3C;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .editInput{
    color: #5D5D5D;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .orgListItem{
    color: #F1880D;
    cursor: pointer;
  }
  .productWrap01{
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #EFEFEF;
    margin:0 10px 10px 0;
    width: 223px;
    padding:6px 10px;
    display: flex;
    .productItem{
      float: left;
      width: 203px;
      el-row{
        margin-bottom: 4px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .contactName{
        color:#5D5D5D;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      .productTitle{
        height: 20px;
        font-size: 14px;
        color: #A8ABB2;
        line-height: 20px;
      }
      .productSelect{
        //height: 20px;
        font-size: 14px;
        color: #5D5D5D;
        line-height: 20px;
        .remarkStyle{
          width: 100%;
          display: inline-block;
          white-space: nowrap; /* 不换行 */
          overflow: hidden;    /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 显示省略号 */
          cursor: pointer;
        }
      }
    }
  }
</style>
