<template>
  <el-drawer
    :title="editDialogTitle"
    :visible.sync="drawer"
    :before-close="handleEditCommon"
    :modal="false"
    :with-header="false"
    :wrapperClosable="false"
    size="980px">
    <div class="drawer_common_wrap">
      <!-- 编辑产品title-->
      <el-row class="newBuildContractWrap">
        <el-col :span="12" style="display: flex">
          <span class="newConstructionContract">{{titleName}}</span>
<!--          <div class="newConstructionContractChange">-->
<!--            <i class="iconfont" style="font-size: 24px;color: #E1E7F5;margin-left: 10px;">&#xe676;</i>-->
<!--            <i class="iconfont" style="font-size: 24px;color: #B3BDD6;margin-left: 10px;">&#xe678;</i>-->
<!--          </div>-->
        </el-col>
<!--        <div v-if="!isHidden">-->
          <el-col :span="12" style="text-align: right;" v-if="!isEditShow">
            <el-button
              v-if="activeName == 5&&!isHidden && taskHandleAuth"
              class="saveBtn"
              plain
              size="mini"
              @click="toDealClick">去处理</el-button>
            <el-button
              v-if="isNeedEdit&&!isHidden && taskEditAuth"
              :disabled="(!addPermission||!editPermission||!delPermission)&& activeNum===0&&(Number(activeName)!==0||(!editBasePermission&&!editContactsPermission)||((editBasePermission||editContactsPermission)&&!editPermission))"
              plain
              size="mini"
              @click="onEdit">编辑</el-button>
            <el-button
              v-if="taskDelAuth"
              :disabled="!delPermission&&activeNum===0"
              class="delBtn" plain size="mini" @click="delectClick">删除</el-button>
          </el-col>
          <el-col :span="12" style="text-align: right;" v-else>
            <el-button
              v-if="!isHidden"
              :disabled="!editBasePermission&&!editContactsPermission&&activeNum===0"
              plain
              size="mini"
              @click="onReset()">重置</el-button>
            <el-button
              v-if="!isHidden"
              :disabled="!editBasePermission&&!editContactsPermission&&activeNum===0"
              class="saveBtn"
              plain size="mini"
              @click="onEditSubmit()">保存</el-button>
            <!-- isEditShow=false-->
          </el-col>
<!--        </div>-->
      </el-row>
      <div v-if="orgType">
        <el-tabs v-if="orgType==getDict(this.dictConst.ORG_TYPE)[0].id" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-if="activeNum==0" label="机构" name="0"></el-tab-pane>
          <el-tab-pane label="合同" name="1"></el-tab-pane>
          <el-tab-pane label="项目" name="2"></el-tab-pane>
          <el-tab-pane v-if="activeNum!==0" label="人员" name="3"></el-tab-pane>
<!--  <el-tab-pane label="产品" name="4"></el-tab-pane>-->
          <el-tab-pane v-if="activeNum==5" label="任务" name="5"></el-tab-pane>
        </el-tabs>
      </div>
      <div v-else>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-if="activeNum==0" label="机构" name="0"></el-tab-pane>
          <el-tab-pane label="合同" name="1"></el-tab-pane>
          <el-tab-pane label="项目" name="2"></el-tab-pane>
          <el-tab-pane v-if="activeNum!==0" label="人员" name="3"></el-tab-pane>
          <!-- <el-tab-pane label="产品" name="4"></el-tab-pane>-->
          <el-tab-pane v-if="activeNum==5" label="任务" name="5"></el-tab-pane>
        </el-tabs>
      </div>
      <div style="flex: 1;">
        <!-- 机构-->
        <edit-org
          :institutionId="orgId"
          v-if="activeName == 0 && !isEditShow">
        </edit-org>
        <!--编辑机构-->
        <!-- <new-construction-institution
          v-if="activeName == 0 && isEditShow"
          ref="editOrgRef"
          :edit-form="row"
          component-source="edit">
        </new-construction-institution> -->
        <edit-construction-institution
          v-if="activeName == 0 && isEditShow"
          ref="editOrgRef"
          :edit-form="row"
          component-source="edit">
        </edit-construction-institution>
        <!-- 合同-->
        <edit-contract
          :institutionId="orgId"
          :contractId="contractId"
          @getContractId="getContractId"
          @contractListLen="contractListLen"
          v-if="activeName == 1 && !isEditShow">
        </edit-contract>
        <new-construction-contract
          :institutionId="orgId"
          :contractId="contractId"
          :isNewConstruction="isNewConstruction"
          :currentContractId="currentContractId"
          :isCanEdit="isCanFinallEdit"
          ref="editContractRef"
          v-if="activeName == 1 && isEditShow">
        </new-construction-contract>
        <!-- 项目-->
        <edit-project
          :institutionId="orgId"
          :projectId="projectId"
          @getProjectId="getProjectId"
          @projectListLen="projectListLen"
          v-if="activeName == 2 && !isEditShow">
        </edit-project>
        <new-construction-project
          :institutionId="orgId"
          :projectId="projectId"
          :isNewConstruction="isNewConstruction"
          :currentProjectId="currentProjectId"
          :isProjectCorrelation="isCorrelation"
          ref="editProjectRef"
          v-if="activeName == 2 && isEditShow">
<!--          editPermission&&activeNum===0-->
        </new-construction-project>
        <!-- 人员-->
        <edit-people
          :projectId="projectId"
          v-if="activeName == 3 && !isEditShow">
        </edit-people>
        <modify-people
          :projectId="projectId"
          ref="modifyPeopleRef"
          v-if="activeName == 3 && isEditShow">
        </modify-people>
        <!-- 产品-->
<!--        <edit-common-product v-if="activeName == 4 && !isEditShow"></edit-common-product>-->
        <!--任务-->
<!--        <task-list v-if="activeName == 5 && !isEditShow"></task-list>-->
<!--        <edit-task v-if="activeName == 5 && !isEditShow"></edit-task>-->
<!--        <modify-task v-if="activeName == 5 && isEditShow"></modify-task>-->
        <!--查看任务-->
        <edit-task
          v-if="activeName == 5 && !isEditShow"
          :task-id="taskId"
          :type="1"
          :visible="false">
        </edit-task>
        <!--编辑任务-->
        <edit-task
          v-if="activeName == 5 && isEditShow"
          ref="editTaskRef"
          :task-id="taskId"
          :task-info="taskInfo"
          :type="3"
          :visible="editDrawer">
        </edit-task>
      </div>
    </div>
    <div class="closeBtn" @click="handleEditCommon">
      <i class="iconfont">&#xe677;</i>
    </div>
  </el-drawer>

</template>

<script>
import EditOrg from "@/modules/crm/components/EditOrg";
import EditConstructionInstitution  from '@/modules/crm/components/EditConstructionInstitution'
import NewConstructionInstitution from '@/modules/crm/components/NewConstructionInstitution'
import EditProject from "@/modules/crm/components/EditProject"
import NewConstructionProject from "@/modules/crm/components/NewConstructionProject";
import EditContract from "@/modules/crm/components/EditContract";
import NewConstructionContract from "@/modules/crm/components/NewConstructionContract";
import EditPeople from "@/modules/crm/components/EditPeople";
import modifyPeople from "@/modules/crm/components/modifyPeople";
import ModifyTask from "@/modules/crm/components/ModifyTask";
import EditTask from '@/modules/crm/view/crm/task/components/editTask'
import {
  delProjectApi,
  checkProjectRelTask
} from "@/modules/crm/api/crm/project";
import {
  delContractApi,
  checkContractRelProjectTask
} from '@/modules/crm/api/crm/contract'
import { delTaskApi } from '@/modules/crm/api/crm/task'
import {
  delOrgApi
} from '@/modules/crm/api/crm/org'
// import EditCommonProduct from "./EditCommonProduct";
// import TaskList from "./taskList";
export default {
  name: "EditCommon" ,
  props: {
    editDialogTitle:  '',
    drawer: { // 遮罩层开关
      type: Boolean,
      required: true
    },
    activeNum: { // 0代表从机构列表跳转编辑
      type: Number,
      required: true
    },
    orgId: {
      type: Number
    },
    contractId: {
      type: Number
    },
    projectId: {
      type: Number
    },
    titleName: {
      type: String,
      required: true
    },
    orgType: {
      type: Number
    },
    row: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isProjectCorrelation: {
      type: Boolean,
      default: false
    },
    taskId:{
      type: Number
    },
    taskInfo: {
      type: Object
    },
    taskEditAuth: {
      type: Boolean,
      default: true
    },
    taskDelAuth: {
      type: Boolean,
      default: true
    },
    taskHandleAuth: {
      type: Boolean,
      default: true
    },
    isCanEdit: {
      type: Boolean,
      default: false
    }
  },
  components:{
    ModifyTask,
    // TaskList,
    // EditCommonProduct,
    EditPeople,
    NewConstructionContract,
    EditContract,
    NewConstructionProject,
    EditProject,
    EditOrg,
    NewConstructionInstitution,
    EditConstructionInstitution,
    modifyPeople,
    EditTask
  },
  computed: {
    addPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.add)
    },
    editPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.edit)
    },
    delPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.list.del)
    },
    editBasePermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.edit.editBase)
    },
    editContactsPermission() {
      return this.hasPermissions(this.permissionsConst.crmOrg.edit.editContacts)
    }
  },
  data() {
    return {
      // 合同teb =》合同、项目、人员
      // 项目teb =》合同、项目、人员
      // 任务teb =》合同、项目、人员、任务
      // 机构teb =》机构、合同、项目
      activeName: this.activeNum.toString(),
      isEditShow: false, // 是否在编辑
      text: '',
      form: {
        contractClassify: 0, // 合同分类
        contractType: 0, // 合同类型
        serverType: 0,
        value1: '',
        remark: '',
        projectClassify: 0, // 机构类型
        purchaser: '',
        seller: 0,
        sellerName: '',
        orderTime: '',
        contractNum: '',
        contractName: '',
        contractPrice: '',
        productList: [],
      },
      rules: {
        contractClassify: [
          { required: true, message: '请选择合同分类', trigger: 'change' }
        ],
        contractType: [
          { required: true, message: '请选择合同类型', trigger: 'change' }
        ],
        projectClassify: [
          { required: true, message: '请选择项目分类', trigger: 'change' }
        ],
        value1: [
          { type: 'daterange', required: true, message: '请选择日期', trigger: 'change' }
        ],
        orderTime: [
          { type: 'data', required: true, message: '请选择日期', trigger: 'change' }
        ],
        purchaser: [
          { required: true, message: '请选择购买方(甲方)', trigger: 'change' }
        ],
        contractNum: [
          { required: true, message: '请填写合同编号', trigger: 'blur' }
        ],
        contractName: [
          { required: true, message: '请填写合同名称', trigger: 'blur' }
        ],
        contractPrice: [
          { required: true, message: '请填写合同金额', trigger: 'blur' }
        ],
        productList: [
          { required: true, message: '请添加产品', trigger: 'change' }
        ]
      },
      isNewConstruction: false, // 编辑状态下
      currentContractId: null,
      currentProjectId:null,
      isNeedEdit: true,
      editType: 3,
      editDrawer: true,
      isHidden:false,
      isCorrelation: false,
      isCanFinallEdit: false
    }
  },
  created() {
    this.isCanFinallEdit = this.isCanEdit
    console.log(this.activeName)
    console.log(this.isHidden)
    console.log(this.taskDelAuth)
    this.isEditShow = this.isEdit
    this.isCorrelation = this.isProjectCorrelation
  },
  methods: {
    getContractId(val) {
      this.currentContractId = val
    },
    contractListLen(val) {
      console.log(val)
      this.isNeedEdit = val
    },
    getProjectId(val) {
      console.log(val)
      this.currentProjectId = val
    },
    projectListLen(val) {
      console.log(val)
      this.isNeedEdit = val
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      console.log(tab.name)
      // console.log(this.activeNum)
      // console.log(this.activeNum !== tab.name)
      if(this.activeNum === Number(tab.name)&&this.isEdit) {
        this.isEditShow = true
      } else {
        this.isEditShow = false
      }
      if (tab.name == 0) {
        console.log(this.orgId)
        if (this.orgId === null) { // 机构
          this.isHidden = true
          this.isNeedEdit = false
        } else {
          this.isHidden = false
          this.isNeedEdit = true
        }
      } else if (tab.name == 1) {
        console.log(this.contractId)
        if(this.contractId === null) { // 合同
          this.isHidden = true
        } else {
          this.isHidden = false
        }
      } else if (tab.name == 2) { // 项目
        console.log(this.projectId)
        if(this.projectId === null) {
          this.isHidden = true
        } else {
          this.isHidden = false
        }
      } else if (tab.name == 3) { // 人员
        console.log(this.projectId)
        if(this.projectId === null) {
          this.isHidden = true
        } else {
          this.isHidden = false
        }
      }
    },
    onSubmit() {
      console.log('submit!');
    },
    contractClassifySelect(val) {
      this.form.contractClassify = val
    },
    contractTypeSelect(val) {
      this.form.contractType = val
    },
    projectClassifySelect(val) {
      this.form.projectClassify = val
    },
    // 关闭弹窗
    handleEditCommon(type) {
      this.$emit('handleEditCommon', type)
    },
    toDealClick() {
      this.$emit('dealTask',true)
    },
    async onEdit() {
      // this.isEditShow = true
      // this.$emit('update:isEdit', true)
      if (this.activeName == 1) { // 合同
        const res = await this.checkContractRelProjectTaskFn(this.contractId)
        const isCanEdit = res.data
        this.isEditShow = true
        this.isCanFinallEdit = isCanEdit
          // console.log(isCanEdit)

        // if (isCanEdit) {
        //   this.msgError(res.msg)
        //   return false
        // } else {
        // this.isEditShow = true
        // }
      } else if (this.activeName == 2) { // 项目
        this.isEditShow = true
        const isCorrelation = await this.checkProjectRelTaskFn(this.projectId)
        // console.log(isCorrelation)
        if (isCorrelation) {
          this.isCorrelation = true
        } else {
          this.isCorrelation = false
        }
      } else {
        this.isEditShow = true
      }
    },
    async onEditSubmit() {
      console.log(this.activeName)
      if (this.activeName == 1) { // 合同
        let flag = this.$refs['editContractRef'].editContract()
        console.log(flag)
        if (flag) {
          this.handleEditCommon()
        }
      } else if (this.activeName == 2) { // 项目
        let flag = this.$refs['editProjectRef'].editProject()
        console.log(flag)
        if(flag){
          this.handleEditCommon()
        }
      } else if (this.activeName == 3) { // 人员
        let flag = this.$refs['modifyPeopleRef'].modifyPeople()
        console.log(flag)
        if(flag){
          this.handleEditCommon()
        }
      } else if (Number(this.activeName) === 0) {
        console.log(this.$refs.editOrgRef)
        if (this.$refs.editOrgRef) {
          let flag = await this.$refs.editOrgRef.editOrgSave()
          console.log(flag)
          if (flag) {
            this.handleEditCommon('edit')
          }
        }
      } else if (this.activeName == 5) { // 任务
          let flag = this.$refs['editTaskRef'].onSubmit()
          console.log(flag)
          if(flag){
            this.handleEditCommon()
          }
      } else {
        this.isEditShow = false
        // this.$emit('update:isEdit', false)
      }

    },
    // 重置
    onReset() {
      console.log(this.activeName)
      if (this.activeName == 1) { // 合同
        let flag = this.$refs['editContractRef'].editReset()
        console.log(flag)
        // if (flag) {
        // }
      } else if (this.activeName == 2) { // 项目
        let flag = this.$refs['editProjectRef'].editReset()
        console.log(flag)
        // if(flag){
        // }
      } else if (this.activeName == 3) { // 人员
        let flag = this.$refs['modifyPeopleRef'].editReset()
        console.log(flag)
        // if(flag){
        // }
      }  else if (Number(this.activeName) === 0) { // 机构
        this.$refs['editOrgRef'] && this.$refs['editOrgRef'].editReset()
      } else if (this.activeName== 5) { // 任务
        let flag = this.$refs['editTaskRef'].reset()
        console.log(flag)
      } else {
        this.isEditShow = false
        // this.$emit('update:isEdit', false)
      }
    },
    // 删除
    async delectClick() {
      console.log(this.activeNum) // 编辑时点击删除对应的选择项
      console.log(this.activeName)
      if (this.activeNum == 0) { // 机构
        await this.orgDelete()
      } else if (this.activeNum == 1) { // 合同
        await this.contratDelete()
      } else if (this.activeNum == 2) { // 项目
        await this.projectDelete()
      } else if (this.activeNum == 3) { // 人员
        await this.projectDelete()
      } else if (this.activeNum == 5) { // 任务
        await this.taskDelete()
      } else {
        this.isEditShow = false
        // this.$emit('update:isEdit', false)
      }
    },
    contratDelete() {
      let that = this
      this.$confirm('确认删除该合同吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delContractApi(that.contractId)
      }).then(() => {
        that.msgSuccess('删除成功')
        that.eventBus.$emit(that.eventConst.contract.delSucceed)
      })
    },
    projectDelete() {
      let that = this
      this.$confirm('确认删除该项目吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delProjectApi(that.projectId)
      }).then(() => {
        that.msgSuccess('删除成功')
        that.eventBus.$emit(that.eventConst.project.delSucceed)
      })
    },
    /** 删除按钮操作 */
    taskDelete() {
      let that = this
      this.$confirm('是否确认删除该任务吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delTaskApi(that.taskId)
      }).then(() => {
        that.msgSuccess('删除成功')
        that.eventBus.$emit(that.eventConst.task.delSucceed)
      })
    },
    /** 删除按钮操作 */
    orgDelete() {
      let that = this
      this.$confirm('确认删除该机构吗?', '删除确认', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        confirmButtonClass: 'confirmButton',
        customClass: 'delMessage'
      }).then(function() {
        return delOrgApi(that.orgId)
      }).then(() => {
        that.msgSuccess('删除成功')
        that.eventBus.$emit(that.eventConst.org.delSucceed)
      })
    },
    async checkContractRelProjectTaskFn(contractId) {
      let res = await checkContractRelProjectTask(contractId)
      // console.log(res)
      if (res&&res.code === 200) {
        return res
      }
    },
    async checkProjectRelTaskFn(projectId) {
      let res = await checkProjectRelTask(projectId)
      // console.log(res)
      if (res&&res.code === 200) {
        return res.data
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
  .closeBtn{
    position:fixed;
    right: 980px;
    top: 50%;
    display: flex;
    height: 60px;
    padding: 20px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 10px 0px 0px 10px;
    background: #FFF;
    /* x 偏移量 | y 偏移量 | 阴影模糊半径 | 阴影扩散半径 | 阴影颜色 */
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.12) !important;
    cursor: pointer;
    i{
      color: #F09933;
      font-size: 20px;
    }
  }
  .creatInstitution{
    margin-left: 10px;
    font-size: 14px;
    color: #F1880D;
  }
  ::v-deep.labelSet{
    .el-form-item__label{
      line-height: 20px!important;
    }
  }
  .drawer_common_wrap{
    display: flex;
    flex-direction: column;
    // height: calc(100% - 20px);
    padding:10px 20px;
    //height: -webkit-fill-available; (谷歌浏览器生效，safari浏览器不生效)
    .newBuildContractWrap{
      padding:22px 0px 10px;
      //border-bottom: 1px dotted #F0F0F0; //点线边框（dashed 虚线边框）
      .newConstructionContract{
        font-size: 24px;
        font-weight: 600;
        color: #3C3C3C;
        line-height: 24px;
        width: 700px;
        display: -webkit-box; /* 将文本显示为块元素 */
        -webkit-line-clamp: 2; /* 设置最大显示行数为2 */
        -webkit-box-orient: vertical; /* 设置块元素内部文本垂直排列 */
        overflow: hidden; /* 当文本溢出块元素时进行隐藏 */
        text-overflow: ellipsis; /* 溢出部分显示省略号 */
        word-break: break-all;
      }
      .el-button--warning.is-plain{
        background: none;
      }
      .delBtn{
        border: 1px solid #F23232;
        color: #F23232;
        &:hover{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
        &:active{
          border: 1px solid #d32b2b;
          color: #d32b2b;
        }
        &:disabled{
          border: 1px solid #fab6b6;
          color: #fab6b6;
        }
      }
      .saveBtn{
        border: 1px solid #F1880D;
        color: #F1880D;
        &:hover{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
        &:active{
          border: 1px solid #d0760c;
          color: #d0760c;
        }
        &:disabled{
          border: 1px solid #f3d19e;
          color: #f3d19e;
        }
      }
    }
  }
  .appreciationPackage{
    padding: 2px 4px;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #DEDEDE;
    color: #5D5D5D;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
    &:last-child{
      margin-right: 0;
    }
  }
</style>
