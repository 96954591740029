var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drvc_wrap" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "el-row",
            { staticClass: "rrTimeWrap" },
            [
              _c("el-col", { staticClass: "rrTime", attrs: { span: 12 } }, [
                _c("b", [_vm._v("回访时间")]),
                _c(
                  "span",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "followUpTime" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "204px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            clearable: "",
                            size: "small",
                            type: "date",
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.form.followUpTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "followUpTime", $$v)
                            },
                            expression: "form.followUpTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12 } }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                margin: "10px",
                padding: "6px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "suggestMark" },
                [
                  _vm._v(" 产品满意评分 "),
                  _c(
                    "el-button",
                    {
                      staticClass: "crm_btn_style",
                      staticStyle: { "margin-left": "12px" },
                      attrs: { size: "mini" },
                      on: { click: _vm.addProductItem },
                    },
                    [_vm._v(" 添加 ")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.form.fpProductRatingList, function (item, index) {
                return _c("div", { key: item.id }, [
                  _c(
                    "div",
                    { staticClass: "suggestTitle" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "24px",
                                  display: "flex",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "productDel",
                                    on: {
                                      click: function ($event) {
                                        return _vm.productDelHandle(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "iconfont" }, [
                                      _vm._v(""),
                                    ]),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(" " + _vm._s(index + 1) + ". "),
                                ]),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "260px" },
                                    attrs: {
                                      placeholder: "请选择产品名称",
                                      size: "mini",
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.choiceProduct(val, index)
                                      },
                                    },
                                    model: {
                                      value: item.productKey,
                                      callback: function ($$v) {
                                        _vm.$set(item, "productKey", $$v)
                                      },
                                      expression: "item.productKey",
                                    },
                                  },
                                  _vm._l(_vm.productList, function (i) {
                                    return _c("el-option", {
                                      key: i.productKey,
                                      attrs: {
                                        disabled: _vm.setDisabled(i, index),
                                        label: i.productName,
                                        value: i.productKey,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                item.extrasList && item.extrasList.length > 0
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-start",
                                          trigger: "hover",
                                          content:
                                            item.extrasList[0].extrasName,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "appreciationPackage appreciationPackageName",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.extrasList[0].extrasName
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      "popper-class": "projectUl_popover",
                                      placement: "bottom",
                                      width: "220",
                                      trigger: "click",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      item.extrasList.slice(1),
                                      function (i) {
                                        return _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#5D5D5D",
                                              "font-size": "14px",
                                              "line-height": "18px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(i.extrasName) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    item.extrasList.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "appreciationPackage lastNum",
                                            staticStyle: { cursor: "pointer" },
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              "+" +
                                                _vm._s(
                                                  item.extrasList.length - 1
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { span: 12 },
                            },
                            _vm._l(_vm.visitGradeDict, function (i) {
                              return _c(
                                "span",
                                {
                                  staticClass: "nomark",
                                  class: { mark: i.id == item.rating },
                                  on: {
                                    click: function ($event) {
                                      return _vm.ratingChange(i, item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(i.dictLabel) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "suggestContent" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "remark" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "99%" },
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入建议内容",
                              maxlength: "500",
                              "show-word-limit": "",
                            },
                            model: {
                              value: item.suggestion,
                              callback: function ($$v) {
                                _vm.$set(item, "suggestion", $$v)
                              },
                              expression: "item.suggestion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                margin: "10px",
                padding: "6px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "suggestMark" },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("span", [_vm._v("服务满意评分")]),
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 12 },
                        },
                        _vm._l(_vm.visitGradeDict, function (i) {
                          return _c(
                            "span",
                            {
                              staticClass: "nomark",
                              class: { mark: i.id == _vm.form.serviceRating },
                              on: {
                                click: function ($event) {
                                  return _vm.serviceRatingChange(i)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(i.dictLabel) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [_vm._v("建议")]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "serviceSuggestion" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "99%" },
                            attrs: {
                              type: "textarea",
                              rows: 4,
                              placeholder: "请输入建议内容",
                              maxlength: "500",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.serviceSuggestion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "serviceSuggestion", $$v)
                              },
                              expression: "form.serviceSuggestion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("客户回执证明"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.baseURL + "/crm/sys/file/uploadFile",
                            "on-change": _vm.handleFileChange,
                            "file-list": _vm.fileList,
                            data: _vm.uploadFileParams,
                            "auto-upload": true,
                            "before-upload": _vm.beforeUpload,
                            accept:
                              ".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx",
                            "on-success": _vm.uploadSuccess,
                            "on-remove": _vm.handleFileRemove,
                          },
                        },
                        [
                          _c("el-button", { attrs: { size: "mini" } }, [
                            _vm._v("添加附件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "本次回访类型", prop: "visitType" } },
                        _vm._l(_vm.returnVisitTypeDict, function (item, index) {
                          return _c(
                            "span",
                            {
                              key: item.id,
                              staticClass: "normal_btn",
                              class: {
                                select_btn: item.id == _vm.form.visitType,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.visitTypeSelect(item.id)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("后续无需服务"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 21 } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#d3d5d8",
                          "active-value": 1,
                          "inactive-value": 0,
                        },
                        on: { change: _vm.laterServiceStatusChange },
                        model: {
                          value: _vm.form.laterServiceStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "laterServiceStatus", $$v)
                          },
                          expression: "form.laterServiceStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.form.laterServiceStatus
                ? _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下次回访时间",
                                prop: "nextFollowUpTime",
                                "label-width": "106px",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "204px" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd",
                                  clearable: "",
                                  size: "small",
                                  type: "date",
                                  placeholder: "请选择日期",
                                  "picker-options": _vm.endPickerOptions,
                                },
                                model: {
                                  value: _vm.form.nextFollowUpTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "nextFollowUpTime", $$v)
                                  },
                                  expression: "form.nextFollowUpTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.form.laterServiceStatus
                ? _c(
                    "el-row",
                    { staticClass: "editProductWrap" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 21 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "下次回访类型",
                                prop: "nextVisitType",
                                "label-width": "106px",
                              },
                            },
                            _vm._l(
                              _vm.returnVisitTypeDict,
                              function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: item.id,
                                    staticClass: "normal_btn",
                                    class: {
                                      select_btn:
                                        item.id == _vm.form.nextVisitType,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextVisitTypeSelect(item.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "editProductWrap" },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("span", { staticClass: "editLabel" }, [
                      _vm._v("回访人"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 21 } }, [
                    _c("span", { staticClass: "editInput" }, [
                      _vm._v(_vm._s(_vm.form.followUpBy)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "rrTimeWrap" },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { float: "right", "padding-bottom": "10px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.editCancel },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "crm_btn_style",
                        attrs: { size: "mini" },
                        on: { click: _vm.saveFpSatisSurvey },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }