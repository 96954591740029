var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indexWrap" }, [
      _c("div", { staticClass: "indexImage" }, [
        _c("img", {
          staticClass: "indexImageContent",
          attrs: { src: require("../assets/images/crmIndex.png") },
        }),
      ]),
      _c("div", { staticClass: "indexFont" }, [
        _vm._v(" 欢迎进入 "),
        _c("span", [_vm._v("[卫心CRM系统]")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }