var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "来源配置",
        width: "540px",
        visible: _vm.sourceDialogVisible,
        "before-close": _vm.cancelBtn,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.sourceDialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "draggable",
            {
              attrs: { "ghost-class": "ghost" },
              model: {
                value: _vm.sourceArr,
                callback: function ($$v) {
                  _vm.sourceArr = $$v
                },
                expression: "sourceArr",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.sourceArr, function (item, index) {
                  return _c(
                    "el-row",
                    { key: item.key, staticClass: "sourceItem" },
                    [
                      _c(
                        "el-col",
                        { staticClass: "dragBtn", attrs: { span: 1 } },
                        [_c("i", { staticClass: "iconfont" }, [_vm._v("")])]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "sourceInput", attrs: { span: 21 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticClass: "sourceDel", attrs: { span: 2 } },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "iconfont",
                              on: {
                                click: function ($event) {
                                  return _vm.delSource(index)
                                },
                              },
                            },
                            [_vm._v("")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 1 } }, [_c("br")]),
              _c(
                "el-col",
                { attrs: { span: 23 } },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.addSource } },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-right": "20px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelBtn } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmBtn } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }