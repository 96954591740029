import request from '@/modules/crm/utils/request'

//用户:查询用户列表
export function userListApi(data) {
  return request({
    url: '/crm/sys/user/list',
    method: 'post',
    data:data
  })
}


//用户:用户详情
export function userInfoApi(userId) {
  return request({
    url: '/crm/sys/user/userInfo/' + userId,
    method: 'post'
  })
}



//用户:新增
export function addUserApi(data) {
  return request({
    url: '/crm/sys/user/addUser',
    method: 'post',
    data: data
  })
}

//用户:修改
export function editUserApi(data) {
  return request({
    url: '/crm/sys/user/editUser',
    method: 'post',
    data: data
  })
}


//用户:修改状态
export function changeUserStatusApi(userId, status) {
  return request({
    url: '/crm/sys/user/changeUserStatus',
    method: 'post',
    data: { id: userId, status }
  })
}


//用户:删除
export function delUserApi(userId) {
  return request({
    url: '/crm/sys/user/delUser/' + userId,
    method: 'post'
  })
}


//用户:查询注册用户列表
export function registerUserListApi(data) {
  return request({
    url: '/crm/sys/user/registerUserList',
    method: 'post',
    data:data
  })
}

//用户:注册审批
export function registerApprovalApi(data) {
  return request({
    url: '/crm/sys/user/registerApproval',
    method: 'post',
    data: data
  })
}


//用户:注册
export function registerUserApi(data) {
  return request({
    url: '/crm/sys/user/registerUser',
    method: 'post',
    data: data
  })
}


//用户:密码重置
export function resetPwdApi(userId) {
  return request({
    url: '/crm/sys/user/resetPwd/' + userId,
    method: 'post'
  })
}


//用户:更新用户资料
export function updateUserProfileApi(data) {
  return request({
    url: '/crm/sys/user/updateUserProfile',
    method: 'post',
    data: data
  })
}


//用户:更新密码
export function updateUserPwdApi(data) {
  return request({
    url: '/crm/sys/user/updateUserPwd',
    method: 'post',
    data: data
  })
}


//用户:查询全部用户列表
export function allUserListApi(data) {
  return request({
    url: '/crm/sys/user/allUserList',
    method: 'post',
    data
  })
}

//发送注册验证码
export function sendRegisterVerificationCode(data) {
  return request({
    url: '/crm/sys/user/sendRegisterVerificationCode',
    method: 'post',
    data: data
  })
}


