<template>

  <el-container class="layout-container login-container">
    <el-header class="login-header">
    </el-header>
    <el-main class="login-main clearfix">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form fr">
        <div style="display: flex; justify-content:center;">

        </div>
<!--        <h1 class="module-title" style="font-size: 26px;">crm</h1>-->
        <div class="login_title">卫心CRM</div>
<!--        用户名/-->
        <el-form-item label="手机号" prop="userName" style="margin-top:20px;">
          <el-input v-model="loginForm.userName" type="text" auto-complete="off" placeholder="账号">
            <i slot="prefix" class="el-icon-user-solid" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            show-password
            v-model="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin">
            <i slot="prefix" icon-class="password" class="el-icon-lock" />
          </el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            :class="submitBtn"
            @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>

<!--        <div>-->
<!--         -->
<!--        </div>-->
        <div class="registerImmediately">还没有账号？<el-button type="text" @click="toRegister">立即注册</el-button></div>
      </el-form>

    </el-main>
<!--    <el-footer class="login-footer">-->
<!--      <div>-->
<!--      <img src="../../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>-->
<!--    </div>-->
<!--      <div>-->
<!--        <img src="../../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>-->
<!--        <a class="baNum" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620"-->
<!--           style="display:inline-block;text-decoration:none;">-->
<!--          <span style="padding-left:5px;">沪公网安备 31010602006620号</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div>-->
<!--        <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>-->
<!--      </div>-->
<!--      <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>-->
<!--      <div>-->
<!--        <a href="/serviceAgreement.html" class="baNum" target="_blank">服务协议</a>-->
<!--      </div>-->
<!--      <div>-->
<!--        <a href="/privacyPolicy.html" class="baNum" target="_blank">隐私政策</a>-->
<!--      </div>-->
<!--    </el-footer>-->
  </el-container>
</template>

<script>
import { doLogin } from '@/modules/crm/api/login/index'
import { setToken, removeToken } from '@/utils/auth'
import{ mapActions } from 'vuex'
import { initDict } from '@/modules/crm/utils/dict'
export default {
  name: 'Login',
  data() {
    return {
      userName:"",
      password:"",
      loginForm: {
        password: '',
        userName: ''
      },
      loading: false,
      loginRules: {
        userName: [
          { required: true, trigger: "blur", message: "用户名不能为空" }
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" }
        ],
      },
    }
  },
  computed: {
    submitBtn() {
      const { userName, password } = this.loginForm
      if (userName.trim().length > 0 && password.trim().length > 0) {
        return 'submit-btn'
      } else {
        return 'submit-btn disabled'
      }
    }
  },
  created() {
    this.$store.dispatch('tagsView/delAllViews')
    removeToken()
  },
  methods: {
    ...mapActions('user', ['loginSuccessAction']),
    login(){
      let loginParams={
        username:this.loginForm.userName,
        password:this.loginForm.password
      }
      doLogin(loginParams).then(res=>{
        setToken(res.data.token)
        this.$router.push({ path: "/index" }).catch(()=>{})
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 进入首页
    onToHome() {
      this.$router.push('/home')
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          /* if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          } */
          /* this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
            this.getCode();
          }); */
          this.login()
        }
      });
    },
    //跳转注册页面
    toRegister(){
      this.$router.push('/register')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
  content: '';
  width: 0px;
  margin-right: 0px;
}
::v-deep.el-form-item__label{
  color: #3C3C3C;
  font-size: 14px;
  font-weight: 500;
}
.registerImmediately{
  position: absolute;
  bottom: -30px;
  //padding-top: 21px;
  width: 408px;
  text-align: center;
  height: 20px;
  font-size: 14px;
  color: #606266;
  line-height: 20px;
  .el-button{
    color: #F1880D!important;
  }
}
.login-container {
  //background: linear-gradient(159deg, #bdcdf6 0%, #e4f1f3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/loginBg.png");
  position: relative;
  background-size: 100% 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  //background-size: cover;
  //background-position: center center;
  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-cnter {
      height: 100%;
      position: relative;
    }
  }
  .login-main {
    margin-left: 60%;
    padding: 0px;
    .login-form {
      position: relative;
      padding:0px 38px 40px;
      width: 480px;
      height: 442px;
      background: #fff;
      margin: 160px 200px 0 0;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 8px 24px 0px rgba(184, 109, 3, 0.06);
      .login_title{
        color: #3C3C3C;
        font-size: 24px;
        font-weight: 600;
        line-height: 68px;
        text-align: center;
      }
      .submit-btn {
        width: 320px;
        height: 36px;
        border-radius: 20px;
        background: linear-gradient(90deg, #F0A433 0%, #EE870E 100%);
        box-shadow: 0px 4px 8px 0px rgba(240, 153, 51, 0.30);
        border: none;
        margin-left: 42px;
        &.disabled {
          background: linear-gradient(90deg, #F0A433 0%, #EE870E 100%);
          box-shadow: 0px 4px 8px 0px rgba(240, 153, 51, 0.30);
          opacity: 0.5;
          border: none;
        }
      }
    }
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    width: 100%;
    height: 40px;
    background: #f1f1f1;
    opacity: 0.8;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .login-footer {
    width: 100%;
    height: 40px!important;
    background: #f1f1f1;
    //height: 36px !important;
    //border-top: 0.5px solid #454b4b;
    //background: linear-gradient(159deg, #3D70F9 0%, #44E0FF 100%);
    color: #999999;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
    .line-dot-wrap {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 10px;
    }
    .line {
      display: inline-block;
      width: 80px;
      height: 1px;
      background-color: #f0f0f0;
    }
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #f0f0f0;
      display: inline-block;
    }
  }
}
</style>
