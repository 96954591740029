var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NewConstructionProject_wrap" },
    [
      !_vm.isNewConstruction && _vm.projectList.length > 0
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px", "margin-bottom": "10px" },
                      attrs: {
                        placeholder: "请选择项目编号",
                        size: "mini",
                        disabled: !_vm.isNewConstruction,
                      },
                      on: { change: _vm.projectChange },
                      model: {
                        value: _vm.projectValue,
                        callback: function ($$v) {
                          _vm.projectValue = $$v
                        },
                        expression: "projectValue",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.projectName, value: item.id },
                      })
                    }),
                    1
                  ),
                  _c("span", { staticClass: "projectNum" }, [
                    _vm._v(
                      "共" +
                        _vm._s(_vm.projectListLen ? _vm.projectListLen : 0) +
                        "个项目"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isNewConstruction && _vm.projectId == null && !_vm.projectValue
        ? _c("div", { staticClass: "noDataWrap" }, [_vm._m(0)])
        : _c(
            "div",
            [
              _c("div", { staticClass: "projectTitle" }, [_vm._v("合同信息")]),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "85px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "编号", prop: "contractId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    filterable: "",
                                    remote: "",
                                    "reserve-keyword": "",
                                    placeholder: "请选择编号",
                                    "remote-method": _vm.remoteMethod,
                                    size: "mini",
                                    disabled: _vm.isProjectCorrelation,
                                    loading: _vm.loading,
                                  },
                                  on: { change: _vm.contractInfoChange },
                                  model: {
                                    value: _vm.form.contractId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "contractId", $$v)
                                    },
                                    expression: "form.contractId",
                                  },
                                },
                                _vm._l(_vm.contractDict, function (item) {
                                  return _c("el-option", {
                                    key: item.contractId,
                                    attrs: {
                                      label: item.contractNum,
                                      value: item.contractId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "contractNameClass",
                              attrs: { label: "名称" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.contractInfoList.contractName)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "金额" } }, [
                            _vm.contractInfoList.contractPrice
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.contractInfoList.contractPrice) +
                                      "万元"
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "下单时间" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.contractInfoList.orderTime)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "项目分类" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.contractInfoList.projectClassifyName)
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "contractNameClass",
                              attrs: {
                                label: "购买方(甲方)",
                                "label-width": "120",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.contractInfoList.purchaserName)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "contractNameClass",
                              attrs: { label: "终端客户" },
                            },
                            _vm._l(
                              _vm.contractInfoList.customerList,
                              function (item, index) {
                                return _c("span", { key: item.id }, [
                                  _vm._v(" " + _vm._s(item.customerName) + " "),
                                  index !==
                                  _vm.contractInfoList.customerList.length - 1
                                    ? _c("span", [_vm._v("、")])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "contractNameClass",
                              attrs: { label: "乙方" },
                            },
                            [
                              _vm.contractInfoList.sellerTypeName
                                ? _c(
                                    "span",
                                    { staticClass: "appreciationPackage" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.contractInfoList.sellerTypeName
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.contractInfoList.sellerTypeName
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.contractInfoList.sellerName)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.contractInfoList.distribution
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "appreciationPackage",
                                      staticStyle: { margin: "0 6px 0 10px" },
                                    },
                                    [_vm._v("分销")]
                                  )
                                : _vm._e(),
                              _vm.contractInfoList.distribution
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.contractInfoList.distributionName
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "projectTitle" }, [
                    _vm._v("项目信息"),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "项目实施方" } },
                            _vm._l(_vm.ssfList, function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: item.id,
                                  staticClass: "normal_btn",
                                  class: {
                                    select_btn: item.id == _vm.form.implType,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.implTypeSelect(item.id)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上传附件",
                                prop: "taskFileIdList",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action:
                                      _vm.baseURL + "/crm/sys/file/uploadFile",
                                    "on-change": _vm.handleFileChange,
                                    "file-list": _vm.fileList,
                                    data: _vm.uploadFileParams,
                                    "auto-upload": true,
                                    "before-upload": _vm.beforeUpload,
                                    accept:
                                      ".bmp, .gif, .jpg, .jpeg, .png, .zip, .rar, .xls, .xlsx, .doc,. docx",
                                    "on-success": _vm.uploadSuccess,
                                    "on-remove": _vm.handleFileRemove,
                                  },
                                },
                                [
                                  _c("el-button", { attrs: { size: "mini" } }, [
                                    _vm._v("添加附件"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 4,
                                  placeholder: "请输入备注信息",
                                  maxlength: "500",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "projectTitle" }, [
                    _vm._v("人员信息"),
                  ]),
                  _c("el-form-item", { attrs: { label: "客户方" } }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.addPurchaserContactsList },
                          },
                          [_vm._v("添加")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "contactList" },
                    _vm._l(
                      _vm.form.purchaserContactsListArr,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "productWrap01" },
                          [
                            _c(
                              "div",
                              { staticClass: "productItem" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 21 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "contract_product",
                                            attrs: {
                                              prop:
                                                "purchaserContactsListArr[" +
                                                index +
                                                "].id",
                                              "show-message": false,
                                              "label-width": "40",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请先选择客户方",
                                                  size: "mini",
                                                  disabled:
                                                    !_vm.form.contractId,
                                                  filterable: "",
                                                  remote: "",
                                                  "reserve-keyword": "",
                                                  "remote-method":
                                                    _vm.remoteKHFMethod,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.purchaserContactsListChange(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.id,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "id", $$v)
                                                  },
                                                  expression: "item.id",
                                                },
                                              },
                                              _vm._l(_vm.pclArr, function (i) {
                                                return _c("el-option", {
                                                  key: i.id,
                                                  attrs: {
                                                    disabled: _vm.setDisabled(
                                                      i,
                                                      index,
                                                      0
                                                    ),
                                                    label: i.name,
                                                    value: i.id,
                                                  },
                                                })
                                              }),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productClose",
                                        attrs: { span: 3 },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "iconfont",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delPurchaserContactsList(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 6 },
                                      },
                                      [_vm._v("类型")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 18 },
                                      },
                                      [
                                        item.pclObject.orgId &&
                                        item.pclObject.orgId ===
                                          _vm.contractInfoList.purchaser
                                          ? _c(
                                              "span",
                                              { staticClass: "typeSpan" },
                                              [_vm._v("购买方")]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.contractInfoList.customerList,
                                          function (i) {
                                            return [
                                              item.pclObject.orgId ==
                                              i.customerId
                                                ? _c(
                                                    "span",
                                                    { staticClass: "typeSpan" },
                                                    [_vm._v(" 终端客户 ")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("部门")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.pclObject.departmentName
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("职务")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.pclObject.postName)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  item.pclObject.phoneList,
                                  function (phoneItem, phoneIndex) {
                                    return _c(
                                      "el-row",
                                      { key: phoneItem.id },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [
                                            _vm._v(
                                              "电话" +
                                                _vm._s(Number(phoneIndex + 1))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(phoneItem.phone)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.pclObject.email)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("微信")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.pclObject.wxAccount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "remarkStyle tipClass",
                                            attrs: {
                                              title: item.pclObject.remark,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.pclObject.remark) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _c("el-form-item", { attrs: { label: "乙方" } }, [
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.addSellerContactsList },
                          },
                          [_vm._v("添加")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "contactList" },
                    _vm._l(
                      _vm.form.sellerContactsListArr,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "productWrap01" },
                          [
                            _c(
                              "div",
                              { staticClass: "productItem" },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 21 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "contract_product",
                                            attrs: {
                                              prop:
                                                "sellerContactsListArr[" +
                                                index +
                                                "].id",
                                              "show-message": false,
                                              "label-width": "40",
                                            },
                                          },
                                          [
                                            _vm.contractInfoList.sellerType ===
                                            _vm.dictDataConst
                                              .CONTRACT_SELLER_TYPE.SELL
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请先选择乙方",
                                                      size: "mini",
                                                      disabled:
                                                        !_vm.form.contractId,
                                                      filterable: "",
                                                      remote: "",
                                                      "reserve-keyword": "",
                                                      "remote-method":
                                                        _vm.remoteWXFMethod,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.sellerContactsListArrChange(
                                                          $event,
                                                          index,
                                                          _vm.userList
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.userList,
                                                    function (i) {
                                                      return _c("el-option", {
                                                        key: i.id,
                                                        attrs: {
                                                          disabled:
                                                            _vm.setDisabled(
                                                              i,
                                                              index,
                                                              1
                                                            ),
                                                          label: i.nickName,
                                                          value: i.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请先选择乙方",
                                                      size: "mini",
                                                      disabled:
                                                        !_vm.form.contractId,
                                                      filterable: "",
                                                      remote: "",
                                                      "reserve-keyword": "",
                                                      "remote-method":
                                                        _vm.remoteYFMethod,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.sellerContactsListArrChange(
                                                          $event,
                                                          index,
                                                          _vm.sclArr
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.sclArr,
                                                    function (i) {
                                                      return _c("el-option", {
                                                        key: i.id,
                                                        attrs: {
                                                          disabled:
                                                            _vm.setDisabled(
                                                              i,
                                                              index,
                                                              2
                                                            ),
                                                          label: i.name,
                                                          value: i.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productClose",
                                        attrs: { span: 3 },
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass: "iconfont",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delSellerContactsList(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("类型")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        item.sclObject.orgId &&
                                        item.sclObject.orgId ===
                                          _vm.contractInfoList.seller
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "appreciationPackage",
                                              },
                                              [_vm._v("经销")]
                                            )
                                          : _vm._e(),
                                        item.sclObject.orgId &&
                                        item.sclObject.orgId ===
                                          _vm.contractInfoList.distribution
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "appreciationPackage",
                                              },
                                              [_vm._v("分销")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("部门")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.sclObject.departmentName
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("职务")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.sclObject.postName)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  item.sclObject.phoneList,
                                  function (phoneItem, phoneIndex) {
                                    return _c(
                                      "el-row",
                                      { key: phoneItem.id },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [
                                            _vm._v(
                                              "电话" +
                                                _vm._s(Number(phoneIndex + 1))
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(phoneItem.phone)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("邮箱")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.sclObject.email)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("微信")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.sclObject.wxAccount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productTitle",
                                        attrs: { span: 5 },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        staticClass: "productSelect",
                                        attrs: { span: 19 },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "remarkStyle tipClass",
                                            attrs: {
                                              title: item.sclObject.remark,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.sclObject.remark) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _vm.contractInfoList.sellerType !==
                  _vm.dictDataConst.CONTRACT_SELLER_TYPE.SELL
                    ? _c("el-form-item", { attrs: { label: "卫心方" } }, [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.addSysUserContactsList },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.contractInfoList.sellerType !==
                  _vm.dictDataConst.CONTRACT_SELLER_TYPE.SELL
                    ? _c(
                        "div",
                        { staticClass: "contactList" },
                        _vm._l(
                          _vm.form.sysUserContactsListArr,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "productWrap01" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "productItem" },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 21 },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "contract_product",
                                                attrs: {
                                                  prop:
                                                    "sysUserContactsListArr[" +
                                                    index +
                                                    "].id",
                                                  "show-message": false,
                                                  "label-width": "40",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请先选择卫宁方",
                                                      size: "mini",
                                                      disabled:
                                                        !_vm.form.contractId,
                                                      filterable: "",
                                                      remote: "",
                                                      "reserve-keyword": "",
                                                      "remote-method":
                                                        _vm.remoteWXFMethod,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.sysUserContactsListArrChange(
                                                          $event,
                                                          index,
                                                          _vm.userList
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.id",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.userList,
                                                    function (i) {
                                                      return _c("el-option", {
                                                        key: i.id,
                                                        attrs: {
                                                          disabled:
                                                            _vm.setDisabled(
                                                              i,
                                                              index,
                                                              3
                                                            ),
                                                          label: i.nickName,
                                                          value: i.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productClose",
                                            attrs: { span: 3 },
                                          },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "iconfont",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delSysUserContactsList(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("部门")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.sclObject.departmentName
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("职务")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.sclObject.postName)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("电话")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.sclObject.phone)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("邮箱")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.sclObject.email)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("微信")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.sclObject.wxAccount)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productTitle",
                                            attrs: { span: 5 },
                                          },
                                          [_vm._v("备注")]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass: "productSelect",
                                            attrs: { span: 19 },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "remarkStyle tipClass",
                                                attrs: {
                                                  title: item.sclObject.remark,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.sclObject.remark
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "projectTitle" }, [
                    _vm._v("产品信息"),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "labelSet productInformation",
                      staticStyle: { display: "flex", "flex-wrap": "wrap" },
                      attrs: {
                        label: "相关产品",
                        "label-width": "45",
                        required: "",
                      },
                    },
                    [
                      _vm.form.contractType ==
                      _vm.getDict(this.dictConst.CONTRACT_TYPE)[1].id
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.form.productListArr,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "productWrap02",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "productItem" },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                                attrs: { span: 18 },
                                              },
                                              [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "200",
                                                      trigger: "hover",
                                                      content: item.productName,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "relatedProductName",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.productName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                item.primaryFlag === 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          padding: "0",
                                                          float: "right",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeContactPrimary(
                                                              item,
                                                              0
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "icon svg-icon shouyao",
                                                            staticStyle: {
                                                              width: "38px",
                                                              height: "20px",
                                                            },
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "#icon-shouyao",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          padding: "0",
                                                          float: "right",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeContactPrimary(
                                                              item,
                                                              1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "icon svg-icon bushishouyao",
                                                            staticStyle: {
                                                              width: "38px",
                                                              height: "20px",
                                                            },
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "#icon-bushishouyao",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass:
                                                  "productSelectMoney",
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.productAmount
                                                        ? item.productAmount
                                                        : 0
                                                    ) + "万元"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "porductContent",
                                            staticStyle: {
                                              background: "#fff",
                                              padding: "0 4px",
                                              height: "250px",
                                            },
                                          },
                                          _vm._l(
                                            item.productStatusProgress,
                                            function (p, pIndex) {
                                              return _c(
                                                "el-row",
                                                {
                                                  key: pIndex,
                                                  staticClass:
                                                    "porductContentRow",
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        height: "20px",
                                                        "align-items": "center",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      item.primaryFlag === 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "oneKeySynchronization",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.oneKeySynchronizationClick(
                                                                      index,
                                                                      p,
                                                                      pIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "iconfont",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#F09933",
                                                                    "font-size":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [_vm._v("")]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      pIndex === 0 ||
                                                      p.startTime !== null ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].startTime !== null &&
                                                        pIndex < 1) ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].endTime !== null &&
                                                        pIndex === 1)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productTitle",
                                                              staticStyle: {
                                                                display: "flex",
                                                                "margin-left":
                                                                  "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  p.productStatusName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productTitle productTitleEmpty",
                                                              staticStyle: {
                                                                display: "flex",
                                                                "margin-left":
                                                                  "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  p.productStatusName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticClass:
                                                        "productSelect",
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      pIndex === 0 ||
                                                      p.startTime !== null ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].startTime !== null &&
                                                        pIndex < 1) ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].endTime !== null &&
                                                        pIndex === 1)
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-row",
                                                                [
                                                                  _c(
                                                                    "el-col",
                                                                    {
                                                                      attrs: {
                                                                        span: 11,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          key: "start",
                                                                          attrs:
                                                                            {
                                                                              prop:
                                                                                "productListArr." +
                                                                                index +
                                                                                ".productStatusProgress." +
                                                                                pIndex +
                                                                                ".startTime",
                                                                              rules:
                                                                                [
                                                                                  {
                                                                                    required: true,
                                                                                    validator:
                                                                                      _vm.fwValidateTime,
                                                                                    message:
                                                                                      "请输入时间",
                                                                                    trigger:
                                                                                      "change",
                                                                                    index:
                                                                                      index,
                                                                                    pIndex:
                                                                                      pIndex,
                                                                                    timeType:
                                                                                      "start",
                                                                                  },
                                                                                ],
                                                                              "show-message": false,
                                                                              "label-width":
                                                                                "0",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-date-picker",
                                                                            {
                                                                              staticClass:
                                                                                "dateChoice",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "90px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  type: "date",
                                                                                  size: "mini",
                                                                                  placeholder:
                                                                                    "请输入时间",
                                                                                  "value-format":
                                                                                    "yyyy-MM-dd",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.fwCheckTime(
                                                                                      $event,
                                                                                      "start",
                                                                                      index,
                                                                                      pIndex
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    p.startTime,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        p,
                                                                                        "startTime",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "p.startTime",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  p.productStatus ===
                                                                  _vm
                                                                    .dictDataConst
                                                                    .PRODUCT_STATUS_PROGRESS
                                                                    .PRODUCT_STATUS
                                                                    ? _c(
                                                                        "el-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              span: 2,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "至"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  p.productStatus ===
                                                                  _vm
                                                                    .dictDataConst
                                                                    .PRODUCT_STATUS_PROGRESS
                                                                    .PRODUCT_STATUS
                                                                    ? _c(
                                                                        "el-col",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "right",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              span: 11,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-form-item",
                                                                            {
                                                                              key: "start",
                                                                              attrs:
                                                                                {
                                                                                  prop:
                                                                                    "productListArr." +
                                                                                    index +
                                                                                    ".productStatusProgress." +
                                                                                    pIndex +
                                                                                    ".endTime",
                                                                                  rules:
                                                                                    [
                                                                                      {
                                                                                        required: true,
                                                                                        validator:
                                                                                          _vm.fwValidateTime,
                                                                                        message:
                                                                                          "请输入时间",
                                                                                        trigger:
                                                                                          "change",
                                                                                        index:
                                                                                          index,
                                                                                        pIndex:
                                                                                          pIndex,
                                                                                        timeType:
                                                                                          "end",
                                                                                      },
                                                                                    ],
                                                                                  "show-message": false,
                                                                                  "label-width":
                                                                                    "0",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-date-picker",
                                                                                {
                                                                                  staticClass:
                                                                                    "dateChoice",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "90px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "date",
                                                                                      size: "mini",
                                                                                      placeholder:
                                                                                        "请输入时间",
                                                                                      "value-format":
                                                                                        "yyyy-MM-dd",
                                                                                      "picker-options":
                                                                                        {
                                                                                          minTime:
                                                                                            p.startTime,
                                                                                        },
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.fwCheckTime(
                                                                                          $event,
                                                                                          "end",
                                                                                          index,
                                                                                          pIndex
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        p.endTime,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            p,
                                                                                            "endTime",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "p.endTime",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mustInputPrevious",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "请先输入上一个状态"
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { staticClass: "zzb_row" },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "productTitle",
                                                attrs: { span: 5 },
                                              },
                                              [_vm._v("增值包")]
                                            ),
                                            item.extrasList.length > 0
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    staticClass:
                                                      "productSelect zzb",
                                                    attrs: { span: 19 },
                                                  },
                                                  [
                                                    item.extrasList &&
                                                    item.extrasList.length > 0
                                                      ? _c(
                                                          "el-popover",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "top-start",
                                                              trigger: "hover",
                                                              content:
                                                                item
                                                                  .extrasList[0]
                                                                  .extrasName,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "appreciationPackage appreciationPackageName",
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .extrasList[0]
                                                                      .extrasName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          "popper-class":
                                                            "projectUl_popover",
                                                          placement: "bottom",
                                                          width: "220",
                                                          trigger: "click",
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.extrasList.slice(
                                                            1
                                                          ),
                                                          function (i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#5D5D5D",
                                                                  "font-size":
                                                                    "14px",
                                                                  "line-height":
                                                                    "18px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      i.extrasName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        item.extrasList.length >
                                                        1
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "appreciationPackage",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "+" +
                                                                    _vm._s(
                                                                      item
                                                                        .extrasList
                                                                        .length -
                                                                        1
                                                                    )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _c(
                            "div",
                            _vm._l(
                              _vm.form.productListArr,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "productWrap02",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "productItem" },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                                attrs: { span: 18 },
                                              },
                                              [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "top-start",
                                                      width: "200",
                                                      trigger: "hover",
                                                      content: item.productName,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "relatedProductName",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.productName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                item.primaryFlag === 1
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          padding: "0",
                                                          float: "right",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeContactPrimary(
                                                              item,
                                                              0
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "icon svg-icon shouyao",
                                                            staticStyle: {
                                                              width: "38px",
                                                              height: "20px",
                                                            },
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "#icon-shouyao",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          padding: "0",
                                                          float: "right",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeContactPrimary(
                                                              item,
                                                              1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "icon svg-icon bushishouyao",
                                                            staticStyle: {
                                                              width: "38px",
                                                              height: "20px",
                                                            },
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true",
                                                            },
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "#icon-bushishouyao",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass:
                                                  "productSelectMoney",
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                                attrs: { span: 6 },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.productAmount
                                                        ? item.productAmount
                                                        : 0
                                                    ) + "万元"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "porductContent",
                                            staticStyle: {
                                              background: "#fff",
                                              padding: "0 4px",
                                              height: "250px",
                                            },
                                          },
                                          _vm._l(
                                            item.productStatusProgress,
                                            function (p, pIndex) {
                                              return _c(
                                                "el-row",
                                                {
                                                  key: pIndex,
                                                  staticClass:
                                                    "porductContentRow",
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        height: "20px",
                                                        "align-items": "center",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      item.primaryFlag === 1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "oneKeySynchronization",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.oneKeySynchronizationClick(
                                                                      index,
                                                                      p,
                                                                      pIndex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "iconfont",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#F09933",
                                                                    "font-size":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [_vm._v("")]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      pIndex === 0 ||
                                                      p.startTime !== null ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].startTime !== null &&
                                                        pIndex < 8) ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].endTime !== null &&
                                                        pIndex === 8)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productTitle",
                                                              staticStyle: {
                                                                display: "flex",
                                                                "margin-left":
                                                                  "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  p.productStatusName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "productTitle productTitleEmpty",
                                                              staticStyle: {
                                                                display: "flex",
                                                                "margin-left":
                                                                  "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  p.productStatusName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticClass:
                                                        "productSelect",
                                                      attrs: { span: 18 },
                                                    },
                                                    [
                                                      pIndex === 0 ||
                                                      p.startTime !== null ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].startTime !== null &&
                                                        pIndex < 8) ||
                                                      (item
                                                        .productStatusProgress[
                                                        pIndex - 1
                                                      ].endTime !== null &&
                                                        pIndex === 8)
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-row",
                                                                [
                                                                  _c(
                                                                    "el-col",
                                                                    {
                                                                      attrs: {
                                                                        span: 11,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        {
                                                                          key: "start",
                                                                          attrs:
                                                                            {
                                                                              prop:
                                                                                "productListArr." +
                                                                                index +
                                                                                ".productStatusProgress." +
                                                                                pIndex +
                                                                                ".startTime",
                                                                              rules:
                                                                                [
                                                                                  {
                                                                                    required: true,
                                                                                    validator:
                                                                                      _vm.validateTime,
                                                                                    message:
                                                                                      "请输入时间",
                                                                                    trigger:
                                                                                      "change",
                                                                                    index:
                                                                                      index,
                                                                                    pIndex:
                                                                                      pIndex,
                                                                                    timeType:
                                                                                      "start",
                                                                                  },
                                                                                ],
                                                                              "show-message": false,
                                                                              "label-width":
                                                                                "0",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-date-picker",
                                                                            {
                                                                              staticClass:
                                                                                "dateChoice",
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "90px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  type: "date",
                                                                                  size: "mini",
                                                                                  placeholder:
                                                                                    "请输入时间",
                                                                                  "value-format":
                                                                                    "yyyy-MM-dd",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.checkTime(
                                                                                      $event,
                                                                                      "start",
                                                                                      index,
                                                                                      pIndex
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    p.startTime,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        p,
                                                                                        "startTime",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "p.startTime",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  p.productStatus ===
                                                                  _vm
                                                                    .dictDataConst
                                                                    .PRODUCT_STATUS_PROGRESS
                                                                    .PRODUCT_STATUS
                                                                    ? _c(
                                                                        "el-col",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              span: 2,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "至"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  p.productStatus ===
                                                                  _vm
                                                                    .dictDataConst
                                                                    .PRODUCT_STATUS_PROGRESS
                                                                    .PRODUCT_STATUS
                                                                    ? _c(
                                                                        "el-col",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "right",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              span: 11,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-form-item",
                                                                            {
                                                                              key: "start",
                                                                              attrs:
                                                                                {
                                                                                  prop:
                                                                                    "productListArr." +
                                                                                    index +
                                                                                    ".productStatusProgress." +
                                                                                    pIndex +
                                                                                    ".endTime",
                                                                                  rules:
                                                                                    [
                                                                                      {
                                                                                        required: true,
                                                                                        validator:
                                                                                          _vm.validateTime,
                                                                                        message:
                                                                                          "请输入时间",
                                                                                        trigger:
                                                                                          "change",
                                                                                        index:
                                                                                          index,
                                                                                        pIndex:
                                                                                          pIndex,
                                                                                        timeType:
                                                                                          "end",
                                                                                      },
                                                                                    ],
                                                                                  "show-message": false,
                                                                                  "label-width":
                                                                                    "0",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-date-picker",
                                                                                {
                                                                                  staticClass:
                                                                                    "dateChoice",
                                                                                  staticStyle:
                                                                                    {
                                                                                      width:
                                                                                        "90px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      type: "date",
                                                                                      size: "mini",
                                                                                      placeholder:
                                                                                        "请输入时间",
                                                                                      "value-format":
                                                                                        "yyyy-MM-dd",
                                                                                      "picker-options":
                                                                                        {
                                                                                          minTime:
                                                                                            p.startTime,
                                                                                        },
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.checkTime(
                                                                                          $event,
                                                                                          "end",
                                                                                          index,
                                                                                          pIndex
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        p.endTime,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            p,
                                                                                            "endTime",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "p.endTime",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mustInputPrevious",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "请先输入上一个状态"
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          { staticClass: "zzb_row" },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "productTitle",
                                                attrs: { span: 5 },
                                              },
                                              [_vm._v("增值包")]
                                            ),
                                            item.extrasList.length > 0
                                              ? _c(
                                                  "el-col",
                                                  {
                                                    staticClass:
                                                      "productSelect zzb",
                                                    attrs: { span: 19 },
                                                  },
                                                  [
                                                    item.extrasList &&
                                                    item.extrasList.length > 0
                                                      ? _c(
                                                          "el-popover",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "top-start",
                                                              trigger: "hover",
                                                              content:
                                                                item
                                                                  .extrasList[0]
                                                                  .extrasName,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "appreciationPackage appreciationPackageName",
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .extrasList[0]
                                                                      .extrasName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          "popper-class":
                                                            "projectUl_popover",
                                                          placement: "bottom",
                                                          width: "220",
                                                          trigger: "click",
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          item.extrasList.slice(
                                                            1
                                                          ),
                                                          function (i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#5D5D5D",
                                                                  "font-size":
                                                                    "14px",
                                                                  "line-height":
                                                                    "18px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      i.extrasName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        item.extrasList.length >
                                                        1
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "appreciationPackage",
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "+" +
                                                                    _vm._s(
                                                                      item
                                                                        .extrasList
                                                                        .length -
                                                                        1
                                                                    )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "noData" }, [
      _c("img", {
        staticStyle: { width: "96px", height: "60px" },
        attrs: { src: require("../assets/images/noData.png") },
      }),
      _c("div", { staticClass: "noDataText" }, [_vm._v("暂无数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }