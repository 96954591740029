var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-editor-wrap" },
    [
      _c("Toolbar", {
        staticStyle: { border: "1px solid #dcdcdc", "border-bottom": "0" },
        attrs: {
          editor: _vm.editor,
          defaultConfig: _vm.toolbarConfig,
          mode: _vm.mode,
        },
      }),
      _c("Editor", {
        style: _vm.editorStyle,
        attrs: { defaultConfig: _vm.editorConfig, mode: _vm.mode },
        on: {
          onCreated: _vm.onCreated,
          change: _vm.onChange,
          input: _vm.onInput,
        },
        model: {
          value: _vm.html,
          callback: function ($$v) {
            _vm.html = $$v
          },
          expression: "html",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }