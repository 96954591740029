<!--菜单权限维护-->
<template>
  <div class="app-container" style="overflow: auto">
    <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="" prop="searchValue">
        <el-input
          v-model="queryParams.searchValue"
          placeholder="请输入菜单名称/权限代码"
          style="width:400px;"
          clearable
          maxlength="30"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="" prop="status">
        <el-select v-model="queryParams.status" placeholder="请选择菜单状态" clearable size="small">
          <el-option
            v-for="dict in statusOptions"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button  icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
        <el-button class="crm_btn_style" icon="el-icon-circle-plus-outline" size="small" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="menuList"
      border
      stripe
      :header-cell-style="{'text-align':'center'}"

      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="menuName" label="菜单名称" align="left" min-width="160"></el-table-column>
      <el-table-column prop="id" label="菜单ID" width="100" align="center"></el-table-column>
      <el-table-column prop="perms" label="权限代码" min-width="120" align="center"></el-table-column>
      <el-table-column prop="menuType" label="类型" width="80" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.menuType=='M'?'目录':(scope.row.menuType=='C'?'菜单':'权限')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="component" label="组件路径" min-width="120"></el-table-column>
      <el-table-column prop="orderNum" label="排序" width="60" align="center"></el-table-column>
      <el-table-column prop="path" label="路由地址" align="left" min-width="160"></el-table-column>
      <el-table-column prop="status" label="状态"  width="60" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status==1">正常</el-tag>
          <el-tag type="info" v-if="scope.row.status==0">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" align="center" width="160"></el-table-column>
      <el-table-column label="操作" align="center" width="220" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini"
                     type="text"
                     style="color:#ed923a"
                     @click="handleUpdate(scope.row)"

          >修改
          </el-button>

          <el-button
            size="mini"
            type="text"
            style="color:#ed923a"
            @click="handleAdd(scope.row)"

          >新增
          </el-button>
          <el-button
            size="mini"
            type="text"
            style="color:#ff0009"
            @click="handleDelete(scope.row)"

          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改菜单对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级">
              <treeselect
                v-model="form.parentId"
                :options="menuOptions"
                :clearable="false"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="menuType">
              <el-radio-group v-model="form.menuType">
                <el-radio label="M">目录</el-radio>
                <el-radio label="C">菜单</el-radio>
                <el-radio label="F">权限</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="图标">
              <el-popover
                placement="bottom-start"
                width="460"
                trigger="click"
                @show="$refs['iconSelect'].reset()"
              >
                <IconSelect ref="iconSelect" @selected="selected"/>
                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                  <svg-icon
                    v-if="form.icon"
                    slot="prefix"
                    :icon-class="form.icon"
                    class="el-input__icon"
                    style="height: 32px;width: 16px;"
                  />
                  <i v-else slot="prefix" class="el-icon-search el-input__icon"/>
                </el-input>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="menuName">
              <el-input v-model="form.menuName" placeholder="请输入名称"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number v-model="form.orderNum" controls-position="right" :min="0"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="路由地址" prop="path">
              <el-input v-model="form.path" placeholder="请输入路由地址"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.menuType == 'C'">
            <el-form-item label="组件路径" prop="component">
              <el-input v-model="form.component" placeholder="请输入组件路径"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="form.menuType != 'F'" label="显示状态">
              <el-radio-group v-model="form.visible">
                <el-radio
                  v-for="dict in visibleOptions"
                  :key="dict.dictValue"
                  :label="dict.dictValue"
                >{{ dict.dictLabel }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dict in statusOptions"
                  :key="dict.dictValue"
                  :label="dict.dictValue"
                >{{ dict.dictLabel }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" style="background-color: #F1880D;border-color: #F1880D;">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {
  menuListApi, menuInfoApi, editMenuApi, delMenuApi
} from '@/modules/crm/api/system/menu'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import IconSelect from '@/components/IconSelect'

export default {
  name: 'sysMenu',
  components: { Treeselect, IconSelect },
  data() {
    return {
      // 遮罩层
      loading: true,

      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 显示状态数据字典
      visibleOptions: [],
      // 菜单状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        searchValue: undefined,
        status: undefined
      },

      // 表单参数
      form: {},
      // 表单校验
      rules: {
        menuName: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '路由地址不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
    this.visibleOptions = [{
      'dictLabel': '显示',
      'dictValue': '1'
    }, {
      'dictLabel': '隐藏',
      'dictValue': '0'
    }]
    this.statusOptions = [{
      'dictLabel': '正常',
      'dictValue': '1'
    }, {
      'dictLabel': '停用',
      'dictValue': '0'
    }]
  },
  methods: {
    /** 选择图标 */
    selected(name) {
      this.form.icon = name
    },
    /** 查询菜单列表 */
    getList() {
      this.loading = true
      menuListApi(this.queryParams).then(response => {
        this.menuList = this.handleTree(response.data, 'id')
        this.loading = false
      })
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }

      let isDisabled = false
      let editForm = this.form
      //编辑模式 且 节点id等于自己，禁止选择
      if (editForm && editForm.id && editForm.id == node.id) {
        isDisabled = true
      }

      return {
        id: node.id,
        label: node.menuName,
        children: node.children,
        isDisabled: isDisabled
      }
    },
    /** 查询菜单下拉树结构 */
    getTreeselect() {
      menuListApi({}).then(response => {
        this.menuOptions = []
        const menu = { id: 0, menuName: '主类目', children: [] }
        menu.children = this.handleTree(response.data, 'id')
        this.menuOptions.push(menu)
      })
    },
    // 显示状态字典翻译
    visibleFormat(row, column) {
      if (row.menuType == 'F') {
        return ''
      }
      return this.selectDictLabel(this.visibleOptions, row.visible)
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        parentId: 0,
        menuName: undefined,
        icon: undefined,
        menuType: 'M',
        orderNum: 1,
        visible: '1',
        status: '1'
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.getTreeselect()
      if (row != null && row.id) {
        this.form.parentId = row.id
      } else {
        this.form.parentId = 0
      }
      this.open = true
      this.title = '添加菜单'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      this.getTreeselect()
      menuInfoApi(row.id).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改菜单'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let res = await editMenuApi(this.form)
          this.msgSuccess()
          this.open = false
          this.getList()
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除菜单"' + row.menuName + '"?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return delMenuApi(row.id)
      }).then(() => {
        this.getList()
        this.msgSuccess('删除成功')
      })
    }

  }
}
</script>


